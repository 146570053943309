import React, { useEffect, useState } from 'react';

import classnames from 'classnames';

import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory,{Type}  from 'react-bootstrap-table2-editor';

import {
  // ButtonGroup,
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

// import DateRangePicker from 'react-bootstrap-daterangepicker';
// import 'bootstrap-daterangepicker/daterangepicker.css';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import moment from 'moment';

import { useToasts } from 'react-toast-notifications';

import api from '../../utils/api';

import { objectToFormData } from 'object-to-formdata';

const INITIAL_VALUES = {};

const mandays_columns = [  {
  dataField: 'product_name',
  text: 'Product Name',
  editable: false,
},
{
  dataField: 'year',
  text: 'Year',
  editable: false,
},
{
  dataField: 'mandays',
  text: 'Man Days',
  editable: false,
},
{
  dataField: 'unit_price',
  text: 'Unit Price',
  editable: false,
},
{
  dataField: 'total',
  text: 'Total',
  editable: false,
},
{
  dataField: 'include_sst',
  text: 'Include SST',
  editor: {
    type: Type.CHECKBOX,
    value: 'Y:N'
  }
},
{
  dataField: 'sst',
  text: 'SST 6%',
  editable: false,
},
{
  dataField: 'grand_total',
  text: 'Grand Total',
  editable: false,
  formatter: (cell, row, rowIdx) => {
      return parseFloat(row.grand_total);
  }
}
];

const Form = (props) => {
  let { id } = useParams();

  let history = useHistory();

  const { addToast } = useToasts();

  const [clients, setClient] = useState([]);

  const [data, setData] = useState(INITIAL_VALUES);

  const [load, reload] = useState(false);

  const [activeTab, setActiveTab] = useState('NewItem');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    const getClients = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/clients?limit=-1')
      .then(function (response) {
        // console.log(response.data);

        setClient(response.data.data);

        reload(false);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }

        reload(false);
      });
    }

    getClients();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/client-revenues/' + id)
        .then(function (response) {
          // console.log(response.data);
          setData(response.data);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error.response && error.response.data) {
            switch(error.response.status) {
              case 404:
                addToast(`Revenue #${id} does not exist`, { appearance: 'error' });
                history.push(props.path);
                break;

              default:
                addToast(error.response.data.message, { appearance: 'error' });
            }
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [load]);

  const deleteHandler = async (tab_idx, item_idx, item_id) => {
    if (window.confirm('Delete this record?')) {
      // let token = await localStorage.getItem('auth-token');

      // token = JSON.parse(token);

      // api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      // api.delete(`/client-revenues/${id}/items/${item_id}`)
      // .then(function (response) {
      //   // console.log(response);

      //   // setData(response.data.data);

      //   addToast('Revenue item successfully deleted', { appearance: 'success' });

      //   // history.push(`${path}`);

      //   history.push(props.path);

      //   reload(true);
      // })
      // .catch(function (error) {
      //   // console.log(error.response);
      //   addToast(error.response.data.message, { appearance: 'error' });
      // });

      let newData = JSON.parse(JSON.stringify(data));

      newData.client_revenue_items[tab_idx].items.splice(item_idx, 1);

      setData(newData);

      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      
      var formData = objectToFormData(newData.client_revenue_items[tab_idx], {indices: true, booleansAsIntegers: true});

      formData.append('_method', 'PUT');

      api({
        method: 'POST',
        url: `/client-revenues/${id}/items/${item_id}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })
      // api.post(`/client-revenues/${id}/items/${item.id}`, JSON.stringify(values), {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // })
      .then(function (response) {
        // console.log(response.data.data.id);

        addToast('Revenue item successfully updated', { appearance: 'success' });

        // history.push(`${props.path}/edit/${id}`);

        // window.location = '/client-application/iso';

        // if (response.data.data.id) {
        //   history.push(`${props.path.replace(/:type/, type)}/edit/${response.data.data.id}`);
        // }
      })
      .catch(function (error) {
        addToast(error.message, { appearance: 'error' });
      });
    }
  }

  return (
    <React.Fragment>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <Card>
            <div className="header">
              <h2>REVENUE</h2>
            </div>
            <div className="body">
            <Formik 
              enableReinitialize={true}
              initialValues={data}
              onSubmit={async (values, { setSubmitting }) => {
                let token = await localStorage.getItem('auth-token');

                token = JSON.parse(token);

                api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                api.put('/client-revenues/' + id, JSON.stringify(values), {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                })
                .then(function (response) {
                  // console.log(response);

                  addToast('Client revenue successfully updated', { appearance: 'success' });

                  history.push(props.path);

                  // window.location = '/client-application/iso';
                })
                .catch(function (error) {
                  addToast(error.message, { appearance: 'error' });
                });
              }}
            >
            {({ isSubmitting, values, setFieldValue, handleChange }) => (
            <FormikForm>
              <div className="row clearfix">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="ClientName">Client Name</Label>
                    <Input 
                      // readOnly={readOnly}
                      disabled={true}
                      type="select" 
                      className="custom-select" 
                      name="client_id" 
                      id="ClientName"
                      required
                      // onChange={handleChange}
                      value={values.client_id}
                    >
                      <option></option>
                      {clients && clients.map((client, i) => 
                        <option key={i} value={client.id}>{client.name}</option>
                      )}
                    </Input>
                  </FormGroup>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="originalDate">Original Date</Label>
                      {/* <DateRangePicker 
                        containerStyles={{ display: "block" }}
                        startDate={values.original_date_start && values.original_date_start.length ? moment(values.original_date_start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        endDate={values.original_date_end && values.original_date_end.length ? moment(values.original_date_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        onApply={(event, picker) => {
                          setFieldValue('original_date_start', picker.startDate.format('YYYY-MM-DD'));
                          setFieldValue('original_date_end', picker.endDate.format('YYYY-MM-DD'));
                        }}
                      > */}
                        <Input 
                          autoComplete="off"
                          // readOnly={readOnly}
                          disabled={true}
                          type="text" 
                          // className="custom-select" 
                          name="original_date" 
                          id="originalDate"
                          value={values.client && values.client.original_date ? moment(values.client.original_date).format('DD/MM/YYYY') : ""}
                          // required
                          // onChange={handleChange}
                          // value={values.original_date_start && values.original_date_end ? moment(values.original_date_start).format('DD/MM/YYYY') + ' - ' + moment(values.original_date_end).format('DD/MM/YYYY') : ""}
                        />
                    {/* </DateRangePicker> */}
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="LastAuditDate">Last Audit Date</Label>
                      {/* <DateRangePicker 
                        containerStyles={{ display: "block" }}
                        startDate={values.last_audit_date_start && values.last_audit_date_start.length ? moment(values.last_audit_date_start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        endDate={values.last_audit_date_end && values.last_audit_date_end.length ? moment(values.last_audit_date_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        onApply={(event, picker) => {
                          setFieldValue('last_audit_date_start', picker.startDate.format('YYYY-MM-DD'));
                          setFieldValue('last_audit_date_end', picker.endDate.format('YYYY-MM-DD'));
                        }}
                      > */}
                        <Input 
                          // readOnly={readOnly}
                          disabled={true}
                          type="text" 
                          // className="custom-select" 
                          name="last_audit_date" 
                          id="LastAuditDate"
                          value={values.client && values.client.last_audit_date ? moment(values.client.last_audit_date).format('DD/MM/YYYY') : ''}
                          // required
                          // onChange={handleChange}
                          // value={values.last_audit_date_start && values.last_audit_date_end ? moment(values.last_audit_date_start).format('DD/MM/YYYY') + ' - ' + moment(values.last_audit_date_end).format('DD/MM/YYYY') : ""}
                        />
                      {/* </DateRangePicker> */}
                  </FormGroup>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="">Mandays </Label> <span class="text-primary"><i>(**Click on Y/N at Include SST to edit)</i></span>

                    <BootstrapTable 
                      bootstrap4
                      // remote={false}
                      bordered={false}
                      classes="table-hover js-basic-example dataTable table-custom m-b-0"
                      headerClasses="thead-dark"
                      keyField='id'
                      // data={[]}
                      data={data.client && data.client.mandays_calculations && data.client.mandays_calculations ? data.client.mandays_calculations : []} 
                      columns={mandays_columns}
                      // pagination={ 
                      //   paginationFactory(pagingInfo)
                      // }
                      // onTableChange={handleTableChange}
                      cellEdit={ 
                        cellEditFactory({ 
                          mode: "click",
                          blurToSave: true,
                          
                          afterSaveCell: async (oldValue, newValue, row, column) => {
                            if (oldValue === null && newValue === "") return;

                            if (oldValue === newValue) return;

                            let token = await localStorage.getItem('auth-token');

                            token = JSON.parse(token);

                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                            values = {
                              [column.dataField]: newValue
                            }

                            api.put('/clients/' + row.client_id +  '/mandays-calculations/' + row.id, JSON.stringify(values), {
                              headers: {
                                'Content-Type': 'application/json',
                              },
                            })
                            .then(function (response) {
                              addToast('Row successfully updated', { appearance: 'success' });

                              reload(true);
                            })
                            .catch(function (error) {
                              addToast(error.message, { appearance: 'error' });
                            });
                          }
                        })
                      }
                    />
                  </FormGroup>
                </div>
              </div>

             {/*  <div className="row clearfix">
                <div className="col-sm-4">
                  <button type="submit" className="btn btn-primary">
                    {'Update'}
                  </button>&nbsp;
                </div>
              </div> */}
            </FormikForm>
          )}
          </Formik>

          <hr />

          <Label for="">Expenses</Label>

              <Nav tabs style={{ marginTop: "50px" }}>
                {data.client_revenue_items && data.client_revenue_items.length ?
                  data.client_revenue_items.map((item, i) => 
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === `Item${item.id}` })}
                        onClick={() => { toggle(`Item${item.id}`); }}
                      >
                        {item.month ? item.month.toString().padStart(2, '0') + '-' : null}{item.year ? item.year : item.id}
                      </NavLink>
                    </NavItem>
                  )
                : ""}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'NewItem' })}
                    onClick={() => { toggle('NewItem'); }}
                  >
                    <i className="icon-plus"></i> New Tab
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                {data.client_revenue_items && data.client_revenue_items.length ?
                  data.client_revenue_items.map((item, i) => 
                    <TabPane tabId={`Item${item.id}`}>
                      <Formik 
                        enableReinitialize={true}
                        initialValues={data.client_revenue_items && data.client_revenue_items.length ? data.client_revenue_items[i] : []}
                        onSubmit={async (values, { setSubmitting }) => {
                          let token = await localStorage.getItem('auth-token');

                          token = JSON.parse(token);

                          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                          
                          var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

                          data.append('_method', 'PUT');

                          api({
                            method: 'POST',
                            url: `/client-revenues/${id}/items/${item.id}`,
                            headers: {
                              'Content-Type': 'multipart/form-data',
                            },
                            data: data,
                          })
                          // api.post(`/client-revenues/${id}/items/${item.id}`, JSON.stringify(values), {
                          //   headers: {
                          //     'Content-Type': 'application/json',
                          //   },
                          // })
                          .then(function (response) {
                            // console.log(response.data.data.id);

                            addToast('Revenue item successfully updated', { appearance: 'success' });

                            history.push(`${props.path}/edit/${id}`);

                            // window.location = '/client-application/iso';

                            // if (response.data.data.id) {
                            //   history.push(`${props.path.replace(/:type/, type)}/edit/${response.data.data.id}`);
                            // }
                          })
                          .catch(function (error) {
                            addToast(error.message, { appearance: 'error' });
                          });
                        }}
                      >
                        {({ isSubmitting, values, setFieldValue, handleChange }) => (
                          <FormikForm>
                            <div className="row clearfix">
                              <div className="col-sm-4">
                                <FormGroup>
                                  <Label for="Month">Month</Label>

                                  <Input 
                                    // readOnly={readOnly}
                                    type="select" 
                                    className="custom-select" 
                                    name="month" 
                                    id="Month"
                                    required
                                    onChange={handleChange}
                                    value={values.month}
                                  >
                                    <option></option>
                                    {[
                                      {
                                        month: 1,
                                        name: 'January'
                                      },
                                      {
                                        month: 2,
                                        name: 'February'
                                      },
                                      {
                                        month: 3,
                                        name: 'March'
                                      },
                                      {
                                        month: 4,
                                        name: 'April'
                                      },
                                      {
                                        month: 5,
                                        name: 'May'
                                      },
                                      {
                                        month: 6,
                                        name: 'June'
                                      },
                                      {
                                        month: 7,
                                        name: 'July'
                                      },
                                      {
                                        month: 8,
                                        name: 'August'
                                      },
                                      {
                                        month: 9,
                                        name: 'September'
                                      },
                                      {
                                        month: 10,
                                        name: 'October'
                                      },
                                      {
                                        month: 11,
                                        name: 'November'
                                      },
                                      {
                                        month: 12,
                                        name: 'December'
                                      },
                                    ].map((month) => 
                                      <option value={month.month}>{month.name}</option>
                                    )}
                                  </Input>
                                </FormGroup>
                              </div>
                              
                              <div className="col-sm-4">
                                <FormGroup>
                                  <Label for="Year">Year</Label>

                                  <Input 
                                    // readOnly={readOnly}
                                    type="text" 
                                    // className="custom-select" 
                                    name="year" 
                                    id="Year"
                                    required
                                    onChange={handleChange}
                                    value={values && values.year ? values.year : ""}
                                  />
                                </FormGroup>
                              </div>

                              <div className="col-sm-4">
                                <FormGroup>
                                  <Label for="Type">Type</Label>

                                  <Input 
                                    // readOnly={readOnly}
                                    type="select" 
                                    className="custom-select" 
                                    name="type" 
                                    id="Type"
                                    // required
                                    onChange={handleChange}
                                    value={values && values.type ? values.type : ""}
                                  >
                                    <option></option>
                                    <option value="Stage 1">Stage 1</option>
                                    <option value="Stage 2">Stage 2</option>
                                    <option value="Surveillance">Surveillance</option>
                                    <option value="Re-Certification">Re-Certification</option>
                                  </Input>
                                </FormGroup>
                              </div>
                            </div>

                            <div className="table">
                              <table className="table mt-4">
                                <thead>
                                  <tr>
                                    <th>Item</th>
                                    <th>Unit</th>
                                    <th>Rate</th>
                                    <th>Total</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                {values.items && values.items.map((ITEM, j) => 
                                  <tr>
                                    <td>
                                      <Input 
                                        // readOnly={readOnly}
                                        type="text" 
                                        name={`items.${j}.item`}
                                        // onChange={handleChange}
                                        onChange={(event) => {
                                          setFieldValue(`items.${j}.item`, event.currentTarget.value);

                                          // console.log(values);
                                        }}
                                        value={values.items[j].item}
                                      />
                                    </td>
                                    <td>
                                      <Input 
                                        // readOnly={readOnly}
                                        type="integer" 
                                        step="any"
                                        name={`items.${j}.unit`}
                                        // onChange={handleChange}
                                        onChange={(event) => {
                                          setFieldValue(`items.${j}.unit`, event.currentTarget.value);

                                          // console.log(values);
                                        }}
                                        value={values.items[j].unit}
                                        min={1}
                                      />
                                    </td>
                                    <td>
                                      <Input 
                                        // readOnly={readOnly}
                                        type="integer" 
                                        step="any"
                                        name={`items.${j}.rate`}
                                        // onChange={handleChange}
                                        onChange={(event) => {
                                          setFieldValue(`items.${j}.rate`, event.currentTarget.value);

                                          // console.log(values);
                                        }}
                                        value={values.items[j].rate}
                                        min={1}
                                      />
                                    </td>
                                    <td>
                                      {/* <Input 
                                        // readOnly={readOnly}
                                        type="text" 
                                        name={`items.${i}.total`}
                                        // onChange={handleChange}
                                        onChange={(event) => {
                                          setFieldValue(`items.${i}.total`, event.currentTarget.value);

                                          // console.log(values);
                                        }}
                                        value={values.items[i].total}
                                      /> */}

                                      {parseFloat(values.items[j].unit) * parseFloat(values.items[j].rate)}
                                    </td>
                                    <td>
                                      <Button 
                                        // className="btn btn-outline-secondary"
                                        type="button"
                                        outline
                                        color="danger" 
                                        // tag={Link} 
                                        // to={props.path}
                                        onClick={(e) => {
                                          deleteHandler(i, j, item.id);
                                        }}
                                      >
                                        <i className="icon-trash"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                )}
                                  <tr>
                                    <td colspan={5}>
                                      <Button 
                                        block 
                                        outline
                                        onClick={(e) => {
                                          e.preventDefault();
    
                                          let { items } = values;
                                          if (!items) {
                                            items = [];
                                          }
                                          items.push({});
    
                                          // setData(data => {
                                          //   return {...data, branches: branches}
                                          // })
    
                                          // console.log(branches);
    
                                          setFieldValue('items', items);
    
                                          // console.log(values);
                                        }}
                                      >
                                        Add Row
                                      </Button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            {/* <BootstrapTable 
                              bootstrap4
                              remote={true}
                              bordered={false}
                              classes="table-hover js-basic-example dataTable table-custom m-b-0"
                              headerClasses="thead-dark"
                              keyField='id' 
                              data={[]} 
                              columns={[
                                {
                                  text: "Item",
                                  footer: () => {
                                    return (
                                      <Input 
                                        type="text"
                                        name="item"
                                        // onChange={handleChange}
                                        // value={values.number || ""}
                                      />
                                    )
                                  }
                                },
                                {
                                  text: "Unit",
                                },
                                {
                                  text: "Rate",
                                },
                                {
                                  text: "Total",
                                }
                              ]}
                              // pagination={ 
                              //   paginationFactory(pagingInfo)
                              // }
                              // onTableChange={handleTableChange}
                            /> */}

                            <div className="row clearfix">
                              <div className="col-sm-4">
                                {/* {readOnly === false ? 
                                <React.Fragment> */}
                                <button type="submit" className="btn btn-primary">
                                  {'Update Tab'}
                                </button>&nbsp;
                                {/* </React.Fragment> : null} */}
                                <Button 
                                  // className="btn btn-outline-secondary"
                                  outline
                                  color="secondary" 
                                  tag={Link} 
                                  to={props.path}
                                >
                                  Cancel
                                </Button>
                              </div>
                              <div className="col-sm-8" style={{ textAlign: "right" }}>
                                <Button 
                                  // className="btn btn-outline-secondary"
                                  type="button"
                                  outline
                                  color="danger" 
                                  // tag={Link} 
                                  // to={props.path}
                                  onClick={async () => {
                                    if (window.confirm('Delete tab?')) {
                                      let token = await localStorage.getItem('auth-token');

                                      token = JSON.parse(token);

                                      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                      // client-revenues/{clientRevenue}/items
                                      api.delete('/client-revenues/' + id + '/items/' + item.id)
                                      .then(function (response) {
                                        // console.log(response);

                                        // setData(response.data.data);

                                        addToast('Tab successfully deleted', { appearance: 'success' });

                                        // history.push(path);

                                        setActiveTab('NewItem');

                                        reload(true);
                                      })
                                      .catch(function (error) {
                                        // console.log(error.response);
                                        addToast(error.response.data.message, { appearance: 'error' });
                                      });
                                    }
                                  }}
                                >
                                  <i className="icon-trash"></i> Delete Tab
                                </Button>
                              </div>
                            </div>
                          </FormikForm>
                        )}
                      </Formik>
                    </TabPane>
                  )
                : ""}
                <TabPane tabId={'NewItem'}>
                  <Formik 
                    enableReinitialize={true}
                    initialValues={data}
                    onSubmit={async (values, { setSubmitting }) => {
                      let token = await localStorage.getItem('auth-token');

                      token = JSON.parse(token);

                      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                      delete values.id;
                      
                      var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

                      // data.append('_method', 'PUT');

                      api({
                        method: 'POST',
                        url: `/client-revenues/${id}/items`,
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                        data: data,
                      })
                      // api.post(`/client-revenues/${id}/items/${item.id}`, JSON.stringify(values), {
                      //   headers: {
                      //     'Content-Type': 'application/json',
                      //   },
                      // })
                      .then(function (response) {
                        // console.log(response.data.data.id);

                        addToast('Revenue item successfully created', { appearance: 'success' });

                        history.push(`${props.path}/edit/${id}`);

                        // window.location = '/client-application/iso';

                        // if (response.data.data.id) {
                        //   history.push(`${props.path.replace(/:type/, type)}/edit/${response.data.data.id}`);
                        // }

                        reload(true);
                      })
                      .catch(function (error) {
                        addToast(error.message, { appearance: 'error' });

                        reload(true);
                      });
                    }}
                  >
                    {({ isSubmitting, values, setFieldValue, handleChange }) => (
                      <FormikForm>
                        <div className="row clearfix">
                          <div className="col-sm-4">
                            <FormGroup>
                              <Label for="Month">Month</Label>

                              <Input 
                                // readOnly={readOnly}
                                type="select" 
                                className="custom-select" 
                                name="month" 
                                id="Month"
                                required
                                onChange={handleChange}
                                value={values.month}
                              >
                                <option></option>
                                {[
                                  {
                                    month: 1,
                                    name: 'January'
                                  },
                                  {
                                    month: 2,
                                    name: 'February'
                                  },
                                  {
                                    month: 3,
                                    name: 'March'
                                  },
                                  {
                                    month: 4,
                                    name: 'April'
                                  },
                                  {
                                    month: 5,
                                    name: 'May'
                                  },
                                  {
                                    month: 6,
                                    name: 'June'
                                  },
                                  {
                                    month: 7,
                                    name: 'July'
                                  },
                                  {
                                    month: 8,
                                    name: 'August'
                                  },
                                  {
                                    month: 9,
                                    name: 'September'
                                  },
                                  {
                                    month: 10,
                                    name: 'October'
                                  },
                                  {
                                    month: 11,
                                    name: 'November'
                                  },
                                  {
                                    month: 12,
                                    name: 'December'
                                  },
                                ].map((month) => 
                                  <option value={month.month}>{month.name}</option>
                                )}
                              </Input>
                            </FormGroup>
                          </div>

                          <div className="col-sm-4">
                            <FormGroup>
                              <Label for="Year">Year</Label>

                              <Input 
                                // readOnly={readOnly}
                                type="text" 
                                // className="custom-select" 
                                name="year" 
                                id="Year"
                                required
                                onChange={handleChange}
                                value={values.year}
                              />
                            </FormGroup>
                          </div>

                          <div className="col-sm-4">
                            <FormGroup>
                              <Label for="Type">Type</Label>

                              <Input 
                                // readOnly={readOnly}
                                type="select" 
                                className="custom-select" 
                                name="type" 
                                id="Type"
                                // required
                                onChange={handleChange}
                                value={values.type}
                              >
                                <option></option>
                                <option value="Stage 1">Stage 1</option>
                                <option value="Stage 2">Stage 2</option>
                                <option value="Surveillance">Surveillance</option>
                                <option value="Re-Certification">Re-Certification</option>
                              </Input>
                            </FormGroup>
                          </div>
                        </div>

                        <div className="table">
                          <table className="table mt-4">
                            <thead>
                              <tr>
                                <th>Item</th>
                                <th>Unit</th>
                                <th>Rate</th>
                                <th>Total</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.items && values.items.map((ITEM, i) => 
                                <tr>
                                  <td>
                                    <Input 
                                      // readOnly={readOnly}
                                      type="text" 
                                      name={`items.${i}.item`}
                                      // onChange={handleChange}
                                      onChange={(event) => {
                                        setFieldValue(`items.${i}.item`, event.currentTarget.value);

                                        // console.log(values);
                                      }}
                                      value={values.items[i].item}
                                    />
                                  </td>
                                  <td>
                                    <Input 
                                      // readOnly={readOnly}
                                      type="integer" 
                                      step="any"
                                      name={`items.${i}.unit`}
                                      // onChange={handleChange}
                                      onChange={(event) => {
                                        setFieldValue(`items.${i}.unit`, event.currentTarget.value);

                                        // console.log(event.currentTarget.validity.valid);
                                        // console.log(event.currentTarget.min);

                                        // if (event.currentTarget.validity.valid && event.currentTarget.min >= 1) {
                                        //   setFieldValue(`items.${i}.unit`, event.currentTarget.value);
                                        // } else {
                                        //   setFieldValue(`items.${i}.unit`, 0);
                                        // }

                                        // console.log(values);
                                      }}
                                      value={values.items[i].unit}
                                      min={1}
                                    />
                                  </td>
                                  <td>
                                    <Input 
                                      // readOnly={readOnly}
                                      type="integer" 
                                      step="any"
                                      name={`items.${i}.rate`}
                                      // onChange={handleChange}
                                      onChange={(event) => {
                                        setFieldValue(`items.${i}.rate`, event.currentTarget.value);

                                        // console.log(values);
                                      }}
                                      value={values.items[i].rate}
                                      min={1}
                                    />
                                  </td>
                                  <td>
                                    {/* <Input 
                                      // readOnly={readOnly}
                                      type="text" 
                                      name={`items.${i}.total`}
                                      // onChange={handleChange}
                                      onChange={(event) => {
                                        setFieldValue(`items.${i}.total`, event.currentTarget.value);

                                        // console.log(values);
                                      }}
                                      value={values.items[i].total}
                                    /> */}

                                    {parseFloat(values.items[i].unit) * parseFloat(values.items[i].rate)}
                                  </td>
                                  <td>
                                    <Button 
                                      // className="btn btn-outline-secondary"
                                      type="button"
                                      outline
                                      color="danger" 
                                      // tag={Link} 
                                      // to={props.path}
                                      onClick={(e) => {
                                        e.preventDefault();

                                        deleteHandler(values.items[i].id);
                                      }}
                                    >
                                      <i className="icon-trash"></i>
                                    </Button>
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td colspan={5}>
                                  <Button 
                                    block 
                                    outline
                                    onClick={(e) => {
                                      e.preventDefault();

                                      let { items } = values;
                                      if (!items) {
                                        items = [];
                                      }
                                      items.push({});

                                      // setData(data => {
                                      //   return {...data, branches: branches}
                                      // })

                                      // console.log(branches);

                                      setFieldValue('items', items);

                                      // console.log(values);
                                    }}
                                  >
                                    Add Row
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* <BootstrapTable 
                          bootstrap4
                          remote={true}
                          bordered={false}
                          classes="table-hover js-basic-example dataTable table-custom m-b-0"
                          headerClasses="thead-dark"
                          keyField='id' 
                          data={[]} 
                          columns={[
                            {
                              text: "Item",
                              footer: () => {
                                return (
                                  <Input 
                                    type="text"
                                    name="item"
                                    // onChange={handleChange}
                                    // value={values.number || ""}
                                  />
                                )
                              }
                            },
                            {
                              text: "Unit",
                            },
                            {
                              text: "Rate",
                            },
                            {
                              text: "Total",
                            }
                          ]}
                          // pagination={ 
                          //   paginationFactory(pagingInfo)
                          // }
                          // onTableChange={handleTableChange}
                        /> */}

                        <div className="row clearfix">
                          <div className="col-sm-4">
                            {/* {readOnly === false ? 
                            <React.Fragment> */}
                            <button type="submit" className="btn btn-primary">
                              {'Save Tab'}
                            </button>&nbsp;
                            {/* </React.Fragment> : null} */}
                            <Button 
                              // className="btn btn-outline-secondary"
                              outline
                              color="secondary" 
                              tag={Link} 
                              to={props.path}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </FormikForm>
                    )}
                  </Formik>
                </TabPane>
              </TabContent>

              {/* <div className="row clearfix">
                <div className="col-sm-4">
                  <button 
                    type="submit" 
                    className="btn btn-primary"
                    disabled={isSubmitting ? true : false}
                  >
                    Update
                  </button>&nbsp;

                  <Button 
                    // className="btn btn-outline-secondary"
                    outline
                    color="secondary" 
                    tag={Link} 
                    to={props.path}
                  >
                    Cancel
                  </Button>
                </div>
              </div> */}
            
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Form;