import React from 'react';

import {
  // Link,
  // Redirect,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import Calendar from './Calendar';

const Schedule = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        {/* <Redirect
          to={{
              pathname: `${path}/calendar`,
              // state: { from: props.location }
          }}
        /> */}
        <Calendar />
      </Route>
      {/* <Route path={`${path}/calendar`}>
        <Calendar />
      </Route> */}
    </Switch>
  )
}

export default Schedule;