import React, { useState, useEffect } from "react";

import {
  Link,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  // Nav,
  // NavItem,
  // NavLink,
  // TabContent,
  // TabPane,
} from "reactstrap";

import Select from 'react-select'

import { 
  Formik,
  Form as FormikForm
} from "formik";

import { useToasts } from 'react-toast-notifications';

import api from "../../../utils/api";

import moment from 'moment';
import SidebarToggler from "../../../components/SidebarToggler";
const Form = (props) => {
  let { id } = useParams();
  let { path } = useRouteMatch();
  let history = useHistory();

  const { addToast } = useToasts();

  const [data, setData] = useState({});

  const [auditors, setAuditor] = useState([{}]);

  const [USERS, setUser] = useState([{}]);

  const [CERTIFICATIONS, setCertifications] = useState({});

  let { readOnly, assign, approval, review }  = props;

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        // setProfile(response.data);

        // if (!readOnly && response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'operation_review.update') === "undefined") {
        //   history.push(props.path);
        // }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getAuditors = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
     api.get('/users?limit=-1&role[]=2&role[]=5')
       //api.get('/auditors?fields[]=id&fields[]=full_name&fields[]=business_mobile&fields[]=private_mobile&withUser=true&limit=-1')
      .then(function (response) {

        let auditors = [];
        response.data.data.map((auditor, i) => auditors.push({
          label: auditor.name,
          value: auditor.id,
         // contact_no: auditor.contact_no,
        }));

        setAuditor(auditors);

        // console.log(auditors);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getAuditors();

    const getUsers = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      
      api.get('/users?limit=-1&role[]=5')
      .then(function (response) {
        
        let USERS = [];
        response.data.data.map((user, i) => USERS.push({
          label: user.name,
          value: user.id
        }));
        setUser(USERS);console.log(USERS);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getUsers();

    const getCertifications = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/lookup/certifications')
      .then(function (response) {
        // console.log(response.data);

        setCertifications(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getCertifications();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/operation-reviews/' + id)
        .then(function (response) {
          delete response.data.data.id;

          if (response.data.data.audit.audit_plan.client.certifications) {
            let selectedCertifications = [];
            response.data.data.audit.audit_plan.client.certifications.map((certification, key) => {
              return selectedCertifications.push(certification.id);
            });

            response.data.data.audit.audit_plan.client.certifications = selectedCertifications;
          }

          setData(response.data.data);

          // reload(false);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data.data) {
            addToast(error.response.data.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        values.action ='send_for_review';

        await api.put('/operation-reviews/' + id, JSON.stringify(values), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          addToast('Operation review successfully updated', { appearance: 'success' });

          history.push(props.path + '?refresh=' + Date.now());
        })
        .catch(function (error) {
          addToast(error.message, { appearance: 'error' });
        });
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
      <div className="container-fluid">
    <div className="block-header">
      <div className="row">
        <div className="col-lg-10 col-md-9 col-sm-12">
          <h2>
            <SidebarToggler />
            Operation Review
          </h2>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">
                <i className="icon-home"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              Review
            </li>
            <li className="breadcrumb-item">
              <Link to={path}>
                Operation Review
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
        <FormikForm>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <Card>
                <div className="header">
                  <h2>Operation Review</h2>
                </div>
              
                <div className="body">
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="ClientName">Client Name</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="client_name" 
                          id="ClientName"
                          // onChange={handleChange}
                          value={values.audit && values.audit.audit_plan && values.audit.audit_plan.client ? values.audit.audit_plan.client.name : ''}
                          // required
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="AuditNo">Audit No.</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="audit_no" 
                          id="AuditNo"
                          // onChange={handleChange}
                          value={values.audit && values.audit.audit_plan && values.audit && values.audit.audit_plan.audit_no ? values.audit.audit_plan.audit_no : ''}
                          // required
                        />
                      </FormGroup>
                    </div>

                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="TypeOfAudit">Type Of Audit</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="type_of_audit" 
                          id="TypeOfAudit"
                          // onChange={handleChange}
                          value={values.audit && values.audit.audit_plan ? values.audit.audit_plan.stage : ''}
                          // required
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Scheme">Scheme</Label>
                        {CERTIFICATIONS.length ? CERTIFICATIONS.map((CERTIFICATION, key) => 
                        values.audit && values.audit.audit_plan && values.audit.audit_plan.client.certifications && values.audit.audit_plan.client.certifications.indexOf(CERTIFICATION.id) !== -1 ? 
                        <React.Fragment key={key}>
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                readOnly={true}
                                type="checkbox" 
                                disabled={true}
                                // name={`certifications`}
                                // onChange={handleChange}
                                // onChange={() => {
                                //   if (values.typeStr !== 'ISO') {
                                //     let { certifications } = values;

                                //     if (certifications && certifications.length) {
                                //       var index = certifications.indexOf(CERTIFICATION.id);
                                //       if (index !== -1) {
                                //         certifications.splice(index, 1);
                                //       } else {
                                //         certifications.push(CERTIFICATION.id);
                                //       }
                                //     } else {
                                //       certifications = [];
                                //       certifications.push(CERTIFICATION.id);
                                //     }

                                //     setFieldValue('certifications', certifications);
                                //   }
                                // }}
                                // value={CERTIFICATION.id}
                                checked={values.audit && values.audit.audit_plan && values.audit.audit_plan.client.certifications && values.audit.audit_plan.client.certifications.length && values.audit.audit_plan.client.certifications.indexOf(CERTIFICATION.id) !== -1} 
                              />
                              <span>
                                {CERTIFICATION.standard} 
                              </span>
                            </label>
                          </div>
                        </React.Fragment>
                         : null
                      ) : null}
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Name">To Be Reviewed By</Label>
                        <Input 
                            // readOnly={readOnly}
                            disabled={readOnly}
                            type="select" 
                            className="custom-select" 
                            name="operation_review_reviewer_id"
                            id=""
                            // onChange={(e) => {
                            //   // console.log(e.target.name, e.target.value);

                            //   setFieldValue(e.target.name, e.target.value);
                            //   // setFieldValue('reviewed_at', "");
                            // }}
                            onChange={handleChange}
                            value={values.operation_review_reviewer_id}
                            required={true}
                          >
                            <option></option>
                            {auditors && auditors.length ? auditors.map((auditor, i) => 
                              <option value={auditor.value}>{auditor.label}</option>
                            ) : ""}
                          </Input>
                        {/* <Input 
                          // readOnly={true}
                          type="text" 
                          name="technical_reviewer_name" 
                          id="Name"
                          // onChange={handleChange}
                          value={values.technical_reviewer_name}
                          // required
                        /> */}
                        {/* <Select
                          inputId="LeadAuditor"
                          name="lead_auditors"
                          // isMulti
                          placeholder=""
                          openMenuOnFocus={true}
                          options={auditors} 
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              color: '#495057',
                              backgroundColor: 'transparent', 
                              borderColor: '#ced4da !important', 
                              boxShadow: 'none',
                              outline: 'none',
                            })
                          }}
                          value={values.lead_auditors}
                          onChange={(selectedAuditors) => {
                            // console.log(auditors);

                            // let id = [];

                            // selectedAuditors.map((auditor, i) =>
                            //   id.push(auditor.value)
                            // );

                            setFieldValue('lead_auditors', selectedAuditors);
                          }}
                        /> */}
                        
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Name">To Be Approved By</Label>
                        <Input 
                              // readOnly={readOnly}
                              // disabled={true}
                              type="select" 
                              className="custom-select" 
                              name="operation_review_approver_id"
                              id="ToBeApprovedBy"
                              // onChange={(e) => {
                              //   // console.log(e.target.name, e.target.value);
    
                              //   setFieldValue(e.target.name, e.target.value);
                              //   // setFieldValue('reviewed_at', "");
                              // }}
                              onChange={handleChange}
                              value={values.operation_review_approver_id}
                              required={true}
                            >
                              <option></option>
                              {USERS && USERS.length ? USERS.map((USER, i) => 
                              <option value={USER.value}>{USER.label}</option>
                            ) : ""}
                            </Input>
                        {/* <Input 
                          // readOnly={true}
                          type="text" 
                          name="technical_reviewer_name" 
                          id="Name"
                          // onChange={handleChange}
                          value={values.technical_reviewer_name}
                          // required
                        /> */}
                        {/* <Select
                          inputId="LeadAuditor"
                          name="lead_auditors"
                          // isMulti
                          placeholder=""
                          openMenuOnFocus={true}
                          options={auditors} 
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              color: '#495057',
                              backgroundColor: 'transparent', 
                              borderColor: '#ced4da !important', 
                              boxShadow: 'none',
                              outline: 'none',
                            })
                          }}
                          value={values.lead_auditors}
                          onChange={(selectedAuditors) => {
                            // console.log(auditors);

                            // let id = [];

                            // selectedAuditors.map((auditor, i) =>
                            //   id.push(auditor.value)
                            // );

                            setFieldValue('lead_auditors', selectedAuditors);
                          }}
                        /> */}
                        
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-4">
                      <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                      </button>&nbsp;
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        tag={Link} 
                        to={props.path}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                
                </div>
              </Card>
            </div>
          </div>
        </FormikForm>
        </div>
      )}
    </Formik>
  )
}

export default Form;