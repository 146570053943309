import React, { useState, useEffect } from "react";

import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom";

import {
  Button,
  // Card,
  FormGroup,
  Input,
  Label,
  // Nav,
  // NavItem,
  // NavLink,
  // TabContent,
  // TabPane,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from "formik";

import DateRangePicker from 'react-bootstrap-daterangepicker';

import moment from 'moment';

import { useToasts } from 'react-toast-notifications';

import api from "../../utils/api";

import SidebarToggler from "../../components/SidebarToggler";

import { objectToFormData } from 'object-to-formdata';

const Form = (props) => {
  let history = useHistory();

  let { audit_report_id, id } = useParams();

  const { addToast } = useToasts();

  const [data, setData] = useState({});
  
  const [load, reload] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const deleteFile = async (evi) => {
    if (window.confirm('Delete file?')) {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      var data = objectToFormData(data, {indices: true, booleansAsIntegers: true});
      
      if(evi==0){
        data.append('evidence', 'null');
      }
      else
      {
        data.append('evidence'+evi, 'null');
      }
      data.append('_method', 'PUT');
      

      // await api.put('/audits/' + audit_report_id + '/non-conformances/' + id , JSON.stringify(values), {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // })
      await api({
        method: 'POST',
        url: '/audits/' + audit_report_id + '/non-conformances/' + id,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: data,
      })
      .then(function (response) {

        addToast('File successfully deleted', { appearance: 'success' });
        window.location.reload();

        reload(true);
      })
      .catch(function (error) {
        addToast(error.response.data.message, { appearance: 'error' });
      });
    }
  }

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        // setProfile(response.data);

        // if (response.data.roles && response.data.roles[0] && response.data.roles[0].name.toLowerCase() !== 'client') {
        //   history.push('/');
        // }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + audit_report_id + '/non-conformances/' + id)
        .then(function (response) {
          delete response.data.id;

         /*  if (response.data.section_b_date !== null) {
            setSubmitted(true);
          } */
          
          setData(response.data);

          // reload(false);
        })
        .catch(function (error) {
          if (error.response && error.response.data) {
            switch(error.response.status) {
              case 404:
                addToast(`NCR ID #${id} does not exist`, { appearance: 'error' });
                history.push('/');
                break;

              default:
                addToast(error.response.data.message, { appearance: 'error' });
            }
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id,load]);

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>
                <SidebarToggler />
                Non Conformity Report
              </h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  Audit
                </li>
                <li className="breadcrumb-item">
                  Audit Report
                </li>
                <li className="breadcrumb-item">
                  <Link to={props.path}>
                    Non Conformity Report
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Formik 
          enableReinitialize={true}
          initialValues={data}
          onSubmit={async (values, { setSubmitting }) => {
            let token = await localStorage.getItem('auth-token');

            token = JSON.parse(token);

            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

            values.status = 1;
            
            // console.log(values);
            
            var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
            
            data.append('_method', 'PUT');
            
            // console.log(data);

            // await api.put('/audits/' + audit_report_id + '/non-conformances/' + id , JSON.stringify(values), {
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            // })
            await api({
              method: 'POST',
              url: '/audits/' + audit_report_id + '/non-conformances/' + id,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: data,
            })
            .then(function (response) {
              addToast('Non conformance report successfully submitted', { appearance: 'success' });

              // reload(true);

              setSubmitted(true);
            })
            .catch(function (error) {
              addToast(error.message, { appearance: 'error' });
            });
          }}
        >
          {({ isSubmitting, values, setFieldValue, handleChange }) => (
            <FormikForm>
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12">
                  <div className="card invoice1">
                    <div className="body">
                      <div className="invoice-top clearfix">
                        <div className="logo">
                          <img src={process.env.PUBLIC_URL + '/logo-niosh.png'} alt="logo" class="rounded-circle img-fluid" />
                        </div>
                        <div className="info">
                        <h6>NIOSH CERTIFICATION SDN. BHD.</h6>
                          <p>7th Floor, NIOSH Tower, Lot 1, Jalan 15/1, Seksyen 15, 43650 Bandar Baru Bangi, Selangor<br />
                            Tel: +603 8769 2100 / +603 8911 3800   |   Fax: +603 8926 2900
                          </p>
                        </div>
                        <div class="title">
                          <h4>NCR #{data.no}</h4>
                        </div>
                      </div>
                      <div className="invoice-mid clearfix">&nbsp;</div>
                      <hr />

                      <div className="row clearfix">
                        <div className="col-md-12">
                          <h6>Organization Name</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {values.audit && values.audit.audit_plan && values.audit.audit_plan.client ? values.audit.audit_plan.client.name : ''}
                          </p>
                        </div>
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-6">
                          <h6>Location</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {values.location}
                          </p>
                        </div>

                        <div className="col-md-6">
                          <h6>Area/Department</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {values.area_department}
                          </p>
                        </div>
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-6">
                          <h6>Standard</h6>
                          <ol style={{ whiteSpace: "pre-wrap" }}>
                            {values.standards ? values.standards.map((standard, i) => 
                              <li>{standard.standard}</li>                                        
                            ) : ""}
                          </ol>
                        </div>

                        <div className="col-md-6">
                          <h6>Clause</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {values.clause}
                          </p>
                        </div>
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-12">
                          <h6>Details / Objective Evidence Of Non Conformity</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {values.details}
                          </p>
                        </div>
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-6">
                          <h6>Auditor Name</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {values.auditor && values.auditor.full_name ? values.auditor.full_name : ""}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <h6>Date</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {moment(values.issued_date).format('DD/MM/YYYY')}
                          </p>
                        </div>
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-12">
                          <h6>Grade</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {values.grade}
                          </p>
                        </div>
                      </div>

                      <FormGroup>
                        <Label for="immediate_action">Correction Taken/Immediate Action</Label>
                        <Input 
                         // readOnly={submitted}
                          type="textarea" 
                          // className="custom-select" 
                          name="immediate_action" 
                          id="immediate_action"
                          onChange={handleChange}
                          value={values.immediate_action}
                          rows={5}
                          required
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="root_cause">Identify The Root Cause</Label>
                        <Input 
                          //readOnly={submitted}
                          type="textarea" 
                          // className="custom-select" 
                          name="root_cause" 
                          id="root_cause"
                          onChange={handleChange}
                          value={values.root_cause}
                          rows={5}
                          required
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="corrective_action_plan">Corrective Action Plan Including Completion Date</Label>
                        <Input 
                          //readOnly={submitted}
                          type="textarea" 
                          // className="custom-select" 
                          name="corrective_action_plan" 
                          id="corrective_action_plan"
                          onChange={handleChange}
                          value={values.corrective_action_plan}
                          rows={5}
                          required
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="">Evidence</Label>
                        {values.evidence && values.evidence.url ? <div><a href={values.evidence.url}>{values.evidence.originalName}</a>&nbsp;
                        <button type="button" class="btn btn-xs btn-danger" onClick={() => deleteFile(0)}/* onClick={deleteFile(0)} */><span aria-hidden="true">×</span></button>
                        
                        </div> :
                        <Input 
                          //disabled={submitted}
                          type="file" 
                          name="evidence" 
                          // id="ROCCrtificate"
                          onChange={(event) => {
                              // console.log(event.currentTarget.files[0]);
                            
                              setFieldValue(`evidence`, event.currentTarget.files[0])
                            }}
                        />}
                        
                        <br />
                        
                        {values.evidence1 && values.evidence1.url ? <div><a href={values.evidence1.url}>{values.evidence1.originalName}</a>&nbsp;
                        <button type="button" class="btn btn-xs btn-danger" onClick={() => deleteFile(1)}><span aria-hidden="true">×</span></button>
                        </div> :
                        <Input 
                          //disabled={submitted}
                          type="file" 
                          name="evidence1" 
                          // id="ROCCrtificate"
                          onChange={(event) => {
                              // console.log(event.currentTarget.files[0]);
                            
                              setFieldValue(`evidence1`, event.currentTarget.files[0])
                            }}
                        />}
                        
                        <br />
                        
                        {values.evidence2 && values.evidence2.url ? <div><a href={values.evidence2.url}>{values.evidence2.originalName}</a>&nbsp;
                        <button type="button" class="btn btn-xs btn-danger" onClick={() => deleteFile(2)}><span aria-hidden="true">×</span></button></div> :
                        <Input 
                          //disabled={submitted}
                          type="file" 
                          name="evidence2" 
                          // id="ROCCrtificate"
                          onChange={(event) => {
                              // console.log(event.currentTarget.files[0]);
                            
                              setFieldValue(`evidence2`, event.currentTarget.files[0])
                            }}
                        />}
                        
                        <br />
                        
                        {values.evidence3 && values.evidence3.url ? <div><a href={values.evidence3.url}>{values.evidence3.originalName}</a>&nbsp;
                        <button type="button" class="btn btn-xs btn-danger" onClick={() => deleteFile(3)}><span aria-hidden="true">×</span></button></div> :
                        <Input 
                          //disabled={submitted}
                          type="file" 
                          name="evidence3" 
                          // id="ROCCrtificate"
                          onChange={(event) => {
                              // console.log(event.currentTarget.files[0]);
                            
                              setFieldValue(`evidence3`, event.currentTarget.files[0])
                            }}
                        />}
                        
                        <br />
                        
                        {values.evidence4 && values.evidence4.url ? <div><a href={values.evidence4.url}>{values.evidence4.originalName}</a>&nbsp;
                        <button type="button" class="btn btn-xs btn-danger" onClick={() => deleteFile(4)}><span aria-hidden="true">×</span></button></div> :
                        <Input 
                          //disabled={submitted}
                          type="file" 
                          name="evidence4" 
                          // id="ROCCrtificate"
                          onChange={(event) => {
                              // console.log(event.currentTarget.files[0]);
                            
                              setFieldValue(`evidence4`, event.currentTarget.files[0])
                            }}
                        />}
                      </FormGroup>

                      <div className="row clearfix">
                        <div className="col-md-8">
                          <Label for="mr_name">Name</Label>
                          <Input 
                            //readOnly={submitted}
                            type="text" 
                            // className="custom-select" 
                            name="mr_name" 
                            id="mr_name"
                            onChange={handleChange}
                            value={values.mr_name}
                            required
                            // rows={5}
                          />
                        </div>
                        <div className="col-md-4">
                          <Label for="section_b_date">Date</Label>
                          <DateRangePicker 
                            disabled={submitted}
                            drops="up"
                            singleDatePicker
                            containerStyles={{ display: "block" }}
                            startDate={values.section_b_date && values.section_b_date.length ? moment(values.section_b_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                            // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                            onApply={(event, picker) => { 
                              setFieldValue('section_b_date', picker.startDate.format('YYYY-MM-DD'));
                              // setFieldValue('date_of_audit_end', picker.endDate.format('YYYY-MM-DD'));

                              // setAuditStartDate(moment(picker.startDate));

                              // setAuditEndDate(moment(picker.endDate));
                            }}
                          >
                            <Input 
                              autoComplete="off"
                              readOnly={submitted}
                              type="text" 
                              // name="date_of_audit" 
                              id="section_b_date"
                              // onChange={handleChange}
                              value={values.section_b_date ? moment(values.section_b_date).format('DD/MM/YYYY') : ""}
                              required
                            />
                          </DateRangePicker>
                        </div>
                      </div>

                      <div className="row clearfix mt-4">
                        <div className="col-sm-4">
                          {/* {readOnly === false ? 
                          <React.Fragment> */}
                          <button 
                            disabled={isSubmitting || submitted}
                            type="submit" 
                            className="btn btn-primary"
                          >
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                          </button>&nbsp;
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </React.Fragment>
  )
}

export default Form;