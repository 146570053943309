import React, { useEffect, useState } from 'react';

import {
  Link,
  useHistory,
  // useLocation,
  useParams,
  // useRouteMatch,
} from 'react-router-dom';

import {
  InputGroup,
} from 'react-bootstrap';

import {
  ButtonGroup,
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import { useToasts } from 'react-toast-notifications';

import api from '../../utils/api';

import { objectToFormData } from 'object-to-formdata';

//let DatePicker = require("reactstrap-date-picker");

import DatePicker from 'reactstrap-date-picker';

// import SignatureCanvas from 'react-signature-canvas';
import SignaturePad from 'react-signature-pad-wrapper';

import './style.css';

import moment from 'moment';

const INITIAL_VALUES = {
  client: {
    num_of_employee_per_shift: {},
    industries: [],
    certifications: [],
  },
  branches: {},
  application: {
    branches: []
  }
};

const STATES = [
  'Johor',
  'Kedah',
  'Kelantan',
  'Melaka',
  'Negeri Sembilan',
  'Pahang',
  'Perak',
  'Perlis',
  'Pulau Pinang',
  'Sabah',
  'Sarawak',
  'Selangor',
  'Terengganu',
  'Wilayah Persekutuan Kuala Lumpur',
  'Wilayah Persekutuan Labuan',
  'Wilayah Persekutuan Putrajaya',
];

// const CERTIFICATIONS = [
//   'ISO 45001:2018 OHS Management Systems Standard',
//   'ISO 9001:2015 Quality Management Systems Standard',
//   'ISO 14001:2015 Environmental Management Systems Standard',
//   'ISO 22000:2005 Food Safety Management Systems Standard',
//   'MS 1480:2007 Hazard Analysis and Critical Control Point Management Systems Standard',
//   'ISO 21101:2013 Adventure Tourism Safety Management Systems Standard',
//   'ISO 27001:2013 Information Security Management Systems Standard',
//   'ISO 39001:2012 Road Traffic Safety Management Systems',
//   'ISO 37001:2016 Anti-Bribery Management Systems',
// ];

const BRANCH = {};

const PRODUCTS = [{
  'label': 'Palm Oil Mill',
  'field': 'palm_oil_mill',
}, {
  'label': 'Refinery',
  'field': 'refinery',
}, {
  'label': 'Kernel Crusher Plant',
  'field': 'kernel_crusher_plant',
}, {
  'label': 'Biodiesel Plant',
  'field': 'biodiesel_plant',
}, {
  'label': 'Others',
  'field': 'others',
}];

let sigCanvas;

const Form = (props) => {
  let { id } = useParams();
  let history = useHistory();
  // let { path } = useRouteMatch();

  // let location = useLocation();
  // let { from } = location.state || { from: { pathname: "/client-application" } };
  let [token, setToken] = useState('');
  const [data, setData] = useState(INITIAL_VALUES);

  const [CERTIFICATIONS, setCertifications] = useState({});
  const [NACE_CODES, setNaceCodes] = useState([]);
  // const [INDUSTRIES, setIndustries] = useState({});
  
  const [displayEligibility, setDisplayEligibility] = useState('none');

  const { addToast } = useToasts();

  let { readOnly }  = props;

  useEffect(() => {
    // console.log(props);
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);
      setToken(token.access_token);
      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        // setProfile(response.data);

        // if (!readOnly && response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'technical_review.update') === "undefined") {
        //   history.push(props.path);
        // }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getCertifications = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/lookup/certifications?type=iso')
      .then(function (response) {
        // console.log(response.data);

        setCertifications(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getCertifications();

    const getNaceCodes = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/nace-codes')
      .then(function (response) {
        // console.log(response.data);

        setNaceCodes(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getNaceCodes();

    // const getIndustries = async () => {
    //   let token = await localStorage.getItem('auth-token');

    //   token = JSON.parse(token);

    //   api.defaults.headers.common['Authorization'] = token.access_token;

    //   api.get('/lookup/industries')
    //   .then(function (response) {
    //     // console.log(response.data);

    //     setIndustries(response.data);
    //   })
    //   .catch(function (error) {
    //     // console.log(error.response);
    //     if (error.response) {
    //       addToast(error.response.data.message, { appearance: 'error' });
    //     } else {
    //       addToast(error.message, { appearance: 'error' });
    //     }
    //   });
    // }

    // getIndustries();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        await api.get('/application-forms/' + id)
        .then(function (response) {
          if (response.data && response.data.industries) {
            let selectedIndustries = [];
            response.data.industries.map((industry, key) => {
              return selectedIndustries.push(industry.id);
            });

            response.data.industries = selectedIndustries;
          }

          if (response.data.certifications) {
            let selectedCertifications = [];
            response.data.certifications.map((certification, key) => {
              return selectedCertifications.push(certification.id);
            });

            response.data.certifications = selectedCertifications;
          }

          if (response.data.nace_codes) {
            let selectedCodes = [];
            response.data.nace_codes.map((nace_code, key) => {
              return selectedCodes.push(nace_code.id);
            });

            response.data.nace_codes = selectedCodes;
          }
          console.log(response.data);
          setData(response.data);
          
          if (response.data && response.data.application && response.data.application.cover_branches) {
            if (response.data.application.cover_branches == 1) {
              setDisplayEligibility('block');
            }
          }

          if (response.data.application && response.data.application.declaration_signature && response.data.application.declaration_signature.length > 0)
          sigCanvas.fromDataURL(response.data.application.declaration_signature);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error.response && error.response.data) {
            switch(error.response.status) {
              case 403:
                addToast("Unauthorized", { appearance: 'error' });
                history.push(props.path);
                break;

              case 404:
                addToast(`Client application ID #${id} does not exist`, { appearance: 'error' });
                history.push(props.path);
                break;

              default:
                addToast(error.response.data.message, { appearance: 'error' });
            }
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        if (readOnly) {
          return;
        }

        if (props.type === 'iso') {
          if (typeof values.certifications === 'undefined' || (values.certifications && values.certifications.length === 0)) {
            addToast('Please select at least one certification', { appearance: 'error' });

            return;
          }
        }

        // const querystring = require('querystring');

        /// console.log(values);

        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        // if (values.application && values.application.declaration_signature.length) {
        //   values.application.declaration_signature = sigCanvas.toDataURL();
        // }

        // switch (props.type) {
        //   default:
        //   case 'iso':
        //     values.type = 1;
        //     break;

        //   case 'mspo':
        //     values.type = 2;
        //     break;
  
        //   case 'mspo-sccs':
        //     values.type = 3;
        //     break;
        // }

        // const blob = new Blob([JSON.stringify(values)], {
        //   type: 'application/json'
        // });

        // const data = new FormData();
        // for (var key in values) {
        //   if (typeof values[key] === 'object') {
        //     data.append(key, JSON.stringify(values[key]));
        //   } else {
        //     data.append(key, values[key]);
        //   }
        // }
        // data.append('document', blob);
        // data.append('roc_certificate', values.roc_certificate);

        values.type = props.type;
        values.iso_type = props.type;
        values.application.declaration_signature = sigCanvas.toDataURL();
        if (!id) {
          // if (!values.certifications) {
          //   values.certifications = [];
          // }

          // if (props.type === 'mspo') { 
          //   values.certifications.push(10);
          // }

          // if (props.type === 'mspo-sccs') {
          //   values.certifications.push(11);
          // }
        }

        var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

        if (!id) {
          await api({
            method: 'POST',
            url: '/application-forms',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          .then(function (response) {

            addToast('Application form successfully saved', { appearance: 'success' });

           // history.push(props.path);

            // window.location = '/client-application/iso';
          })
          .catch(function (error) {
            if (error && error.response && error.response.data && error.response.data.data && (error.response.data.data.message || error.response.data.data.messages)) {
              if(error.response.data.data.messages){
                if(error.response.data.data.messages.contact_person_email || error.response.data.data.messages.email){
                  addToast('The email is required', { appearance: 'error' });
                }
              }
              else{
                addToast(error.response.data.data.message, { appearance: 'error' });
              }  
            } else {
              addToast(error.message, { appearance: 'error' });
            }

          });
        } else {
          data.append('_method', 'PUT');

          await api({
            method: 'POST',
            url: '/application-forms/' + id,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          // api.put('/application-forms/' + id, JSON.stringify(values), {
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          // })
          .then(function (response) {
            // console.log(response);

            addToast('Application form has been updated', { appearance: 'success' });

            history.push(props.path);

            // window.location = '/client-application/iso';
          })
          .catch(function (error) {
            
            if (error && error.response && error.response.data && error.response.data.data) {
              console.log(error.response.data.data.messages);
              addToast(error.response.data.messages, { appearance: 'error' });
            } else {
              addToast(error.message, { appearance: 'error' });
            }          });
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
      <FormikForm>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <Card>
            <div className="header">
              <h2>COMPANY DETAILS</h2>
            </div>
            <div className="body">
              <div className="row clearfix">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="CompanyName">Name of Company/Organization</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="name" 
                      id="CompanyName"
                      onChange={handleChange}
                      value={values.name}
                      required
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="CompanyAddress1">Full Address</Label>
                    <Input 
                      readOnly={readOnly}
                      type="textarea" 
                      name="address_1" 
                      id="CompanyAddress1"
                      onChange={handleChange}
                      value={values.address_1}
                    />
                  </FormGroup>

                  {/* <FormGroup>
                    <Label for="CompanyAddress2">Address (Line 2)</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="address_2" 
                      id="CompanyAddress2"
                      onChange={handleChange}
                      value={values.address_2}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="CompanyAddress3">Address (Line 3)</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="address_3" 
                      id="CompanyAddress3"
                      onChange={handleChange}
                      value={values.address_3}
                    />
                  </FormGroup> */}
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="Postcode">Postcode</Label>
                    <Input 
                      readOnly={readOnly}
                      type="number" 
                      name="postcode" 
                      id="Postcode"
                      // onChange={handleChange}
                      onChange={(e) => {
                        setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      }}
                      value={values.postcode}
                      maxLength={6}
                      pattern="\d*"
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="State">State</Label>
                    <Input 
                      type="select" 
                      className="custom-select" 
                      name="state" 
                      id="State"
                      onChange={handleChange}
                      value={values.state}
                    >
                      <option></option>
                      {STATES.map((STATE, key) => <option key={key} value={STATE}>{STATE}</option>)}
                    </Input>
                  </FormGroup>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-sm-3">
                  <FormGroup>
                    <Label for="Tel">Tel</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="tel" 
                      id="Tel"
                      onChange={handleChange}
                      value={values.tel}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-3">
                  <FormGroup>
                    <Label for="Fax">Fax</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="fax" 
                      id="Fax"
                      onChange={handleChange}
                      value={values.fax}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-3">
                  <FormGroup>
                    <Label for="Email">Webmail</Label>
                    <Input 
                      readOnly={readOnly}
                      type="email" 
                      name="email" 
                      id="Email"
                      onChange={handleChange}
                      value={values.email}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-3">
                  <FormGroup>
                    <Label for="Website">Website</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="website" 
                      id="Website"
                      onChange={handleChange}
                      value={values.website}
                    />
                  </FormGroup>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="LegalStatus">Legal Status (R.O.C or R.O.B No)</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="legal_status" 
                      id="LegalStatus"
                      onChange={handleChange}
                      value={values.legal_status}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="ROCCrtificate">Copy of R.O.C Certificate</Label>
                    <Input 
                      type="file" 
                      name="roc_certificate" 
                      //id="ROCCrtificate"
                      onChange={(event) => {
                        setFieldValue('roc_certificate', event.currentTarget.files[0])
                      }}
                    />
                    <div>
                        {values.roc_certificate && values.roc_certificate.path && values.roc_certificate.url ?

                        <a className="btn btn-link" href={values.roc_certificate.url} target="_blank" >{values.roc_certificate.originalName}</a>  
                      : values.roc_certificate && values.roc_certificate.path ?
                        <a className="btn btn-link" href={values.roc_certificate.path} target="_blank" >{values.roc_certificate.originalName}</a> : null }
                    </div>
                  </FormGroup>
                </div>
              </div>
{/* <div><a className="btn btn-link" href={values.roc_certificate.url}>{values.roc_certificate}</a></div> */}
              <div className="row clearfix">
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="ManagementRepresentativeName">Name of Management Representative</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="management_representative_name" 
                      id="ManagementRepresentativeName"
                      onChange={handleChange}
                      value={values.management_representative_name ? values.management_representative_name : null}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="ManagementRepresentativeDesignation">Management Representative Designation</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="management_representative_designation" 
                      id="ManagementRepresentativeDesignation"
                      onChange={handleChange}
                      value={values.management_representative_designation ? values.management_representative_designation : null}
                    />
                  </FormGroup>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="ContactPersonName">Name of Contact Person</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="contact_person_name" 
                      id="ContactPersonName"
                      onChange={handleChange}
                      value={values.contact_person_name ? values.contact_person_name : null}
                    />
                  </FormGroup>
                </div>

                <div className="col-sm-4">
                  <FormGroup>
                    <Label for="ContactPersonPosition">Position</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="contact_person_position" 
                      id="ContactPersonPosition"
                      onChange={handleChange}
                      value={values.contact_person_position ? values.contact_person_position : null}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                  <FormGroup>
                    <Label for="ContactPersonPhone">H/Phone No.</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="contact_person_phone" 
                      id="ContactPersonPhone"
                      onChange={handleChange}
                      value={values.contact_person_phone ? values.contact_person_phone : null}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                  <FormGroup>
                    <Label for="ContactPersonEmail">Email</Label>
                    <Input 
                      readOnly={readOnly}
                      type="email" 
                      name="contact_person_email" 
                      id="ContactPersonEmail"
                      onChange={handleChange}
                      value={values.contact_person_email ? values.contact_person_email : null}
                    />
                  </FormGroup>
                </div>
              </div>

              {props.type !== 'mspo-sccs' ?
                null
              : 
                <React.Fragment>
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="">Type of Operating Unit & Product Name(s)</Label>

                          {PRODUCTS.map((PRODUCT, key) => 
                          <InputGroup className="mb-2">
                            <InputGroup.Prepend style={{ width: "190px" }}>
                              <InputGroup.Text style={{ backgroundColor: "#fff", fontSize: "0.8rem", maxHeight: "35px", paddingBottom: 0, width: "190px" }}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      readOnly={readOnly}
                                      type="checkbox" 
                                      name={PRODUCT.field}
                                      onClick={handleChange}
                                      onChange={() => {
                                        // if (!values[PRODUCT.field]) {
                                        //   setFieldValue(`client.${PRODUCT.field}_products`, null);
                                        // } else {
                                        //   setFieldValue(`client.${PRODUCT.field}`, );
                                        // }

                                        // if (!values[PRODUCT.field]) {
                                        //   setFieldValue(`client.${PRODUCT.field}`, 1);
                                        // } else {
                                        //   setFieldValue(`client.${PRODUCT.field}`, 1);
                                        // }

                                        // console.log(values[PRODUCT.field]);

                                        if (values[PRODUCT.field]) {
                                          setFieldValue(`${PRODUCT.field}`, !values[PRODUCT.field]);
                                        } else {
                                          setFieldValue(`${PRODUCT.field}`, 1);
                                        }
                                      }}
                                      value="1"
                                      checked={values[PRODUCT.field]} 
                                    />
                                    <span>
                                      {PRODUCT.label}
                                    </span>
                                  </label>
                                </div>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Input 
                              // disabled={values[`${PRODUCT.field}`] !== '1'}
                              readOnly={readOnly ? true : (values[PRODUCT.field] ? false : true)}
                              type="text" 
                              name={`${PRODUCT.field}_products`} 
                              id={`${PRODUCT.field}_products`} 
                              onChange={handleChange}
                              value={values[`${PRODUCT.field}_products`]}
                            />
                          </InputGroup>
                          )}
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="OutsourcedActivities">
                            Please state should do you have an outsource activities (if any)
                          </Label>
                          <Input 
                            readOnly={readOnly}
                            type="text" 
                            // className="custom-select" 
                            name="application.outsource_activities" 
                            id="OutsourcedActivities"
                            onChange={handleChange}
                            value={values.application && values.application.outsource_activities ? values.application.outsource_activities : ""}
                          />
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="TravelHour">
                            Travel hour from the operating unit (if any)
                          </Label>
                          <Input 
                            readOnly={readOnly}
                            type="text" 
                            // className="custom-select" 
                            name="application.travel_hour_from_operating_unit" 
                            id="TravelHour"
                            onChange={handleChange}
                            value={values.application && values.application.travel_hour_from_operating_unit}
                          />
                        </FormGroup>
                      </div>
                    </div>
                </React.Fragment>
              }

              {props.type === 'iso' ?
              <React.Fragment>
                <div className="row clearfix mt-4">
                  <div className="col-sm-12">
                    <Label>Number of  employees (For Head Office &amp; Site):</Label>

                    <div className="row clearfix">
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label for="NumOfEmployeeManagement">Management</Label>
                          <Input 
                            readOnly={readOnly}
                            type="number" 
                            name="num_of_employee_management" 
                            id="NumOfEmployeeManagement"
                            onChange={handleChange}
                            value={values.num_of_employee_management}
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label for="NumOfEmployeeContractWorkers">Contract Workers</Label>
                          <Input 
                            readOnly={readOnly}
                            type="number" 
                            name="num_of_employee_contract_workers" 
                            id="NumOfEmployeeContractWorkers"
                            onChange={handleChange}
                            value={values.num_of_employee_contract_workers}
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label for="NumOfEmployeeStaff">Staff</Label>
                          <Input 
                            readOnly={readOnly}
                            type="number" 
                            name="num_of_employee_staff" 
                            id="NumOfEmployeeStaff"
                            onChange={handleChange}
                            value={values.num_of_employee_staff}
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-sm-6">
                        <FormGroup>
                          <Label for="NumOfEmployeeOthers">Others</Label>
                          <Input 
                            readOnly={readOnly}
                            type="number" 
                            name="num_of_employee_others" 
                            id="NumOfEmployeeOthers"
                            onChange={handleChange}
                            value={values.num_of_employee_others}
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row clearfix mt-4">
                  <div className="col-sm-12">
                    <Label>Detail of Shift:</Label>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="Size">Shift Operation</Label>
                          <Input type="select" 
                            className="custom-select" 
                            name="shift_operation" 
                            id="ShiftOperation"
                            onChange={handleChange}
                            value={values.shift_operation}
                          >
                            <option></option>
                            <option value="1">Yes (please indicate the number of employee per shift on the following)</option>
                            <option value="0">No</option>
                          </Input>
                        </FormGroup>
                      </div>
                    </div>

                    {parseInt(values.shift_operation) === 1 ?
                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <FormGroup>
                          <Label for="NumOfEmployeeFirstShift">1<sup>st</sup> Shift</Label>
                          <Input 
                            readOnly={readOnly}
                            type="number" 
                            name="num_of_employee_per_shift.0" 
                            id="NumOfEmployeeFirstShift"
                            onChange={handleChange}
                            value={values.num_of_employee_per_shift && values.num_of_employee_per_shift[0]}
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-sm-4">
                        <FormGroup>
                          <Label for="NumOfEmployeeSecondShift">2<sup>nd</sup> Shift</Label>
                          <Input 
                            readOnly={readOnly}
                            type="number" 
                            name="num_of_employee_per_shift.1" 
                            id="NumOfEmployeeSecondShift"
                            onChange={handleChange}
                            value={values.num_of_employee_per_shift && values.num_of_employee_per_shift[1]}
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-sm-4">
                      <FormGroup>
                          <Label for="NumOfEmployeeThirdShift">3<sup>rd</sup> Shift</Label>
                          <Input 
                            readOnly={readOnly}
                            type="number" 
                            name="num_of_employee_per_shift.2" 
                            id="NumOfEmployeeThirdShift"
                            onChange={handleChange}
                            value={values.num_of_employee_per_shift && values.num_of_employee_per_shift[2]}
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    : null}
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="CoverBranches">Do you want to include the certification in the same registration to cover your network of local offices or branches?</Label>
                      <Input 
                        type="select" 
                        className="custom-select" 
                        name="application.cover_branches" 
                        id="CoverBranches"
                        onChange={(event) => {
                          setFieldValue('application.cover_branches', event.currentTarget.value);
                          
                          if (event.currentTarget.value == 1) {
                            setDisplayEligibility('block');
                          } else {
                            setDisplayEligibility('none');
                          }
                        }}
                        value={values.application && values.application.cover_branches ? values.application.cover_branches : ""}
                      >
                        <option></option>
                        <option value="1">Yes (please indicate below)</option>
                        <option value="0">No</option>
                      </Input>
                    </FormGroup>
                  </div>
                </div>

                {values.application && values.application.cover_branches && parseInt(values.application.cover_branches) === 1 ?
                <React.Fragment>
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Sites</th>
                              <th>Address</th>
                              <th>No. Of Employees</th>
                              <th>No. Of Shifts</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.application && values.application.branches && values.application.branches.length && values.application.branches.map((BRANCH, key) => 
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>
                                  <Input 
                                    readOnly={readOnly}
                                    type="text" 
                                    name={`application.branches.${key}.address`}
                                    // onChange={handleChange}
                                    onChange={(event) => {
                                      setFieldValue(`application.branches.${key}.address`, event.currentTarget.value);
                                    }}
                                    value={values.application.branches[key].address}
                                  />
                                </td>
                                <td>
                                  <Input 
                                    readOnly={readOnly}
                                    type="number" 
                                    name={`application.branches.${key}.no_of_employees`}
                                    // onChange={handleChange}
                                    onChange={(event) => {
                                      setFieldValue(`application.branches.${key}.no_of_employees`, event.currentTarget.value);
                                    }}
                                    value={values.application.branches[key].no_of_employees}
                                    min={0}
                                    step={1}
                                  />
                                </td>
                                <td>
                                  <Input 
                                    readOnly={readOnly}
                                    type="number" 
                                    name={`application.branches.${key}.no_of_shifts`}
                                    // onChange={handleChange}
                                    onChange={(event) => {
                                      setFieldValue(`application.branches.${key}.no_of_shifts`, event.currentTarget.value);
                                    }}
                                    value={values.application.branches[key].no_of_shifts}
                                    min={0}
                                    step={1}
                                  />
                                </td>
                                <td>
                                  <Button
                                    type="button"
                                    outline
                                    color="danger"
                                    onClick={() => {
                                      if (window.confirm('Delete this row?')) {
                                        let { branches } = values.application;

                                        delete branches[key];

                                        setFieldValue('application.branches', branches);
                                      }
                                    }}
                                  >
                                    <i className="icon-trash"></i>
                                  </Button>
                                </td>
                              </tr>
                            )}
                            {!readOnly ?
                            <tr>
                              <td colSpan="5">
                                <Button 
                                  block 
                                  outline
                                  onClick={(e) => {
                                    e.preventDefault();

                                    let { branches } = values.application;
                                    if (!branches) {
                                      branches = [];
                                    }
                                    branches.push(BRANCH);

                                    // setData(data => {
                                    //   return {...data, branches: branches}
                                    // })

                                    // console.log(branches);

                                    setFieldValue('application.branches', branches);

                                    // console.log(values);
                                  }}
                                >
                                  Add Row
                                </Button>
                              </td>
                            </tr>
                            : null}
                          </tbody>
                        </table>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="">No Of Site</Label>
                        <Input 
                          type="text" 
                          // name="management_representative_name" 
                          // id="ManagementRepresentativeName"
                          // onChange={handleChange}
                          value={values.application && values.application.branches ? values.application.branches.length : 0}
                          disabled
                        />
                      </FormGroup>
                    </div>
                  </div>
                </React.Fragment>
                : null}
              </React.Fragment>
              : null}

              {props.type === 'mspo' ?
              <React.Fragment>
                <div className="row clearfix mt-4">
                  <div className="col-sm-12">
                    <Label>Please indicate the Oil Palm Plantation / Palm Oil Mills details below</Label>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="TypeOfMSPOCertification">Types Of MSPO Certification</Label>
                          <Input 
                            type="select" 
                            className="custom-select" 
                            name="application.type_of_mspo_certification" 
                            id="TypeOfMSPOCertification"
                            onChange={handleChange}
                            value={values.application && values.application.type_of_mspo_certification ? values.application.type_of_mspo_certification : ""}
                          >
                            <option></option>
                            <option value="I">
                              Individual Certification
                            </option>
                            <option value="G">
                              Group Certification
                            </option>
                          </Input>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Sites</th>
                                <th>Full Address Of Estates / Mill</th>
                                <th>Area of Plantation (Ha) / Mill Capacity (Mt/Hr)</th>
                                <th>Travel Hours from Nearest Town</th>
                                <th>Travel Hours from Nearest Airport</th>
                                <th>Years of Planting</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.application && values.application.branches && values.application.branches.length && values.application.branches.map((BRANCH, key) => 
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>
                                    <Input 
                                      readOnly={readOnly}
                                      type="text" 
                                      name={`application.branches.${key}.address`}
                                      onChange={(event) => {
                                        setFieldValue(`application.branches.${key}.address`, event.currentTarget.value);
                                      }}
                                      value={values.application.branches[key].address}
                                    />
                                  </td>
                                  <td>
                                    <Input 
                                      readOnly={readOnly}
                                      type="text" 
                                      name={`application.branches.${key}.area_or_capacity`}
                                      onChange={(event) => {
                                        setFieldValue(`application.branches.${key}.area_or_capacity`, event.currentTarget.value);
                                      }}
                                      value={values.application.branches[key].area_or_capacity}
                                    />
                                  </td>
                                  <td>
                                    <Input 
                                      readOnly={readOnly}
                                      type="text" 
                                      name={`application.branches.${key}.travel_hour_town`}
                                      onChange={(event) => {
                                        setFieldValue(`application.branches.${key}.travel_hour_town`, event.currentTarget.value);
                                      }}
                                      value={values.application.branches[key].travel_hour_town}
                                    />
                                  </td>
                                  <td>
                                    <Input 
                                      readOnly={readOnly}
                                      type="text" 
                                      name={`application.branches.${key}.travel_hour_airport`}
                                      onChange={(event) => {
                                        setFieldValue(`application.branches.${key}.travel_hour_airport`, event.currentTarget.value);
                                      }}
                                      value={values.application.branches[key].travel_hour_airport}
                                    />
                                  </td>
                                  <td>
                                    <Input 
                                      readOnly={readOnly}
                                      type="text" 
                                      name={`application.branches.${key}.years_planting`}
                                      onChange={(event) => {
                                        setFieldValue(`application.branches.${key}.years_planting`, event.currentTarget.value);
                                      }}
                                      value={values.application.branches[key].years_planting}
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      type="button"
                                      outline
                                      color="danger"
                                      onClick={() => {
                                        if (window.confirm('Delete this row?')) {
                                          let { branches } = values.application;

                                          delete branches[key];

                                          setFieldValue('application.branches', branches);
                                        }
                                      }}
                                    >
                                      <i className="icon-trash"></i>
                                    </Button>
                                  </td>
                                </tr>
                              )}
                              {!readOnly ?
                              <tr>
                                <td colSpan="7">
                                  <Button 
                                    block 
                                    outline
                                    onClick={(e) => {
                                      e.preventDefault();

                                      let { branches } = values.application;
                                      if (!branches) {
                                        branches = [];
                                      }
                                      branches.push(BRANCH);

                                      // setData(data => {
                                      //   return {...data, branches: branches}
                                      // })

                                      // console.log(branches);

                                      setFieldValue('application.branches', branches);
                                    }}
                                  >
                                    Add Row
                                  </Button>
                                </td>
                              </tr>
                              : null}
                            </tbody>
                          </table>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="">No Of Site</Label>
                          <Input 
                            type="text" 
                            // name="management_representative_name" 
                            // id="ManagementRepresentativeName"
                            // onChange={handleChange}
                            value={values.application && values.application.branches ? values.application.branches.length : 0}
                            disabled
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
              : null}
            </div>
          </Card>
          
          {props.type !== 'mspo-sccs' ?
            <Card style={{ display: displayEligibility }}>
            <div className="header">
              <h2>ELIGIBILITY OF AN ORGANIZATION FOR MULTI-SITE SAMPLING AUDIT
                <small>
                  Please tick the following situations to determine the 
                  eligibility for certification of multiple-sites based 
                  on sampling
                </small>
              </h2>
            </div>
            <div className="body">
              <div className="row clearfix">
                <div className="col-sm-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>NO</th>
                        <th>SITUATIONS</th>
                        <th>YES</th>
                        <th>NO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1a</td>
                        <td style={{ whiteSpace: 'normal' }}>
                          The processes at all sites have to be substantially of 
                          the same kind and have to be operated to similar 
                          methods and procedures. <b>AND/OR</b>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_1a" 
                                value="Y" 
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_1a === 'Y'}
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_1a"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_1a', 'Y')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_1a ? values.application.eligibility_for_multisite_sampling_audit_1a === 'Y' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_1a" 
                                value="N"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_1a === 'N'}
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_1a"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_1a', 'N')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_1a ? values.application.eligibility_for_multisite_sampling_audit_1a === 'N' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>1b</td>
                        <td style={{ whiteSpace: 'normal' }}>
                          Processes in each location 
                          are not similar but are clearly linked (e.g. fabrication 
                          in one location, assembly in several other locations
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_1b" 
                                value="Y"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_1b === 'Y'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_1b"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_1b', 'Y')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_1b ? values.application.eligibility_for_multisite_sampling_audit_1b === 'Y' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_1b" 
                                value="N"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_1b === 'N'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_1b"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_1b', 'N')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_1b ? values.application.eligibility_for_multisite_sampling_audit_1b === 'N' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td style={{ whiteSpace: 'normal' }}>
                          The organization’s management system shall be under a 
                          centrally controlled and administered plan and be subject 
                          to central management review.
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_2" 
                                value="Y"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_2 === 'Y'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_2"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_2', 'Y')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_2 ? values.application.eligibility_for_multisite_sampling_audit_2 === 'Y' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_2" 
                                value="N"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_2 === 'N'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_2"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_2', 'N')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_2 ? values.application.eligibility_for_multisite_sampling_audit_2 === 'N' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td style={{ whiteSpace: 'normal' }}>
                          All the relevant sites shall be subject to the organization’s internal 
                          audit program and all shall have been audited in accordance with that 
                          program prior to the certification body, NIOSH Certification starting 
                          its audit.
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_3" 
                                value="Y" 
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_3 === 'Y'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_3"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_3', 'Y')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_3 ? values.application.eligibility_for_multisite_sampling_audit_3 === 'Y' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_3" 
                                value="N"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_3 === 'N'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_3"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_3', 'N')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_3 ? values.application.eligibility_for_multisite_sampling_audit_3 === 'N' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td style={{ whiteSpace: 'normal' }}>
                          The organization’s is able to collect and analyze data from all sites and 
                          authorize to initiate organizational change if required.
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_4" 
                                value="Y"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_4 === 'Y'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_4"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_4', 'Y')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_4 ? values.application.eligibility_for_multisite_sampling_audit_4 === 'Y' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_4" 
                                value="N"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_4 === 'N'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_4"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_4', 'N')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_4 ? values.application.eligibility_for_multisite_sampling_audit_4 === 'N' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td style={{ whiteSpace: 'normal' }}>
                          Use of temporary sites which are to be included within the scope of 
                          certification.
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_5" 
                                value="Y" 
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_5 === 'Y'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_5"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_5', 'Y')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_5 ? values.application.eligibility_for_multisite_sampling_audit_5 === 'Y' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Label check>
                              <Input 
                                type="radio" 
                                name="application.eligibility_for_multisite_sampling_audit_5" 
                                value="N"
                                onClick={handleChange}
                                checked={values.eligibility_for_multisite_sampling_audit_5 === 'N'} 
                              />
                            </Label>
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.eligibility_for_multisite_sampling_audit_5"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.eligibility_for_multisite_sampling_audit_5', 'N')
                              }}
                              checked={values.application && values.application.eligibility_for_multisite_sampling_audit_5 ? values.application.eligibility_for_multisite_sampling_audit_5 === 'N' : ""}
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Card>
          : null}

          
            <Card>
            <div className="header">
              <h2>SCOPE/PROCESS</h2>
            </div>
            <div className="body">
              <div className="row clearfix">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="ScopeOfCertification">Scope Of Certification</Label>
                    <Input 
                      readOnly={readOnly}
                      type="textarea" 
                      name="scope_of_certification" 
                      id="ScopeOfCertification" 
                      onChange={handleChange}
                      value={values.scope_of_certification} 
                    />
                  </FormGroup>
                </div>
              </div>

              {props.type === 'iso' ?
              <div className="row clearfix">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="NaceCode">Nace Code</Label>
                    {/* <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="client.nace_code" 
                      id="NaceCode" 
                      onChange={handleChange}
                      value={values.client && values.client.nace_code ? values.client.nace_code : null} 
                    /> */}
                    {/* <Input 
                      multiple
                      readOnly={readOnly}
                      type="select" 
                      className="custom-select" 
                      name="client.nace_code" 
                      id="NaceCode"
                      onChange={handleChange}
                      value={values.client.nace_code}
                    >
                      <option></option>
                      {NACE_CODES && NACE_CODES.map((NACE_CODE, i) => 
                        <option value={NACE_CODE.code}>{NACE_CODE.code} - {NACE_CODE.description}</option>
                      )}
                    </Input> */}
                    <div className="row">
                      <div className="col-sm-6">
                        {NACE_CODES.length ? NACE_CODES.map((NACE_CODE, i) =>
                          i < 20 ? (
                          <React.Fragment key={i}>
                            <div className="fancy-checkbox">
                              <label>
                                <Input 
                                  disabled={readOnly}
                                  type="checkbox" 
                                  // disabled={true}
                                  name={`nace_codes`}
                                  // onChange={handleChange}
                                  onChange={() => {
                                    // if (values.typeStr !== 'ISO') {
                                      let { nace_codes } = values;

                                      if (nace_codes && nace_codes.length) {
                                        var index = nace_codes.indexOf(NACE_CODE.id);
                                        if (index !== -1) {
                                          nace_codes.splice(index, 1);
                                        } else {
                                          nace_codes.push(NACE_CODE.id);
                                        }
                                      } else {
                                        nace_codes = [];
                                        nace_codes.push(NACE_CODE.id);
                                      }

                                      setFieldValue('nace_codes', nace_codes);
                                    // }
                                  }}
                                  // value={CERTIFICATION.id}
                                  checked={values.nace_codes && values.nace_codes.length && values.nace_codes.indexOf(NACE_CODE.id) !== -1} 
                                />
                                <span>
                                  {NACE_CODE.code} <span className="text-muted">- {NACE_CODE.description}</span>
                                </span>
                              </label>
                            </div>
                          </React.Fragment>
                          ) : null
                        ) : null}
                      </div>
                      
                      <div className="col-sm-6">
                        {NACE_CODES.length ? NACE_CODES.map((NACE_CODE, i) =>
                          i >= 20 ? (
                          <React.Fragment key={i}>
                            <div className="fancy-checkbox">
                              <label>
                                <Input 
                                  disabled={readOnly}
                                  type="checkbox" 
                                  // disabled={true}
                                  name={`nace_codes`}
                                  // onChange={handleChange}
                                  onChange={() => {
                                    //if (values.typeStr !== 'ISO') {
                                      let { nace_codes } = values;

                                      if (nace_codes && nace_codes.length) {
                                        var index = nace_codes.indexOf(NACE_CODE.id);
                                        if (index !== -1) {
                                          nace_codes.splice(index, 1);
                                        } else {
                                          nace_codes.push(NACE_CODE.id);
                                        }
                                      } else {
                                        nace_codes = [];
                                        nace_codes.push(NACE_CODE.id);
                                      }

                                      setFieldValue('nace_codes', nace_codes);
                                    // }
                                  }}
                                  // value={CERTIFICATION.id}
                                  checked={values.nace_codes && values.nace_codes.length && values.nace_codes.indexOf(NACE_CODE.id) !== -1} 
                                />
                                <span>
                                  {NACE_CODE.code} <span className="text-muted">- {NACE_CODE.description}</span>
                                </span>
                              </label>
                            </div>
                          </React.Fragment>
                          ) : null
                        ) : null}
                      </div>
                    </div>

                    {/* <BootstrapForm.Text className="text-muted">
                      Identify the lowest level NACE code
                    </BootstrapForm.Text> */}
                  </FormGroup>
                </div>
                {/* <div className="col-sm-12">
                  <Label for="">Type of Industry (Please tick relevant to your industry)</Label>
                  <div className="row clearfix">
                    <div className="col-sm-6">
                      {INDUSTRIES.length ? INDUSTRIES.map((INDUSTRY, key) => 
                      key < 19 ? (
                        <React.Fragment key={key}>
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                readOnly={readOnly}
                                type="checkbox" 
                                name={`client.industries`}
                                // onChange={handleChange}
                                onChange={() => {
                                  let { industries } = values;

                                  if (!industries) {
                                    industries = [];
                                  }

                                  if (industries.length) {
                                    var index = industries.indexOf(INDUSTRY.id);
                                    if (index !== -1) {
                                      industries.splice(index, 1);
                                    } else {
                                      industries.push(INDUSTRY.id);
                                    }
                                  } else {
                                    industries.push(INDUSTRY.id);
                                  }

                                  setFieldValue('client.industries', industries);
                                }}
                                value={INDUSTRY.id}
                                checked={values.industries && values.industries.indexOf(INDUSTRY.id) !== -1} 
                              />
                              <span>
                                {INDUSTRY.name}
                              </span>
                            </label>
                          </div>
                        </React.Fragment>
                      ) : null
                      ) : null}
                    </div>
                    <div className="col-sm-6">
                      {INDUSTRIES.length ? INDUSTRIES.map((INDUSTRY, key) => 
                      key > 19 ? (
                        <React.Fragment key={key}>
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                readOnly={readOnly}
                                type="checkbox" 
                                name={`client.industries`}
                                onChange={() => {
                                  let { industries } = values;

                                  if (!industries) {
                                    industries = [];
                                  }

                                  if (industries.length) {
                                    var index = industries.indexOf(INDUSTRY.id);
                                    if (index !== -1) {
                                      industries.splice(index, 1);
                                    } else {
                                      industries.push(INDUSTRY.id);
                                    }
                                  } else {
                                    industries.push(INDUSTRY.id);
                                  }

                                  setFieldValue('client.industries', industries);
                                }}
                                value={INDUSTRY.id}
                                checked={values.industries && values.industries.indexOf(INDUSTRY.id) !== -1}
                              />
                              <span>
                                {INDUSTRY.name}
                              </span>
                            </label>
                          </div>
                        </React.Fragment>
                      ) : null
                      ) : null}
                    </div>
                  </div>
                </div> */}
              </div>
              : null}

            </div>
          </Card>


          {props.type === 'iso' ?
            <Card>
            <div className="header">
              <h2>CERTIFICATION(S) REQUIRED</h2>
            </div>
            <div className="body">
              <div className="row clearfix">
                <div className="col-sm-12">

                    {CERTIFICATIONS.length ? CERTIFICATIONS.map((CERTIFICATION, key) => 
                        <React.Fragment key={key}>
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                readOnly={readOnly}
                                type="checkbox" 
                                name={`client.certifications`}
                                // onChange={handleChange}
                                onChange={() => {
                                  let certifications = values.certifications ? values.certifications : [];

                                  if (certifications.length) {
                                    var index = certifications.indexOf(CERTIFICATION.id);
                                    if (index !== -1) {
                                      certifications.splice(index, 1);
                                    } else {
                                      certifications.push(CERTIFICATION.id);
                                    }
                                  } else {
                                    certifications.push(CERTIFICATION.id);
                                  }

                                  setFieldValue('certifications', certifications);

                                  // console.log(values.client.certifications);
                                }}
                                value={CERTIFICATION.id}
                                checked={values.certifications && values.certifications.length && values.certifications.indexOf(CERTIFICATION.id) !== -1} 
                              />
                              <span>
                                {CERTIFICATION.standard}
                              </span>
                            </label>
                          </div>
                        </React.Fragment>
                      ) : null}

                </div>
              </div>
            </div>
          </Card>
          : null}
          
          {props.type !== 'mspo-sccs' ?
            <Card>
            <div className="header">
              <h2>ADDITIONAL INFORMATION</h2>
            </div>
            <div className="body">
              <div className="row clearfix mt-4">
                <div className="col-sm-12">
                  <Label>When do you plan to be certified:</Label>

                  <div className="row clearfix">
                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="TentativeStage1Audit">Tentative Stage 1 Audit</Label>
                        <DatePicker
                          autoComplete="off"
                          name="application.tentative_s1_audit" 
                          id="TentativeStage1Audit"
                          value={values.application && values.application.tentative_s1_audit ? values.application.tentative_s1_audit : ""}
                          onChange={date => setFieldValue('application.tentative_s1_audit', moment(date).format('YYYY-MM-DD'))}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="TentativeStage2Audit">Tentative Stage 2 Audit</Label>
                        <DatePicker
                          autoComplete="off"
                          name="application.tentative_s2_audit" 
                          id="TentativeStage2Audit"
                          value={values.application && values.application.tentative_s2_audit ? values.application.tentative_s2_audit : ""}
                          onChange={date => setFieldValue('application.tentative_s2_audit', moment(date).format('YYYY-MM-DD'))}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="HasDocumentationReady">Has your management system documentation is ready?</Label>
                    <Input 
                      type="select" 
                      className="custom-select" 
                      name="application.documentation_ready" 
                      id="IsDocumentationReady"
                      onChange={handleChange}
                      value={values.application && values.application.documentation_ready ? values.application.documentation_ready : ""} 
                    >
                      <option></option>
                      <option value="Y">Yes (please proceed to the next questions)</option>
                      <option value="N">No</option>
                    </Input>
                  </FormGroup>
                </div>

                {values.application && values.application.documentation_ready && values.application.documentation_ready === 'Y' ?
                <div className="col-sm-6">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td style={{ borderTop: 'none' }}>Is it implemented?</td>
                        <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Input 
                              type="radio" 
                              name="documentation_implemented" 
                              value="Y" 
                              onClick={handleChange}
                              checked={values.documentation_implemented === 'Y'}
                            />
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.documentation_implemented"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.documentation_implemented', 'Y')
                              }}
                              checked={values.application && values.application.documentation_implemented ? values.application.documentation_implemented === 'Y' : ""}
                            />
                            <span>
                              <i></i> Yes
                            </span>
                          </label>
                        </td>
                        <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Input 
                              type="radio" 
                              name="documentation_implemented" 
                              value="N" 
                              onClick={handleChange}
                              checked={values.documentation_implemented === 'N'}
                            />
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.documentation_implemented"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.documentation_implemented', 'N')
                              }}
                              checked={values.application && values.application.documentation_implemented ? values.application.documentation_implemented === 'N' : ""}
                            />
                            <span>
                              <i></i> No
                            </span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderTop: 'none' }}>Have you conducted Internal Audit?</td>
                        <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Input 
                              type="radio" 
                              name="internal_audit_conducted" 
                              value="Y"
                              onClick={handleChange}
                              checked={values.internal_audit_conducted === 'Y'}
                            />
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.internal_audit_conducted"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.internal_audit_conducted', 'Y')
                              }}
                              checked={values.application && values.application.internal_audit_conducted ? values.application.internal_audit_conducted === 'Y' : ""}
                            />
                            <span>
                              <i></i> Yes
                            </span>
                          </label>
                        </td>
                        <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Input 
                              type="radio" 
                              name="internal_audit_conducted" 
                              value="N"
                              onClick={handleChange}
                              checked={values.internal_audit_conducted === 'N'}
                            />
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.internal_audit_conducted"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.internal_audit_conducted', 'N')
                              }}
                              checked={values.application && values.application.internal_audit_conducted ? values.application.internal_audit_conducted === 'N' : ""}
                            />
                            <span>
                              <i></i> No
                            </span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderTop: 'none' }}>Have you conducted management review?</td>
                        <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Input 
                              type="radio" 
                              name="management_review_conducted" 
                              value="Y" 
                              onClick={handleChange}
                              checked={values.management_review_conducted === 'Y'}
                            />
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.management_review_conducted"
                              value="1"
                              onClick={() => {
                                setFieldValue('application.management_review_conducted', 'Y')
                              }}
                              checked={values.application && values.application.management_review_conducted ? values.application.management_review_conducted === 'Y' : ""}
                            />
                            <span>
                              <i></i> Yes
                            </span>
                          </label>
                        </td>
                        <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                          {/* <FormGroup check>
                            <Input 
                              type="radio" 
                              name="management_review_conducted" 
                              value="N"
                              onClick={handleChange}
                              checked={values.management_review_conducted === 'N'}
                            />
                          </FormGroup> */}
                          <label className="fancy-radio">
                            <Input
                              type="radio"
                              name="application.management_review_conducted"
                              value="0"
                              onClick={() => {
                                setFieldValue('application.management_review_conducted', 'N')
                              }}
                              checked={values.application && values.application.management_review_conducted ? values.application.management_review_conducted === 'N' : ""}
                            />
                            <span>
                              <i></i> No
                            </span>
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                : null}
              </div>
            
              <div className="row clearfix">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="HasCertifiedOtherStandard">Has the company been certified to any other standards?</Label>
                    <Input 
                      type="select" 
                      className="custom-select" 
                      name="application.has_other_cert" 
                      id="HasCertifiedOtherStandard"
                      onChange={handleChange}
                      value={values.application && values.application.has_other_cert ? values.application.has_other_cert : ""}
                    >
                      <option></option>
                      <option value="Y">Yes (please specify standard)</option>
                      <option value="N">No</option>
                    </Input>
                  </FormGroup>
                </div>
                {values.application && values.application.has_other_cert && values.application.has_other_cert === 'Y' ?
                <React.Fragment>
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="OtherStandard">Standard</Label>
                      <Input 
                        readOnly={readOnly}
                        type="text" 
                        name="application.other_standard" 
                        id="OtherStandard"
                        onChange={handleChange}
                        value={values.application && values.application.other_standard ? values.application.other_standard : ""}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="CertificateCopy">Please Provide Copy of Certificate</Label>
                            <Input
                              type="file" 
                              name="certificate_copy" 
                              onChange={(event) => {
                                console.log(event.currentTarget.files[0])
                                setFieldValue(`certificate_copy`, event.currentTarget.files[0])
                              }}
                            />
                        <div>
                        {values.application.certificate_copy && values.application.certificate_copy.path && values.application.certificate_copy.url ?

                        <a className="btn btn-link" href={values.application.certificate_copy.url} target="_blank" >{values.application.certificate_copy.originalName}</a>  
                      : values.application.certificate_copy && values.application.certificate_copy.path ?
                        <a className="btn btn-link" href={values.application.certificate_copy.path} target="_blank" >{values.application.certificate_copy.originalName}</a> : null }</div>
                      {/* <div><a className="btn btn-link" href=""
                        onClick={() => {
                          window.open(`${process.env.REACT_APP_API_URL}/application-forms/view/${id}?token=${token}`);
                        }}
                        >{values.application.certificate_copy.originalName}</a></div> */}
                      {/* 
                      <Button
                          outline
                          color="success"
                          type="button"
                          onClick={() => {
                            window.open(`${values.application.certificate_copy.url}`, '_blank');
                          }}
                        ><i className="icon-cloud-download"></i> Download
                        </Button> */}

                      {/* <Input 
                          type="file" 
                          name="certificate_copy" 
                          id="CertificateCopy"
                          onChange={(event) => {
                            console.log(event.currentTarget.files[0]);
                            setFieldValue('certificate_copy', event.currentTarget.files[0])
                          }}
                        />
                        
                        {values.application.certificate_copy ? process.env.NODE_ENV === 'development' ? 
                          
                        <a 
                          href={`${values.application.certificate_copy}`} 
                          target="_blank"
                        >
                          Download
                        </a>

                          : 
                          <a 
                          href={`https://niosh.s3-ap-southeast-1.amazonaws.com/${values.application.certificate_copy}`} 
                          target="_blank"
                        >
                          Download
                        </a>

                           : 'No file chosen'}
 */}
                        
                    </FormGroup>
                  </div>
                </React.Fragment>
                : null}
              </div>

              <div className="row clearfix">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="HasConsultant">The company has consultant?</Label>
                    <Input 
                      type="select" 
                      className="custom-select" 
                      name="application.has_consultant" 
                      id="HasConsultant"
                      onChange={handleChange}
                      value={values.application && values.application.has_consultant ? values.application.has_consultant : ""}
                    >
                      <option></option>
                      <option value="Y">Yes (please state consultant name)</option>
                      <option value="N">No</option>
                    </Input>
                  </FormGroup>
                </div>
                {values.application && values.application.has_consultant && values.application.has_consultant === 'Y' ? 
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="ConsultantName">Consultant Name</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="application.consultant_name" 
                      id="ConsultantName"
                      onChange={handleChange}
                      value={values.application && values.application.consultant_name ? values.application.consultant_name : ""}
                    />
                  </FormGroup>
                </div>
                : null }
              </div>

              <div className="row clearfix">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="">How did you hear of NIOSH Certification?</Label>
                    <div className="fancy-checkbox">
                      <label>
                        <Input 
                          readOnly={readOnly}
                          type="checkbox" 
                          name="application.how_did_you_hear_newspaper"
                          onChange={() => {
                            // setFieldValue('application.how_did_you_hear_newspaper', values.application && values.application.how_did_you_hear_newspaper ? !values.application.how_did_you_hear_newspaper : values.application.how_did_you_hear_newspaper);

                            if (values.application && values.application.how_did_you_hear_newspaper) {
                              setFieldValue('application.how_did_you_hear_newspaper', !values.application.how_did_you_hear_newspaper);
                            } else {
                              setFieldValue('application.how_did_you_hear_newspaper', 1);
                            }
                          }}
                          value="1"
                          checked={values.application && values.application.how_did_you_hear_newspaper ? values.application.how_did_you_hear_newspaper : ""} 
                        />
                        <span>
                          Newspaper
                        </span>
                      </label>
                    </div>

                    <div className="fancy-checkbox">
                      <label>
                        <Input 
                          readOnly={readOnly}
                          type="checkbox" 
                          name="application.how_did_you_hear_website"
                          onChange={() => {
                            // setFieldValue('application.how_did_you_hear_website', values.application && values.application.how_did_you_hear_website ? !values.application.how_did_you_hear_website : "");

                            if (values.application && values.application.how_did_you_hear_website) {
                              setFieldValue('application.how_did_you_hear_website', !values.application.how_did_you_hear_website);
                            } else {
                              setFieldValue('application.how_did_you_hear_website', 1);
                            }
                          }}
                          value="1"
                          checked={values.application && values.application.how_did_you_hear_website ? values.application.how_did_you_hear_website : ""} 
                        />
                        <span>
                          Website
                        </span>
                      </label>
                    </div>

                    <div className="fancy-checkbox">
                      <label>
                        <Input 
                          readOnly={readOnly}
                          type="checkbox" 
                          name="application.how_did_you_hear_facebook"
                          // onClick={handleChange}
                          onChange={() => {
                            // setFieldValue('application.how_did_you_hear_facebook', values.application && values.application.how_did_you_hear_facebook ? !values.application.how_did_you_hear_facebook : "");

                            if (values.application && values.application.how_did_you_hear_facebook) {
                              setFieldValue('application.how_did_you_hear_facebook', !values.application.how_did_you_hear_facebook);
                            } else {
                              setFieldValue('application.how_did_you_hear_facebook', 1);
                            }
                          }}
                          value="1"
                          checked={values.application && values.application.how_did_you_hear_facebook ? values.application.how_did_you_hear_facebook : ""} 
                        />
                        <span>
                          Facebook/Blog
                        </span>
                      </label>
                    </div>

                    <div className="fancy-checkbox">
                      <label>
                        <Input 
                          readOnly={readOnly}
                          type="checkbox" 
                          name="application.how_did_you_hear_others"
                          // onClick={handleChange}
                          onChange={() => {
                            // setFieldValue('application.how_did_you_hear_others', values.application && values.application.how_did_you_hear_others ? !values.application.how_did_you_hear_others : "");

                            if (values.application && values.application.how_did_you_hear_others) {
                              setFieldValue('application.how_did_you_hear_others', !values.application.how_did_you_hear_others);
                            } else {
                              setFieldValue('application.how_did_you_hear_others', 1);
                            }
                          }}
                          value="1"
                          checked={values.application && values.application.how_did_you_hear_others ? values.application.how_did_you_hear_others : ""} 
                        />
                        <span>
                          Others
                        </span>
                      </label>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          </Card>
          : null}

          <Card>
            <div className="header">
              <h2>DECLARATION</h2>
            </div>
            <div className="body">
              <div className="row clearfix">
                <div className="col-sm-12">
                  <Label for="">I/We hereby declared that the information given in this application is true.</Label>
                </div>
              </div>

              <div className="row clearfix pb-5">
                <div className="col-sm-6">
                  {/* <SignatureCanvas 
                    canvasProps={{width: 600, height: 200, className: 'sigCanvas'}} 
                    ref={(ref) => { sigCanvas = ref }}
                    onEnd={() => {
                      values.declaration_signature = sigCanvas.toDataURL();
                    }}
                  /> */}
                  <SignaturePad 
                    // width={500} 
                    // height={200}
                    ref={ref => sigCanvas = ref} 
                    // onEnd={() => {
                    //   values.declaration_signature = sigCanvas.toDataURL();
                    // }}
                  />
                  {!readOnly ?
                  <Button 
                    block 
                    outline
                    type="button"
                    onClick={() => {
                      sigCanvas.clear();
                      
                      values.application.declaration_signature = null;
                    }}>
                      Clear signature
                    </Button>
                    : null}
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="DeclarationName">Name</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="application.declaration_name" 
                      id="DeclarationName"
                      onChange={handleChange}
                      value={values.application && values.application.declaration_name ? values.application.declaration_name : ""}
                    />
                  </FormGroup>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="DeclarationPosition">Position</Label>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="application.declaration_position" 
                      id="DeclarationPosition"
                      onChange={handleChange}
                      value={values.application && values.application.declaration_position ? values.application.declaration_position : ""}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                  <FormGroup>
                    <Label for="DeclarationDate">Date</Label>
                    <DatePicker
                      autoComplete="off"
                      name="application.declaration_date" 
                      id="DeclarationDate"
                      value={values.application && values.application.declaration_date ? values.application.declaration_date : ""}
                      onChange={date => setFieldValue('application.declaration_date', moment(date).format('YYYY-MM-DD'))}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </Card>
          <Card>
            <div className="header">
              <h2>DISCLAIMER</h2>
            </div>
            <div className="body">
              <div className="row clearfix pb-5">
                <div className="col-sm-12">
                  Disclaimer regarding use of consultancy services. NIOSH Certification does not warrant or agree with any statement or suggestion that certification would be creation or preparation of a management system.
                </div>
              </div>
              <div className="row clearfix">
                <div className="col-sm-4">
                  {!readOnly ?
                  <React.Fragment>
                  <button 
                    type="submit" 
                    className="btn btn-primary"
                    disabled={isSubmitting ? true : false}
                  >
                    {id ? 'Update' : 'Create'}
                  </button>&nbsp;
                  </React.Fragment>
                  : null}
                  <Button 
                    // className="btn btn-outline-secondary"
                    outline
                    color="secondary" 
                    tag={Link} 
                    to={props.path}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
        {/* <div class="col-sm-12">
          <div class="mt-4">
            
          </div>
        </div> */}
      </div>
      </FormikForm>
      )}
    </Formik>
  )
}

export default Form;