import React, { useState, useEffect } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import MetisMenu from 'metismenujs';

// import Menu from '../Menu';

import api from '../../utils/api';

const NCSBMenu = (props) => {

  const { role } = props;

  let { path } = useRouteMatch();
  
  useEffect(() => {
    new MetisMenu(".metismenu");
  }, []);

  return (
    <React.Fragment>
      <ul className="main-menu metismenu">
        <li className={path === '/technical' || path.includes('/analytics') ? 'active' : null}>
          <a href="#" className="has-arrow">
            <i className="icon-speedometer"></i><span>PIP Analytic</span>
          </a>
          <ul>
            <li className={path === '/technical' ? 'active' : null}>
              <Link to="/technical">
              Technical
              </Link>
            </li>
            <li className={path.includes('/analytics') ? 'active' : null}>
              <Link to="/analytics">
                Operation
              </Link>
            </li>
          </ul>
        </li>
        <li className={path.includes('/boss-chart') ? 'active' : null}>
          <Link to="/boss-chart">
            <i className="icon-graph"></i><span>Boss Chart</span>
          </Link>
        </li>
      </ul>

      {/* <ul className="main-menu metismenu">
        <Menu 
          title="Dashboard"
          icon="speedometer"
          to="/" 
        />

        <Menu 
          title="PIP Analytics"
          icon="speedometer"
          to="/analytics" 
        />

        <Menu 
          title="Boss Chart"
          icon="graph"
          to="/boss-chart"
          // onClick={() => setActiveMenu('user')}
          // activeMenu={activeMenu}
        />
      </ul> */}
    </React.Fragment>
  );
}

export default NCSBMenu;