import React from 'react';

import {
  Redirect,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

// import ISO from './ISO';
// import MSPO from './MSPO';
// import MSPO_SCCS from './MSPO_SCCS';

import Form from './Form';

const BasicDataInformation = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route 
          path={`${path}/edit/:id`} 
          render={(props) => <Form path={path} {...props} />}
        />
    </Switch>
  )
}

export default BasicDataInformation;