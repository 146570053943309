import React, { useEffect, useState } from "react";

import {
  // Link,
  useParams,
} from "react-router-dom";

// import BootstrapTable from 'react-bootstrap-table-next';

import {
  // Button,
  // Card,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from "formik";

import moment from 'moment';

import DateRangePicker from 'react-bootstrap-daterangepicker';

import { useToasts } from 'react-toast-notifications';

import api from "../../../../utils/api";

import classnames from 'classnames';

// const HtmlToReactParser = require('html-to-react').Parser;
// const htmlToReactParser = new HtmlToReactParser();

let config = {
  document: {
    url: ''
  }
};

let editor;
const INITIAL_VALUES = {
  certification_info: [],
};
const CertificateMultisites = (props) => {
  let { id } = useParams();

  const { addToast } = useToasts();

  const [data, setData] = useState({});

  const [token, setToken] = useState('');

  const [load, reload] = useState(false);
  
  const [activeTab1, setActiveTab1] = useState(1);

  const [activeTab2, setActiveTab2] = useState(1);

  const [readOnly, setReadOnly] = useState(props.readOnly);


  const toggle1 = tab1 => {
    if(activeTab1 !== tab1) setActiveTab1(tab1);
  }
  const toggle2 = tab2 => {
    if(activeTab2 !== tab2) setActiveTab2(tab2);
  }
  // const [editor, setEditor] = useState();
  
  // const [config, setConfig] = useState({});
  
  useEffect(() => {
    // const getToken = async () => {
    //   let token = await localStorage.getItem('auth-token');

    //   token = JSON.parse(token);

    //   setToken(token.access_token);
    // }

    // getToken();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);
        
        setToken(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;

          setData(response.data.data);
          console.log(response.data.data);
          if (response.data && response.data.meta && response.data.meta.roles) {
            // console.log(response.data.meta.roles.find(o => o.name.toLowerCase() === 'auditor'))
            if (response.data.meta.roles.find(o => o.name.toLowerCase() === 'auditor')) {
              setReadOnly(true);
            }
          }
          
          if (
            response.data.data.audit_plan
            && response.data.data.audit_plan.type
            && response.data.data.audit_plan.type.toUpperCase() === 'ISO'
            && response.data.data.multisites){
            response.data.data.multisites.map((certification, i) => {
              // if (token) {
              config = {
                type: 'desktop',
                height: '1000px',
                document: {
                    fileType: 'docx',
                    key: `${id}-${Date.now()}`,
                    title: 'Certificate',
                    url: `${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&cert=${certification.certification_id}&scope=${certification.id}&token=${token.access_token}&time=${Date.now()}`,
                    permissions: {
                        download: false,
                        edit: false,
                        print: false,
                        review: false,
                    }
                  },
                  documentType: 'text',
                  editorConfig: {
                      callbackUrl: '',
                      mode: 'view',
                      customization: {
                          zoom: 70,
                          customer: {
                              address: '',
                              info: '',
                              logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                              mail: 'iskandar@greentagpro.com',
                              name: 'Greentagpro Solution',
                              www: 'greentagpro.com',
                          },
                          chat: false,
                          forcesave: true,
                          comments: false,
                      }
                  },
              }

              new window.DocsAPI.DocEditor(`placeholder_${certification.id}`, config);
            })
           
            // }
          } else {
            config = {
                type: 'desktop',
                height: '1000px',
                document: {
                    fileType: 'docx',
                    key: `${id}-${Date.now()}`,
                    title: 'Certificate',
                    url: `${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&token=${token.access_token}&time=${Date.now()}`,
                    permissions: {
                        download: false,
                        edit: false,
                        print: false,
                        review: false,
                    }
                  },
                  documentType: 'text',
                  editorConfig: {
                      callbackUrl: '',
                      mode: 'view',
                      customization: {
                          zoom: 70,
                          customer: {
                              address: '',
                              info: '',
                              logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                              mail: 'iskandar@greentagpro.com',
                              name: 'Greentagpro Solution',
                              www: 'greentagpro.com',
                          },
                          chat: false,
                          forcesave: true,
                          comments: false,
                      }
                  },
              }

              new window.DocsAPI.DocEditor('placeholder', config);
          }

          reload(false);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }

    // if (id) {
    //   const getData = async () => {
    //     let token = await localStorage.getItem('auth-token');

    //     token = JSON.parse(token);

    //     api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    //     api.get('/audits/certificate/' + id)
    //     .then(function (response) {
    //       console.log(response.data);

    //       // delete response.data.id;

    //       setData(response.data);
    //     })
    //     .catch(function (error) {
    //       if (error && error.response && error.response.data) {
    //         addToast(error.response.data.message, { appearance: 'error' });
    //       } else {
    //         addToast(error.message, { appearance: 'error' });
    //       }
    //     });
    //   }

    //   getData();
    // }
  }, [id, config]);

  return (
    <React.Fragment>
      {/* <Card>
        <div className="header">
          <h2></h2>
        </div>
        <div className="body">
          
        </div>
      </Card> */}
      <div className="card invoice1">
        <div className="body">

          <Nav pills key={1}>
          {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'ISO' && data.audit_plan.client && data.audit_plan.client.certifications ? data.audit_plan.client.certifications.map((certification, i) => 
            <NavItem key={certification.id}>
              <NavLink
                  className={classnames({ active: activeTab1 === certification.id })}
                  onClick={() => { 
                    toggle1(certification.id);
                  }}
                >
                  {certification.code}
                </NavLink>
            </NavItem>
          ) : null}
          </Nav>
        <hr />

        <Nav pills key={2}>
          {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'ISO' && data.multisites && data.multisites.length ? data.multisites.map((multisite, i) => multisite.certification_id===activeTab1 ?
            <NavItem key={multisite.id}>
              <NavLink
                  className={classnames({ active: activeTab2 === multisite.id })}
                  onClick={() => { 
                    toggle2(multisite.id);
                  }}
                >
                  {multisite.site_name}
                </NavLink>
            </NavItem>
          : null) : null}
          </Nav>
          
          <TabContent activeTab={activeTab2}>
            {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'ISO' && data.certification_multisites ? 
            data.certification_multisites.map((certification, i) => /* certification.asoc_id === activeTab2 ? */
            <TabPane tabId={certification.asoc_id}>
              <Formik 
                enableReinitialize={true}
                initialValues={data}
                onSubmit={async (values, { setSubmitting }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
          //        values.certification_id = certification.id;
                  await api.put('/audits/' + id, JSON.stringify(values), {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                  .then(function (response) {
                    addToast('Certificate information successfully updated', { appearance: 'success' });

                    reload(true);

                    window.location.reload();
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });
                }}
              >
              {({ isSubmitting, values, setFieldValue, handleChange }) => (
                
                <FormikForm>
                  <div className="row">
                   
                  <div className="col-md-3">
                    <FormGroup>
                      <Label for="CertificateNo">Certificate No.</Label>
                      <Input 
                        readOnly={readOnly}
                        // disabled={true}
                        type="text" 
                        // className="custom-select" 
                        //name="certificate_no"
                        name={`certification_multisites.${i}.certificate_no`}
                        onChange={(event) => {
                          setFieldValue(`certification_multisites.${i}.certificate_no`, event.currentTarget.value);  
                        }}
                        id={`CertificateNo${certification.id}`}
                        //value = {values.certification_multisites[i].certificate_no}
                       // value={values.certification_multisites && values.certification_multisites[i].certificate_id === certification.id && values.certification_multisites[i].certificate_no ? values.certification_multisites[i].certificate_no : null}
                        value={values.certification_multisites && values.certification_multisites[i].certificate_no ? values.certification_multisites[i].certificate_no : null}
                        // onChange={(e) => {
                        //   // console.log(e.target.name, e.target.value);

                        //   setFieldValue(e.target.name, e.target.value);
                        //   // setFieldValue('reviewed_at', "");
                        // }}
                       // onChange={handleChange}
                       // value={values.certificate_no}
                        // required={true}
                      />
                      <Input 
                        type="hidden" 
                        name={`certification_multisites.${i}.certificate_id`}
                      //  id={`CertificateId${certification.id}`}
                        value={values.certification_multisites && values.certification_multisites[i].certificate_id ?  values.certification_multisites[i].certificate_id :null}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                      <Label for="InitialCertificateDate">Initial Certificate Date</Label>
                      <DateRangePicker 
                        disabled={readOnly}
                        singleDatePicker={true}
                        containerStyles={{ display: "block" }}
                        startDate={values.date_of_audit_start && values.certification_multisites && values.certification_multisites.length && values.certification_multisites[i].initial_certificate_date ? moment(values.certification_multisites[i].initial_certificate_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        onApply={(event, picker) => {
                          setFieldValue(`certification_multisites.${i}.initial_certificate_date`, picker.startDate.format('YYYY-MM-DD'));
                          // setFieldValue('date_of_audit_end', picker.endDate.format('YYYY-MM-DD'));
                        }}
                      >
                        <Input 
                          autoComplete="off"
                          readOnly={readOnly}
                          type="text" 
                          // name="date_of_audit" 
                          id={`InitialDate${certification.id}`}
                          // onChange={handleChange}
                          value={values.certification_multisites && values.certification_multisites.length && values.certification_multisites[i].initial_certificate_date ? moment(values.certification_multisites[i].initial_certificate_date).format('DD/MM/YYYY') : ""}
                        />
                      </DateRangePicker>
                    </FormGroup>
                  </div>
                  <div className="col-md-3">
                    <FormGroup>
                      <Label for="ValidUntil">Valid Until</Label>
                      <DateRangePicker 
                        disabled={readOnly}
                        singleDatePicker={true}
                        showDropdowns={true}
                        minDate={moment()}
                        containerStyles={{ display: "block" }}
                        startDate={values.certification_multisites && values.certification_multisites.length && values.certification_multisites[i].valid_until ? moment(values.certification_multisites[i].valid_until).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        onApply={(event, picker) => {
                          setFieldValue(`certification_multisites.${i}.valid_until`, picker.startDate.format('YYYY-MM-DD'));
                          // setFieldValue('date_of_audit_end', picker.endDate.format('YYYY-MM-DD'));
                        }}
                      >
                        <Input 
                          autoComplete="off"
                          readOnly={readOnly}
                          type="text" 
                          // name="date_of_audit" 
                          id={`ValidUntil${certification.id}`}
                          // onChange={handleChange}
                          value={values.certification_multisites && values.certification_multisites.length && values.certification_multisites[i].valid_until ? moment(values.certification_multisites[i].valid_until).format('DD/MM/YYYY') : null}
                        />
                      </DateRangePicker>
                    </FormGroup>
                  </div>
                  <div className="col-md-2">
                    
                    <FormGroup>
                      <Label for="">&nbsp;</Label>
                      <button 
                        disabled={isSubmitting || readOnly}
                        type="submit" 
                        className="btn btn-primary btn-block"
                      >
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                    </FormGroup>
                  </div>
                  
                </div>
                </FormikForm>
              )}
              </Formik>
              <div id={`placeholder_${certification.asoc_id}`}></div>
              
              <hr />

              <div className="col-md-12 text-right">
                <button 
                  // disabled={isSubmitting}
                  className="btn btn-outline-secondary" 
                  type="button" 
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&cert=${certification.certificate_id}&scope=${certification.asoc_id}&token=${token.access_token}`);
                  }}
                >
                  <i className="icon-arrow-down"></i> Download Certificate
                </button>
              </div>
            </TabPane>
            
            /* : null */)  : null}
            </TabContent>

           
            
         
          
          {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'MSPO' ? 
            <div>
              <Formik 
                enableReinitialize={true}
                initialValues={data}
                onSubmit={async (values, { setSubmitting }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
          //        values.certification_id = certification.id;
                  await api.put('/audits/' + id, JSON.stringify(values), {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                  .then(function (response) {
                    addToast('Certificate information successfully updated', { appearance: 'success' });

                    reload(true);

                    window.location.reload();
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });
                }}
              >
              {({ isSubmitting, values, setFieldValue, handleChange }) => (
                
                <FormikForm>
                  <div className="row">
                   
                  <div className="col-md-3">
                    <FormGroup>
                      <Label for="CertificateNo">Certificate No.</Label>
                      <Input 
                        readOnly={readOnly}
                        type="text" 
                        name={`certification_info.0.certificate_no`}
                        onChange={(event) => {
                          setFieldValue(`certification_info.0.certificate_no`, event.currentTarget.value);
                        }}
                        id={`CertificateNo`}
                        value={values.certification_info && values.certification_info[0].certificate_no ? values.certification_info[0].certificate_no : null}
                      />
                      <Input 
                        type="hidden" 
                        name={`certification_info.0.certificate_id`}
                        value={values.certification_info && values.certification_info[0].certificate_id ?  values.certification_info[0].certificate_id :null}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                      <Label for="InitialCertificateDate">Initial Certificate Date</Label>
                      <DateRangePicker 
                        disabled={readOnly}
                        singleDatePicker={true}
                        containerStyles={{ display: "block" }}
                        startDate={values.date_of_audit_start && values.certification_info && values.certification_info.length && values.certification_info[0].initial_certificate_date ? moment(values.certification_info[0].initial_certificate_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        onApply={(event, picker) => {
                          setFieldValue(`certification_info.0.initial_certificate_date`, picker.startDate.format('YYYY-MM-DD'));
                        }}
                      >
                        <Input 
                          autoComplete="off"
                          readOnly={readOnly}
                          type="text" 
                          id={`InitialDate`}
                          value={values.certification_info && values.certification_info.length && values.certification_info[0].initial_certificate_date ? moment(values.certification_info[0].initial_certificate_date).format('DD/MM/YYYY') : ""}
                        />
                      </DateRangePicker>
                    </FormGroup>
                  </div>
                  <div className="col-md-3">
                    <FormGroup>
                      <Label for="ValidUntil">Valid Until</Label>
                      <DateRangePicker 
                        disabled={readOnly}
                        singleDatePicker={true}
                        showDropdowns={true}
                        minDate={moment()}
                        containerStyles={{ display: "block" }}
                        startDate={values.certification_info && values.certification_info.length && values.certification_info[0].valid_until ? moment(values.certification_info[0].valid_until).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        onApply={(event, picker) => {
                          setFieldValue(`certification_info.0.valid_until`, picker.startDate.format('YYYY-MM-DD'));
                        }}
                      >
                        <Input 
                          autoComplete="off"
                          readOnly={readOnly}
                          type="text" 
                          id={`ValidUntil`}
                          value={values.certification_info && values.certification_info.length && values.certification_info[0].valid_until ? moment(values.certification_info[0].valid_until).format('DD/MM/YYYY') : null}
                        />
                      </DateRangePicker>
                    </FormGroup>
                  </div>
                  <div className="col-md-2">
                    
                    <FormGroup>
                      <Label for="">&nbsp;</Label>
                      <button 
                        disabled={isSubmitting || readOnly}
                        type="submit" 
                        className="btn btn-primary btn-block"
                      >
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </button>
                    </FormGroup>
                  </div>
                  
                </div>
                </FormikForm>
              )}
              </Formik>
              <div id="placeholder"></div>
              
              <hr />

              <div className="col-md-12 text-right">
                <button 
                  // disabled={isSubmitting}
                  className="btn btn-outline-secondary" 
                  type="button" 
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=certificate&token=${token.access_token}`);
                  }}
                >
                  <i className="icon-arrow-down"></i> Download Certificate
                </button>
              </div>
            </div>
          : null}
          
        </div>
      </div>
    </React.Fragment>
  )
}

export default CertificateMultisites;
