import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
  useHistory,
  // useParams,
  useLocation,
} from 'react-router-dom';

import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import SidebarToggler from '../../components/SidebarToggler';

import api from '../../utils/api';

import { useToasts } from 'react-toast-notifications';

import moment from 'moment';

import qs from 'query-string';

const INITIAL_QUERY = {}

const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Listing = (props) => {
  let { path } = useRouteMatch();
  let history = useHistory();
  let queryString = useQuery();

  const [profile, setProfile] = useState({});
  const [data, setData] = useState([]);
  const [query, setQuery] = useState(INITIAL_QUERY);
  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);

  const { addToast } = useToasts();

  const columns = [
    {
      editable: false,
      text: "No",
      formatter: (cell, row, rowIndex) => {
       let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
        return <span>{rowNumber}.</span>;
      }
    },
    {
      text: profile && profile.roles && profile.roles[0].name.toLowerCase() === 'ncsb admin' ? 'Auditor Name' :'Company Name',
      formatter: (cell, row, rowIndex) => {
          return (profile && profile.roles && profile.roles[0].name.toLowerCase() === 'ncsb admin') ? row.auditor ?  row.auditor.full_name
          : '' : row.audit_plan && row.audit_plan.client &&  row.audit_plan.client.name ? row.audit_plan.client.name :'' ;
      }
    },
  {
    text: 'Date of Audit',
    formatter: (cell, row, rowIndex) => {
      return row.audit_plan && row.audit_plan.date_of_audit_start && row.audit_plan.date_of_audit_end ? moment(row.audit_plan.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(row.audit_plan.date_of_audit_end).format('DD/MM/YYYY') : ""
    }
  },
  
  {
    text: 'Claim Date',
    formatter: (cell, row, rowIndex) => {
      return row.created_at  ? moment(row.created_at).format('DD/MM/YYYY') : ""
    }
  }, {
    text: 'Total Amount (RM)',
    formatter: (cell, row, rowIndex) => {
      return row.total_amount  ? row.total_amount : "";
    }
  }, {
    text: 'Status',
    formatter: (cell, row, rowIndex) => {
      if(row.action && row.action=="save_draft"){
        return (<span><Badge color="warning">Draft</Badge> 
        </span>)
      }
     
    }
  },
  {
    dataField: 'id',
    text: ' ',
    csvExport: false,
    formatter: (cell, row, rowIndex) => {
      return (
        <ButtonGroup size="sm">
          
          <Button outline tag={Link}  to={`${path}/view/${cell}`} >
            <i className="icon-eye"></i>
          </Button>
          <Button outline tag={Link}  to={`${path}/edit/${cell}`}>
            <i className="icon-pencil"></i>
          </Button>
          <Button outline color="danger">
            <i className="icon-trash"></i>
          </Button>
        </ButtonGroup>
      )
    },
    style: { textAlign: 'center' }
  }];

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
         setProfile(response.data);
      })
      .catch(function (error) {
      });
    }

    getMe();
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/auditor-claims', {
        params: query,
      })
      .then(function (response) {
         console.log(response);

        setData(response.data.data);

        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
        }));
      })
      .catch(function (error) {
        console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getData();
  }, [props, query]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2>
                <SidebarToggler />
                Auditor Claim
              </h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                Auditor Claim
                </li>
                <li className="breadcrumb-item">
                  <Link to={props.path}>
                    Setting
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>SETTING LIST</h2>
                {<ul className="header-dropdown">
                  <li>
                    <Button 
                      className="m-t-10 m-l-20"
                      color="primary" 
                      tag={Link} 
                      to={`${path}/create`}
                    >
                      Add New
                    </Button>
                  </li>
                </ul>}
              </div>
              <div className="body">
            <Formik
              enableReinitialize={true}
              initialValues={{
                name  : queryString.get('name') ? queryString.get('name') : '',
               
                // state: queryString.get('state') ? queryString.get('state') : "",
                // certifications: queryString.get('certifications') ? queryString.get('certifications') : "",
                // status: queryString.get('status') ? queryString.get('status') : "ACTIVE",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                var searchString = qs.stringify(values); // objectToFormData(values, {indices: true, booleansAsIntegers: true});
                setQuery(values);

                history.push({
                  pathname: path,
                  search: searchString
                });

                setQuery(values);
              }}
            >
              {({ isSubmitting, values, setFieldValue, handleChange }) => (
              <FormikForm>
                <FormGroup>
                  <Label for="Name">{profile && profile.roles && profile.roles[0].name.toLowerCase() === 'ncsb admin' ? 'Auditor Name' : 'Company Name'}</Label>
                  <Input 
                    type="text" 
                    // className="custom-select" 
                    name="name"
                    id="Name"
                    onChange={handleChange}
                    value={values.name}
                  />
                </FormGroup>

                <div className="row clearfix">
                  <div className="col-sm-4">
                    <Label>&nbsp;</Label>
                    <FormGroup>
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        // tag={Link} 
                        // to="/client-database"
                      >
                        Search
                      </Button>
                      <Button 
                        // className="btn btn-outline-secondary"
                        type="button"
                        outline
                        color="link" 
                        // tag={Link} 
                        // to={`${path}`}
                        onClick={() => {
                          history.push({
                            pathname: path,
                          });
          
                          setQuery(INITIAL_QUERY);
                        }}
                      >
                        Reset
                      </Button>
                    </FormGroup>
                  </div>
                </div>
              </FormikForm>
              )}
            </Formik>

            <hr />
          </div>
              <div className="body project_report">
                <div className="table">
                  <BootstrapTable 
                    remote={true}
                    bootstrap4
                    bordered={false}
                    classes="table-hover js-basic-example dataTable table-custom m-b-0"
                    headerClasses="thead-dark"
                    keyField="id"
                    data={ data } 
                    columns={ columns }
                    pagination={ 
                      paginationFactory(pagingInfo)
                    }
                    onTableChange={handleTableChange}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Listing;