import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  // Badge,
  Button,
  ButtonGroup,
  Card,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import api from '../../utils/api';

import { useToasts } from 'react-toast-notifications';

// import { objectToFormData } from 'object-to-formdata';

import qs from 'query-string';

const INITIAL_QUERY = {}

const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Listing = (props) => {
  let { path } = useRouteMatch();
  let history = useHistory();
  let queryString = useQuery();
  const [refresh, setRefreshing] = useState(false);
  
  const [data, setData] = useState([]);
  const [query, setQuery] = useState(INITIAL_QUERY);
  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);
  
  const { addToast } = useToasts();
  
  const columns = [
  {
    dataField: 'start_date',
    text: 'Start Date',
    style: {
      whiteSpace: 'pre-wrap',
    },
  },
  {
    dataField: 'end_date',
    text: 'End Date',
    style: {
      whiteSpace: 'pre-wrap',
    },
  },
  {
    dataField: 'objectives',
    text: 'Descriptions',
    style: {
      whiteSpace: 'pre-wrap',
    },
  },
  {
    dataField: 'location',
    text: 'Location',
    style: {
      whiteSpace: 'pre-wrap',
    },
  },
  {
    dataField: 'id',
    text: ' ',
    formatter: (cell, row, rowIndex) => {
      return (
        <ButtonGroup size="sm">
          <Button outline tag={Link} to={`${path}/view/${cell}`} >
            <i className="icon-eye"></i>
          </Button>
          
          <Button outline tag={Link} to={`${path}/edit/${cell}`}>
          <i className="icon-pencil"></i>
          </Button>
          
          <Button 
            disabled={cell <= 2 ? true : false}
            outline 
            color="danger" 
            onClick={() => deleteHandler(cell)}>
            <i className="icon-trash"></i>
          </Button>
          
        </ButtonGroup>
      )
    },
    style: { textAlign: 'center' }
  }
  ];
  
  useEffect(() => {
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/meeting-notifications?' + queryString.toString(), {
        params: query,
      })
      .then(function (response) {
        // console.log(response);

        setData(response.data.data);

        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
        }));
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });

      setRefreshing(false);
    }

    getData();
  }, []);
  
  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }
  
  const deleteHandler = async (id) => {
    if (window.confirm('Delete this record?')) {
      // let token = await localStorage.getItem('auth-token');

      // token = JSON.parse(token);

      // api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      // api.delete('/users/' + id)
      // .then(function (response) {
      //   // console.log(response);

      //   // setData(response.data.data);

      //   addToast('User successfully deleted', { appearance: 'success' });

      //   history.push(path);

      //   setRefreshing(true);
      // })
      // .catch(function (error) {
      //   // console.log(error.response);
      //   addToast(error.response.data.message, { appearance: 'error' });
      // });
    }
  }
  
  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <Card>
          <div className="header">
            <h2>NOTIFICATION LIST</h2>
            
            <ul className="header-dropdown">
              <li>
                <Button 
                  className="m-t-10 m-l-20"
                  color="primary" 
                  tag={Link} 
                  to={`${path}/create`}
                >
                  Add New
                </Button>
              </li>
            </ul>
           
          </div>
          <div className="body">
            <div className="body project_report">
            <div className="table">
              <BootstrapTable 
                remote={true}
                bootstrap4
                bordered={false}
                classes="table-hover js-basic-example dataTable table-custom m-b-0"
                headerClasses="thead-dark"
                keyField='id' 
                data={ data } 
                columns={ columns }
                pagination={ 
                  paginationFactory(pagingInfo)
                }
                onTableChange={handleTableChange}
              />
            </div>
          </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Listing;