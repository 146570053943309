import React, { useEffect, useState } from "react";

import {
  Link,
  useParams,
} from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

// import moment from 'moment';

// import DateRangePicker from 'react-bootstrap-daterangepicker';

// import {
//   OverlayTrigger,
//   Tooltip,
// } from "react-bootstrap";

import {
  Button,
  // ButtonGroup,
  Card,
  FormGroup,
  Input,
  Label,
  // ListGroup,
  // ListGroupItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import BootstrapSwitchButton from "bootstrap-switch-button-react"

import classnames from 'classnames';

import { 
  Formik,
  Form as FormikForm
} from "formik";

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import api from "../../../../utils/api";

import "./styles.css";

const AuditInformation = (props) => {
  let { id, type } = useParams();

  const { addToast } = useToasts();

  const [data, setData] = useState({scope_of_certifications: []});

  // const [auditStartDate, setAuditStartDate] = useState();

  // const [auditEndDate, setAuditEndDate] = useState();

  const [load, reload] = useState(false);

  const [activeTab, setActiveTab] = useState(1);

  const [CERTIFICATIONS, setCertifications] = useState({});

  let tabs = [
    {
      id: 1,
     // label: "Scope Of Certification",
      label: "Multisites of Certification",
    }, {
      id: 2,
      label: "Audit Standards",
    }, {
      id: 3,
      label: "Auditor Information",
    }, {
      id: 4,
      label: "Audit Objective",
    }, {
      id: 5,
      label: "Previous Audit Result",
    }, 
    // {
    //   id: 6,
    //   label: "Changes From Previous Audit",
    // }, 
    {
      id: 7,
      label: "Client Reference Document",
    }, {
      id: 8,
      label: "Exclusions",
    }
  ];

  if (type.toLowerCase() !== 'iso') {
    tabs = [
      {
        id: 1,
        label: "Scope Of Certification",
      }, {
        id: 2,
        label: "Audit Standards",
      }, {
        id: 3,
        label: "Auditor Information",
      }, {
        id: 4,
        label: "Audit Objective",
      }, {
        id: 5,
        label: "Previous Audit Result",
      }, {
        id: 6,
        label: "Changes From Previous Audit",
      }, {
        id: 7,
        label: "Other certification held",
      }
    ];
  }

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  // const duration = useMemo(() => {
  //   if (auditStartDate && auditEndDate) {
  //     let duration = auditEndDate.diff(auditStartDate, 'days') + 1;
  //     if (duration > 1) {
  //       return duration + ' days';
  //     }
  //     return duration + ' day';
  //   }
  // }, [auditStartDate, auditEndDate]);

  useEffect(() => {
    // const getCertifications = async () => {
    //   let token = await localStorage.getItem('auth-token');

    //   token = JSON.parse(token);

    //   api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    //   api.get('/lookup/certifications')
    //   .then(function (response) {
    //     // console.log(response.data);

    //     let certifications = [];
    //     // response.data.map((CERTIFICATION, i) => 
    //     //   certifications.push({
    //     //     value: CERTIFICATION.id,
    //     //     label: CERTIFICATION.standard,
    //     //   })
    //     // )

    //     if (
    //       response.data.client &&
    //       response.data.client.certifications &&
    //       response.data.client.certifications.length
    //     ) {
    //       response.data.client.certifications.map((certification, i) => 
    //         certifications[certification.id] = {
    //           value: certification.id,
    //           label: certification.standard,
    //         }
    //       );

    //       console.log(certifications);

    //       setCertifications(certifications);
    //     }
    //   })
    //   .catch(function (error) {
    //     // console.log(error.response);
    //     if (error.response) {
    //       addToast(error.response.data.message, { appearance: 'error' });
    //     } else {
    //       addToast(error.message, { appearance: 'error' });
    //     }
    //   });
    // }

    // getCertifications();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;

          setData(response.data.data);

          // if (response.data.date_of_audit_start && response.data.date_of_audit_start.length) {
          //   setAuditStartDate(moment(response.data.date_of_audit_start));
          // }

          // if (response.data.date_of_audit_end && response.data.date_of_audit_end.length) {
          //   setAuditEndDate(moment(response.data.date_of_audit_end));
          // }

          let certifications = [];

          if (
            response.data.audit_plan &&
            response.data.audit_plan.client &&
            response.data.audit_plan.client.certifications &&
            response.data.audit_plan.client.certifications.length
          ) {
            response.data.audit_plan.client.certifications.map((certification, i) => 
              certifications[certification.id] = {
                value: certification.id,
                label: certification.standard,
              }
            );

            setCertifications(certifications);
          }

          reload(false);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id, load]);

  const deleteHandler = async (resource) => {
    if (window.confirm("Delete this row?")) {
      // console.log(resource);
      
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.delete(resource)
      .then(function (response) {
        // console.log(response);

        addToast('Row successfully deleted', { appearance: 'success' });

        reload(true);
      })
      .catch(function (error) {
        // console.log(error.response);

        if (error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }
  }

  // const auditScopeDeleteHandler = async (scopeId) => {
  //   if (window.confirm("Delete this row?")) {
  //     let token = await localStorage.getItem('auth-token');

  //     token = JSON.parse(token);

  //     api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

  //     api.delete('/audits/' + id + '/scope-of-certifications/' + scopeId)
  //     .then(function (response) {
  //       // console.log(response);

  //       addToast('Row successfully deleted', { appearance: 'success' });

  //       reload(true);
  //     })
  //     .catch(function (error) {
  //       // console.log(error.response);

  //       if (error.response && error.response.data) {
  //         addToast(error.response.data.message, { appearance: 'error' });
  //       } else {
  //         addToast(error.message, { appearance: 'error' });
  //       }
  //     });
  //   }
  // }

  return (
    <React.Fragment>
      <Card>
        <div className="header">
          <h2>Audit Information</h2>
        </div>
        <div className="body">
          <Nav pills>
            {tabs.map((tab, i) => 
              <NavItem key={i}>
                <NavLink
                  className={classnames({ active: activeTab === tab.id })}
                  onClick={() => { toggle(tab.id); }}
                >
                  3.{i + 1} {tab.label}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <hr />
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              <Formik 
                key={1}
                enableReinitialize={true}
                initialValues={{}}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                  api.post('/audits/' + id + '/scope-of-certifications', JSON.stringify(values), {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                  .then(function (response) {
                    addToast('Row successfully updated', { appearance: 'success' });

                    reload(true);
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });

                  resetForm();
                }}
              >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup>
                      <div className="table">
                        <BootstrapTable 
                          // remote={true}
                          bootstrap4
                          bordered={false}
                          classes="table-hover js-basic-example dataTable table-custom m-b-0"
                          headerClasses="thead-dark"
                          keyField="id"
                          data={data.scope_of_certifications ? data.scope_of_certifications : []} 
                          columns={[
                            {
                              dataField: "language",
                              text: "Language",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="text"
                                    name="language"
                                    onChange={handleChange}
                                    value={values.language || ""}
                                  />
                                )
                              }
                            },
                            {
                              dataField: "certification_id",
                              text: "Standard",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {type.toLowerCase() === 'iso' ? (row.certification && row.certification.standard ? row.certification.standard : "") : ""}
          
                                    {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'MSPO' && data.audit_plan.subtype.toUpperCase() === 'PART-2' ?
                                      <React.Fragment>Malaysian Sustainable Palm Oil (MSPO) Part 2: General principles for independent smallholders (MS 2530-2:2013)</React.Fragment>
                                    : null}

                                    {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'MSPO' && data.audit_plan.subtype.toUpperCase() === 'PART-3' ?
                                      <React.Fragment>Malaysian Sustainable Palm Oil (MSPO) Part 3: General principles for oil palm plantations and organized smallholders (MS 2530-3:2013)</React.Fragment>
                                    : null}

                                    {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'MSPO' && data.audit_plan.subtype.toUpperCase() === 'PART-4' ?
                                      <React.Fragment>Malaysian Sustainable Palm Oil (MSPO) Part 4: General principles for palm oil mills (MS 2530-4:2013)</React.Fragment>
                                    : null}

                                    {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'MSPO-SCCS' ?
                                      <React.Fragment>MSPO Supply Chain Certification Standard: MSPO-SCCS-01</React.Fragment>
                                    : null}
                                  </div>
                                )
                              },
                              style: {
                                whiteSpace: "pre-wrap"
                              },
                               editable: false,

                              footer: () => {
                                return (
                                <ul>
                                {data.audit_plan && data.audit_plan.client && data.audit_plan.client.certifications ? 
                                  data.audit_plan.client.certifications.map((certification, i) => 
                                  <li>
                                    {certification.standard === 'MSPO' ? 'MS2530:2013' : certification.standard}
                                  </li>)
                                
                                : ""}&nbsp;</ul>
                                )
                              }
                              
                            },
                            {
                              dataField: "site_name",
                              text: "Site Name",
                              headerStyle: {
                                whiteSpace: "nowrap",
                              },
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="text"
                                    name="site_name"
                                    onChange={handleChange}
                                    value={values.site_name || ""}
                                  />
                                )
                              },
                            },
                            {
                              dataField: "site_address",
                              text: "Site Address",
                              headerStyle: {
                                whiteSpace: "nowrap",
                              },
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="textarea"
                                    name="site_address"
                                    onChange={handleChange}
                                    value={values.site_address || ""}
                                  />
                                )
                              },
                            },
                            {
                              dataField: "is_displayed",
                              //text: "Head Office",
                              text: "Display in Cert?",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <BootstrapSwitchButton
                                    //disabled={true}
                                    checked={parseInt(cell) === 1}
                                    onlabel={<i className="icon-check"></i>}
                                    offlabel={<i className="icon-uncheck"></i>}
                                    // onChange={(checked: boolean) => {

                                    // }}
                                  />
                                )
                              },
                              editable: true,
                              editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                                <BootstrapSwitchButton
                                  checked={parseInt(value) == 1}
                                  onlabel={<i className="icon-check"></i>}
                                  offlabel={<i className="icon-uncheck"></i>}
                                  onChange={(checked) => {
                                    // console.log(value, row, column);
                                    console.log(value);
                                    editorProps.onUpdate(checked);
                                  }}
                                  value={value}
                                />
                              ),
                              footer: () => {
                                return (
                                  <BootstrapSwitchButton
                                    checked={false}
                                    onlabel={<i className="icon-check"></i>}
                                    offlabel={<i className="icon-uncheck"></i>}
                                    onChange={(checked: boolean) => {
                                        setFieldValue("is_displayed", checked)
                                    }}
                                  />
                                )
                              }
                            },
                            {
                              dataField: "scope",
                              text: "Scope Of Certification",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              style: {
                                whiteSpace: "pre-wrap"
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="textarea"
                                    name="scope"
                                    onChange={handleChange}
                                    value={values.scope || ""}
                                  />
                                )
                              },
                              editor: {
                                type: Type.TEXTAREA
                              }
                            },
                            {
                              dataField: "id",
                              text: "",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <Button 
                                    color="danger" 
                                    type="button"
                                    onClick={() => {
                                      // console.log(cell);
                                      // auditScopeDeleteHandler(cell)
                                      deleteHandler('/audits/' + id + '/scope-of-certifications/' + cell);
                                    }}
                                  >
                                    <i className="icon-trash"></i>
                                  </Button>
                                )
                              },
                              footer: () => {
                                return (
                                  <Button color="primary">
                                    <i className="icon-plus"></i>
                                  </Button>
                                )
                              }
                            },
                          ]}
                          cellEdit={ 
                            cellEditFactory({ 
                              mode: "click",
                              blurToSave: true,
                              afterSaveCell: async (oldValue, newValue, row, column) => {
                                // console.log(oldValue, newValue, row, column)

                                if (oldValue === null && newValue === "") return;

                                if (oldValue === newValue) return;

                                let token = await localStorage.getItem('auth-token');

                                token = JSON.parse(token);

                                api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                values = {
                                  [column.dataField]: newValue
                                }

                                api.put('/audits/' + id + '/scope-of-certifications/' + row.id, JSON.stringify(values), {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                })
                                .then(function (response) {
                                  addToast('Row successfully updated', { appearance: 'success' });

                                  reload(true);
                                })
                                .catch(function (error) {
                                  addToast(error.message, { appearance: 'error' });
                                });
                              }
                            })
                          }
                          // pagination={ 
                          //   paginationFactory(pagingInfo)
                          // }
                          // onTableChange={handleTableChange}
                        />
                      </div>
                    </FormGroup>
                  </FormikForm>
                )}
              </Formik>

              {/* <Formik 
                enableReinitialize={true}
                initialValues={data}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                  api.put('/audits/' + id, JSON.stringify(values), {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                  .then(function (response) {
                    addToast('Audit successfully updated', { appearance: 'success' });

                    reload(true);
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });

                  resetForm();
                }}
              >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <div className="row clearfix">
                      <div className="col-md-4">
                        <FormGroup>
                          <Label>Type</Label>
                          <Input
                            type="text"
                            name="audit_type"
                            id=""
                            onChange={handleChange}
                            value={values.audit_type}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup>
                          <Label>Audit Date</Label>
                          <DateRangePicker 
                            drops="up"
                            containerStyles={{ display: "block" }}
                            startDate={values.date_of_audit_start && values.date_of_audit_start.length ? moment(values.date_of_audit_start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                            endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                            onEvent={(event, picker) => { 
                              setFieldValue('date_of_audit_start', picker.startDate.format('YYYY-MM-DD'));
                              setFieldValue('date_of_audit_end', picker.endDate.format('YYYY-MM-DD'));

                              setAuditStartDate(moment(picker.startDate));

                              setAuditEndDate(moment(picker.endDate));
                            }}
                          >
                            <Input 
                              autoComplete="off"
                              // readOnly={true}
                              type="text" 
                              // name="date_of_audit" 
                              id="DateOfAudit"
                              // onChange={handleChange}
                              value={values.date_of_audit_start && values.date_of_audit_end ? moment(values.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(values.date_of_audit_end).format('DD/MM/YYYY') : ""}
                            />
                          </DateRangePicker>
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup>
                          <Label>Duration</Label>
                          <Input
                            type="text"
                            disabled={true}
                            defaultValue={duration}
                            value={duration}
                          />
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>&nbsp;
                        
                      </div>
                    </div>
                  </FormikForm>
                )}
              </Formik>
             */}
            </TabPane>
            <TabPane tabId={2}>
              <FormGroup>
                <Label for="">Audit Standard(s)</Label>
                {/* <ol>
                {data.scope_of_certifications && data.scope_of_certifications.length ? data.scope_of_certifications.map((STANDARD, i) => 
                  <li key={i}>{STANDARD.certification.standard}</li>
                ) : null}
                </ol> */}
                {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'MSPO' && data.audit_plan.subtype.toUpperCase() === 'PART-2' ?
                  <p>Malaysian Sustainable Palm Oil (MSPO) Part 2: General principles for independent smallholders (MS 2530-2:2013)</p>
                : null}

                {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'MSPO' && data.audit_plan.subtype.toUpperCase() === 'PART-3' ?
                  <p>Malaysian Sustainable Palm Oil (MSPO) Part 3: General principles for oil palm plantations and organized smallholders (MS 2530-3:2013)</p>
                : null}

                {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'MSPO' && data.audit_plan.subtype.toUpperCase() === 'PART-4' ?
                  <p>Malaysian Sustainable Palm Oil (MSPO) Part 4: General principles for palm oil mills (MS 2530-4:2013)</p>
                : null}

                {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'MSPO-SCCS' ?
                  <p>MSPO Supply Chain Certification Standard: MSPO-SCCS-01</p>
                : null}

                <ol>{data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'ISO' && data.audit_plan.client && data.audit_plan.client.certifications ? 
                  data.audit_plan.client.certifications.map((certification, i) => 
                  <li key={i}>{certification.standard}</li>
                ) : ""}&nbsp;</ol>
              </FormGroup>
            </TabPane>
            <TabPane tabId={3}>
              <FormGroup>
                <Label>Lead Auditors</Label>
                <ol>{data.audit_plan && data.audit_plan.lead_auditors ? 
                data.audit_plan.lead_auditors.map((auditor, i) => 
                  <li key={i}>
                    {auditor.full_name}<br />
                    <em>{auditor.profile}</em>
                  </li>
                 ) : ""}&nbsp;
              </ol>
              </FormGroup>

              <FormGroup>
                <Label>Team Member</Label>
                <ol>{data.audit_plan && data.audit_plan.audit_team_members ? 
                data.audit_plan.audit_team_members.map((auditor, i) => 
                  <li key={i}>
                      {auditor.full_name}<br />
                      <em>{auditor.profile}</em>
                  </li>
                 ) : ""}&nbsp;</ol>
              </FormGroup>
            </TabPane>
            <TabPane tabId={4}>
              <Formik 
                key={4}
                enableReinitialize={true}
                initialValues={{}}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  // let token = await localStorage.getItem('auth-token');

                  // token = JSON.parse(token);

                  // api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                  // api.put('/audits/' + id, JSON.stringify(values), {
                  //   headers: {
                  //     'Content-Type': 'application/json',
                  //   },
                  // })
                  // .then(function (response) {
                  //   addToast('Audit successfully updated', { appearance: 'success' });

                  //   reload(true);
                  // })
                  // .catch(function (error) {
                  //   addToast(error.message, { appearance: 'error' });
                  // });

                  // resetForm();
                }}
              >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup>
                      <Label for="">Audit Objectives</Label>
                      <p>{data.audit_plan && data.audit_plan.audit_objective ? data.audit_plan.audit_objective.split(/(?:\r\n|\r|\n)/g).map((str, i) => <>{str}<br /></>) : ""}</p>
                      {/* <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        value={data.objectives}
                        init={{
                          height: 500,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('objectives', content);
                        }}
                      /> */}
                    </FormGroup>
                    
                    {/* <div className="row clearfix">
                      <div className="col-sm-12">
                        <button type="submit" className="btn btn-primary">
                          Save Audit Objectives
                        </button>&nbsp;
                      </div>
                    </div> */}
                  </FormikForm>
                )}
              </Formik>

              {/* <Formik 
                enableReinitialize={true}
                initialValues={{}}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                  api.post('/audits/' + id + '/activities', JSON.stringify(values), {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                  .then(function (response) {
                    addToast('Row successfully updated', { appearance: 'success' });

                    reload(true);
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });

                  resetForm();
                }}
              >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup className="mt-4">
                      <div className="table-responsive">
                        <BootstrapTable 
                          // remote={true}
                          bootstrap4
                          bordered={false}
                          classes="table-hover js-basic-example dataTable table-custom m-b-0"
                          headerClasses="thead-dark"
                          keyField="id"
                          data={data.audit_activities ? data.audit_activities : []} 
                          columns={[
                            {
                              dataField: "date_time_activity",
                              text: "Date - Time/Activity",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    // type="textarea"
                                    name="date_time_activity"
                                    onChange={handleChange}
                                    value={values.date_time_activity || ""}
                                  />
                                )
                              },
                            },
                            {
                              dataField: "site_name",
                              text: "Site Name",
                              headerStyle: {
                                whiteSpace: "nowrap",
                              },
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    // type="textarea"
                                    name="site_name"
                                    onChange={handleChange}
                                    value={values.site_name || ""}
                                  />
                                )
                              },
                            },
                            {
                              dataField: "process",
                              text: "Process",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    // type="textarea"
                                    name="process"
                                    onChange={handleChange}
                                    value={values.process || ""}
                                  />
                                )
                              },
                            },
                            {
                              dataField: "process_abbr",
                              text: "Process Abbr.",
                              headerStyle: {
                                whiteSpace: "nowrap",
                              },
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    // type="textarea"
                                    name="process_abbr"
                                    onChange={handleChange}
                                    value={values.process_abbr || ""}
                                  />
                                )
                              },
                            },
                            {
                              text: "Auditor",
                              footer: "",
                            },
                            {
                              dataField: "comment",
                              text: "Comment",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="textarea"
                                    name="comment"
                                    onChange={handleChange}
                                    value={values.comment || ""}
                                  />
                                )
                              },
                              editor: {
                                type: Type.TEXTAREA
                              },
                            },
                            {
                              text: "",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <Button 
                                    color="danger" 
                                    type="button"
                                    onClick={() => {
                                      // console.log(cell);
                                      deleteHandler('/audits/' + id + '/activities/' + cell)
                                    }}
                                  >
                                    <i className="icon-trash"></i>
                                  </Button>
                                )
                              },
                              footer: () => {
                                return (
                                  <Button color="primary">
                                    <i className="icon-plus"></i>
                                  </Button>
                                )
                              }
                            },
                          ]}
                          cellEdit={ 
                            cellEditFactory({ 
                              mode: "click",
                              blurToSave: true,
                              afterSaveCell: async (oldValue, newValue, row, column) => {
                                // console.log(oldValue, newValue, row, column)

                                if (oldValue === null && newValue === "") return;

                                if (oldValue === newValue) return;

                                let token = await localStorage.getItem('auth-token');

                                token = JSON.parse(token);

                                api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                values = {
                                  [column.dataField]: newValue
                                }

                                api.put('/audits/' + id + '/activities/' + row.id, JSON.stringify(values), {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                })
                                .then(function (response) {
                                  addToast('Row successfully updated', { appearance: 'success' });

                                  reload(true);
                                })
                                .catch(function (error) {
                                  addToast(error.message, { appearance: 'error' });
                                });
                              }
                            })
                          }
                          // pagination={ 
                          //   paginationFactory(pagingInfo)
                          // }
                          // onTableChange={handleTableChange}
                        />
                      </div>
                    </FormGroup>
                  </FormikForm>
                )}
              </Formik> */}
            </TabPane>
            <TabPane tabId={5}>
              <Formik
                key={5}
                enableReinitialize={true}
                initialValues={data}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                  api.post('/audits/' + id + '/previous-audit-results', JSON.stringify(values), {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                  .then(function (response) {
                    addToast('Row successfully updated', { appearance: 'success' });

                    reload(true);
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });

                  resetForm();
                }}
              >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup>
                      <div className="table">
                        <BootstrapTable 
                          // remote={true}
                          bootstrap4
                          bordered={false}
                          classes="table-hover js-basic-example dataTable table-custom m-b-0"
                          headerClasses="thead-dark"
                          keyField="id"
                          data={data.previous_audit_results ? data.previous_audit_results : []} 
                          columns={[
                            {
                              dataField: "clause",
                              text: "Clause",
                              style: {
                                whiteSpace: "pre-wrap"
                              },
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="text"
                                    name="clause"
                                    onChange={handleChange}
                                    value={values.clause || ""}
                                  />
                                )
                              },
                              editCellStyle: {
                                // Scope Of CertificationminHeight: "500px",
                                minWidth: "300px",
                              },
                              editor: {
                                type: Type.TEXTAREA
                              }
                            },
                            {
                              dataField: "description",
                              text: "Description",
                              style: {
                                whiteSpace: "pre-wrap"
                              },
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="textarea"
                                    name="description"
                                    onChange={handleChange}
                                    value={values.description || ""}
                                  />
                                )
                              },
                              editCellStyle: {
                                minHeight: "500px",
                                minWidth: "300px",
                              },
                              editor: {
                                type: Type.TEXTAREA
                              }
                            },
                            // {
                            //   text: "IR",
                            //   formatter: (cell, row, rowIndex) => {
                            //     return (
                            //       <div></div>
                            //     )
                            //   },
                            //   footer: () => {
                            //     return (
                            //       <div></div>
                            //     )
                            //   }
                            // },
                            // {
                            //   text: "SV1",
                            //   formatter: (cell, row, rowIndex) => {
                            //     return (
                            //       <div></div>
                            //     )
                            //   },
                            //   footer: () => {
                            //     return (
                            //       <div></div>
                            //     )
                            //   }
                            // },
                            // {
                            //   text: "SV2",
                            //   formatter: (cell, row, rowIndex) => {
                            //     return (
                            //       <div></div>
                            //     )
                            //   },
                            //   footer: () => {
                            //     return (
                            //       <div></div>
                            //     )
                            //   }
                            // },
                            {
                              dataField: "comment",
                              text: "Comment",
                              style: {
                                whiteSpace: "pre-wrap"
                              },
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="textarea"
                                    name="comment"
                                    onChange={handleChange}
                                    value={values.comment || ""}
                                  />
                                )
                              },
                              editCellStyle: {
                                minHeight: "500px",
                                minWidth: "300px",
                              },
                              editor: {
                                type: Type.TEXTAREA
                              }
                            },
                            {
                              dataField: "id",
                              text: "",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <Button 
                                    color="danger" 
                                    type="button"
                                    onClick={() => {
                                      // console.log(cell);
                                      // auditScopeDeleteHandler(cell)
                                      deleteHandler('/audits/' + id + '/previous-audit-results/' + cell);
                                    }}
                                  >
                                    <i className="icon-trash"></i>
                                  </Button>
                                )
                              },
                              footer: () => {
                                return (
                                  <Button color="primary">
                                    <i className="icon-plus"></i>
                                  </Button>
                                )
                              }
                            },
                          ]}
                          cellEdit={ 
                            cellEditFactory({ 
                              mode: "click",
                              blurToSave: true,
                              afterSaveCell: async (oldValue, newValue, row, column) => {
                                // console.log(oldValue, newValue, row, column)

                                if (oldValue === null && newValue === "") return;
                                
                                if (oldValue === newValue) return;

                                let token = await localStorage.getItem('auth-token');

                                token = JSON.parse(token);

                                api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                values = {
                                  [column.dataField]: newValue
                                }

                                api.put('/audits/' + id + '/previous-audit-results/' + row.id, JSON.stringify(values), {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                })
                                .then(function (response) {
                                  addToast('Audit successfully updated', { appearance: 'success' });

                                  reload(true);
                                })
                                .catch(function (error) {
                                  addToast(error.message, { appearance: 'error' });
                                });
                              }
                            })
                          }
                          // pagination={ 
                          //   paginationFactory(pagingInfo)
                          // }
                          // onTableChange={handleTableChange}
                        />
                      </div>
                    </FormGroup>
                  </FormikForm>
                )}
              </Formik>
            </TabPane>
            <TabPane tabId={6}>
              <Formik 
                key={6}
                enableReinitialize={true}
                initialValues={data}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                  api.put('/audits/' + id, JSON.stringify(values), {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                  .then(function (response) {
                    addToast('Audit successfully updated', { appearance: 'success' });

                    reload(true);
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });

                  resetForm();
                }}
              >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup>
                      <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        value={values.changes_from_previous_result}
                        init={{
                          height: 500,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('changes_from_previous_result', content);
                        }}
                      />
                    </FormGroup>

                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>&nbsp;
                        {/* <Button 
                          // className="btn btn-outline-secondary"
                          outline
                          color="secondary" 
                          tag={Link} 
                          to={props.path}
                        >
                          Cancel
                        </Button> */}
                      </div>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </TabPane>
            <TabPane tabId={7}>
            <Formik 
                key={7}
                enableReinitialize={true}
                initialValues={data}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                  api.put('/audits/' + id, JSON.stringify(values), {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                  .then(function (response) {
                    addToast('Audit successfully updated', { appearance: 'success' });

                    reload(true);
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });

                  resetForm();
                }}
              >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup>
                      <Input
                        type="textarea"
                        name={type.toLowerCase() === 'iso' ? 'client_reference_documents' :'other_certification_held'}
                        onChange={handleChange}
                        value={type.toLowerCase() === 'iso' ? 
                                values.client_reference_documents && values.client_reference_documents.length > 0 ? 
                                  values.client_reference_documents : '' : values.other_certification_held && values.other_certification_held.length > 0 ? 
                                  values.other_certification_held : ''                              
                              }
                        rows={5}
                      /> 
                    </FormGroup>

                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>&nbsp;
                        {/* <Button 
                          // className="btn btn-outline-secondary"
                          outline
                          color="secondary" 
                          tag={Link} 
                          to={props.path}
                        >
                          Cancel
                        </Button> */}
                      </div>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </TabPane>
            <TabPane tabId={8}>
            <Formik 
                key={8}
                enableReinitialize={true}
                initialValues={data}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                  api.put('/audits/' + id, JSON.stringify(values), {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                  .then(function (response) {
                    addToast('Audit successfully updated', { appearance: 'success' });

                    reload(true);
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });

                  resetForm();
                }}
              >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup>
                      <Input
                        type="textarea"
                        name="exclusions"
                        onChange={handleChange}
                        value={values.exclusions && values.exclusions.length > 0 ? values.exclusions : ''}
                        rows={5}
                      />
                    </FormGroup>

                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>&nbsp;
                        {/* <Button 
                          // className="btn btn-outline-secondary"
                          outline
                          color="secondary" 
                          tag={Link} 
                          to={props.path}
                        >
                          Cancel
                        </Button> */}
                      </div>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </TabPane>

          </TabContent>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default AuditInformation;