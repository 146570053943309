import React from 'react';

import {
  Button
} from 'reactstrap';

const SidebarToggler = () => {
  const toggler = () => {
    const element = document.querySelector('body');

    element.classList.toggle('layout-fullwidth');
  }

  return (
    <Button 
      color="link" 
      size="xs" 
      className="btn-toggle-fullwidth"
      onClick={() => toggler()}
      style={{marginTop: -6, paddingLeft: 0, paddingRight: 17}}
    >
      <i className="fa fa-arrow-left"></i>
    </Button>
  );
}

export default SidebarToggler;