import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  // useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
  // ListGroup,
  // ListGroupItem,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

// import SidebarToggler from '../../components/SidebarToggler';

import api from '../../utils/api';

import { useToasts } from 'react-toast-notifications';

const INITIAL_VALUES = {

};

const Form = (props) => {
  let { id } = useParams();

  // let { path } = useRouteMatch();

  let history = useHistory();

  // let location = useLocation();
  // let { from } = location.state || { from: { pathname: "/client" } };

  const { addToast } = useToasts();

  const [data, setData] = useState(INITIAL_VALUES);

  const [auditors, setAuditor] = useState([]);

  const [clients, setClient] = useState([]);

  const [roles, setRole] = useState([]);

  const [isSubmit, setSubmit] = useState(false);

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        // setProfile(response.data);

        if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'user.update') === "undefined") {
          history.push(props.path);
        }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getRoles = async () => {
      let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/roles')
        .then(function (response) {
          // console.log(response.data);

          delete response.data.id;

          setRole(response.data);
        })
        .catch(function (error) {

        });
    }

    getRoles();

    const getAuditors = async () => {
      let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/auditors?limit=-1')
        .then(function (response) {
          // console.log(response.data);

          delete response.data.id;

          setAuditor(response.data.data);
        })
        .catch(function (error) {

        });
    }

    getAuditors();

    const getClients = async () => {
      let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/clients?limit=-1')
        .then(function (response) {
          // console.log(response.data);

          delete response.data.id;

          setClient(response.data.data);
        })
        .catch(function (error) {

        });
    }

    getClients();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/users/' + id)
        .then(function (response) {
          // console.log(response.data);

          delete response.data.id;

          if (response.data && response.data && response.data.roles && response.data.roles[0]) {
            response.data.role_id = response.data.roles[0].id;
          }

          setData(response.data);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, []);

  return (
    <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmit(true);

        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
        
        if (!id) {
          api.post('/users', JSON.stringify(values), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(function (response) {
            // console.log(response.data.data.id);

            addToast('User successfully created with default password abc1234', { appearance: 'success' });

            // history.push('/audit/audits');

            // window.location = '/client-application/iso';

            history.push(`${props.path}`);
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        } else {
          api.put('/users/' + id, JSON.stringify(values), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(function (response) {
            // console.log(response);

            addToast('User successfully updated', { appearance: 'success' });

            history.push(`${props.path}`);

            // window.location = '/client-application/iso';
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });

          setSubmit(false);
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
        <FormikForm>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <Card>
                <div className="header">
                  <h2>USER</h2>
                </div>
                <div className="body">
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Role">Group</Label>
                        <Input 
                          // readOnly={readOnly}
                          type="select" 
                          className="custom-select" 
                          name="role_id" 
                          id="Role"
                          onChange={handleChange}
                          value={values.role_id}
                          required
                        >
                          <option></option>
                          {roles.map((role, i) => 
                            <option value={role.id}>{role.name.replace('NCSB', 'NioshCert')}</option>
                          )}
                        </Input>
                      </FormGroup>
                    </div>
                  </div>

                  {roles.map((role, i) => parseInt(role.id) === parseInt(values.role_id) && role.name.toLowerCase() === 'auditor' ?
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Auditor">Auditor</Label>
                        <Input 
                          // readOnly={readOnly}
                          type="select" 
                          className="custom-select" 
                          name="auditor_id" 
                          id="Auditor"
                          onChange={(e) => {
                            // console.log(e.currentTarget.options[e.currentTarget.selectedIndex].text);

                            setFieldValue('auditor_id', e.currentTarget.value);
                            setFieldValue('name', e.currentTarget.options[e.currentTarget.selectedIndex].text);
                            setFieldValue('email', e.currentTarget.options[e.currentTarget.selectedIndex].dataset.email);
                          }}
                          value={values.auditor_id}
                          required
                        >
                          <option></option>
                          {auditors && auditors.length ? auditors.map((auditor, i) => 
                            <option 
                              data-email={auditor.email}
                              value={auditor.id}
                            >
                              {auditor.full_name}
                            </option>
                          ) : null}
                        </Input>
                      </FormGroup>
                    </div>
                  </div>
                  : null)}

                  {roles.map((role, i) => parseInt(role.id) === parseInt(values.role_id) && role.name.toLowerCase() === 'client' ?
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Client">Client</Label>
                        <Input 
                          // readOnly={readOnly}
                          type="select" 
                          className="custom-select" 
                          name="client_id" 
                          id="Client"
                          onChange={(e) => {

                            setFieldValue('client_id', e.currentTarget.value);
                            setFieldValue('name', e.currentTarget.options[e.currentTarget.selectedIndex].dataset.name);
                            setFieldValue('email', e.currentTarget.options[e.currentTarget.selectedIndex].dataset.email);
                          }}
                          value={values.client_id}
                          required
                        >
                          <option></option>
                          {clients && clients.length ? clients.map((client, i) => 
                            <option 
                              data-name={client.contact_person_name}
                              data-email={client.contact_person_email}
                              value={client.id}
                            >
                              {client.name}
                            </option>
                          ) : null}
                        </Input>
                      </FormGroup>
                    </div>
                  </div>
                  : null)}

                  {roles.map((role, i) => parseInt(role.id) === parseInt(values.role_id) && role.name.toLowerCase() !== 'auditor' ?
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Name">Name</Label>
                        <Input 
                          // readOnly={readOnly}
                          type="text" 
                          // className="custom-select" 
                          name="name" 
                          id="Name"
                          onChange={handleChange}
                          value={values.name}
                          required
                        />
                      </FormGroup>
                    </div>
                  </div>
                  : null)}

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Email">Email</Label>
                        <Input 
                          // readOnly={readOnly}
                          type="text" 
                          // className="custom-select" 
                          name="email" 
                          id="Email"
                          onChange={handleChange}
                          value={values.email}
                          required
                        />
                      </FormGroup>
                    </div>
                  </div>
                  
                  {roles.map((role, i) => parseInt(role.id) === parseInt(values.role_id) && role.name.includes('NCSB') ?
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <div className="fancy-checkbox">
                        <label>
                          <Input 
                            // disabled={readOnly}
                            type="checkbox" 
                            // disabled={true}
                            name={`msc`}
                            // onChange={handleChange}
                            onChange={(e) => {
                              setFieldValue('msc', e.currentTarget.checked ? 1 : 0);
                            }}
                            // value={CERTIFICATION.id}
                            checked={values.msc && parseInt(values.nace_codes) == 1} 
                          />
                          <span>
                            Receive MSC notification?
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  : null)}

                  <div className="row clearfix">
                    <div className="col-sm-4">
                      {/* {readOnly === false ? 
                      <React.Fragment> */}
                      <button type="submit" className="btn btn-primary" disabled={isSubmit ? true : false}>
                        {id ? (isSubmit ? 'Updating...' : 'Update') : (isSubmit ? 'Creating...' : 'Create')}
                      </button>&nbsp;
                      {/* </React.Fragment> : null} */}
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        tag={Link} 
                        to={props.path}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  )
}

export default Form;
