import React from 'react';

import {
  // Link,
  // Redirect,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import Form from './Form';

const NonConformance = () => {
  let { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route 
            path={`${path}/update/:audit_report_id/:id`} 
            render={(props) => <Form path={path} {...props} />} 
          />
      </Switch>
    </React.Fragment>
  )
}

export default NonConformance;