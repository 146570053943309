import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  Card,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useToasts } from 'react-toast-notifications';

import api from '../../../utils/api';

import qs from 'query-string';

const INITIAL_QUERY = {
  application: true,
}
const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
  pageStartIndex: true,
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Listing = (props) => {
  let { path } = useRouteMatch();

  let history = useHistory();
  
  let queryString = useQuery();

  const { addToast } = useToasts();

  const [data, setData] = useState([]);
  
  const [query, setQuery] = useState(INITIAL_QUERY);

  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);

  const columns = [
    {
      editable: false,
      text: "No",
      formatter: (cell, row, rowIndex) => {
       let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
        return <span>{rowNumber}.</span>;
      }
    },
    {
    dataField: 'prefix',
    text: 'Prefix/Title',
  }, {
    dataField: 'full_name',
    text: 'Name',
    formatter: (cell, row, rowIndex) => {
      let initial = row.initial && row.initial !== null ? ' (' + row.initial + ')' : '';
      return cell + initial;
    }
  }, {
    dataField: 'ic_no',
    text: 'I/C No.',
  }, {
    dataField: 'position_applied',
    text: 'Position Applied',
    formatter: (cell, row, rowIndex) => {
      return (
        <ul>
          {cell && cell.length > 0 && cell.map((POSITION, key) => 
            <li>{POSITION}</li>
          )}
        </ul>
      )
    }
  }, {
    dataField: 'certifications',
    text: 'Core Competencies',
    formatter: (cell, row, rowIndex) => {
      return (
        <ul>
          {cell && cell.length > 0 && cell.map((CERT, key) => 
            <li>{CERT.code}</li>
          )}
        </ul>
      )
    }
  }, {
    dataField: 'id',
    text: ' ',
    formatter: (cell, row, rowIndex) => {
      return (
        <ButtonGroup size="sm">
          <Button outline tag={Link} to={`${path}/view/` + cell} >
            <i className="icon-eye"></i>
          </Button>
          <Button outline tag={Link} to={`${path}/edit/` + cell}>
            <i className="icon-pencil"></i>
          </Button>
          <Button outline color="danger" onClick={() => deleteHandler(cell)}>
            <i className="icon-trash"></i>
          </Button>
        </ButtonGroup>
      )
    },
    style: { textAlign: 'center' }
  }];

  useEffect(() => {
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/auditors', {
        params: query,
      })
      .then(function (response) {
        console.log(response);

        setData(response.data.data);
        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
        }));
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getData();
  }, [props, query]);

  const deleteHandler = async (id) => {
    if (window.confirm('Delete this record?')) {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.delete('/auditors/' + id)
      .then(function (response) {
        // console.log(response);

        // setData(response.data.data);

        // addToast('Application form successfully deleted', { appearance: 'success' });

        history.push('/auditor-application/application-form');
      })
      .catch(function (error) {
        // console.log(error.response);
        addToast(error.response.data.message, { appearance: 'error' });
      });
    }
  }
  
  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <Card>
          <div className="header">
            <h2>APPLICATION LIST</h2>
            <ul className="header-dropdown">
              <li>
                <Button 
                  className="m-t-10 m-l-20"
                  color="primary" 
                  tag={Link} 
                  to={`${path}/create/`}
                >
                  Add New
                </Button>
              </li>
            </ul>
          </div>
          <div className="body">
            <Formik
              enableReinitialize={true}
              initialValues={{
                application: true,
                name: queryString.get('name') ? queryString.get('name') : "",
                // region: queryString.get('region') ? queryString.get('region') : "",
                // state: queryString.get('state') ? queryString.get('state') : "",
                // certifications: queryString.get('certifications') ? queryString.get('certifications') : "",
                // status: queryString.get('status') ? queryString.get('status') : "ACTIVE",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                var searchString = qs.stringify(values); // objectToFormData(values, {indices: true, booleansAsIntegers: true});

                history.push({
                  pathname: path,
                  search: searchString
                });

                setQuery(values);
              }}
            >
              {({ isSubmitting, values, setFieldValue, handleChange }) => (
              <FormikForm>
                <FormGroup>
                  <Label for="Name">Name</Label>
                  <Input 
                    type="text" 
                    // className="custom-select" 
                    name="name" 
                    id="Name"
                    onChange={handleChange}
                    value={values.name}
                  />
                </FormGroup>

                <div className="row clearfix">
                  <div className="col-sm-4">
                    <Label>&nbsp;</Label>
                    <FormGroup>
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        // tag={Link} 
                        // to="/client-database"
                      >
                        Search
                      </Button>
                      <Button 
                        // className="btn btn-outline-secondary"
                        type="button"
                        outline
                        color="link" 
                        // tag={Link} 
                        // to={`${path}`}
                        onClick={() => {
                          history.push({
                            pathname: path,
                          });
          
                          setQuery(INITIAL_QUERY);
                        }}
                      >
                        Reset
                      </Button>
                    </FormGroup>
                  </div>
                </div>
              </FormikForm>
              )}
            </Formik>

            <hr />
          </div>
          <div className="body project_report">
            <div className="table">
            <BootstrapTable 
                  remote={true}
                  bootstrap4
                  bordered={false}
                  classes="table-hover js-basic-example dataTable table-custom m-b-0"
                  headerClasses="thead-dark"
                  keyField='id' 
                  data={ data } 
                  columns={ columns }
                  pagination={ 
                    paginationFactory(pagingInfo)
                  }
                  onTableChange={handleTableChange}
                />
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Listing;