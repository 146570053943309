import React, { useEffect, useState } from "react";

import {
  Link
} from "react-router-dom";

import {
  Button,
  Spinner,
} from "reactstrap";

import SidebarToggler from "../../components/SidebarToggler";

import api from './../../utils/api';

import { formatDate } from "@fullcalendar/core";

import moment from "moment";

const ClientDashboard = () => {
  const [data, setData] = useState({});

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/dashboard')
      .then(function (response) {
        setData(response.data.data);

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
    }

    getData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Dashboard
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">Dashboard</li>
              {/* <li className="breadcrumb-item active">Departments List</li> */}
            </ul>
          </div>
        </div>
      </div>

      {isLoading ?
      <div className="row clearfix">
        <div className="col-sm-12" style={{ textAlign: "center" }}>
          <Spinner size="lg" />
        </div>
      </div>
      :
      <React.Fragment>
        <div className="row clearfix">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="card text-center bg-warning">
              <div className="body">
                <div className="p-15 text-light">
                  <h3>{data.ncr && data.ncr.total ? data.ncr.total : '0'}</h3>
                  <span>Pending NCR</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="card text-center bg-danger">
              <div className="body">
                <div className="p-15 text-light">
                  <h3>{data.ncr && data.ncr.major ? data.ncr.major : '0'}</h3>
                  <span>Major NCR</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="card text-center bg-info">
              <div className="body">
                <div className="p-15 text-light">
                  <h3>{data.ncr && data.ncr.minor ? data.ncr.minor : '0'}</h3>
                  <span>Minor NCR</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div className="header">
                <h2>Non Conformance</h2>
              </div>
              <div className="body">
                <table className="table m-b-0 table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th>NCR No.</th>
                      <th>Clause</th>
                      <th style={{ textAlign: "center", verticalAlign: "middle" }}>Grade</th>
                      <th style={{ textAlign: "center", verticalAlign: "middle" }}>Status</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.ncr && data.ncr.list && data.ncr.list.length ? data.ncr.list.map((ncr, i) => 
                      <tr key={i}>
                        <td style={{ whiteSpace: "pre-wrap" }}>{ncr.no}</td>
                        <td style={{ whiteSpace: "pre-wrap" }}>{ncr.clause}</td>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                          <span className={ncr.grade === 'Major' ? 'badge badge-danger' : 'badge badge-warning'}>
                            {ncr.grade}
                          </span>
                        </td>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                          <span className={ncr.ncr_close === 'Y' ? 'badge badge-success' : 'badge badge-warning'}>
                            {ncr.ncr_close === 'Y' ? 'Closed' : 'Open'}
                          </span>
                        </td>
                        <td>
                          <Button 
                            outline 
                            size="sm" 
                            tag={Link} 
                            to={`/audit-module/audit-report/non-conformance/update/${ncr.audit_id}/${ncr.id}`}
                          >
                            <i className="icon-pencil"></i>
                          </Button>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div className="header">
                <h2>Opportunity For Improvements</h2>
              </div>
              <div className="body">
                <table className="table m-b-0 table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th>Date</th>
                      <th>No.</th>
                      <th>Process</th>
                      <th style={{ width: "50%" }}>OFI</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.ofi && data.ofi.list && data.ofi.list.length ? data.ofi.list.map((ofi, i) => 
                      <tr key={i}>
                        <td>{moment(ofi.date).format('DD/MM/YY')}</td>
                        <td>{ofi.number}</td>
                        <td>{ofi.process}</td>
                        <td style={{ fontSize: "12px" }}>{ofi.ofi}</td>
                        <td>
                          {/* <Button 
                            outline 
                            size="sm" 
                            tag={Link} 
                            to={`/audit-module/audit-report/${ofi.audit.audit_plan.type}/edit/${ofi.audit_id}?tab=audit_process`}
                          >
                            <i className="icon-pencil"></i>
                          </Button> */}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
      }
    </div>
  )
}

export default ClientDashboard;