import React, { useEffect, useState } from "react";

import {
  Link,
  useParams,
} from "react-router-dom";

// import BootstrapTable from 'react-bootstrap-table-next';

import {
  Button,
  Card,
  FormGroup,
  // Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from 'classnames';

import { 
  Formik,
  Form as FormikForm
} from "formik";

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import api from "../../../../utils/api";

const ExecutiveAuditSummary = (props) => {
  let { id, type } = useParams();

  const { addToast } = useToasts();

  const [data, setData] = useState({});

  const [activeTab, setActiveTab] = useState(1);

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const [tabs, setTabs] = useState([
    {
      id: 1,
      label: "Audit Summary",
    }, {
      id: 4,
      label: "Review and follow-up on previous audit findings",
    }, {
      id: 5,
      label: "Review changes to the MS",
    }, {
      id: 6,
      label: "Interview session",
    }, 
    {
      id: 7,
      label: "Others",
    }, 
    // {
    //   id: 6,
    //   label: "Stakeholder List",
    // }, {
    //   id: 7,
    //   label: "Stakeholders Consultation Session",
    // }, 
    // {
    //   id: 7,
    //   label: "Unresolved Points On Diverging Opinions",
    // }, {
    //   id: 8,
    //   label: "Review",
    // }
  ])

  // if (type.toLowerCase() !== 'iso') {
  //   tabs = [
  //     {
  //       id: 1,
  //       label: "Audit Methodolody & Audit Programme",
  //     }, {
  //       id: 2,
  //       label: "Summary Audit Findings",
  //     }, {
  //       id: 3,
  //       label: "Executive Summary",
  //     }, {
  //       id: 4,
  //       label: "Previous Audit Findings Status",
  //     }, {
  //       id: 5,
  //       label: "Location & Map",
  //     }, {
  //       id: 6,
  //       label: "Stakeholder List",
  //     }, {
  //       id: 7,
  //       label: "Stakeholders Consultation Session",
  //     }, 
  //     // {
  //     //   id: 7,
  //     //   label: "Unresolved Points On Diverging Opinions",
  //     // }, {
  //     //   id: 8,
  //     //   label: "Review",
  //     // }
  //   ];
  // }

  useEffect(() => {
    if (type.toLowerCase() !== 'iso') {
      setTabs([
        {
          id: 1,
          label: "Audit Methodolody & Audit Programme",
        }, {
          id: 2,
          label: "Summary Audit Findings",
        }, {
          id: 3,
          label: "Executive Summary",
        }, {
          id: 4,
          label: "Previous Audit Findings Status",
        }, {
          id: 7,
          label: "Stakeholders Consultation Session",
        }, 
        // {
        //   id: 7,
        //   label: "Unresolved Points On Diverging Opinions",
        // }, {
        //   id: 8,
        //   label: "Review",
        // }
      ]);
    }

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;

          setData(response.data.data);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <React.Fragment>
      <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

          api.put('/audits/' + id, JSON.stringify(values), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(function (response) {
            addToast('Audit successfully updated', { appearance: 'success' });
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <Card>
              <div className="header">
                <h2>Executive Audit Summary</h2>
              </div>
              <div className="body">
                <Nav pills>
                  {tabs.map((tab, i) => 
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === tab.id })}
                        onClick={() => { toggle(tab.id); }}
                      >
                        6.{i + 1} {tab.label}
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <hr />
                <TabContent activeTab={activeTab} className="primary">
                  <TabPane tabId={1}>
                    <FormGroup>
                      <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        initialValue={data.audit_summary_report ? data.audit_summary_report : null}
                        init={{
                          height: 500,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('audit_summary_report', content);
                        }}
                      />
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId={2}>
                    <FormGroup>
                      <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        initialValue={data.recommendation}
                        init={{
                          height: 500,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('recommendation', content);
                        }}
                      />
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId={3}>
                    <FormGroup>
                      <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        initialValue={data.audit_summary}
                        init={{
                          height: 500,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('audit_summary', content);
                        }}
                      />
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId={4}>
                    <FormGroup>
                      <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        initialValue={data.previous_audit_finding_status}
                        init={{
                          height: 500,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('previous_audit_finding_status', content);
                        }}
                      />
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId={5}>
                    <div className="alert alert-info">Copy &amp; paste or drag &amp; drop image in the editor</div>                   
                    <br />                    
                    <FormGroup>
                      <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        initialValue={data.review_changes_to_the_qms}
                        init={{
                          height: 500,
                          menubar: '', // 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('review_changes_to_the_qms', content);
                        }}
                      />
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId={6}>
                    <FormGroup>
                      <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        initialValue={data.interview_session}
                        init={{
                          height: 500,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('interview_session', content);
                        }}
                      />
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId={7}>
                    <FormGroup>
                      <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        initialValue={data.unresolved_points_on_diverging_opinions}
                        init={{
                          height: 500,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('unresolved_points_on_diverging_opinions', content);
                        }}
                      />
                    </FormGroup>
                  </TabPane>

                  {/* <TabPane tabId={8}></TabPane> */}
                  
                  <div className="row clearfix">
                    <div className="col-sm-4">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>&nbsp;
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        tag={Link} 
                        to={props.path.replace(/:type/, props.type)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </TabContent>
              </div>
            </Card>
          </FormikForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default ExecutiveAuditSummary;