import React, { useEffect, useState } from "react";

import {
  // Link,
  useHistory,
  useParams,
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import moment from 'moment';

import DateRangePicker from 'react-bootstrap-daterangepicker';

// import BootstrapSwitchButton from "bootstrap-switch-button-react"

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import classnames from 'classnames';
import { 
  Formik,
  Form as FormikForm
} from "formik";

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import api from "../../../../utils/api";
import { objectToFormData } from 'object-to-formdata';
import "./styles.css";

const TeamLeaderRecommendation = (props) => {
  let { id, type, subtype } = useParams();
  let history = useHistory();
  const { addToast } = useToasts();

  const [data, setData] = useState({scope_of_certifications: []});

  const [load, reload] = useState(false);

  const [token, setToken] = useState('');

  const [isSending, setSending] = useState(false);
  const [isSubmitting1, setSubmitting1] = useState(false);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const [profile, setProfile] = useState({
    auditor_id: null,
    client_id: null,
  });

  const sendForDraft = async () => {
    if (!window.confirm('Send for draft review?')) {
      return;
    }
    
    setSending(true);

    let token = await localStorage.getItem('auth-token');

    token = JSON.parse(token);

    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
    
    api.post('audits/send-audit-confirmation/'+id, JSON.stringify({
      audit_id: id,
    }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function (response) {
      // console.log(response.data.data.id);

      addToast('Draft review successfully submitted', { appearance: 'success' });

      setSending(false);

      reload(true);

      // history.push('/audit/audits');

      // window.location = '/client-application/iso';

      // if (response.data.data.id) {
      //   history.push(`${props.path.replace(/:type/, type)}/edit/${response.data.data.id}?tab=introduction`);
      // }
    })
    .catch(function (error) {
      addToast(error.message, { appearance: 'error' });

      setSending(false);
    });
  }

  const sendForOperationReview = async () => {
    if (!window.confirm('Send for operation review?')) {
      return;
    }
    
    setSending(true);

    let token = await localStorage.getItem('auth-token');

    token = JSON.parse(token);

    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
    
    api.post('/operation-reviews', JSON.stringify({
      audit_id: id,
    }), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function (response) {
      // console.log(response.data.data.id);

      addToast('Operation review successfully submitted', { appearance: 'success' });

      setSending(false);

      reload(true);
      history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));
      // history.push('/audit/audits');

      // window.location = '/client-application/iso';

      // if (response.data.data.id) {
      //   history.push(`${props.path.replace(/:type/, type)}/edit/${response.data.data.id}?tab=introduction`);
      // }
    })
    .catch(function (error) {
      addToast(error.message, { appearance: 'error' });

      setSending(false);
    });
  }

  const [activeTab, setActiveTab] = useState(1);

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const [tabs, setTabs] = useState([
    {
      id: 1,
      label: "Team Leader Recommendation",
    }, {
      id: 2,
      label: "Declaration Form of Non Interest",
    } 
  ])


  useEffect(() => {
    const getToken = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);
    }

    getToken();

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        console.log(response.data);
        
        if (typeof response.data.auditor_id == "undefined") {
          response.data.auditor_id = null;
        }
        setProfile(response.data);
      

        // if (!readOnly && response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'audit_plan.update') === "undefined") {
        //   history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype).replace(/:id/, ''));
        // }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;
          console.log(response.data.data);
          setData(response.data.data);

          reload(false);
          if (response.data.data.declaration_of_interest && response.data.data.declaration_of_interest.url) {
            let config = {
              type: response.data.data.declaration_of_interest && response.data.data.declaration_of_interest.originalExtension ? response.data.data.declaration_of_interest.originalExtension : 'pdf',
              height: window.innerHeight - 110,
                document: {
                    fileType: 'pdf',
                    key: `${id}-${Date.now()}`,
                    title: 'Declaration Form for Non Interest',
                    url: response.data.data.declaration_of_interest.url,
                    permissions: {
                        download: true,
                        edit: false,
                        print: true,
                        review: false,
                    }
                },
                documentType: 'text',
                editorConfig: {
                    callbackUrl: '',
                    mode: 'view',
                    customization: {
                        zoom: 70,
                        customer: {
                            address: '',
                            info: '',
                            logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                            mail: 'iskandar@greentagpro.com',
                            name: 'Greentagpro Solution',
                            www: 'greentagpro.com',
                        },
                        chat: false,
                        forcesave: true,
                        comments: false,
                    }
                },
            }
    
            new window.DocsAPI.DocEditor("placeholder", config);
          }
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <React.Fragment>
      <Card>
        <div className="header">
          <h2>Team Leader Recommendation</h2>
        </div>
        <div className="body">
        <Nav pills>
            {tabs.map((tab, i) => 
              <NavItem key={i}>
                <NavLink
                  className={classnames({ active: activeTab === tab.id })}
                  onClick={() => { toggle(tab.id); }}
                >
                  9.{i + 1} {tab.label}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <hr />
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
            <Formik 
              enableReinitialize={true}
              initialValues={data}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                let token = await localStorage.getItem('auth-token');

                token = JSON.parse(token);

                api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                api.put('/audits/' + id, JSON.stringify(values), {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                })
                .then(function (response) {
                  addToast('Recommendation successfully updated', { appearance: 'success' });

                  reload(true);
                })
                .catch(function (error) {
                  addToast(error.message, { appearance: 'error' });
                });

                // resetForm();
              }}
            >
            {({ isSubmitting, values, setFieldValue, handleChange }) => (
              <FormikForm>
                <FormGroup>
                  <Label for="audit_conclusions">Audit Conclusions</Label>
                  {/* <Input
                    type="textarea"
                    id="audit_conclusions"
                    name="audit_conclusions"
                    onChange={handleChange}
                    value={values.audit_conclusions}
                    rows={5}
                  /> */}
                  <Editor 
                    apiKey={process.env.REACT_APP_TINYMCE_KEY}
                    initialValue={data.audit_conclusions}
                    init={{
                      height: 300,
                      menubar: 'file edit view insert format tools table tc help',
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                      paste_data_images: true,
                      content_style: "body { font-family: Arial; font-size: 10pt; }",
                    }}
                    onEditorChange={(content) => {
                      setFieldValue('audit_conclusions', content);
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="team_leader_recommendation">Recommendation</Label>
                  {/* <Input
                    type="textarea"
                    id="team_leader_recommendation"
                    name="team_leader_recommendation"
                    onChange={handleChange}
                    value={values.team_leader_recommendation}
                    rows={5}
                  /> */}
                  <Editor 
                    apiKey={process.env.REACT_APP_TINYMCE_KEY}
                    initialValue={data.team_leader_recommendation}
                    init={{
                      height: 300,
                      menubar: 'file edit view insert format tools table tc help',
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                      paste_data_images: true,
                      content_style: "body { font-family: Arial; font-size: 10pt; }",
                    }}
                    onEditorChange={(content) => {
                      setFieldValue('team_leader_recommendation', content);
                    }}
                  />
                </FormGroup>

                {values.audit_plan && values.audit_plan.stage && values.audit_plan.stage.toLowerCase() !== 'stage 1' ?
                  <FormGroup>
                    <Label for="reason_for_issue_certification">Reason for issue or change of the certificate</Label>
                    <Input
                      type="text"
                      id="reason_for_issue_certification"
                      name="reason_for_issue_certification"
                      onChange={handleChange}
                      value={values.reason_for_issue_certification}
                    />
                  </FormGroup>
                : null}
                <FormGroup>
                  <Label for="scope_modification">Is scope needs modification?</Label>
                  <div>
                    <label className="fancy-radio">
                      <Input
                        type="radio"
                        name="scope_modification"
                        value="1"
                        onClick={() => {
                          setFieldValue('scope_modification', 1)
                        }}
                        checked={values.scope_modification === 1}
                      />
                      <span>
                        <i></i> Yes
                      </span>
                    </label>

                    <label className="fancy-radio">
                      <Input
                        type="radio"
                        name="scope_modification"
                        value="0"
                        onClick={() => {
                          setFieldValue('scope_modification', 0)
                        }}
                        checked={values.scope_modification === 0}
                      />
                      <span>
                        <i></i> No
                      </span>
                    </label>
                  </div>
                </FormGroup>
                {values.scope_modification && values.scope_modification === 1 ?

                <FormGroup>
                  <Label for="scope_modification_comments">Scope of Certifications Modification (If any please fill up, else leave it blank)</Label>
                  <Input
                    type="textarea" 
                    id="scope_modification_comments"
                    name="scope_modification_comments"
                    onChange={handleChange}
                    value={values.scope_modification_comments}
                  />
                </FormGroup>
                : null}
                {values.audit_plan && values.audit_plan.stage && values.audit_plan.stage.toLowerCase() !== 'stage 1' ?
                  <FormGroup>
                    <Label for="follow_up_audit_required">Is a follow up audit required</Label>
                    <div>
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="follow_up_audit_required"
                          value="1"
                          onClick={() => {
                            setFieldValue('follow_up_audit_required', 'Y')
                          }}
                          checked={values.follow_up_audit_required === 'Y'}
                        />
                        <span>
                          <i></i> Yes
                        </span>
                      </label>

                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="follow_up_audit_required"
                          value="0"
                          onClick={() => {
                            setFieldValue('follow_up_audit_required', 'N')
                          }}
                          checked={values.follow_up_audit_required === 'N'}
                        />
                        <span>
                          <i></i> No
                        </span>
                      </label>
                    </div>
                  </FormGroup>
                : null}

                <FormGroup>
                  <Label for="follow_up_audit_start_date">Next audit date</Label>
                  <DateRangePicker 
                    singleDatePicker
                    containerStyles={{ display: "block" }}
                    drops="up"
                    startDate={values.follow_up_audit_start_date && values.follow_up_audit_start_date.length ? moment(values.follow_up_audit_start_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                    // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                    onApply={(event, picker) => {
                      // console.log(picker);
                      setFieldValue('follow_up_audit_start_date', picker.startDate.format('YYYY-MM-DD'));
                      // setFieldValue('date_of_audit_end', picker.endDate.format('YYYY-MM-DD'));
                    }}
                  >
                    <Input 
                      autoComplete="off"
                      type="text" 
                      // name="date_of_audit" 
                      id="follow_up_audit_start_date"
                      // onChange={handleChange}
                      value={values.follow_up_audit_start_date ? moment(values.follow_up_audit_start_date).format('DD/MM/YYYY') : ""}
                    />
                  </DateRangePicker>
                </FormGroup>

                <FormGroup>
                  <Label for="follow_up_audit_duration">Duration (days)</Label>
                  <Input
                    type="number"
                    id="follow_up_audit_duration"
                    name="follow_up_audit_duration"
                    onChange={handleChange}
                    value={values.follow_up_audit_duration}
                    pattern="\d*"
                  />
                </FormGroup>

                <div className="row clearfix">
                  <div className="col-sm-4">
                    <button 
                      type="submit" 
                      className="btn btn-primary"
                      disabled={isSubmitting ? true : false}
                    >
                      Save
                    </button>&nbsp;
                  </div>
                  <div className="col-sm-8 text-right">

                  {type.toLowerCase() !== 'iso' ?
                  <button 
                  // disabled={isSubmitting}
                  className="btn btn-outline-secondary" 
                  type="button" 
                  onClick={toggleModal}
                  disabled={isSending || data.send_for_draft_audit_report_date !== null ? true : false || (data.audit_plan && data.audit_plan.lead_auditors.find(o => parseInt(o.id) === parseInt(profile.auditor_id)) ? false : true)}
                  >
                  <i className="icon-envelope"></i> {'Send Draft Audit Confirmation'}
                  </button>
                  : null}
                    &nbsp;

                    <button 
                      type="button" 
                      className="btn btn-outline-secondary"
                      disabled={isSending 
                          || data.send_for_operation_review_date !== null ? true : false
                          || (data.audit_plan && data.audit_plan.type.toLowerCase() !== 'iso' && data.send_for_draft_audit_report_date === null ? true : false )
                          || (data.audit_plan && data.audit_plan.lead_auditors.find(o => parseInt(o.id) === parseInt(profile.auditor_id)) ? false : true)}
                      onClick={sendForOperationReview}
                    >
                      {isSending ? 'Sending for operation review...' : 'Send for operation review'}
                    </button>
                    &nbsp;
                    <button 
                        className="btn btn-outline-secondary" 
                        type="button"
                        onClick={() => {
                          window.open(`${process.env.REACT_APP_API_URL}/audits/view/report/${id}?token=${token}`);
                        }}
                      >
                        <i className="icon-printer"></i>
                      </button>&nbsp;
                  </div>
                </div>
              </FormikForm>
            )}
            </Formik>
            </TabPane>
            <TabPane tabId={2}>
            <Formik 
                initialValues={data}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                  values._method = 'PUT';

                  var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

                  await api({
                    method: 'POST',
                    url: '/audits/' + id,
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                    data: data,
                  })
                  .then(function (response) {
                    addToast('Declaration of Interest successfully uploaded', { appearance: 'success' });

                    window.location.reload();
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });
                }}
              >
              {({ isSubmitting, values, setFieldValue, handleChange }) => (
                <FormikForm>

                  <FormGroup>
                    <Label for="">Upload Declaration Form Non of Interest</Label>
                    <div className="input-group">
                      <div className="custom-file">
                        <Input
                          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          className="custom-file-input"
                          type="file" 
                          name="declaration_of_interest" 
                          // id="ROCCrtificate"
                          onChange={(event) => {
                            setFieldValue(`declaration_of_interest`, event.currentTarget.files[0])
                          }}
                        />
                        <Label className="custom-file-label">
                          {values.declaration_of_interest && values.declaration_of_interest.name ? values.declaration_of_interest.name : 'No file chosen'}
                        </Label>
                      </div>
                      <div className="input-group-append">
                        <button 
                          disabled={isSubmitting}
                          className="btn btn-outline-secondary" 
                          type="submit"
                          >
                            {isSubmitting ? 'Uploading...' : 'Upload'}
                          </button>
                      </div>
                    </div>

                  </FormGroup>

                  <div id="placeholder"></div>
                </FormikForm>
              )}
              </Formik>
            </TabPane>
            </TabContent>              
        </div>
        <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
        <Formik 
          enableReinitialize={true}
          initialValues={data}
          onSubmit={async (values, { setSubmitting }) => {
            // console.log(values);
            
            setSubmitting1(true);
            
            let data = {
              id: id,
            };

            // throw new Error();

            // return;

            let token = await localStorage.getItem('auth-token');

            token = JSON.parse(token);

            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

            data = objectToFormData(data, {indices: true, booleansAsIntegers: true});

            await api({
              method: 'POST',
              url: '/audits/send-audit-confirmation/' + id,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: data,
            })
            .then(function (response) {
              addToast('Audit confirmation has been sent', { appearance: 'success' });

              setSubmitting1(false);
              
              toggleModal();
            })
            .catch(function (error) {
              addToast(error.message, { appearance: 'error' });
              
              setSubmitting1(false);
            });

          }}
        >
          {({ isSubmitting, values, setFieldValue, handleChange }) => (
            <FormikForm>
              <ModalHeader toggle={toggleModal}>Auditor Confirmation</ModalHeader>
              <ModalBody className="p-0">
                {/*<iframe 
                  src={`${process.env.REACT_APP_API_URL}/audit-plans/preview-audit-confirmation/${id}?token=${token}`}
                  style={{ border: 0, height: "320px", width: "100%" }}
                >
                </iframe> */}
                

                <div className="m-2 row clearfix">
                  <div className="col-sm-12">
                    Link for audit report draft confirmation will be send to client's email. Are you sure to proceed?
                  </div>
                </div>

              </ModalBody>
              <ModalFooter>
                <Button 
                    type="submit"
                    // disabled={true}
                    color="primary" 
                    // onClick={sendAuditConfirmationHandler}
                  >
                  {isSubmitting1 ? 'Sending...' : 'Send'}
                </Button>{' '}
                <Button color="secondary" onClick={toggleModal}>Cancel</Button>
              </ModalFooter>
            </FormikForm>
          )}
        </Formik>
      </Modal>
      </Card>      
    </React.Fragment>
    
  )
}

export default TeamLeaderRecommendation;