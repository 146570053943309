import React, { useEffect } from 'react';

import {
  useRouteMatch,
  NavLink,
} from 'react-router-dom';

const Menu = (props) => {
  let { path } = useRouteMatch();

  // console.log(path, props.to, props.to.includes(path), path.includes(props.to));

  useEffect(() => {

  }, [path]);

  return (
    <li className={props.to !== '/' && path.includes(props.to) ? 'active' : (props.to === path ? 'active' : null)}>
      <NavLink 
        to={!props.children ? props.to : '#'} 
        className={props.children ? 'has-arrow' : null}
        // onClick={() => {
        //   setActiveMenu(props.title);

        //   // console.log(path);
        //   // props.children ? toggleCollapse(!collapse) : setActive()

        //   // const element = document.querySelector('body');

        //   // if (!props.children && element.classList.contains('offcanvas-active')) {
        //   //   element.classList.toggle('offcanvas-active');
        //   // }
        // }}
        // onClick={props.onClick}
        aria-expanded={path.includes(props.to) ? true : false}
        {...props}
      >
        {
          props.icon 
        ? 
          <i className={'icon-' + props.icon}></i> 
        : 
          null
        }
        <span>{props.title}</span>
      </NavLink>
      {
        props.children
      ?
        <ul 
          className={path.includes(props.to) ? 'collapse in' : 'collapse'} 
          aria-expanded={path.includes(props.to) ? true : false}
        >
          {props.children}
        </ul>
      :
          null
      }
    </li>
  )
}

export default Menu;