import React, { useState, useEffect } from 'react';

import Menu from '../Menu';

import api from '../../utils/api';

const ClientMenu = (props) => {

  const { client_id, type, subtype } = props.user;

  useEffect(() => {

  }, []);

  return (
    <React.Fragment>
      <ul className="main-menu metismenu">
        <Menu 
          title="Dashboard"
          icon="speedometer"
          to="/" 
        />

        <Menu 
          title="Application Form"
          icon="doc"
          to={`/application-review/client-application/view/${client_id}`}
        />
        {console.log(type)}
        {type && (type.toLowerCase() === 'mspo' || type.toLowerCase() === 'mspo-sccs') ?
        <Menu 
          title="Basic Data Information"
          icon="list"
          to={`/basic-data-information/edit/${client_id}`}
        />: 
        <Menu 
          title="Data Details"
          icon="list"
          to="/application-review/client-data-details" 
        />
        }

        {type && (type.toLowerCase() === 'mspo' || type.toLowerCase() === 'mspo-sccs') ?
        <Menu 
          title="Stakeholder List"
          icon="users"
          to={`/stakeholder-list/edit/${client_id}`}
        /> : 
        null
        }

        <Menu 
          title="Client Feedback"
          icon="speech"
          to="/application-review/client-feedbacks" 
        />

        <Menu 
          title="Auditor Evaluation"
          icon="check"
          to="/application-review/auditor-evaluations" 
        />

        <Menu 
          title="Audit History"
          icon="layers"
          to={`/audit-module/audit-report/${type}/${subtype}`}
        />
      </ul>
    </React.Fragment>
  );
}

export default ClientMenu;