import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
  useLocation,
  useHistory,
  // useParams,
} from 'react-router-dom';

import {
  Input,
  Label,
  Button,
  ButtonGroup,
  Card,
  FormGroup,
} from 'reactstrap';
import { 
  Formik,
  Form as FormikForm
} from 'formik';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import SidebarToggler from '../../../components/SidebarToggler';

import api from '../../../utils/api';

import { useToasts } from 'react-toast-notifications';
import qs from 'query-string';
import moment from 'moment';

const INITIAL_QUERY = {}

const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
  pageStartIndex: true,
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Listing = (props) => {
  let { path } = useRouteMatch();
  let history = useHistory();
  
   let queryString = useQuery();

  let [profile, setProfile] = useState({});
  const [refresh, setRefreshing] = useState(false);
  const [data, setData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [query, setQuery] = useState(INITIAL_QUERY);
  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);

  const { addToast } = useToasts();
  console.log(data);
  const columns = [
  {
    editable: false,
    text: "No",
    formatter: (cell, row, rowIndex) => {
      let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
      return <span>{rowNumber}.</span>;
    }
  },
  {
    text: 'Date of Audit',
    formatter: (cell, row, rowIndex) => {
      return row.audit&& row.audit.audit_plan && row.audit.audit_plan.date_of_audit_start && row.audit.audit_plan.date_of_audit_end ? moment(row.audit.audit_plan.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(row.audit.audit_plan.date_of_audit_end).format('DD/MM/YYYY') : ""
    }
  },
  {
    text: 'Type of Audit',
    formatter: (cell, row, rowIndex) => {
      if(row.audit && row.audit.audit_plan && row.audit.audit_plan.stage){
        if(row.audit.audit_plan.other_audit){
          return row.audit.audit_plan.other_audit + ' - ' + row.audit.audit_plan.stage;
        }
        else{
          return row.audit.audit_plan.stage;
        }
      }else{
        return '';
      }
    }
  },
  {
    text: 'Organization',
    formatter: (cell, row, rowIndex) => {
        if (row.audit && row.audit.audit_plan && row.audit.audit_plan.client && row.audit.audit_plan.client.name) {
            return row.audit.audit_plan.client.name;
        }
    }
  }, {
    text: 'Standards',
    formatter: (cell, row, rowIndex) => {
      return (
        <ul>{row.audit && row.audit.audit_plan && row.audit.audit_plan.client && row.audit.audit_plan.client.certifications ? 
          row.audit.audit_plan.client.certifications.map((certification, i) => 
            <li>{certification.code}</li>
            ) : ""}&nbsp;</ul>
      )
    }
  }, 
  // {
  //   text: 'Team Leader',
  //   formatter: (cell, row, rowIndex) => {
  //     return (
  //       <ol>{row.client && row.client.audit_plan && row.client.audit_plan.lead_auditors ? 
  //         row.client.audit_plan.lead_auditors.map((auditor, i) => 
  //           <li>{auditor.full_name}</li>
  //          ) : ""}&nbsp;</ol>
  //     )
  //   }
  // }, 
  {
    dataField: 'status_text',
    text: 'Status',
  },
  {
    dataField: 'state_text',
    text: 'OR State',
  },
  {
    dataField: 'id',
    text: ' ',
    formatter: (cell, row, rowIndex) => {
      return (
        <ButtonGroup size="sm">
          {/* <Button outline tag={Link} to={`${path}/view/${cell}`} >
            <i className="icon-eye"></i>
          </Button> */}
          <Button outline tag={Link} to={`${path}/view/${cell}`} >
            <i className="icon-eye"></i>
          </Button>
          {row.state_text === 'Created' && permissions && permissions.find(o => o.name.toLowerCase() === 'operation_review.assign') ?
            <Button outline tag={Link} to={`${path}/assign/${cell}`}>
              <i className="icon-user-following"></i>
            </Button>
          : null}
          {
            !(row.audit && row.audit.audit_plan && row.audit.audit_plan.lead_auditors && profile.auditor_id === row.audit.audit_plan.lead_auditors[0].id && row.operation_review_state === '3')  && permissions && permissions.find(o => o.name.toLowerCase() === 'operation_review.update') ? 
          <Button outline tag={Link} to={`${path}/edit/${cell}`}>
          <i className="icon-pencil"></i>
          </Button>
          : null}
          
          {
            (row.audit && row.audit.audit_plan && row.audit.audit_plan.lead_auditors && profile.auditor_id === row.audit.audit_plan.lead_auditors[0].id && row.operation_review_state === '3') ?
            <Button outline tag={Link} to={`${path}/lead-auditor/edit/${cell}`}>
              <i className="icon-pencil"></i>
            </Button>
            : null}
{/*           {(row.operation_review_state === '7' || row.operation_review_state === '4') && permissions && permissions.find(o => o.name.toLowerCase() === 'operation_review.review') ?
 */}          {(row.operation_review_state === '7' || row.operation_review_state === '4') && profile && profile.auditor_id === row.operation_review_reviewer_id ?
          <Button outline tag={Link} to={`${path}/review/${cell}`}>
              <i className="icon-note"></i>
            </Button>
          : null}
          {row.operation_review_state === '5' && permissions && permissions.find(o => o.name.toLowerCase() === 'operation_review.approve') ?
           <Button outline tag={Link} to={`${path}/approve/${cell}`}>
              <i className="icon-check"></i>
            </Button>
          : null}
        </ButtonGroup>
      )
    },
    style: { textAlign: 'center' }
  }];

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));
        console.log(response.data);
        setProfile(response.data);
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      api.get('/operation-reviews?type[]=iso&type[]=mspo&type[]=mspo-sccs&status[]=1&status[]=2&status[]=3', {
        //api.get('/operation-reviews?type[]=MSPO&type[]=MSPO-SCCS&step[]=2&step[]=3&step[]=4&step[]=5', {
          params: query,
        })
     /*  api.get('/operation-reviews', {
        params: query,
      }) */
      .then(function (response) {
        // console.log(response);

        setPermissions(response.data.meta.permissions);

        // console.log(response.data.meta.permissions.find(o => o.name.toLowerCase() === 'operation_review.update'));

        setData(response.data.data);

        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
        }));
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
      setRefreshing(false);

    }

    getData();
  }, [props, query]);

  // const deleteHandler = async (id) => {
  //   if (window.confirm('Delete this record?')) {
  //     let token = await localStorage.getItem('auth-token');

  //     token = JSON.parse(token);

  //     api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

  //     api.delete('/audits/' + id)
  //     .then(function (response) {
  //       // console.log(response);

  //       // setData(response.data.data);

  //       addToast('Audit summary successfully deleted', { appearance: 'success' });

  //       history.push(path);
  //     })
  //     .catch(function (error) {
  //       // console.log(error.response);
  //       addToast(error.response.data.message, { appearance: 'error' });
  //     });
  //   }
  // }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }

  return (
    <React.Fragment>
  <div className="container-fluid">
    <div className="block-header">
      <div className="row">
        <div className="col-lg-10 col-md-9 col-sm-12">
          <h2>
            <SidebarToggler />
            Operation Review
          </h2>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">
                <i className="icon-home"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              Review
            </li>
            <li className="breadcrumb-item">
              <Link to={path}>
                Operation Review
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <Card>
          <div className="header">
            <h2>Operation Review</h2>
            {/* <ul className="header-dropdown">
              <li>
                <Button 
                  className="m-t-10 m-l-20"
                  color="primary" 
                  tag={Link} 
                  to={`${path}/create`}
                >
                  Add New
                </Button>
              </li>
            </ul> */}
          </div>
          <div className="body">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    client_name: queryString.get('client_name') ? queryString.get('client_name') : "",
                    

                    // region: queryString.get('region') ? queryString.get('region') : "",
                    // state: queryString.get('state') ? queryString.get('state') : "",
                    // certifications: queryString.get('certifications') ? queryString.get('certifications') : "",
                    // status: queryString.get('status') ? queryString.get('status') : "",
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    var searchString = qs.stringify(values); // objectToFormData(values, {indices: true, booleansAsIntegers: true});

                    setQuery(values);

                    history.push({
                      pathname: props.path,
                      search: searchString
                    });

                    setQuery(values);
                  }}
                >
                  {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <div className="row clearfix">
                      <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Name">Organization</Label>
                        <Input 
                          type="text" 
                          // className="custom-select" 
                          name="client_name" 
                          id="Name"
                          onChange={handleChange}
                          value={values.client_name}
                        />
                      </FormGroup>
                      </div>
                      
                    </div>
                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <Label>&nbsp;</Label>
                        <FormGroup>
                          <Button 
                            // className="btn btn-outline-secondary"
                            outline
                            color="secondary" 
                            // tag={Link} 
                            // to="/client-database"
                          >
                            Search
                          </Button>
                          <Button 
                            // className="btn btn-outline-secondary"
                            type="button"
                            outline
                            color="link" 
                            // tag={Link} 
                            // to={`${path}`}
                            onClick={() => {
                              setQuery(INITIAL_QUERY);

                              history.push({
                                pathname: props.path,
                              });
                             // setFieldValue('stage', '');
                              setQuery(INITIAL_QUERY);
                            }}
                          >
                            Reset
                          </Button>
                        </FormGroup>
                      </div>
                    </div>
                  </FormikForm>
                  )}
                </Formik>

                <hr />
          </div>
          <div className="body project_report">
            
            <div className="table">
              <BootstrapTable 
                remote={true}
                bootstrap4
                bordered={false}
                classes="table-hover js-basic-example dataTable table-custom m-b-0"
                headerClasses="thead-dark"
                keyField="id"
                data={ data } 
                columns={ columns }
                pagination={ 
                  paginationFactory(pagingInfo)
                }
                onTableChange={handleTableChange}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
    </div>
    </React.Fragment>
    
  )
}

export default Listing;