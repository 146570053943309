import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  // useRouteMatch,
} from 'react-router-dom';

import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';


import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import SidebarToggler from '../../components/SidebarToggler';

import api from '../../utils/api';

import moment from 'moment';

import { objectToFormData } from 'object-to-formdata';

// import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

const INITIAL_VALUES = {

};

const DETAIL = { type:'', from:'', to:'', description: '', amount:'', quantity:'', attachment:'' }

const FormCreate = (props) => {
  //let { id } = useParams();

  const { addToast } = useToasts();
  const [auditors, setAuditor] = useState([]);
  const [data, setData] = useState(INITIAL_VALUES);
  const [auditplans, seAudit] = useState([]);
  const [profile, setProfile] = useState({});

  const [claimSetting, setClaimSetting]= useState({});
  const [mileagePerKm, setMileagePerKm]= useState(0);
  const [feePerDay, setFeePerDay]= useState(0);
  const [allowancePerDay, setAllowancePerDay]= useState(0);

  const [milageValue,setMilageValues] = useState({})
  const [tollValue,setTollValues] = useState({})
  const [transportValue,setTransportValues] = useState({})
  const [accoValue,setAccoValues] = useState({})
  const [parkingValue,setParkingValues] = useState({})

  const [types,setType] = useState({})
  const [amountName,setAmountName] = useState({})
  const [total_mileage,set_total_mileage]=useState(0.00);
  const [total_mileage_km,set_total_mileage_km]=useState(0);
  const [total_toll,set_total_toll]=useState(0.00);
  const [total_transportation,set_total_transportation]=useState(0.00);
  const [total_accomodation,set_total_accomodation]=useState(0.00);
  const [total_parking,set_total_parking]=useState(0.00);
  
  const [auditFee,setAuditFee]=useState(0.00);
  const [allowance,setAllowance]=useState(0.00);
  const [grandTotal,setGrandTotal]=useState(0.00);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);
    let { readOnly }  = props;

  useEffect(() => {
    // console.log(path);

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

         setProfile(response.data);
        /* if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'audit_plan.update') === "undefined") {
          history.push(props.path.replace(/:type/, type));
        } */
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getClaimSetting = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      api.get('auditor-claim-settings/1')
     // api.get('client-list?exclude_audits=false&sort=date_of_audit_start&type=iso&type=mspo&limit=-1')
      .then(function (response) {
        console.log(response.data.data);

        setClaimSetting(response.data.data);

      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getClaimSetting();

    const getAudits = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      api.get('client-list?exclude_claims=true&sort=date_of_audit_start&type[]=iso&type[]=mspo&type[]=mspo-sccs&limit=-1&flag=auditor_claim')
     // api.get('client-list?exclude_audits=false&sort=date_of_audit_start&type=iso&type=mspo&limit=-1')
      .then(function (response) {
         console.log(response.data.data);

        // setClient(response.data.data);

        let auditsArray = [];
          response.data.data.map((plan, i) => {
              auditsArray.push({
                
                id: plan.id,
                date : moment(plan.date_of_audit_start).format('DD MMMM YYYY') + ' - ' + moment(plan.date_of_audit_end).format('DD MMMM YYYY') ,
               /*  stage : plan.audit_plan.stage,
                client_name : plan.audit_plan && plan.audit_plan.client ? plan.audit_plan.client.name : '',
                client_address : plan.audit_plan && plan.audit_plan.client ? plan.audit_plan.client.address_1 : '',
                auditor_role : plan.auditor_role ?   ,
                mandays : '',
                daily_allowance : '' */
          
              })
            
            }
          );
        seAudit(auditsArray);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }
    getAudits();
    
    /*   const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/auditor-claims/' + id)
        .then(function (response) {
          // console.log(response.data);

          delete response.data.id;

          setData(response.data);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

       getData(); */
  },[]);
    
   
  const value_handler = (e) => {
    let name= e.target.name;
    let value= e.target.value;
    
   // console.log(types);
    const newMValues = {
        ...milageValue,
      [name]: Number(value)
    }
    console.log(newMValues);
    setMilageValues(newMValues)
    calc_total_mileage(newMValues)
    calc_grand_total(newMValues)
    
}
const calc_total_mileage = (mileages) => {
  
  let aux = 0
  let aux2 = 0;
  console.log(mileagePerKm)
  for (var key in mileages){
    
      aux += mileages[key] * Number(mileagePerKm)
      aux2 += mileages[key] 
  }
  set_total_mileage_km(Math.round(aux2 * 100) / 100)
  set_total_mileage(Math.round(aux * 100) / 100)
  
}
const calc_total_toll = (tolls) => {
  let aux = 0
  for (var key in tolls){
      aux += tolls[key]
  }
  set_total_toll(Math.round(aux * 100) / 100)

}
const calc_total_transportation = (transportations) => {
  
  let aux = 0
  for (var key in transportations){
      aux += transportations[key] 
  }
  set_total_transportation(Math.round(aux * 100) / 100)

}
const calc_total_accomodation = (accomodations) => {
  
  let aux = 0
  for (var key in accomodations){
      aux += accomodations[key] 
  }
  set_total_accomodation(Math.round(aux * 100) / 100)

}
const calc_total_parking = (parkings) => {
  
  let aux = 0
  for (var key in parkings){
      aux += parkings[key] 
  }
  set_total_parking(Math.round(aux * 100) / 100)

}

const calc_grand_total = (all) => {
  let aux = 0
  for (var key in all){
      aux += all[key]
  }
  setGrandTotal(Math.round(aux * 100) / 100)
}
//set_total(total_mileage+total_toll);
let totalAll;
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2 style={{ textTransform: "capitalize" }}>
              <SidebarToggler />
               Auditor Claim
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Auditor
              </li>
              <li className="breadcrumb-item">
              Auditor Claim
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          if(values.details == null){
            if (!window.confirm('You haven\'t fill up claim details. Are you going to proceed?')) {
              return;
            }
          }
          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Berer ' + token.access_token;
          values.initial = auditors ? auditors.map((auditor, key) => {
            if(profile && profile.auditor_id && auditor.id==profile.auditor_id)
             return auditor.initial;
          }) : '';
          values.audit_no = values && values.audit_no ? values.audit_no : '';
          values.total_allowance = allowance;
          values.total_auditor_fee = auditFee;
          values.total_amount = totalAll;
          values.auditor_id = profile && profile.auditor_id ? profile.auditor_id : 0;
          values.reviewed_by = claimSetting.reviewer_id;
          values.approved_by = claimSetting.approver_id;
         // values.allowance_per_day = allowancePerDay;
         // values.fee_per_day = feePerDay;
         // values.mileage_per_km = mileagePerKm;

          var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
            console.log(values.action);

            await api({
              method: 'POST',
              url: '/auditor-claims',
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: data,
            })
            .then(function (response) {
              // console.log(response.data.data.id);

              addToast('Claim successfully saved', { appearance: 'success' });

              // history.push('/audit/audits');

               window.location = '/auditor/auditor-claim';

              /* if (response.data.data.id) {
                history.push(`${props.path.replace(/:type/, type).replace(/:subtype/, subtype)}/edit/${response.data.data.id}?tab=introduction`);
              } */
            })
            .catch(function (error) {
              console.log(error);
              if (error && error.response && error.response.data) {
                addToast(error.response.data.error.message, { appearance: 'error' });
              } else {
                addToast(error.message , { appearance: 'error' });
              }
            });
          
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <Card>
                  <div className="header">
                    <h2>AUDITOR CLAIM</h2>
                  </div>
                  <div className="body">
                      <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="Organization">Select audit for claim</Label>
                          <Input 
                            type="select" 
                            className="custom-select" 
                            name="audit_plan_id" 
                            id="auditPlan"
                            required
                            // onChange={handleChange}
                            onChange={async (e) => {
                              let { value } = e.currentTarget;

                              // console.log(value);

                              setFieldValue('audit_plan_id', value);

                              let token = await localStorage.getItem('auth-token');

                              token = JSON.parse(token);

                              api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                              api.get('/audit-plans/' + value)
                              .then((response) => {
                                 console.log(response.data.data);
                               
                                  let selectedAllowance = 0;
                                  let selectedFee = 0;
                                  let selectedMandays = 0;
                                  let selectedMileage = 0;

                                  if(response.data && response.data.data.type =='mspo'){

                                    claimSetting.mspo_allowance_per_day.map((displayAllowance, key)=>{
                                      if (response.data && response.data.data.auditor_role) {
                                        response.data.data.auditor_role.map((role, key) => {
                                          if(profile && profile.auditor_id && role.auditor_id==profile.auditor_id && displayAllowance.role==role.role){
                                              selectedAllowance = displayAllowance.allowance_per_day;
                                          }
                                        });
                                      }
                                    });

                                    claimSetting.mspo_fee_per_day.map((displayFee, key)=>{
                                      if (response.data && response.data.data.auditor_role) {
                                        response.data.data.auditor_role.map((role, key) => {
                                          if(profile && profile.auditor_id && role.auditor_id==profile.auditor_id && displayFee.role==role.role){
                                            selectedFee = displayFee.fee_per_day;
                                          }
                                        });
                                      }
                                    });

                                    claimSetting.mspo_mileage_per_km.map((displayMileage, key)=>{
                                      if (response.data && response.data.data.auditor_role) {
                                        response.data.data.auditor_role.map((role, key) => {
                                          if(profile && profile.auditor_id && role.auditor_id==profile.auditor_id && displayMileage.role==role.role){
                                            selectedMileage = displayMileage.mileage_per_km;
                                          }
                                        });
                                      }
                                    });
                                  }
                                  else
                                  {
                                    claimSetting.allowance_per_day.map((displayAllowance, key)=>{
                                      if (response.data && response.data.data.auditor_role) {
                                        response.data.data.auditor_role.map((role, key) => {
                                          if(profile && profile.auditor_id && role.auditor_id==profile.auditor_id && displayAllowance.role==role.role){
                                              selectedAllowance = displayAllowance.allowance_per_day;
                                          }
                                        });
                                      }
                                    });  
                                    
                                    claimSetting.fee_per_day.map((displayFee, key)=>{
                                      if (response.data && response.data.data.auditor_role) {
                                        response.data.data.auditor_role.map((role, key) => {
                                          if(profile && profile.auditor_id && role.auditor_id==profile.auditor_id && displayFee.role==role.role){
                                            selectedFee = displayFee.fee_per_day;
                                          }
                                        });
                                      }
                                    });

                                    claimSetting.mileage_per_km.map((displayMileage, key)=>{
                                      if (response.data && response.data.data.auditor_role) {
                                        response.data.data.auditor_role.map((role, key) => {
                                          if(profile && profile.auditor_id && role.auditor_id==profile.auditor_id && displayMileage.role==role.role){
                                            selectedMileage = displayMileage.mileage_per_km;
                                          }
                                        });
                                      }
                                    });
                                  }

                                if (response.data && response.data.data.date_of_audit_start) {
                                  setFieldValue('date_of_audit_start', response.data.data.date_of_audit_start);
                                } else {
                                  setFieldValue('date_of_audit_start', '');
                                }
                                if (response.data && response.data.data.date_of_audit_end) {
                                  setFieldValue('date_of_audit_end', response.data.data.date_of_audit_end);
                                } else {
                                  setFieldValue('date_of_audit_end', '');
                                }
                                let auditors = [];
                                if (response.data && response.data.data.both_auditors) {
                                  
                                  response.data.data.both_auditors.map((auditor, key) => auditors.push({
                                  // if(auditor.id==profile.auditor_id){
                                      id : auditor.id,
                                      full_name: auditor.full_name,
                                      initial: auditor.initial,
                                      business_email: auditor.business_email,
                                      private_mobile: auditor.private_mobile,
                                      address : auditor.private_address_1 && (auditor.private_address2 || auditor.private_address3  || auditor.private_postcode || auditor.private_state) ?  auditor.private_address_1 + ', \n'+ auditor.private_address_2 + ', \n' +  auditor.private_address_3 +', \n' +  auditor.private_postcode +' ' +  auditor.private_state : '' 
                                  }));
                                  setAuditor(auditors);
                                }
                                if (response.data && response.data.data.stage) {
                                  setFieldValue('stage', response.data.data.stage);
                                } else {
                                  setFieldValue('stage', '');
                                }

                                if (response.data && response.data.data.client && response.data.data.client.name) {
                                  setFieldValue('client.name', response.data.data.client.name);
                                } else {
                                  setFieldValue('client.name', '');
                                }

                                if (response.data && response.data.data.client && response.data.data.client.address_1) {
                                  setFieldValue('client.address_1', response.data.data.client.address_1);
                                } else {
                                  setFieldValue('client.address_1', '');
                                }
                                let selectedRoles = [];
                                if (response.data && response.data.data.auditor_role) {
                                  
                                  response.data.data.auditor_role.map((role, key) => {
                                    if(profile && profile.auditor_id && role.auditor_id==profile.auditor_id)
                                      return selectedRoles.push(role.role);
                                  });
                                  //if(selectedRoles == role dari audit_claim_setting table.role) 
                                  // setMileageperkm(audit_claim_setting table.mileage_per_km)
                                  // by default setting adalah yg active shj
                                  setFieldValue('auditor_role', selectedRoles);
                                }
                                if (response.data && response.data.data.mandays) {

                                 let selectedMandays = 0;
                                  response.data.data.mandays.map((manday, key) => {
                                    if(profile && profile.auditor_id && manday.auditor_id==profile.auditor_id)
                                      selectedMandays = manday.mandays;
                                  });
                             
                                  if (response.data && response.data.data.audit_no) {
                                    setFieldValue('audit_no', response.data.data.audit_no);
                                  } else {
                                    setFieldValue('audit_no', '');
                                  }
                                  if (response.data && response.data.data.auditor_claim_max_id) {
                                    setFieldValue('auditor_claim_max_id', response.data.data.auditor_claim_max_id);
                                  } else {
                                    setFieldValue('auditor_claim_max_id',1);
                                  }



                                  setAuditFee(selectedMandays*selectedFee);   
                                  
                                  setMileagePerKm(selectedMileage);
                                  setFeePerDay(selectedFee);
                                  setAllowancePerDay(selectedAllowance);

                                  console.log(mileagePerKm);
                                  setFieldValue('mileage_per_km', selectedMileage);
                                  setFieldValue('allowance_per_day', selectedAllowance);
                                  setFieldValue('auditor_fee_per_day', selectedFee);
                                  setFieldValue('mandays', selectedMandays);
                               }

                                // if (response.data && response.data.contract_review && response.data.contract_review.lead_auditor.full_name) {
                                //   setFieldValue('client.contract_review.lead_auditor.full_name', response.data.contract_review.lead_auditor.full_name);
                                // } else {
                                //   setFieldValue('client.contract_review.lead_auditor.full_name', '');
                                // }
                              })
                              .catch((error) => {

                              });
                            }}
                            value={values.id}
                          >
                            <option></option>
                            {auditplans && auditplans.map((auditplan, i) => 
                              <option key={i} value={auditplan.id}>{auditplan.date}</option>
                            )}
                          </Input>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <FormGroup>
                          <Label for="type_o">Type of Audit</Label>
                          <Input 
                            type="text" 
                            name="stage" 
                            id="stage"
                            value={values && values.stage ? values.stage : ''}
                            disabled={true}
                            >
                            
                          </Input>
                        </FormGroup>
                      </div>
                    </div>  

                    <div className="row clearfix">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <FormGroup>
                        <Label for="ClientName">Company</Label>
                        <Input 
                          // readOnly={readOnly}
                          disabled={true}
                          type="text" 
                          name="name" 
                          id="ClientName"
                          // onChange={handleChange}
                          value={values && values.client && values.client.name ? values.client.name : ''}
                        >
                          <option></option>
                          {/* {clients && clients.map((client, i) => 
                            <option key={i} value={client.id}>{client.name}</option>
                          )} */}
                        </Input>
                        </FormGroup>
                      </div>
                    </div>  

                    <div className="row clearfix">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <FormGroup>
                        <Label for="CompanyAddress1">Company Address</Label>
                        <Input 
                          disabled={true}
                          type="textarea" 
                          name="address_1" 
                          id="CompanyAddress1"
                          rows="5"
                          onChange={handleChange}
                          value={values && values.client && values.client.address_1 ? values.client.address_1 : ''}
                        />
                        </FormGroup>
                      </div>
                    </div>  

                    <div className="row clearfix">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <FormGroup>
                        <Label for="auditor_role">Role</Label>
                        <Input 
                          disabled={true}
                          type="text" 
                          name="auditor_role" 
                          id="auditor_role"
                          //onChange={handleChange}
                          value={values.auditor_role}
                        />
                        </FormGroup>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <FormGroup>
                        <Label for="mandays">Audit mandays allocated</Label>
                        <Input 
                          disabled={true}
                          type="text" 
                          name="mandays" 
                          id="mandays"
                         // onChange={handleChange}
                          value={values.mandays}
                        />
                        </FormGroup>
                      </div>
                    </div>  

                    <div className="row clearfix">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <FormGroup>
                        <Label for="auditor_fee">Audit Fee</Label>
                        <Input 
                          disabled={true}
                          type="text" 
                          name="auditor_fee_per_day" 
                          id="auditor_fee_per_day"
                          //onChange={handleChange}
                          value={feePerDay}
                        />
                        </FormGroup>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <FormGroup>
                        <Label for="total_auditor_fee">Daily Allowance</Label>
                        <Input 
                          disabled={true}
                          type="text" 
                          name="allowance_per_day" 
                          id="allowance_per_day"
                          value={allowancePerDay}
                        />
                        </FormGroup>
                      </div>        
                    </div>  
                    <div className="row clearfix">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <FormGroup>
                        <Label for="auditor_fee">No. of day(s) outstation</Label>
                        <Input 
                          //disabled={true}
                          type="text" 
                          name="outstation_day" 
                          id="outstation_day"
                          //onChange={handleChange}
                          onChange={(e) => {
                            let value= e.currentTarget.value;
                            setAllowance(value * allowancePerDay)
                            setFieldValue('outstation_day', value);

                          }}
                          value={values.outstation_day}
                        />
                        </FormGroup>
                      </div>    
                    </div>                      
                  </div>
                </Card>
               
                <Card>
                  <div className="header">
                    <h2>CLAIM DETAILS</h2>
                  </div>
                  <div className="body">

                    <div className="row cleafix">
                    <div className="col-sm-12">
                        <FormGroup>
                          <Label for="">Claim Details</Label>
                          <small> Please select claim type to make a claim</small>
                          <h6 className="float-right">Total(RM) : {totalAll= total_mileage + total_toll + total_transportation + total_accomodation + total_parking + auditFee + allowance }</h6>
                          <table className="table table-hover js-basic-example dataTable table-custom m-b-0">
                            <thead className="thead-dark">
                              <tr>
                                <th style={{width: '15% !important;'}}>Type</th>
                                <th style={{width: '10% !important;'}}>From (if applicable)</th>
                                <th style={{width: '10% !important;'}}>To (if applicable)</th>
                                <th style={{width: '15% !important;'}}>Description</th>
                                <th style={{width: '10% !important;'}}>Amount (RM) (if applicable)</th>
                                <th style={{width: '10% !important;'}}>Quantity / Distance(KM) </th>
                                <th style={{width: '20% !important;'}}>Attachments</th>
                                <th style={{width: '10% !important;', textAlign: 'right'}}><Button
                                      type="button"
                                      outline
                                      color="success"
                                      disabled={readOnly}
                                      //onClick={addTableRows}
                                      onClick={(e) => {
                                        e.preventDefault();
  
                                        let { details } = values;
                                        if (!details) {
                                          details = [];
                                        }
                                        details.push(DETAIL);
  
                                        // setData(data => {
                                        //   return {...data, mileages: mileages}
                                        // })
  
                                        setFieldValue('details', details);
                                      }}
                                    >
                                      <i className="icon-plus"></i>
                                    </Button></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.details && values.details.map((detail, key) => 
                                <tr key={key}>
                                  <td>
                                  <Input 
                                      type="select" 
                                      id="type"
                                      onChange={(e) => {
                                        let value = e.currentTarget.options[e.currentTarget.selectedIndex].value;
                                        let name= e.currentTarget.name;
                                        setType(value);
                                       /*  const newType = {
                                          ...types,
                                          [name]: value
                                        }
                                        setType(newType); */
                                        setFieldValue(`details.${key}.type`, value);
                                      }}
                                     // name = {`details.${key}.type`}
                                     required={values.details[key].quantity || values.details[key].attachment}
                                    >
                                      <option></option>
                                      <option value="mileages">Mileages</option>
                                      <option value="tolls">Tolls</option>
                                      <option value="transportations">Transportations</option>
                                      <option value="parkings">Parkings</option>
                                      <option value="accomodations">Accomodations</option>
                                  </Input>
                                  </td>
                                  <td>
                                    <Input 
                                      type="text" 
                                      name={`details.${key}.from`}
                                      onChange={(event) => {
                                        setFieldValue(`details.${key}.from`, event.currentTarget.value)
                                      }}
                                      readOnly={readOnly}
                                    />
                                  </td>
                                  <td>
                                    <Input 
                                      type="text" 
                                      name={`details.${key}.to`}
                                      onChange={(event) => {
                                        setFieldValue(`details.${key}.to`, event.currentTarget.value)
                                      }}
                                      readOnly={readOnly}
                                    />
                                  </td>
                                  <td>

                                  {values.details[key].type == 'transportations' ?
                                    <Input 
                                      type="select"
                                      name={`details.${key}.description`}
                                      onChange={(event) => {
                                        setFieldValue(`details.${key}.description`, event.currentTarget.value)
                                      }}
                                      //value={values.details[key].description}
                                      readOnly={readOnly}
                                    >
                                      <option></option>
                                      <option value="Flight">Flight</option>
                                      <option value="Bus">Bus</option>
                                      <option value="Taxi">Taxi</option>
                                      <option value="E-Hailing">E-Hailing</option>
                                      <option value="Train/LRT/MRT">Train/LRT/MRT</option>
                                      
                                    </Input> :
                                    <Input 
                                      type="textarea" 
                                      name={`details.${key}.description`}
                                      onChange={(event) => {
                                        setFieldValue(`details.${key}.description`, event.currentTarget.value)
                                      }}
                                      //value={values.details[key].description}
                                      readOnly={readOnly}
                                    />
                                  }
                                  </td>
                                  <td>
                                    <Input 
                                      type="text" 
                                      name={`details.${key}.amount`}
                                      //onChange={handleChange}
                                       onChange={(e) => {
                                        let name= e.currentTarget.name;
                                        let value= e.currentTarget.value;
                                      /*  
                                        if(types=="tolls"){
                                          const newValuesTolls = {
                                            ...tollValue,
                                            [name]: Number(value)
                                          }
                                          console.log('set amount')
                                          console.log(newValuesTolls)
                                          setAmountName(name)
                                          setTollValues(newValuesTolls)
                                          calc_total_toll(newValuesTolls) 
                                        }
                                        if(types == 'transportations'){
                                          const newValuestransportations = {
                                            ...transportValue,
                                            [name]: Number(value)
                                          }
                                          console.log(newValuestransportations)
                                          setTransportValues(newValuestransportations)
                                          calc_total_transportation(newValuestransportations) 
                                        }
                                        if(types == 'accomodations'){
                                          const newValuesAccomodations = {
                                            ...accoValue,
                                            [name]: Number(value)
                                          }
                                          setAccoValues(newValuesAccomodations)
                                          calc_total_accomodation(newValuesAccomodations) 
                                        }
                                        if(types == 'parkings'){
                                          const newValuesParkings = {
                                            ...parkingValue,
                                            [name]: Number(value)
                                          }
                                          setParkingValues(newValuesParkings)
                                          calc_total_parking(newValuesParkings) 
                                        }   */                                     
                                        setFieldValue(`details.${key}.amount`, e.currentTarget.value ? e.currentTarget.value : 1)
                                      }} 
                                      /* onChange={(e) => {
                                        let value = e.currentTarget;
                                        
                                        setFieldValue(`details.${key}.amount`, value)
                                      }} */
                                     // value={values.details[key].amount}
                                      readOnly={readOnly}
                                    />
                                  </td>
                                  <td>
                                  <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`tooltip-3`}>
                                          KM, Units, Trips, etc
                                        </Tooltip>
                                      }
                                    >
                                    <Input 
                                      required={values.details}
                                      type="text" 
                                      name={`details.${key}.quantity`}
                                      onChange={(e) => {
                                        let name= e.target.name;
                                        let value= e.target.value;
                                        if(values.details[key].type == 'mileages'){
                                          const newMValues = {
                                              ...milageValue,
                                            [name]: Number(value)
                                          }

                                          setMilageValues(newMValues)
                                          calc_total_mileage(newMValues)
                                        }
                                        else
                                        {
                                          
                                          if(values.details[key].type == 'tolls'){
                                            let newAmount = Number(values.details[key].amount * (value ? value : 1));
                                            const newValues = {
                                              ...tollValue,
                                              [name] : newAmount
                                            }
                                            console.log(newValues)
                                            setTollValues(newValues)
                                            calc_total_toll(newValues) 

                                          }
                                          if(values.details[key].type == 'transportations'){
                                            let newAmount = Number(values.details[key].amount * (value ? value : 1));
                                            const newValues = {
                                              ...transportValue,
                                              [name] : newAmount
                                            }
                                            console.log(newValues)
                                            setTransportValues(newValues)
                                            calc_total_transportation(newValues) 
                                          }
                                          if(values.details[key].type == 'accomodations'){
                                            let newAmount = Number(values.details[key].amount * (value ? value : 1));
                                            const newValues = {
                                              ...accoValue,
                                              [name] : newAmount
                                            }
                                            setAccoValues(newValues)
                                            calc_total_accomodation(newValues) 
                                          }
                                          if(values.details[key].type == 'parkings'){
                                            let newAmount = Number(values.details[key].amount * (value ? value : 1));
                                            const newValues = {
                                              ...parkingValue,
                                              [name] : newAmount
                                            }
                                            setParkingValues(newValues)
                                            calc_total_parking(newValues) 
                                          }
                                        }
                                        setFieldValue(`details.${key}.quantity`, e.currentTarget.value)
                                      }}
                                     // onChange={value_handler}
                                      readOnly={readOnly}
                                    /></OverlayTrigger>
                                  </td>
                                  <td>
                                    <Input 
                                      type="file" 
                                      name={`details.${key}.attachment`} 
                                      // id="ROCCrtificate"
                                      onChange={(event) => {
                                        // console.log(event.currentTarget.files[0]);
                                        setFieldValue(`details.${key}.attachment`, event.currentTarget.files[0])
                                      }}
                                      disabled={readOnly}
                                      required
                                    />
                                  </td>
                                  <td style={{textAlign: 'right'}}>
                                    <Button
                                      type="button"
                                      outline
                                      color="danger"
                                      disabled={readOnly}
                                      //onClick={()=>(deleteTableRows(key))}
                                      onClick={() => {
                                        if (window.confirm('Delete this row?')) {
                                         
                                          let { details } = values;
                                          const rows = [...details];
                                          rows.splice(key, 1);
                                          console.log(rows);
                                          setFieldValue('details', rows);
                                        
                                        }
                                      }}
                                    >
                                      <i className="icon-trash"></i>
                                    </Button>
                                  </td>
                                </tr>
                              )}
                              
                            </tbody>
                          </table>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <button name="action" type="submit"  onClick={() => {
                        setFieldValue('action', 'save_draft')
                         }} className="btn btn-outline-secondary" value="save_draft" disabled={isSubmitting || !values.audit_plan_id}>
                        {isSubmitting && values.action == "save_draft" ? 'Saving Draft...' : 'Save Draft'} 
                        </button>&nbsp;
                        <button type="button" disabled={!values.audit_plan_id}  onClick={toggleModal} className="btn btn-info">
                        {'Preview'}
                        </button>&nbsp;
                        <button type="submit" name="action" onClick={() => {
                        setFieldValue('action', 'send_for_review')
                         }} className="btn btn-primary" value="submit" disabled={isSubmitting || !values.audit_plan_id}>
                        {isSubmitting && values.action == "send_for_review" ? 'Submitting Claim...' : 'Submit'} {/* {id ? 'Update' : 'Create'} */}
                        </button>&nbsp;
                        
                      </div>
                    </div>                  
                  </div>
                </Card>

              </div>
            </div>
            <Modal className="modal-xl" isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Preview Invoice</ModalHeader>
                    <ModalBody className="p-0">
                      <div className="m-2 row clearfix" id="printarea">
                      <div className="col-lg-12 col-md-12">
                        <div className="card invoice1">                        
                            <div className="body">
                                <div className="invoice-top clearfix">
                                    <div className="info col-lg-6 col-md-6">
                                        <h6>{auditors ? auditors.map((auditor, key) => {
                                            if(profile && profile.auditor_id && auditor.id==profile.auditor_id)
                                              return auditor.full_name;
                                          }) : ''}</h6>
                                        <p> 
                                          {auditors ? auditors.map((auditor, key) => {
                                            if(profile && profile.auditor_id && auditor.id==profile.auditor_id)
                                              if(auditor.address!=null) {return auditor.address} else {return '<update your address>'}
                                          }) : ''}<br />
                                          {auditors ? auditors.map((auditor, key) => {
                                            if(profile && profile.auditor_id && auditor.id==profile.auditor_id)
                                              return auditor.private_mobile;
                                          }) : ''}<br />
                                            {auditors ? auditors.map((auditor, key) => {
                                            if(profile && profile.auditor_id && auditor.id==profile.auditor_id)
                                              return auditor.business_email;
                                          }) : ''}
                                        </p>

                                    </div>
                                    <div className="title">
                                      <h4>INV/{auditors ? auditors.map((auditor, key) => {
                                            if(profile && profile.auditor_id && auditor.id==profile.auditor_id)
                                             return auditor.initial;
                                          }) : ''}/{values && values.audit_no ? values.audit_no : '' }/{values && values.auditor_claim_max_id ? values.auditor_claim_max_id+1 : 1}</h4>
                                        <p>Issued: {moment().format('D MMMM YYYY')}<br />
                                           {/* Payment Due: June 27, 2018 */}
                                        </p>
                                    </div>
                                </div>
                                <hr />
                                <div className="invoice-mid clearfix">
                                    <div className="info col-lg-6 col-md-6">
                                        <h6>Audit</h6> <p>{values && values.stage ? values.stage : ''}</p>
                                        <h6>Company</h6> <p>{values && values.client && values.client.name ? values.client.name : ''}<br />{values && values.client && values.client.address_1 ? values.client.address_1 : ''}</p>
                                        <h6>Audit Date</h6> <p>{moment(values.date_of_audit_start).format('DD MMMM YYYY') + ' - ' + moment(values.date_of_audit_end).format('DD MMMM YYYY') }</p>
                                        <h6>Manday</h6> <p>{values && values.mandays ? values.mandays : ''}</p>
                                        <h6>Role</h6> <p>{values && values.auditor_role ? values.auditor_role : ''}</p>
                                    </div>   
                                
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>#</th>
                                                <th style={{width: '350px'}}>Description</th>
                                                <th>Unit</th>
                                                <th>Quantity</th>
                                                <th style={{width: '100px'}}>Total (RM)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Audit Fee</td>
                                                <td>{feePerDay}</td>
                                                <td>{values.mandays}</td>
                                                <td>{Math.round(auditFee * 100) / 100 }</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Daily Allowance</td>
                                                <td>{allowancePerDay}</td>
                                                <td>{values.outstation_day}</td>
                                                <td>{Math.round(allowance * 100) / 100  }</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Mileage Claim</td>
                                                <td>{mileagePerKm}</td>
                                                <td>{total_mileage_km+' KM'}</td>
                                                <td>{Math.round(mileagePerKm * total_mileage_km * 100) / 100  }</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Toll Claim</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                            </tr>
                                            {values.details ? values.details.map((toll, key) => 
                                              values.details[key].type == "tolls" ? 
                                            <tr key={key}>
                                                <td></td>
                                                <td><ul><li>{values.details[key].from} - {values.details[key].to} {values.details[key].description && values.details[key].description != '' ?  '\n ('+values.details[key].description+')' :''}</li></ul></td>
                                                <td>{values.details[key].amount}</td>
                                                <td>{values.details[key].quantity}</td>
                                                <td>{values.details[key].amount * values.details[key].quantity}</td>
                                            </tr>
                                              :  ''):''}

                                            <tr>
                                                <td>5</td>
                                                <td>Transportation Claim</td>
                                                <td>{values.details ? values.details.map((transportation, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                            </tr>
                                            {values.details ? values.details.map((toll, key) => 
                                              values.details[key].type == "transportations" ? 
                                            <tr key={key}>
                                                <td></td>
                                                <td><ul><li>{values.details[key].description}{values.details[key].from && values.details[key].to ? ' ('+values.details[key].from+'-'+values.details[key].to+')' : ''}</li></ul></td>
                                                <td>{values.details[key].amount}</td>
                                                <td>{values.details[key].quantity}</td>
                                                <td>{values.details[key].amount * values.details[key].quantity}</td>
                                            </tr>
                                              :  ''):''}
                                            <tr>
                                                <td>6</td>
                                                <td>Parking Claim</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                            </tr>
                                            {values.details ? values.details.map((toll, key) => 
                                              values.details[key].type == "parkings" ? 
                                            <tr key={key}>
                                                <td></td>
                                                <td><ul><li>{values.details[key].description}</li></ul></td>
                                                <td>{values.details[key].amount}</td>
                                                <td>{values.details[key].quantity}</td>
                                                <td>{values.details[key].amount * values.details[key].quantity}</td>
                                            </tr>
                                              :  ''):''}
                                              <tr>
                                                <td>7</td>
                                                <td>Accomodation Claim</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                            </tr>
                                            {values.details ? values.details.map((toll, key) => 
                                              values.details[key].type == "accomodations"? 
                                            <tr key={key}>
                                                <td></td>
                                                <td><ul><li>{values.details[key].description}</li></ul></td>
                                                <td>{values.details[key].amount}</td>
                                                <td>{values.details[key].quantity}</td>
                                                <td>{values.details[key].amount * values.details[key].quantity}</td>
                                            </tr>
                                              :  ''):''}
                                        </tbody>
                                    </table>
                                </div>
                                <hr />
                                <div className="row clearfix">
                                    <div className="col-md-12 text-right">
                                        {/* <p className="m-b-0"><b>Sub-total:</b> 560.00</p>
                                        <p className="m-b-0">Discout: 12.9%</p>
                                        <p className="m-b-0">VAT: 12.9%</p>     */}        
                                        <h3 className="m-b-0 m-t-10">RM {totalAll = total_mileage + total_toll + total_transportation + total_accomodation + total_parking + auditFee + allowance }
                                      {/*   {'total_mileage=' + total_mileage+
                                        ', total_toll=' +total_toll + 
                                        ', total_transportation='+total_transportation + 
                                        ', total_accomodation='+total_accomodation+
                                         ', total_parking='+total_parking + 
                                         ', auditFee='+auditFee + 
                                         ', allowance='+allowance} */}
                                        </h3>
                                    </div>                                    
                                    <div className="col-md-12">
                                      <br /> <br />
                                      <small>
                                       <p>I hereby declare that the information given in this form is true and correct to the best of my knowledge and belief. In case any information given in this form proves to be false or incorrect, my right to claim reimbursement of the expenses shall be forfeited.</p>
                                        <h5>Note</h5>
                                        <p>Claim submitted <b>before 7th</b>, our commitment is to make sure the payment to be remitted <b>on or before 31st every month</b> and any submission <b>after 7th</b> will be resulted the remittance in subsequent month.</p>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>                      
                    </div>
                    </ModalBody>
                    <ModalFooter>
                    <button className="btn btn-outline-secondary" onClick={(event) => { window.print(); }}><i className="icon-printer"></i></button>
                    <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </ModalFooter>
                
                </Modal>      
          </FormikForm>
        )}
      </Formik>
            
    </div>
  )
}

export default FormCreate;