import React from 'react';

import {
  Link,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import SidebarToggler from '../../components/SidebarToggler';

import ChangePassword from './ChangePassword';

const Profile = () => {
  let { path } = useRouteMatch();

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Profile
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to={path}>
                  Profile
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Switch>
        <Route exact path={`${path}/change-password`}>
          <ChangePassword />
        </Route>
      </Switch>
    </div>
  )
}

export default Profile;