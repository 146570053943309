import React, { useEffect, useState } from "react";

import {
  Link,
  // Route,
  // Switch,
  useParams,
  useHistory,
  useLocation,
  // useRouteMatch,
} from "react-router-dom";

import {
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import {
  Introduction,
  ClientInformation,
  AuditInformation,
  AuditNotes,
  AuditProcess,
  PerformanceToDate,
  ExecutiveAuditSummary,
  TeamLeaderRecommendation,
  CertificateInformation,
  CertificateMultisites,
  BasicDataAndStakeholderList,
  Recertification,
  Attendance,
  MspoLogoUsage,
} from "./tabs";

import SidebarToggler from '../../../components/SidebarToggler';

import api from '../../../utils/api';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Form = (props) => {
  let { type, subtype, id } = useParams();
  let history = useHistory();
  let query = useQuery();

  // let { path } = useRouteMatch();

  let [menuIsRendered, setMenuIsRendered] = useState(false);

  let [stage, setStage] = useState('');

  let [tabs, setTabs] = useState([
    {
      "tab": "introduction",
      "label": "Title", 
    },
    {
      "tab": "client_information",
      "label": "Client Information", 
    },
    {
      "tab": "audit_information",
      "label": "Audit Information", 
    },
    {
      "tab": "audit_notes",
      "label": "Audit Notes", 
    },
    {
      "tab": "audit_process",
      "label": "NCR/AOC/OFI", 
    },
    {
      "tab": "executive_audit_summary",
      "label": "Executive Audit Summary", 
    },
    {
      "tab": "team_leader_recommendation",
      "label": "Team Leader Recommendations", 
    },
    {
      "tab": "certificate_information",
      "label": "Certificate Information", 
    },
    {
      "tab": "certificate_multisites",
      "label": "Certificate Multisites", 
    },
    {
        "tab": "recertification",
        "label": "Re-Certification", 
    },
    {
        "tab": "attendance",
        "label": "Attendance", 
    }
  ]);
  
  useEffect(() => {
    if (type.toLowerCase() === 'mspo' || type.toLowerCase() === 'mspo-sccs') {
      let newTabs = [
        {
          "tab": "introduction",
          "label": "Title", 
        },
        {
          "tab": "client_information",
          "label": "Client Information", 
        },
        {
          "tab": "audit_information",
          "label": "Audit Information", 
        },
        {
          "tab": "audit_notes",
          "label": "Audit Notes", 
        },
        {
          "tab": "audit_process",
          "label": "NCR/OFI", 
        },
        {
          "tab": "executive_audit_summary",
          "label": "Executive Audit Summary", 
        },
        {
          "tab": "basic_data_and_stakeholder_list",
          "label": "Basic Data & Stakeholder List", 
        },
        {
          "tab": "mspo_logo_usage",
          "label": "MSPO Logo Usage", 
        },
        {
          "tab": "team_leader_recommendation",
          "label": "Team Leader Recommendations", 
        },
        {
          "tab": "certificate_information",
          "label": "Certificate Information", 
        },
        {
            "tab": "recertification",
            "label": "Re-Certification", 
        },
        {
            "tab": "attendance",
            "label": "Attendance", 
        }
      ];
      setTabs(newTabs);
    }
    setMenuIsRendered(true);

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        // setProfile(response.data);
       // console.log(response);
        if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'audit_report.update') === "undefined") {
          history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));
        }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;
          console.log(response);
          // setData(response.data);

          setStage(response.data.data.audit_plan.stage);

          // console.log(stage.toLowerCase());
          // console.log(response.data.audit_plan.stage.includes('Re-Certification')); 
          
          // console.log(response.data.data.audit_plan.stage !== 'Surveillance');
          if (response.data.data.audit_plan.stage !== 'Surveillance') {
            let newtabs = tabs.filter(tab => tab.tab !== 'mspo_logo_usage');
            setTabs(newtabs);
          }

          if (response.data.data.audit_plan.stage.includes('Re-Certification')) {
            // let newTabs = tabs;
            // newTabs.push({
            //   "tab": "certificate_information",
            //   "label": "Certificate Information", 
            // })
            // setTabs(newTabs);

            // setTabs(tabs.concat({
            //     "tab": "recertification",
            //     "label": "Re-Certification", 
            // }).concat({
            //     "tab": "attendance",
            //     "label": "Attendance", 
            // }));
          } else {
            let newtabs = tabs.filter(tab => tab.tab !== 'recertification');
              setTabs(newtabs);
          }

          if (response.data.data.audit_plan.stage.includes('Stage 1')) {
            let newtabs = tabs.filter(tab => tab.tab !== 'certificate_information');
            // newtabs = newtabs.concat({
            //   "tab": "attendance",
            //   "label": "Attendance", 
            // });
            // setTabs(newtabs);
          }
          if(response.data.data.multisites.length){
           
          }
          else{
            let newtabs = tabs.filter(tab => tab.tab !== 'certificate_multisites');
            setTabs(newtabs);
          }

        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            // addToast(error.response.data.message, { appearance: 'error' });
          } else {
            // addToast(error.message, { appearance: 'error' });
          }
        });
    }

    getData();

    if (menuIsRendered) {
      setTabs(tabs.concat({
        "tab": "attendance",
        "label": "Attendance", 
      }));
    }
  }, [id, type, menuIsRendered]);

  const renderTab = (tab) => {
    switch (tab) {

      case "introduction":
      default:
        return <Introduction type={type} {...props} />;

      case "client_information":
        return <ClientInformation type={type} {...props} />;

      case "audit_information":
        return <AuditInformation type={type} {...props} />;

      case "audit_notes":
        return <AuditNotes type={type} {...props} />;

      case "audit_process":
        return <AuditProcess type={type} {...props} />;

      case "performance_to_date":
        return <PerformanceToDate type={type} {...props} />;

      case "executive_audit_summary":
        return <ExecutiveAuditSummary type={type} {...props} />;

      case "team_leader_recommendation":
        return <TeamLeaderRecommendation type={type} {...props} />;

      case "basic_data_and_stakeholder_list":
        return <BasicDataAndStakeholderList type={type} {...props} />;

      case "mspo_logo_usage":
        return <MspoLogoUsage type={type} subtype={subtype} {...props} />;
        
      case "surveillance_plan":
        return;

      case "audit_programme":
        return;

      case "next_visit_audit_plam":
        return;

      case "sites_appendix":
        return;

      case "certificate_information":
        return <CertificateInformation />;

      case "certificate_multisites":
        return <CertificateMultisites />;

        case "recertification":
        return <Recertification />;

      case "attendance":
        return <Attendance />;
    }
  }

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2 style={{ textTransform: "capitalize" }}>
              <SidebarToggler />
              {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ')}` : ""} Audit Report
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Audit
              </li>
              <li className="breadcrumb-item">
                Audit Report
              </li>
              <li className="breadcrumb-item" style={{ textTransform: "capitalize" }}>
                <Link to={props.path.replace(/:type/, type).replace(/:subtype/, subtype)}>
                  {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ')}` : ""}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-lg-3 col-md-3" style={{ marginBottom: "4em" }}>
        <ListGroup>
          {tabs.map((item, i) => 
            <ListGroupItem 
              key={i}
              tag="button" 
              action
              active={item.tab === query.get("tab")}
              onClick={(e) => {
                e.preventDefault()

                history.push(`${props.path.replace(/:type/, type).replace(/:subtype/, subtype)}/edit/${id}?tab=${item.tab}`);
              }}
            >
              {i + 1}.0 {item.label}
            </ListGroupItem>
          )}
        </ListGroup>
        </div>
        <div className="col-lg-9 col-md-9">
          {renderTab(query.get("tab"))}
        </div>
      </div>
    </div>
  )
}

export default Form;