import React, { useState, useEffect } from "react";

import {
  Link,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  ButtonGroup,
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  // Nav,
  // NavItem,
  // NavLink,
  // TabContent,
  // TabPane,
} from "reactstrap";

// import Select from 'react-select'

import { 
  Formik,
  Form as FormikForm
} from "formik";

import { useToasts } from 'react-toast-notifications';

import api from "../../../utils/api";

import moment from 'moment';

import { objectToFormData } from 'object-to-formdata';

import SidebarToggler from "../../../components/SidebarToggler";

const Form = (props) => {
  let i=0;

  let { id } = useParams();

  let { path } = useRouteMatch();

  let history = useHistory();

  let [token, setToken] = useState('');

  let [profile, setProfile] = useState({});

  const { addToast } = useToasts();

  const [data, setData] = useState({});

  const [auditors, setAuditor] = useState([{}]);

  const [USERS, setUser] = useState([{}]);

  const [CERTIFICATIONS, setCertifications] = useState({});

  const [editMode, setEditMode] = useState();

  const [modal, setModal] = useState(false);

  const [isSubmitting1, setSubmitting1] = useState(false);

  const [isSubmitting2, setSubmitting2] = useState(false);

  const toggleModal = () => setModal(!modal);
  
  const toggleModal2 = () => setModal(!modal);
  
  const divStyle = {
    width: '10%',
  };
  let { readOnly, mode, review, approval }  = props;

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);
      setToken(token.access_token);
      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        console.log(response.data);
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        setProfile(response.data);

        // if (!readOnly && response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'operation_review.update') === "undefined") {
        //   history.push(props.path);
        // }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getAuditors = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      api.get('/users?limit=-1&role[]=2&role[]=5')
     // api.get('/auditors?fields[]=id&fields[]=full_name&fields[]=business_mobile&fields[]=private_mobile&withUser=true&limit=-1')
      .then(function (response) {
        // console.log(response.data);

        let auditors = [];
        response.data.data.map((auditor, i) => auditors.push({
          label: auditor.name,
          value: auditor.id,
         // contact_no: auditor.contact_no,
        }));

        setAuditor(auditors);

        // console.log(auditors);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getAuditors();

    const getUsers = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      
      api.get('/users?limit=-1&role[]=5')
      .then(function (response) {
        
        let USERS = [];
        response.data.data.map((user, i) => USERS.push({
          label: user.name,
          value: user.id
        }));
        setUser(USERS);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getUsers();

    const getCertifications = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/lookup/certifications')
      .then(function (response) {
        // console.log(response.data);

        setCertifications(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getCertifications();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/operation-reviews/' + id)
        .then(function (response) {
          delete response.data.data.id;

          if (response.data.data.audit.audit_plan.client.certifications) {
            let selectedCertifications = [];
            response.data.data.audit.audit_plan.client.certifications.map((certification, key) => {
              return selectedCertifications.push(certification.id);
            });

            response.data.data.audit.audit_plan.client.certifications = selectedCertifications;
          }
          setData(response.data.data);
          if (response.data.data.operation_review_state === '3') {
            setEditMode('lead_auditor_comments');
          }
          
          // reload(false);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data.data) {
            addToast(error.response.data.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <h2>
                <SidebarToggler />
                Operation Review
              </h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  Review
                </li>
                <li className="breadcrumb-item">
                  <Link to={path}>
                    Operation Review
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-8 col-md-8 col-sm-12 text-right">
                <Button 
                  outline
                  color="primary" 
                  onClick={() => { window.open(`/audit-module/audit-plan/${data.audit && data.audit.audit_plan && data.audit.audit_plan.type ? data.audit.audit_plan.type : null}/${data.audit && data.audit.audit_plan && data.audit.audit_plan.subtype ? data.audit.audit_plan.subtype : null}/view/${data.audit && data.audit.audit_plan && data.audit.audit_plan.id ? data.audit.audit_plan.id : null}`);}}
                >
                <i className="icon-docs"></i> Audit Plan
                </Button>  
                &nbsp;
                <Button outline
                    color="primary"                              
                    onClick={() => { window.open(`/audit-module/audit-report/${data.audit && data.audit.audit_plan && data.audit.audit_plan.type ? data.audit.audit_plan.type : null}/${data.audit && data.audit.audit_plan && data.audit.audit_plan.subtype ? data.audit.audit_plan.subtype : null}/view/${data.audit && data.audit.id ? data.audit.id : null}?tab=introduction`);}} 
                >
                <i className="icon-note"></i> Audit Report
                </Button>&nbsp;
                {data.previous_audit_report !== null ? 
                
               <Button outline
                  color="primary"
                  target="_blank"  
                  href={data.previous_audit_report && data.previous_audit_report.url ? data.previous_audit_report.url : null}
                >
                <i className="icon-paper-clip"></i> Prev. Audit Report
                </Button>
                : 
                <Button outline
                  color="primary"
                  onClick={toggleModal2}
                >
                <i className="icon-cloud-upload"></i> Upload Previous Report
                </Button>
                
                }
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                  <h2>Operation Review</h2>
              </div>
              
              <div className="body">
                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Name">Name of Reviewer</Label>
                      <Input 
                          // readOnly={readOnly}
                          disabled={true}
                          type="select" 
                          className="custom-select" 
                          name="operation_review_reviewer_id"
                          id="operation_review_reviewer_id"
                          // onChange={(e) => {
                          //   // console.log(e.target.name, e.target.value);

                          //   setFieldValue(e.target.name, e.target.value);
                          //   // setFieldValue('reviewed_at', "");
                          // }}
                          // onChange={handleChange}
                          value={data.operation_review_reviewer_id}
                        >
                          <option></option>
                          {auditors && auditors.length ? auditors.map((auditor, i) => 
                            <option value={auditor.value}>{auditor.label}</option>
                          ) : ""}
                        </Input>
                    </FormGroup>

                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Name">Name of Approver</Label>
                      <Input 
                          // readOnly={readOnly}
                          disabled={true}
                          type="select" 
                          className="custom-select" 
                          name="operation_review_approver_id"
                          id="operation_review_approver_id"
                          // onChange={(e) => {
                          //   // console.log(e.target.name, e.target.value);

                          //   setFieldValue(e.target.name, e.target.value);
                          //   // setFieldValue('reviewed_at', "");
                          // }}
                          // onChange={handleChange}
                          value={data.operation_review_approver_id}
                        >
                          <option></option>
                          {USERS && USERS.length ? USERS.map((USER, i) => 
                              <option value={USER.value}>{USER.label}</option>
                          ) : ""}
                        </Input>
                    </FormGroup>
                    
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Name">Name of Lead Auditor</Label>
                      <Input 
                          // readOnly={readOnly}
                          disabled={true}
                          type="text" 
                          // className="custom-select" 
                          // name="operation_review_reviewer_id"
                          id=""
                          // onChange={(e) => {
                          //   // console.log(e.target.name, e.target.value);

                          //   setFieldValue(e.target.name, e.target.value);
                          //   // setFieldValue('reviewed_at', "");
                          // }}
                          // onChange={handleChange}
                          value={data.audit && data.audit.audit_plan && data.audit.audit_plan.lead_auditors ? data.audit.audit_plan.lead_auditors[0].full_name : null}
                        />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="ClientName">Client Name</Label>
                      <Input 
                        readOnly={true}
                        type="text" 
                        name="client_name" 
                        id="ClientName"
                        // onChange={handleChange}
                        value={data.audit && data.audit.audit_plan && data.audit.audit_plan.client ? data.audit.audit_plan.client.name : ''}
                        
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="AuditNo">Audit No.</Label>
                      <Input 
                        readOnly={true}
                        type="text" 
                        name="audit_no" 
                        id="AuditNo"
                        // onChange={handleChange}
                        value={data.audit && data.audit.audit_plan ? data.audit.audit_plan.audit_no : ''}
                        
                      />
                    </FormGroup>
                  </div>

                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="TypeOfAudit">Type Of Audit</Label>
                      <Input 
                        readOnly={true}
                        type="text" 
                        name="type_of_audit" 
                        id="TypeOfAudit"
                        // onChange={handleChange}
                        value={data.audit && data.audit.audit_plan ? data.audit.audit_plan.stage : ''}
                        
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Scheme">Scheme</Label>
                      {CERTIFICATIONS.length ? CERTIFICATIONS.map((CERTIFICATION, key) => 
                      data.audit && data.audit.audit_plan && data.audit.audit_plan.client.certifications && data.audit.audit_plan.client.certifications.indexOf(CERTIFICATION.id) !== -1 ? 
                      <React.Fragment key={key}>
                        <div className="fancy-checkbox">
                          <label>
                            <Input 
                              readOnly={true}
                              type="checkbox" 
                              disabled={true}
                              // name={`certifications`}
                              // onChange={handleChange}
                              // onChange={() => {
                              //   if (data.typeStr !== 'ISO') {
                              //     let { certifications } = data;

                              //     if (certifications && certifications.length) {
                              //       var index = certifications.indexOf(CERTIFICATION.id);
                              //       if (index !== -1) {
                              //         certifications.splice(index, 1);
                              //       } else {
                              //         certifications.push(CERTIFICATION.id);
                              //       }
                              //     } else {
                              //       certifications = [];
                              //       certifications.push(CERTIFICATION.id);
                              //     }

                              //     setFieldValue('certifications', certifications);
                              //   }
                              // }}
                              // value={CERTIFICATION.id}
                              checked={data.audit && data.audit.audit_plan && data.audit.audit_plan.client.certifications && data.audit.audit_plan.client.certifications.length && data.audit.audit_plan.client.certifications.indexOf(CERTIFICATION.id) !== -1} 
                            />
                            <span>
                              {CERTIFICATION.standard} 
                            </span>
                          </label>
                        </div>
                      </React.Fragment>
                        : null
                    ) : null}
                    </FormGroup>
                  </div>
                </div>    
                <Formik noValidate={true}
                    enableReinitialize={true}
                    initialValues={data}
                    onSubmit={async (values, { setSubmitting }) => {
                      let token = await localStorage.getItem('auth-token');

                      token = JSON.parse(token);

                      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                      
                      //FOR THOSE ADMIN THAT LINK FROM EDIT AS WELL
                      if (values.operation_review_reviewer_id == profile.id && (profile.permissions && profile.permissions.find(o => o.name.toLowerCase() === 'operation_review.update'))) {
                        if(values.operation_review_reviewed_at === null ){
                          values.action = 'send_for_approval';
                          values.operation_review_reviewed_at = moment().format('YYYY-MM-DD HH:mm:ss');
                        }
                      }

                      if (values.operation_review_approver_id == profile.id && (profile.permissions && profile.permissions.find(o => o.name.toLowerCase() === 'operation_review.update'))) {
                        if(values.operation_review_approved_at === null){
                          values.action = 'approved';
                          values.operation_review_approved_at = moment().format('YYYY-MM-DD HH:mm:ss');
                        }
                      }
                      values.len = values.checklist.length;
                      const reviewerComments = [];
                      const leadAuditorComments = [];
                      for (i=0; i < values.len; i++){
                        reviewerComments.push(values.tr_comments && values.tr_comments[i] ? values.tr_comments[i]:null);
                        leadAuditorComments.push(values.lead_auditor_comments && values.lead_auditor_comments[i] ? values.lead_auditor_comments[i]:null);
                      } 
                    
                      values.tr_comments = reviewerComments ;
                      values.lead_auditor_comments = leadAuditorComments ;
                      
                      
                      var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

                      data.append('_method', 'PUT');
                      
                      // await api.post('/operation-reviews/' + id, JSON.stringify(values), {
                      //   headers: {
                      //     'Content-Type': 'application/json',
                      //   },
                      // })
                      await api({
                          method: 'POST',
                          url: '/operation-reviews/' + id,
                          headers: {
                            'Content-Type': 'multipart/form-data',
                          },
                          data: data,
                        })
                      .then(function (response) {
                       
                        addToast('Operation review successfully updated', { appearance: 'success' });
                        history.push(props.path + '?refresh=' + Date.now());

                      })
                      .catch(function (error) {
                        addToast(error.message, { appearance: 'error' });
                      });
                    }}
                  >
                  {({ isSubmitting, values, setFieldValue, handleChange }) => (
                   
                  <FormikForm>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th style={{ width: "250px" }}>Checklist</th>
                        <th style={{ width: "120px" }}>Y/N</th>
                        <th>OR Comments</th>
                        <th>Lead Auditor Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                    {[
                      'Audit Plan submitted according to procedure?',
                      'Draft Report sent to Client according to procedure?',
                      'Comments provided by Client addressed by lead auditor?',
                      'All respective sections covered in report?',
                      'Consistency of reporting',
                      'Quality of NC',
                      'Grading of NC',
                      'Does the OFI sound like an NC?',
                      'Does the OFI provide any added value to the Client?',
                      'Grammar',
                    ].map((item, i) =>
                      <tr>
                        <td>{i + 1}</td>
                        <td style={{ whiteSpace: "pre-wrap" }}>{item}</td>
                        <td>
                          {i !== 4 && i !== 5 && i !== 6 && i !== 9 ?
                          <React.Fragment>
                            <label className="fancy-radio">
                              <Input
                                disabled={readOnly || editMode === 'lead_auditor_comments'}
                                type="radio"
                                name={`checklist.${i}`}
                                value="Y"
                                onClick={handleChange}
                                // onClick={() => {
                                //   setFieldValue('checklist', 'Y')
                                // }}
                                checked={values.checklist && values.checklist[i] ? values.checklist[i] === 'Y' : ""}
                                required={true}
                              />
                              <span>
                                <i></i> Yes
                              </span>
                            </label>
                            <br />
                            <label className="fancy-radio">
                              <Input
                                disabled={readOnly || editMode === 'lead_auditor_comments'}
                                type="radio"
                                name={`checklist.${i}`}
                                value="N"
                                onClick={handleChange}
                                // onClick={() => {
                                //   setFieldValue('checklist', 'N')
                                // }}
                                checked={values.checklist && values.checklist[i] ? values.checklist[i] === 'N' : ""}
                                required={true}
                              />
                              <span>
                                <i></i> No
                              </span>
                            </label>
                          </React.Fragment>
                          : null}

                          {i === 6 ?
                          <React.Fragment>
                            <label className="fancy-radio">
                              <Input
                                disabled={readOnly || editMode === 'lead_auditor_comments'}
                                type="radio"
                                name={`checklist.${i}`}
                                value="A"
                                onClick={handleChange}
                                // onClick={() => {
                                //   setFieldValue('checklist', 'A')
                                // }}
                                checked={values.checklist && values.checklist[i] ? values.checklist[i] === 'A' : ""}
                                required={true}
                              />
                              <span>
                                <i></i> Agree
                              </span>
                            </label>
                            <br />
                            <label className="fancy-radio">
                              <Input
                                disabled={readOnly || editMode === 'lead_auditor_comments'}
                                type="radio"
                                name={`checklist.${i}`}
                                value="D"
                                onClick={handleChange}
                                // onClick={() => {
                                //   setFieldValue('checklist', 'D')
                                // }}
                                checked={values.checklist && values.checklist[i] ? values.checklist[i] === 'D' : ""}
                                required={true}
                              />
                              <span>
                                <i></i> Disagree
                              </span>
                            </label>
                          </React.Fragment>
                          : null}

                          {i === 4 || i === 5 || i === 9 ?
                          <React.Fragment>
                            <label className="fancy-radio">
                              <Input
                                disabled={readOnly || editMode === 'lead_auditor_comments'}
                                type="radio"
                                name={`checklist.${i}`}
                                value="G"
                                onClick={handleChange}
                                // onClick={() => {
                                //   setFieldValue('checklist', 'G')
                                // }}
                                checked={values.checklist && values.checklist[i] ? values.checklist[i] === 'G' : ""}
                                required={true}
                              />
                              <span>
                                <i></i> Good
                              </span>
                            </label>
                            <br />
                            <label className="fancy-radio">
                              <Input
                                disabled={readOnly || editMode === 'lead_auditor_comments'}
                                type="radio"
                                name={`checklist.${i}`}
                                value="A"
                                onClick={handleChange}
                                // onClick={() => {
                                //   setFieldValue('checklist', 'A')
                                // }}
                                checked={values.checklist && values.checklist[i] ? values.checklist[i] === 'A' : ""}
                                required={true}
                              />
                              <span>
                                <i></i> Average
                              </span>
                            </label>
                            <br />
                            <label className="fancy-radio">
                              <Input
                                disabled={readOnly || editMode === 'lead_auditor_comments'}
                                type="radio"
                                name={`checklist.${i}`}
                                value="P"
                                onClick={handleChange}
                                // onClick={() => {
                                //   setFieldValue('checklist', 'P')
                                // }}
                                checked={values.checklist && values.checklist[i] ? values.checklist[i] === 'P' : ""}
                                required={true}
                              />
                              <span>
                                <i></i> Poor
                              </span>
                            </label>
                          </React.Fragment>
                          : null}

                        </td>
                        
                        <td style={{ whiteSpace: "pre-wrap" }}>
                          {readOnly || editMode === 'lead_auditor_comments' ?
                            values.tr_comments && values.tr_comments[`${i}`] ? values.tr_comments[`${i}`] : ''
                          :
                            <Input 
                              readOnly={readOnly}
                              type="textarea" 
                              name={`tr_comments.${i}`}
                              id=""
                              onChange={handleChange}
                              value={values.tr_comments && values.tr_comments[`${i}`]  ? values.tr_comments[`${i}`] : null}
                              
                            />
                          }
                        </td>
                        <td style={{ whiteSpace: "pre-wrap" }}>
                          {readOnly || editMode !== 'lead_auditor_comments' ?
                            values.lead_auditor_comments && values.lead_auditor_comments[`${i}`] ? values.lead_auditor_comments[`${i}`] : ''
                          :
                            <Input 
                              readOnly={readOnly}
                              type="textarea" 
                              name={`lead_auditor_comments.${i}`}
                              id=""
                              onChange={handleChange}
                              value={values.lead_auditor_comments && values.lead_auditor_comments[`${i}`] ? values.lead_auditor_comments[`${i}`] : null}
                              
                            />
                          }
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                  {values.operation_review_state === '7' ? 
                  <div className="alert alert-danger" role="alert">Operation Review is REJECTED. Reason : {values.rejected_comment}</div>
                  :null}


                  {review || approval || values.operation_review_reviewer_id != profile.id || values.operation_review_state === '6' || values.operation_review_state === '5' ? 
                    null
                  :
                  editMode !== 'lead_auditor_comments' ?
                 
                  <React.Fragment>
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="action"
                        value="save_draft"
                        onClick={handleChange}
                        // onClick={() => {
                        //   setFieldValue('checklist', 'A')
                        // }}
                        checked={values.action === 'save_draft'}
                      />
                      <span>
                        <i></i> Save Draft
                      </span>
                    </label>

                    <br />

                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="action"
                        value="request_for_auditor_comments"
                        onClick={handleChange}
                        // onClick={() => {
                        //   setFieldValue('checklist', 'A')
                        // }}
                        checked={values.action === 'request_for_auditor_comments'}
                      />
                      <span>
                        <i></i> Request for Auditor Comments
                      </span>
                    </label>

                    <br />

                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="action"
                        value="send_for_approval"
                        onClick={handleChange}
                        // onClick={() => {
                        //   setFieldValue('checklist', 'A')
                        // }}
                        checked={values.action === 'send_for_approval'}
                      />
                      <span>
                        <i></i> Send for Approval
                      </span>
                    </label> <br />
                  </React.Fragment>
                  : null}
                  {editMode === 'lead_auditor_comments' ?
                  <div className="fancy-checkbox">
                    <label>
                      <Input 
                        // readOnly={readOnly}
                        type="checkbox" 
                        // disabled={readOnly || values.or_reviewed_at}
                        name="action"
                        // onChange={handleChange}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFieldValue(e.target.name, e.target.value);
                          } else {
                            setFieldValue(e.target.name, null);
                          }
                        }}
                        value="send_for_review"
                        // checked={values.action} 
                      />

                      <span>
                        Send for review?
                      </span>
                    </label>
                  </div>
                  : null}
                  {!(review || approval || values.operation_review_reviewer_id != profile.id || values.operation_review_state === '6' || values.operation_review_state === '5') ?
                  <div className="row clearfix">
                    <div className="col-sm-8">
                      {!readOnly ?
                      <Button 
                        disabled={isSubmitting}
                        type="submit" 
                        className="btn btn-primary"
                        color="primary"
                      >
                        {isSubmitting ? 'Submitting...' : values.action === 'request_for_auditor_comments' ? 'Request For Auditor Comments' : values.action === 'send_for_approval' ? 'Send For Approval' : values.action === 'send_for_review' ? 'Send For Review' : 'Save'}
                      </Button>
                      : null}&nbsp;
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        tag={Link} 
                        to={props.path}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                  :
                  null
                  
                  }

                  {review && values.operation_review_reviewer_id === profile.id ? 
                  <div className="row clearfix">
                    <div className="col-sm-8">
                      <Button 
                        disabled={isSubmitting}
                        type="submit" 
                        className="btn btn-primary"
                        color="primary"
                      >
                        {isSubmitting ? 'Reviewing...' : 'Review'}
                      </Button>
                      &nbsp;
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        tag={Link} 
                        to={props.path}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                  : 
                  null
                  }

                  {values.operation_review_approved_at === null && approval && values.operation_review_approver_id === profile.id ?
                  <div className="row clearfix">
                    <div className="col-sm-8">
                      <Button 
                        disabled={isSubmitting}
                        type="submit" 
                        color="primary"
                        className="btn"
                        name="action"
                        value="approved"
                      >
                        {isSubmitting  ? 'Approving...' : 'Approve'}
                      </Button>
                      &nbsp;

                      <Button 
                        disabled={isSubmitting}
                        //type="submit" 
                        className="btn btn-danger"
                        onClick={toggleModal}
                      >
                        {'Reject'}
                      </Button>
                      &nbsp;
                      <Button 
                        outline
                        tag={Link} 
                        to={props.path}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                  : 
                  null
                  }
            
                  </FormikForm>
      
                  )}
    
                </Formik>
   
                <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
                  <Formik 
                    enableReinitialize={true}
                    initialValues={data}
                    onSubmit={async (values, { setSubmitting }) => {
                    let token = await localStorage.getItem('auth-token');

                    token = JSON.parse(token);

                    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                    // if(values.rejected_comment!==null){
                    values.action = 'rejected';
                    values.operation_review_rejected_at = moment().format('YYYY-MM-DD HH:mm:ss');        
                    //  }
                    setSubmitting1(true);

                    console.log(values.action);
                    var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
                    console.log(data);
                    data.append('_method', 'PUT');
                    
                    // await api.post('/operation-reviews/' + id, JSON.stringify(values), {
                    //   headers: {
                    //     'Content-Type': 'application/json',
                    //   },
                    // })
                    api({
                      method: 'POST',
                      url: '/operation-reviews/' + id /*  +'/rejected' */ ,
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                      data: data,
                    })
                    .then(function (response) {
                      addToast('Operation review successfully updated', { appearance: 'success' });
                      setSubmitting1(false);
                      toggleModal();
                      history.push(props.path + '?refresh=' + Date.now());
                    })
                    .catch(function (error) {
                      addToast(error.message, { appearance: 'error' });
                    });
                  }}
                >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                <FormikForm>
                    <ModalHeader toggle={toggleModal}>Rejection comments</ModalHeader>
                    <ModalBody className="p-0">
                      <div className="m-2 row clearfix">
                        <div className="col-sm-12">
                        <Input 
                          readOnly={false}
                          type="textarea" 
                          name="rejected_comment"
                          id=""
                          value={values.rejected_comment? values.rejected_comment : null}
                          required
                        />                  
                          </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button 
                          type="submit"
                          // disabled={true}
                          color="primary" 
                          // onClick={sendAuditConfirmationHandler}
                        >
                        {isSubmitting1 ? 'Sending...' : 'Send'}
                      </Button>{' '}
                      <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </FormikForm>
                )}
                </Formik>
                </Modal>
                <Modal className="modal-lg" isOpen={modal} toggle={toggleModal2}>
                <Formik
                  enableReinitialize={true}
                  initialValues={data}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    let token = await localStorage.getItem('auth-token');

                    token = JSON.parse(token);

                    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                    values._method = 'PUT';

                    setSubmitting2(true);

                    var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
                    if (id) {
                      data.append('_method', 'PUT');
                      await api({
                        method: 'POST',
                        url: '/operation-reviews/' + id +'/upload-previous-report',
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                        data: data,
                      })
                      .then(function (response) {
                        addToast('Previous Audit Report successfully uploaded', { appearance: 'success' });
                        
                        setSubmitting2(false);

                        window.location.reload();
                      })
                      .catch(function (error) {
                        addToast(error.message, { appearance: 'error' });
                      });
                    }
                  }}
              >
                {({ isSubmitting2, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup>
                      
                    </FormGroup>
                    <ModalHeader toggle={toggleModal2}>Upload Previous Audit Report</ModalHeader>
                    <ModalBody className="p-0">
                      <div className="m-2 row clearfix">
                        <div className="col-sm-12">
                        <div className="input-group">
                        <div className="custom-file">
                          <Input
                            disabled={isSubmitting2}
                            //accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            className="custom-file-input"
                            type="file" 
                            name="previous_audit_report" 
                            id="previous_audit_report"
                            onInput={(event) => {
                              setFieldValue('previous_audit_report', event.target.files[0])
                            }}
                          />
                          <Label className="custom-file-label">
                            {values && values.previous_audit_report && values.previous_audit_report.name ? values.previous_audit_report.name : 'No file chosen'}
                          </Label>
                        </div>
                        <div className="input-group-append">
                          <button 
                            disabled={isSubmitting2}
                            className="btn btn-outline-secondary" 
                            type="submit"
                            >
                              {isSubmitting2 ? 'Uploading...' : 'Upload'}
                            </button>
                        </div>
                      </div>           
                          </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button 
                          type="submit"
                          disabled={isSubmitting2}
                          color="primary" 
                          // onClick={sendAuditConfirmationHandler}
                        >
                        {isSubmitting2 ? 'Saving...' : 'Save'}
                      </Button>{' '}
                      <Button color="secondary" onClick={toggleModal2}>Cancel</Button>
                    </ModalFooter>

                  </FormikForm>
                )}
                </Formik>
                </Modal>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>  
  )
}

export default Form;