import React, { useState, useEffect } from 'react';

import Menu from '../Menu';

import api from '../../utils/api';

const PeerReviewPanelMenu = (props) => {

  const { role } = props;
  
  useEffect(() => {

  }, []);

  return (
    <React.Fragment>
      <ul className="main-menu metismenu">
        <Menu 
          title="Boss Chart"
          icon="graph"
          to="/boss-chart"
          // onClick={() => setActiveMenu('user')}
          // activeMenu={activeMenu}
        />

          <Menu 
            title="Peer Review"
            icon="shuffle"
            to="/review/peer-review" 
          />
      </ul>
    </React.Fragment>
  );
}

export default PeerReviewPanelMenu;