import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  // useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import SidebarToggler from '../../components/SidebarToggler';

import api from '../../utils/api';

import moment from 'moment';

import { objectToFormData } from 'object-to-formdata';

// import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

const INITIAL_data = {};

const View = (props) => {
  let { id } = useParams();

  const { addToast } = useToasts();
  const [auditors, setAuditor] = useState([]);
  const [data, setData] = useState(INITIAL_data);
  const [profile, setProfile] = useState({});

  const [claimSettingAllowance, setClaimSettingAllowance]= useState({});
  const [claimSettingFee, setClaimSettingFee]= useState({});
  const [claimSettingMileage, setClaimSettingMileage]= useState({});
  const [mileagePerKm, setMileagePerKm]= useState(0);
  const [feePerDay, setFeePerDay]= useState(0);
  const [allowancePerDay, setAllowancePerDay]= useState(0);

  const [total_mileage_km,set_total_mileage_km]=useState(0);
  const [auditFee,setAuditFee]=useState(0.00);
  const [allowance,setAllowance]=useState(0.00);
  const [grandTotal,setGrandTotal]=useState(0.00);
  const [modal, setModal] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const toggleModal = () => setModal(!modal);
  let { readOnly, approval, review }  = props;

  useEffect(() => {
    // console.log(path);

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

         setProfile(response.data);
        /* if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'audit_plan.update') === "undefined") {
          history.push(props.path.replace(/:type/, type));
        } */
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getClaimSetting = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      api.get('auditor-claim-settings/1')
     // api.get('client-list?exclude_audits=false&sort=date_of_audit_start&type=iso&type=mspo&limit=-1')
      .then(function (response) {
        console.log(response.data.data);


      let allowanceArray = [];
        response.data.data.allowance_per_day.map((set, i) => {
          allowanceArray.push({
            allowance_per_day: set.allowance_per_day,
            role: set.role,
          })}
        ); 
      setClaimSettingAllowance(allowanceArray);

      let feeArray = [];
        response.data.data.fee_per_day.map((set, i) => {
          feeArray.push({
            fee_per_day: set.fee_per_day,
            role: set.role,
          })}
        ); 
      setClaimSettingFee(feeArray);

      let mileageArray = [];
        response.data.data.mileage_per_km.map((set, i) => {
          mileageArray.push({
            mileage_per_km: set.mileage_per_km,
            role: set.role,
          })}
        ); 
      setClaimSettingMileage(mileageArray);

      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getClaimSetting();


      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/auditor-claims/' + id)
        .then(function (response) {

          delete response.data.data.id;
          console.log(response.data.data);
          setData(response.data.data);

          let content = [];
          if(response.data && response.data.data && response.data.data.details){
            response.data.data.details.map((mileage, key) => 
              mileage.type =='mileages' ?  content[key] = mileage : ''
           )
          }
          let sum = content.reduce(function(prev, current) {
            return prev + + current.quantity
          }, 0); 
          set_total_mileage_km(sum)  


          if (response.data && response.data.data && response.data.data.audit_plan && response.data.data.audit_plan.both_auditors) {
                                  
            response.data.data.audit_plan.both_auditors.map((auditor, key) => auditors.push({
            // if(auditor.id==profile.auditor_id){
                id : auditor.id,
                full_name: auditor.full_name,
                business_email: auditor.business_email,
                private_mobile: auditor.private_mobile,
                address : auditor.private_address_1 && (auditor.private_address2 || auditor.private_address3  || auditor.private_postcode || auditor.private_state) ?  auditor.private_address_1 + ', \n'+ auditor.private_address_2 + ', \n' +  auditor.private_address_3 +', \n' +  auditor.private_postcode +' ' +  auditor.private_state : '' 
            }));
            setAuditor(auditors);
          }
          if (response.data && response.data.data && response.data.data.mandays) {

              let selectedMandays = 0;
              selectedMandays = response.data.data.mandays;
              

              let selectedAllowance = 0;
              selectedAllowance = response.data.data.allowance_per_day;
                 

              let selectedFee = 0;
              selectedFee=response.data.data.fee_per_day;
                  

              let selectedMileage = 0;
              selectedMileage=response.data.data.mileage_per_km;
                 
              setAuditFee(selectedMandays*selectedFee);   
              setAllowance(selectedMandays*selectedAllowance);   
              setMileagePerKm(selectedMileage);
              setFeePerDay(selectedFee);
              setAllowancePerDay(selectedAllowance);

            /*   setFieldValue('mileage_per_km', selectedMileage);
              setFieldValue('allowance_per_day', selectedAllowance);
              setFieldValue('auditor_fee_per_day', selectedFee);
              setFieldValue('mandays', selectedMandays); */
            }

        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

       getData();
  }, [id]);

const calc_grand_total = (all) => {
  let aux = 0
  for (var key in all){
      aux += all[key]
  }
  setGrandTotal(Math.round(aux * 100) / 100)
}
const sendApprovalHandler = async (values) => {
    
    

    if (!window.confirm('Are you sure to proceed ?')) {
      return;
    }
  
    setSubmitting(true);
  let token = await localStorage.getItem('auth-token');

  token = JSON.parse(token);
    values.id = id;
  if(review && values.reviewed_by == profile.id){
    values.action = 'send_for_approval';
    values.reviewed_at = moment().format('YYYY-MM-DD HH:mm:ss');        
  }
  if(approval && values.approved_by == profile.id){
    values.action = 'approved';
    values.approved_at = moment().format('YYYY-MM-DD HH:mm:ss');        
  }
  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
  var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
  data.append('_method', 'PUT');
  await api({
    method: 'POST',
    url: '/auditor-claims/' + id,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
  
  .then(function (response) {

    addToast('Claim successfully updated', { appearance: 'success' });

    window.location = '/auditor/auditor-claim';
    
    // toggleModal();
  })
  .catch(function (error) {

    if (error && error.response && error.response.data && error.response.data.data && error.response.data.data.message) {
      
      addToast(error.response.data.data.message, { appearance: 'error' });
    
    } else {
      
      addToast(error.message, { appearance: 'error' });
    }

    //setSubmitting(false);
  });
}
  return (
    <div className="container-fluid">
      <div className="block-header" id="no-print">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2 style={{ textTransform: "capitalize" }}>
              <SidebarToggler />
               Auditor Claim
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Auditor
              </li>
              <li className="breadcrumb-item">
              Auditor Claim
              </li>
            </ul>
          </div>
        </div>
      </div>
      
   
          <Card>
            <div className="body" id="printarea">
              <div className="m-2 row clearfix">
                
                  <div className="card invoice1">                        
                      <div className="body">
                          <div className="row clearfix">
                              <div className="col-lg-8 col-md-8">
                                  <h6>{auditors ? auditors.map((auditor, key) => {
                                      if(data && data.auditor_id && auditor.id==data.auditor_id)
                                        return auditor.full_name;
                                    }) : ''}</h6>
                                  <p> 
                                    {auditors ? auditors.map((auditor, key) => {
                                      if(data && data.auditor_id && auditor.id==data.auditor_id)
                                        if(auditor.address!=null) {return auditor.address} else {return '<update your address>'}
                                    }) : ''}<br />
                                    {auditors ? auditors.map((auditor, key) => {
                                      if(data && data.auditor_id && auditor.id==data.auditor_id)
                                        return auditor.private_mobile;
                                    }) : ''}<br />
                                      {auditors ? auditors.map((auditor, key) => {
                                      if(data && data.auditor_id && auditor.id==data.auditor_id)
                                        return auditor.business_email;
                                    }) : ''}
                                  </p>

                              </div>
                              <div className="col-lg-4 col-md-4 float-right">
                                  <h4>{data && data.invoice_no ? data.invoice_no : 'INV/'}</h4>
                                  <p>{ data && data.submitted_at ? 'Issued: '+moment( data.submitted_at).format('D MMMM YYYY') :null}<br />
                                  { data && data.approved_at ? 'Approved: '+moment( data.approved_at).format('D MMMM YYYY') :null}</p> 
                              </div>
                          </div>
                          <hr />
                          <div className="row clearfix">
                              <div className="col-lg-6 col-md-6">
                                  <h6>Audit</h6> <p>{id? data && data.audit_plan && data.audit_plan.stage ? data.audit_plan.stage : '' : data && data.stage ? data.stage : ''}</p>
                                  <h6>Company</h6> <p>{id? data && data.audit_plan && data.audit_plan.client ? data.audit_plan.client.name : '' : data && data.client && data.client.name ? data.client.name : ''}<br />{id? data && data.audit_plan && data.audit_plan.client ? data.audit_plan.client.address_1 : '' : data && data.client && data.client.address_1 ? data.client.address_1 : ''}</p>
                                  <h6>Audit Date</h6> <p>{moment(data && data.audit_plan && data.audit_plan.date_of_audit_start).format('DD MMMM YYYY') + ' - ' + moment(data && data.audit_plan && data.audit_plan.date_of_audit_end).format('DD MMMM YYYY') }</p>
                                  <h6>Manday</h6> <p>{data && data.mandays ? data.mandays : ''}</p>
                                  <h6>Role</h6> <p>{data && data.auditor_role ? data.auditor_role : ''}</p>
                              </div>   
                          </div>
                          <div className="table-responsive">
                              <table className="table table-hover">
                                  <thead className="thead-dark">
                                      <tr>
                                          <th>#</th>
                                          <th style={{width: '350px'}}>Description</th>
                                          <th>Unit</th>
                                          <th>Quantity</th>
                                          <th style={{width: '100px'}}>Total (RM)</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>1</td>
                                          <td>Audit Fee</td>
                                          <td>{data.auditor_fee_per_day}</td>
                                          <td>{data.mandays}</td>
                                          <td>{Math.round(data.auditor_fee_per_day * data.mandays * 100) / 100 }</td>
                                      </tr>
                                      <tr>
                                          <td>2</td>
                                          <td>Daily Allowance</td>
                                          <td>{data.allowance_per_day}</td>
                                          <td>{data.outstation_day}</td>
                                          <td>{Math.round(data.allowance_per_day * data.outstation_day * 100) / 100  }</td>
                                      </tr>
                                      <tr>
                                          <td>3</td>
                                          <td>Mileage Claim</td>
                                          <td>{mileagePerKm}</td>
                                          <td>{total_mileage_km+' KM'}</td>
                                          <td>{Math.round(mileagePerKm * total_mileage_km * 100) / 100  }</td>
                                      </tr>
                                      <tr>
                                          <td>4</td>
                                          <td>Toll Claim</td>
                                          <td>{data.details ? data.details.map((toll, key) => 
                                        data.details[key] ? '' :'' ) :''}</td>
                                          <td>{data.details ? data.details.map((toll, key) => 
                                        data.details[key] ? '' :'' ) :''}</td>
                                          <td>{data.details ? data.details.map((toll, key) => 
                                        data.details[key] ? '' :'' ) :''}</td>
                                      </tr>
                                      {data.details ? data.details.map((toll, i) => 
                                        data.details[i].type == "tolls" ? 
                                      <tr key={i}>
                                          <td></td>
                                          <td><ul><li>{data.details[i].from} - {data.details[i].to} {data.details[i].description && data.details[i].description != '' ?  '\n ('+data.details[i].description+')' :''}</li></ul></td>
                                          <td>{data.details[i].amount}</td>
                                          <td>{data.details[i].quantity}</td>
                                          <td>{data.details[i].amount * data.details[i].quantity}</td>
                                      </tr>
                                        :  ''):''}

                                      <tr>
                                          <td>5</td>
                                          <td>Transportation Claim</td>
                                          <td>{data.details ? data.details.map((toll, key) => 
                                        data.details[key] ? '' :'' ) :''}</td>
                                          <td>{data.details ? data.details.map((toll, key) => 
                                        data.details[key] ? '' :'' ) :''}</td>
                                          <td>{data.details ? data.details.map((toll, key) => 
                                        data.details[key] ? '' :'' ) :''}</td>
                                      </tr>
                                      {data.details ? data.details.map((toll, key) => 
                                        data.details[key].type == "transportations" ? 
                                      <tr key={key}>
                                          <td></td>
                                          <td><ul><li>{data.details[key].description}{data.details[key].from && data.details[key].to ? ' ('+data.details[key].from+'-'+data.details[key].to+')' : ''}</li></ul></td>
                                          <td>{data.details[key].amount}</td>
                                          <td>{data.details[key].quantity}</td>
                                          <td>{data.details[key].amount * data.details[key].quantity}</td>
                                      </tr>
                                        :  ''):''}
                                        <tr>
                                          <td>6</td>
                                          <td>Parking Claim</td>
                                          <td>{data.details ? data.details.map((toll, key) => 
                                        data.details[key] ? '' :'' ) :''}</td>
                                          <td>{data.details ? data.details.map((toll, key) => 
                                        data.details[key] ? '' :'' ) :''}</td>
                                          <td>{data.details ? data.details.map((toll, key) => 
                                        data.details[key] ? '' :'' ) :''}</td>
                                      </tr>
                                      {data.details ? data.details.map((toll, key) => 
                                        data.details[key].type == "parkings" ? 
                                      <tr key={key}>
                                          <td></td>
                                          <td><ul><li>{data.details[key].description}</li></ul></td>
                                          <td>{data.details[key].amount}</td>
                                          <td>{data.details[key].quantity}</td>
                                          <td>{data.details[key].amount * data.details[key].quantity}</td>
                                      </tr>
                                        :  ''):''}
                                      <tr>
                                          <td>7</td>
                                          <td>Accomodation Claim</td>
                                          <td>{data.details ? data.details.map((toll, key) => 
                                        data.details[key] ? '' :'' ) :''}</td>
                                          <td>{data.details ? data.details.map((toll, key) => 
                                        data.details[key] ? '' :'' ) :''}</td>
                                          <td>{data.details ? data.details.map((toll, key) => 
                                        data.details[key] ? '' :'' ) :''}</td>
                                      </tr>
                                      {data.details ? data.details.map((toll, key) => 
                                        data.details[key].type == "accomodations" ? 
                                      <tr key={key}>
                                          <td></td>
                                          <td><ul><li>{data.details[key].description}</li></ul></td>
                                          <td>{data.details[key].amount}</td>
                                          <td>{data.details[key].quantity}</td>
                                          <td>{data.details[key].amount * data.details[key].quantity}</td>
                                      </tr>
                                        :  ''):''}
                                  </tbody>
                              </table>
                          </div>
                          <hr />
                          <div className="row clearfix">
                              <div className="col-md-6">
                                  <h6>Attachment(s):</h6>
                                  {data.details ? data.details.map((toll, key) =>
                                  /* data.details[key].type == "mileages" && */ data.details[key].attachment ? 
                                  <p>
                                  <a target="_blank" href={data.details[key].attachment.url ? data.details[key].attachment.url : null}>{data.details[key].attachment.originalName}</a></p>
                                  :'' ) :''}
                              </div>
                              <div className="col-md-6 text-right">
                                  {/* <p className="m-b-0"><b>Sub-total:</b> 560.00</p>
                                  <p className="m-b-0">Discout: 12.9%</p>
                                  <p className="m-b-0">VAT: 12.9%</p>     */}        
                                  <h3 className="m-b-0 m-t-10">RM {data.total_amount}</h3>
                              </div>  
                              <div className="col-md-12">
                                <br /> <br />
                                
                                  <p>I hereby declare that the information given in this form is true and correct to the best of my knowledge and belief. In case any information given in this form proves to be false or incorrect, my right to claim reimbursement of the expenses shall be forfeited.</p>
                                  <h5>Note</h5>
                                  <p>Claim submitted <b>before 7th</b>, our commitment is to make sure the payment to be remitted <b>on or before 31st every month</b> and any submission <b>after 7th</b> will be resulted the remittance in subsequent month.</p>
                              </div>                                  
                              
                          </div>
                      </div>
                  </div>
                                     
              </div>

              <div className="row clearfix" id="no-print">
                {review && data.reviewed_by == profile.id && data.status == 2 ? 
                  <div className="col-sm-4">
                    <Button 
                      // disabled={isSubmitting}
                      //type="submit" 
                      className="btn btn-danger"
                      onClick={toggleModal}
                    >{'Reject'}
                    </Button> &nbsp;
                      
                    <button 
                      disabled={isSubmitting}
                      onClick={() => sendApprovalHandler(data)}
                      className="btn btn-primary"
                    >
                      {isSubmitting ? 'Sending...' : 'Send For Approval'}
                    </button> &nbsp;
                    <Button 
                      // className="btn btn-outline-secondary"
                      outline
                      color="secondary" 
                      tag={Link} 
                      to={props.path}
                    >
                      Cancel
                    </Button>
                    </div>
                : null}
                {approval && data.approved_by == profile.id && data.status == 3 ? 
                  <div className="col-sm-4">
                    <button 
                      className="btn btn-danger"
                      onClick={toggleModal}
                    > Reject
                    </button> &nbsp;
                    <button 
                      disabled={isSubmitting}
                      onClick={() => sendApprovalHandler(data)}  
                      className="btn btn-primary"
                    >
                    {isSubmitting ? 'Approving...' : 'Approve'}
                    </button> &nbsp;
                    <Button 
                      // className="btn btn-outline-secondary"
                      outline
                      color="secondary" 
                      tag={Link} 
                      to={props.path}
                    >Cancel
                    </Button>
                  </div>
                : null}
                <div className="col-sm-12 text-right">
                  <button className="btn btn-outline-secondary" onClick={(event) => { window.print();}}>
                    <i className="icon-printer"></i>
                  </button>
                </div>
              </div>
              
              <Modal id="no-print" className="modal-lg" isOpen={modal} toggle={toggleModal}>
                <Formik 
                  enableReinitialize={true}
                  initialValues={data}
                  onSubmit={async (values, { setSubmitting }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                  values.id = id;
                  if(review && values.reviewed_by == profile.id){
                    values.action = 'review_rejected';
                    values.review_rejected_by = profile.id;
                    values.review_rejected_at = moment().format('YYYY-MM-DD HH:mm:ss');        
                  }
                  if(approval && values.approved_by == profile.id){
                    values.action = 'approval_rejected';
                    values.approval_rejected_by = profile.id;
                    values.approval_rejected_at = moment().format('YYYY-MM-DD HH:mm:ss');        
                  }

                // setSubmitting1(true);

                  console.log(values.action);
                  var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
                  console.log(data);
                  data.append('_method', 'PUT');
                  
                  // await api.post('/operation-reviews/' + id, JSON.stringify(values), {
                  //   headers: {
                  //     'Content-Type': 'application/json',
                  //   },
                  // })
                  await api({
                    method: 'POST',
                    url: '/auditor-claims/' + id /*  +'/rejected' */ ,
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                    data: data,
                  })
                  .then(function (response) {
                    addToast('Claim auditor has been rejected successfully', { appearance: 'success' });
                  //  setSubmitting1(false);
                    toggleModal();
                    window.location = '/auditor/auditor-claim';
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });
                }}
              >
              {({ isSubmitting, values, setFieldValue, handleChange }) => (
              <FormikForm>
                  <ModalHeader toggle={toggleModal}>Rejection comments</ModalHeader>
                  <ModalBody className="p-0">
                    <div className="m-2 row clearfix">
                      <div className="col-sm-12">
                      <Input 
                        readOnly={false}
                        type="textarea" 
                        name={review ? "review_rejected_comment" : approval ? "approval_rejected_comment" :""}
                        rows="5"
                        onChange={handleChange}
                        value={review ? values.review_rejected_comment : approval ? values.approval_rejected_comment : null}
                        required
                      />                  
                        </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button 
                        type="submit"
                        // disabled={true}
                        color="danger" 
                        // onClick={sendAuditConfirmationHandler}
                      >
                        {isSubmitting ? 'Sending...' : 'Reject the claim'}
                    </Button>{' '}
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                  </ModalFooter>
              </FormikForm>
              )}
                </Formik>
              </Modal>
            </div>
          </Card>
             
                
    </div>
  )
}

export default View;