import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  // useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import SidebarToggler from '../../components/SidebarToggler';

import api from '../../utils/api';
import './style.css';
import moment from 'moment';

import { objectToFormData } from 'object-to-formdata';

// import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

const INITIAL_VALUES = {

};

const DETAIL = { type:'', from:'', to:'', description: '', amount:'', quantity:'', attachment:'' }
/*{type:'mileages',
  mileages:{
    from:'', to:'', description: '', amount:'', km: '', attachment:''
  }} ,
  {type:'tolls',
  tolls:{
    from:'', to:'', description: '', amount:'', quantity:'', attachment:''
  }},
  {type:'transportations',
  transportations:{
    from:'', to:'', description: '', amount:'', quantity:'', attachment:''
  }},
  {type:'parkings',
  parkings :{
    from:'', to:'', description: '', amount:'', quantity:'', attachment:''
  }},
  {type:'accomodations',
  accomodations:{
    from:'', to:'', description: '', amount:'', quantity:'', attachment:''
  }} */;
  /* from:'', to:'', description: '', amount:'', quantity:'', km: '', attachment:''} */
/*
const DETAIL = {

  mileages:'',//{from:'', to:'', description: '', amount:'', quantity:'', km: '', attachment:''},
  tolls:'',//{from:'', to:'', description: '', amount:'', quantity:'', km: '', attachment:''},
  transportations:'',//{from:'', to:'', description: '', amount:'', quantity:'', km: '', attachment:''},
  accomodations:'',//{from:'', to:'', description: '', amount:'', quantity:'', km: '', attachment:''}
   type: '',
  from: '',
  to: '',
  description: '',
  amount:'',
  km: '',
  attachment:'' */
  /* tolls:[{ type: '',
  from: '',
  to: '',
  description: '',
  amount:'',
  km: '',
  attachment:''}],
  mileages:[{ type: '',
  from: '',
  to: '',
  description: '',
  amount:'',
  km: '',
  attachment:''}],
  transportations:[{ type: '',
  from: '',
  to: '',
  description: '',
  amount:'',
  km: '',
  attachment:''}],
  accomodations:[{ type: '',
  from: '',
  to: '',
  description: '',
  amount:'',
  km: '',
  attachment:''}] */
 
//};

const MILEAGE = {
  from: '',
  to: '',
  km: '',
  attachment:''
};

const TOLL = {
  from: '',
  to: '',
  amount:'',
  attachment:''
};

const TRANSPORT = {
  description: '',
  amount:'',
  attachment:''
};

const ACCOMODATION = {
  description: '',
  amount:'',
  attachment:''
};


const Form = (props) => {
  let { id } = useParams();

  const { addToast } = useToasts();
  const [auditors, setAuditor] = useState([]);
  const [data, setData] = useState(INITIAL_VALUES);
  const [profile, setProfile] = useState({});

  const [claimSettingAllowance, setClaimSettingAllowance]= useState({});
  const [claimSettingFee, setClaimSettingFee]= useState({});
  const [claimSettingMileage, setClaimSettingMileage]= useState({});
  const [mileagePerKm, setMileagePerKm]= useState(0.00);
  const [feePerDay, setFeePerDay]= useState(0);
  const [allowancePerDay, setAllowancePerDay]= useState(0);

  const [milageValue,setMilageValues] = useState({})
  const [tollValue,setTollValues] = useState({})
  const [transportValue,setTransportValues] = useState({})
  const [accoValue,setAccoValues] = useState({})
  const [parkingValue,setParkingValues] = useState({})

  const [types,setType] = useState({})
  const [amountName,setAmountName] = useState({})
  //const [total_mileage,set_total_mileage]=useState(0.00);
  const [total_mileage_km,set_total_mileage_km]=useState(0);
  const [total_toll,set_total_toll]=useState(0.00);
  const [total_transportation,set_total_transportation]=useState(0.00);
  const [total_accomodation,set_total_accomodation]=useState(0.00);
  const [total_parking,set_total_parking]=useState(0.00);


  const [auditFee,setAuditFee]=useState(0.00);
  const [allowance,setAllowance]=useState(0.00);
  const [grandTotal,setGrandTotal]=useState(0.00);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);
    let { readOnly }  = props;


    const deleteFile = async (originalName,type) => {
      if (window.confirm('Delete file?')) {
        let token = await localStorage.getItem('auth-token');
  
        token = JSON.parse(token);
  
        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
  
        var data = objectToFormData(data, {indices: true, booleansAsIntegers: true});
        
        data.append('_method', 'PUT');
        data.append('filename',originalName);
        data.append('type',type);
        
        // await api.put('/audits/' + audit_report_id + '/non-conformances/' + id , JSON.stringify(values), {
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        // })
        await api({
          method: 'POST',
          url: '/auditor-claims/' + id + '/' + type,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: data,
        })
        .then(function (response) {
  
          addToast('File successfully deleted', { appearance: 'success' });
          window.location.reload();
  
     //     reload(true);
        })
        .catch(function (error) {
          addToast(error.response.data.message, { appearance: 'error' });
        });
      }
    }
    
    useEffect(() => {
    // console.log(path);

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

         setProfile(response.data);
        /* if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'audit_plan.update') === "undefined") {
          history.push(props.path.replace(/:type/, type));
        } */
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getClaimSetting = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      api.get('auditor-claim-settings/1')
     // api.get('client-list?exclude_audits=false&sort=date_of_audit_start&type=iso&type=mspo&limit=-1')
      .then(function (response) {
        console.log(response.data.data);


      let allowanceArray = [];
        response.data.data.allowance_per_day.map((set, i) => {
          allowanceArray.push({
            allowance_per_day: set.allowance_per_day,
            role: set.role,
          })}
        ); 
      setClaimSettingAllowance(allowanceArray);

      let feeArray = [];
        response.data.data.fee_per_day.map((set, i) => {
          feeArray.push({
            fee_per_day: set.fee_per_day,
            role: set.role,
          })}
        ); 
      setClaimSettingFee(feeArray);

      let mileageArray = [];
        response.data.data.mileage_per_km.map((set, i) => {
          mileageArray.push({
            mileage_per_km: set.mileage_per_km,
            role: set.role,
          })}
        ); 
      setClaimSettingMileage(mileageArray);

      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getClaimSetting();


      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/auditor-claims/' + id)
        .then(function (response) {
           console.log(response.data);

          delete response.data.data.id;

          setData(response.data.data);

          
          if (response.data && response.data.data && response.data.data.audit_plan && response.data.data.audit_plan.both_auditors) {
                                  
            response.data.data.audit_plan.both_auditors.map((auditor, key) => auditors.push({
            // if(auditor.id==profile.auditor_id){
                id : auditor.id,
                full_name: auditor.full_name,
                business_email: auditor.business_email,
                private_mobile: auditor.private_mobile,
                address : auditor.private_address_1 && (auditor.private_address2 || auditor.private_address3  || auditor.private_postcode || auditor.private_state) ?  auditor.private_address_1 + ', \n'+ auditor.private_address_2 + ', \n' +  auditor.private_address_3 +', \n' +  auditor.private_postcode +' ' +  auditor.private_state : '' 
            }));
            setAuditor(auditors);
          }
          if (response.data && response.data.data && response.data.data.mandays) {

              let selectedMandays = 0;
              selectedMandays = response.data.data.mandays;
              

              let selectedAllowance = 0;
              selectedAllowance = response.data.data.allowance_per_day;
                 

              let selectedFee = 0;
              selectedFee=response.data.data.auditor_fee_per_day;

              let selectedMileage = 0;
              selectedMileage=response.data.data.mileage_per_km;

              setAuditFee(selectedMandays*selectedFee);   

              setMileagePerKm(selectedMileage);

              setFeePerDay(response.data.data.auditor_fee_per_day ? response.data.data.auditor_fee_per_day : 0);
              setAllowancePerDay(response.data.data.allowance_per_day ? response.data.data.allowance_per_day : 0);
              console.log(allowancePerDay)
            /*   setFieldValue('mileage_per_km', selectedMileage);
              setFieldValue('allowance_per_day', selectedAllowance);
              setFieldValue('auditor_fee_per_day', selectedFee);
              setFieldValue('mandays', selectedMandays); */
            }
          let newMValues ={};
          if (response.data && response.data.data && response.data.data.details){
            response.data.data.details.map((detail, key) => 
              detail.type == "mileages" ? 
                newMValues[`details.${key}.quantity`] = Number(detail.quantity)
              : '' 
            )
          }
          
          setMilageValues(newMValues);
          calc_total_mileage(newMValues);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

       getData();
  }, [id]);
console.log(data.mileage_per_km);
const calc_total_mileage = (mileages) => {
  let aux = 0
  let aux2 = 0;
  for (var key in mileages){
    //  aux += mileages[key] * Number(data.mileage_per_km)
      aux2 += mileages[key] 
  }

  set_total_mileage_km(Math.round(aux2 * 100) / 100)
//  set_total_mileage(Math.round(aux * 100) / 100)
  
}
const calc_total_toll = (tolls) => {
  let aux = 0
  for (var key in tolls){
      aux += tolls[key]
  }
  set_total_toll(Math.round(aux * 100) / 100)

}
const calc_total_transportation = (transportations) => {
  
  let aux = 0
  for (var key in transportations){
      aux += transportations[key] 
  }
  set_total_transportation(Math.round(aux * 100) / 100)

}
const calc_total_accomodation = (accomodations) => {
  
  let aux = 0
  for (var key in accomodations){
      aux += accomodations[key] 
  }
  set_total_accomodation(Math.round(aux * 100) / 100)

}

const calc_total_parking = (parkings) => {
  
  let aux = 0
  for (var key in parkings){
      aux += parkings[key] 
  }
  set_total_parking(Math.round(aux * 100) / 100)

}

//set_total(total_mileage+total_toll);
let totalAll;
let total_mileage = (Math.round(total_mileage_km * data.mileage_per_km * 100) / 100);

//setAllowance(Math.round(data.outstation_day * data.allowance_per_day * 100) / 100);

  return (
    <div className="container-fluid" id="main">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2 style={{ textTransform: "capitalize" }}>
              <SidebarToggler />
               Auditor Claim
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Auditor
              </li>
              <li className="breadcrumb-item">
              Auditor Claim
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Berer ' + token.access_token;

          values.total_allowance = allowance;
          values.total_auditor_fee = auditFee;
          values.total_amount = totalAll;
          values.auditor_id = profile && profile.auditor_id ? profile.auditor_id : 0;
         // values.allowance_per_day = allowancePerDay;
         // values.fee_per_day = feePerDay;
         // values.mileage_per_km = mileagePerKm;

          var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

          data.append('_method', 'PUT');

          await api({
            method: 'POST',
            url: '/auditor-claims/' + id,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          .then(function (response) {
            // console.log(response.data.data.id);

            addToast('Claim successfully submitted', { appearance: 'success' });

            // history.push('/audit/audits');

             window.location = '/auditor/auditor-claim';

            /* if (response.data.data.id) {
              history.push(`${props.path.replace(/:type/, type).replace(/:subtype/, subtype)}/edit/${response.data.data.id}?tab=introduction`);
            } */
          })
          .catch(function (error) {
            console.log(error);
            if (error && error.response && error.response.data) {
              addToast(error.response.data.error.message, { appearance: 'error' });
            } else {
              addToast(error.message , { appearance: 'error' });
            }
          });
            // api.put('/audits/' + id, JSON.stringify(values), {
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            // })
            // .then(function (response) {
            //   // console.log(response);

            //   addToast('Audit successfully updated', { appearance: 'success' });

            //   history.push('/audit/audits');

            //   // window.location = '/client-application/iso';
            // })
            // .catch(function (error) {
            //   addToast(error.message, { appearance: 'error' });
            // });
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <Card>
                  <div className="header">
                    <h2>AUDITOR CLAIM</h2>
                  </div>
                  <div className="body">
                      <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="Organization">Claim for Audit</Label>
                          <Input 
                            disabled={id}
                            type="text" 
                            name="audit_plan_id" 
                            id="auditPlan"
                            value={values && values.audit_plan && values.audit_plan.date_of_audit_start && values.audit_plan.date_of_audit_end ? moment(values.audit_plan.date_of_audit_start).format('DD MMMM YYYY') + ' - ' + moment(values.audit_plan.date_of_audit_end).format('DD MMMM YYYY') : ''}
                            // onChange={handleChange}
                            />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <FormGroup>
                          <Label for="type_o">Type of Audit</Label>
                          <Input 
                            type="text" 
                            name="stage" 
                            id="stage"
                            value={id? values && values.audit_plan && values.audit_plan.stage ? values.audit_plan.stage : '' : values && values.stage ? values.stage : ''}
                            disabled={true}
                            />
                        </FormGroup>
                      </div>
                    </div>  

                    <div className="row clearfix">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <FormGroup>
                        <Label for="ClientName">Company</Label>
                        <Input 
                          // //readOnly={readOnly}
                          disabled={true}
                          type="text" 
                          name="name" 
                          id="ClientName"
                          value={id? values && values.audit_plan && values.audit_plan.client ? values.audit_plan.client.name : '' : values && values.client && values.client.name ? values.client.name : ''}
                       />
                        </FormGroup>
                      </div>
                    </div>  

                    <div className="row clearfix">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <FormGroup>
                        <Label for="CompanyAddress1">Company Address</Label>
                        <Input 
                          disabled={true}
                          type="textarea" 
                          name="address_1" 
                          id="CompanyAddress1"
                          rows="5"
                          value={id? values && values.audit_plan && values.audit_plan.client ? values.audit_plan.client.address_1 : '' : values && values.client && values.client.address_1 ? values.client.address_1 : ''}
                        />
                        </FormGroup>
                      </div>
                    </div>  

                    <div className="row clearfix">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <FormGroup>
                        <Label for="auditor_role">Role</Label>
                        <Input 
                          disabled={true}
                          type="text" 
                          name="auditor_role" 
                          id="auditor_role"
                          //onChange={handleChange}
                          value={values.auditor_role}
                        />
                        </FormGroup>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <FormGroup>
                        <Label for="mandays">Audit mandays allocated</Label>
                        <Input 
                          disabled={true}
                          type="text" 
                          name="mandays" 
                          id="mandays"
                         // onChange={handleChange}
                          value={values.mandays}
                        />
                        </FormGroup>
                      </div>
                    </div>  

                    <div className="row clearfix">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <FormGroup>
                        <Label for="auditor_fee">Audit Fee</Label>
                        <Input 
                          disabled={true}
                          type="text" 
                          name="auditor_fee_per_day" 
                          id="auditor_fee_per_day"
                          //onChange={handleChange}
                          value={values.auditor_fee_per_day}
                        />
                        </FormGroup>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <FormGroup>
                        <Label for="total_auditor_fee">Daily Allowance</Label>
                        <Input 
                          disabled={true}
                          type="text" 
                          name="total_auditor_fee" 
                          id="total_auditor_fee"
                          value={values.total_auditor_fee}
                        />
                        </FormGroup>
                      </div>        
                    </div>  
                    <div className="row clearfix">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <FormGroup>
                        <Label for="auditor_fee">No. of day(s) outstation</Label>
                        <Input 
                          //disabled={true}
                          type="text" 
                          name="outstation_day" 
                          id="outstation_day"
                          //onChange={handleChange}
                          onChange={(e) => {
                            let value= e.currentTarget.value;
                            setAllowance(value * allowancePerDay)
                            setFieldValue('outstation_day', value);

                          }}
                          value={values.outstation_day}
                        />
                        </FormGroup>
                      </div>    
                    </div>                         
                  </div>
                </Card>
               
                <Card>
                  <div className="header">
                    <h2>CLAIM DETAILS</h2>
                  </div>
                  <div className="body">

                    <div className="row cleafix">
                    <div className="col-sm-12">
                        <FormGroup>
                          <Label for="">Claim Details</Label>
                          <small> Please select claim type to make a claim</small>
                          <h6 className="float-right">Total(RM) : { totalAll =  total_mileage == 0 && total_toll == 0 && total_transportation == 0 && total_accomodation==0 && total_parking == 0 ? data.total_amount :  (total_mileage + total_toll + total_transportation + total_accomodation + total_parking + auditFee + (allowance!=0 ? allowance : (Math.round(values.outstation_day * values.allowance_per_day * 100) / 100))) }
                          
                          </h6>
                          <table className="table table-hover js-basic-example dataTable table-custom m-b-0">
                            <thead className="thead-dark">
                              <tr>
                                <th style={{width: '15% !important;'}}>Type</th>
                                <th style={{width: '10% !important;'}}>From (if applicable)</th>
                                <th style={{width: '10% !important;'}}>To (if applicable)</th>
                                <th style={{width: '15% !important;'}}>Description</th>
                                <th style={{width: '10% !important;'}}>Amount (RM) (if applicable)</th>
                                <th style={{width: '10% !important;'}}>Quantity / Distance(KM) </th>
                                <th style={{width: '20% !important;'}}>Attachments</th>
                                <th style={{width: '10% !important;', textAlign: 'right'}}><Button
                                      type="button"
                                      outline
                                      color="success"
                                      //disabled={readOnly}
                                      //onClick={addTableRows}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        console.log(values);
                                        let { details } = values;
                                        if (!details) {
                                          details = [];
                                        }
                                        details.push(DETAIL);
  
                                        // setData(data => {
                                        //   return {...data, mileages: mileages}
                                        // })
  
                                        setFieldValue('details', details);
                                      }}
                                    >
                                      <i className="icon-plus"></i>
                                    </Button></th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.details && values.details.length && values.details.map((detail, key) => 
                                <tr key={key}>{setType(detail.type)}
                                  <td>
                                  <Input 
                                     // disabled="TRUE"
                                      type="select" 
                                      id="type"
                                      onChange={handleChange}

                                      /* onChange={(e) => {
                                        let value = e.currentTarget.options[e.currentTarget.selectedIndex].value;
                                        let name= e.currentTarget.name;
                                        setType(value);
                                      
                                        setFieldValue(`details.${key}.type`, value);
                                      }} */
                                     name = {`details.${key}.type`}
                                     value = {values.details[`${key}`]['type']}
                                    >
                                      <option></option>
                                      <option value="mileages">Mileages</option>
                                      <option value="tolls">Tolls</option>
                                      <option value="transportations">Transportations</option>
                                      <option value="parkings">Parkings</option>
                                      <option value="accomodations">Accomodations</option>
                                  </Input>
                                  </td>
                                  <td>
                                    <Input 
                                      type="text" 
                                      name={`details.${key}.from`}
                                      onChange={handleChange}
                                      //readOnly={readOnly}
                                      value={values.details[key].from}

                                    />
                                  </td>
                                  <td>
                                    <Input 
                                      type="text" 
                                      name={`details.${key}.to`}
                                      onChange={handleChange}
                                      /* onChange={(event) => {
                                        setFieldValue(`details.${key}.to`, event.currentTarget.value)
                                      }} */
                                      value = {values.details[key].to}

                                      //readOnly={readOnly}
                                    />
                                  </td>
                                  <td>
                                  {values.details[key].type == 'transportations' ?
                                    <Input 
                                      type="select"
                                      name={`details.${key}.description`}
                                      onChange={(event) => {
                                        setFieldValue(`details.${key}.description`, event.currentTarget.value)
                                      }}
                                      value = {values.details[key].description}
                                    >
                                      <option></option>
                                      <option value="Flight">Flight</option>
                                      <option value="Bus">Bus</option>
                                      <option value="Taxi">Taxi</option>
                                      <option value="E-Hailing">E-Hailing</option>
                                      <option value="Train/LRT/MRT">Train/LRT/MRT</option>
                                      
                                    </Input> :
                                    <Input 
                                    type="textarea"  
                                    name={`details.${key}.description`}
                                    onChange={handleChange}
                                   /*  onChange={(event) => {
                                      setFieldValue(`details.${key}.${values.details[`${key}`]['type']}.description`, event.currentTarget.value)
                                    }} */
                                    value = {values.details[key].description}

                                    //value={values.details[key].description}
                                    //readOnly={readOnly}
                                  />
                                  }
                                    
                                  </td>
                                  <td>
                                    <Input 
                                      type="text" 
                                      name={`details.${key}.amount`}
                                      onChange={handleChange}
                                      value={values.details[key].amount}
                                      required={values.details}                                    />
                                  </td>
                                  <td>
                                    <Input 
                                      type="text" 
                                      name={`details.${key}.quantity`}
                                      value = {values.details[key]['quantity'] }

                                      onChange={(e) => {
                                        let name= e.target.name;
                                        let value= e.target.value;
                                        if(types == 'mileages'){
                                          const newMValues = {
                                              ...milageValue,
                                            [name]: Number(value)
                                          }
                                          setMilageValues(newMValues)
                                          calc_total_mileage(newMValues)
                                          setFieldValue(`details.${key}.quantity`, e.currentTarget.value);
                                        }
                                        else
                                        {
                                          
                                          if(values.details[key].type == 'tolls'){
                                            let newAmount = Number(values.details[key].amount * (value ? value : 1));
                                            const newValues = {
                                              ...tollValue,
                                              [name] : newAmount
                                            }
                                            setTollValues(newValues)
                                            calc_total_toll(newValues) 
                                            setFieldValue(`details.${key}.quantity`, e.currentTarget.value);
                                          }
                                          if(values.details[key].type == 'transportations'){
                                            let newAmount = Number(values.details[key].amount * (value ? value : 1));
                                            const newValues = {
                                              ...tollValue,
                                              [name] : newAmount
                                            }
                                            setTransportValues(newValues)
                                            calc_total_transportation(newValues) 
                                            setFieldValue(`details.${key}.quantity`, e.currentTarget.value);
                                          }
                                          if(values.details[key].type == 'accomodations'){
                                            let newAmount = Number(values.details[key].amount * (value ? value : 1));
                                            const newValues = {
                                              ...accoValue,
                                              [name] : newAmount
                                            }
                                            setAccoValues(newValues)
                                            calc_total_accomodation(newValues) 
                                            setFieldValue(`details.${key}.quantity`, e.currentTarget.value);
                                          }
                                          if(values.details[key].type == 'parkings'){
                                            let newAmount = Number(values.details[key].amount * (value ? value : 1));
                                            const newValues = {
                                              ...parkingValue,
                                              [name] : newAmount
                                            }
                                            setParkingValues(newValues)
                                            calc_total_parking(newValues) 
                                            setFieldValue(`details.${key}.quantity`, e.currentTarget.value);
                                          }
                                        }
                                      }}
                                     // onChange={value_handler}
                                      //readOnly={readOnly}
                                    />
                                  </td>
                                  <td>
                                    {values.details[key].attachment && values.details[key].attachment.path ? 
                                    <div>
                                      <a href={data.details[key].attachment.url ? data.details[key].attachment.url : null} target="_blank" download>{values.details[key].attachment.originalName}</a>&nbsp;
                                      <button type="button" class="btn btn-xs btn-danger" onClick={() => deleteFile(values.details[key].attachment.originalName, values.details[key].type)}><span aria-hidden="true">×</span></button>
                                    </div> 
                                    :
                                    <Input 
                                      type="file" 
                                      name={`details.${key}.attachment`} 
                                      onChange={(event) => {
                                        setFieldValue(`details.${key}.attachment`, event.currentTarget.files[0])
                                      }}
                                      required
                                    />}
                                  </td>
                                  <td style={{textAlign: 'right'}}>
                                    <Button
                                      type="button"
                                      outline
                                      color="danger"
                                      //disabled={readOnly}
                                      //onClick={()=>(deleteTableRows(key))}
                                      onClick={() => {
                                        if (window.confirm('Delete this row?')) {
                                         
                                          let { details } = values;
                                          const rows = [...details];
                                          rows.splice(key, 1);
                                          setFieldValue('details', rows);
                                        
                                        }
                                      }}
                                    >
                                      <i className="icon-trash"></i>
                                    </Button>
                                  </td>
                                </tr>
                              )}
                              
                            </tbody>
                          </table>
                        </FormGroup>
                      </div>
                     
                    </div>
                    {values.status == 5 ? 
                      <div className="alert alert-danger" role="alert">The claim is REJECTED by Reviewer. Comment from reviewer : {values.review_rejected_comment}</div>
                      :null}
                      {values.status == 6 ? 
                      <div className="alert alert-danger" role="alert">The claim is REJECTED by Approver. Comment from approver : {values.approval_rejected_comment}</div>
                      :null}
                    <div className="row clearfix">
                      <div className="col-sm-4">


                      {values.action == 'save_draft' ? 
                        <button name="action" type="submit"  onClick={() => {
                        setFieldValue('action', 'save_draft')
                         }} className="btn btn-outline-secondary" value="save_draft" disabled={isSubmitting || !values.audit_plan_id}>
                        {isSubmitting && values.action == "save_draft" ? 'Saving Draft...' : 'Save Draft'} 
                        </button>
                        : null}
                        &nbsp;
                        <button type="button" disabled={!values.audit_plan_id}  onClick={toggleModal} className="btn btn-info">
                        {'Preview'}
                        </button>&nbsp;

                        <button type="submit" name="action" onClick={() => {
                        setFieldValue('action', 'send_for_review')
                         }} className="btn btn-primary" value="submit" disabled={isSubmitting || !values.audit_plan_id}>
                        {isSubmitting && values.action == "send_for_review" ? 'Submitting Claim...' : 'Submit'} {/* {id ? 'Update' : 'Create'} */}
                        </button>&nbsp;
                        
                      </div>
                    </div>                  
                  </div>
                </Card>

              </div>
            </div>
            <Modal className="modal-xl" isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Preview Invoice</ModalHeader>
                    <ModalBody className="p-0">
                      <div className="m-2 row clearfix" id="printarea">
                     
                        <div className="card invoice1">                        
                            <div  className="body">
                                  <div className="row clearfix">
                                    <div className="col-lg-8 col-md-8">
                                        <h6>{auditors ? auditors.map((auditor, key) => {
                                            if(values && values.auditor_id && auditor.id==values.auditor_id)
                                              return auditor.full_name;
                                          }) : ''}</h6>
                                        <p> 
                                          {auditors ? auditors.map((auditor, key) => {
                                           if(values && values.auditor_id && auditor.id==values.auditor_id)
                                              if(auditor.address!=null) {return auditor.address} else {return '<update your address>'}
                                          }) : ''}<br />
                                          {auditors ? auditors.map((auditor, key) => {
                                            if(values && values.auditor_id && auditor.id==values.auditor_id)
                                              return auditor.private_mobile;
                                          }) : ''}<br />
                                            {auditors ? auditors.map((auditor, key) => {
                                            if(values && values.auditor_id && auditor.id==values.auditor_id)
                                              return auditor.business_email;
                                          }) : ''}
                                        </p>

                                    </div>
                                    <div className="col-lg-4 col-md-4 text-right">
                                    <h4>{values && values.invoice_no ? values.invoice_no : 'INV/'}</h4>
                                        <p>Issued: {moment(values &&  values.submitted_at!= null ? values.submitted_at :null).format('D MMMM YYYY')}<br /></p> 
                                    </div>
                                </div>
                                <hr />
                                <div className="row clearfix">
                              <div className="col-lg-6 col-md-6">
                                        <h6>Audit</h6> <p>{id? values && values.audit_plan && values.audit_plan.stage ? values.audit_plan.stage : '' : values && values.stage ? values.stage : ''}</p>
                                        <h6>Company</h6> <p>{id? values && values.audit_plan && values.audit_plan.client ? values.audit_plan.client.name : '' : values && values.client && values.client.name ? values.client.name : ''}<br />{id? values && values.audit_plan && values.audit_plan.client ? values.audit_plan.client.address_1 : '' : values && values.client && values.client.address_1 ? values.client.address_1 : ''}</p>
                                        <h6>Audit Date</h6> <p>{moment(values.date_of_audit_start).format('DD MMMM YYYY') + ' - ' + moment(values.date_of_audit_end).format('DD MMMM YYYY') }</p>
                                        <h6>Manday</h6> <p>{values && values.mandays ? values.mandays : ''}</p>
                                        <h6>Role</h6> <p>{values && values.auditor_role ? values.auditor_role : ''}</p>
                                    </div>   
                                
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>#</th>
                                                <th style={{width: '350px'}}>Description</th>
                                                <th>Unit</th>
                                                <th>Quantity</th>
                                                <th style={{width: '100px'}}>Total (RM)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Audit Fee</td>
                                                <td>{values.auditor_fee_per_day ? values.auditor_fee_per_day : 0.00}</td>
                                                <td>{values.mandays}</td>
                                                <td>{ values.total_auditor_fee ? values.total_auditor_fee : 0.00}</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Daily Allowance</td>
                                                <td>{values.allowance_per_day ? values.allowance_per_day : allowancePerDay}</td>
                                                <td>{values.outstation_day}</td>
                                                <td>{ allowance!=0 ? Math.round(allowance * 100) / 100 : (Math.round(values.outstation_day * values.allowance_per_day * 100) / 100) }</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Mileage Claim</td>
                                                <td>{mileagePerKm}</td>
                                                <td>{total_mileage_km+' KM'}</td>
                                                <td>{total_mileage}</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Toll Claim</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] && values.details[key] !='' ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] && values.details[key] !='' ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] && values.details[key] !='' ? '' :'-' ) :''}</td>
                                            </tr>
                                            {values.details ? values.details.map((toll, key) => 
                                              values.details[key].type == "tolls" ? 
                                            <tr key={key}>
                                                <td></td>
                                                <td><ul><li>{values.details[key].from} - {values.details[key].to} {values.details[key].description && values.details[key].description != '' ?  '\n ('+values.details[key].description+')' :''}</li></ul></td>
                                                <td>{values.details[key].amount}</td>
                                                <td>{values.details[key].quantity}</td>
                                                <td>{values.details[key].amount * values.details[key].quantity}</td>
                                            </tr>
                                              :  ''):''}

                                            <tr>
                                                <td>5</td>
                                                <td>Transportation Claim</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                            </tr>
                                            {values.details ? values.details.map((toll, key) => 
                                              values.details[key].type == "transportations" ? 
                                            <tr key={key}>
                                                <td></td>
                                                <td><ul><li>{values.details[key].description}{values.details[key].from && values.details[key].to ? ' ('+values.details[key].from+'-'+values.details[key].to+')' : ''}</li></ul></td>
                                                <td>{values.details[key].amount}</td>
                                                <td>{values.details[key].quantity}</td>
                                                <td>{values.details[key].amount * values.details[key].quantity}</td>
                                            </tr>
                                              :  ''):''}
                                            <tr>
                                                <td>6</td>
                                                <td>Parking Claim</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                            </tr>
                                            {values.details ? values.details.map((toll, key) => 
                                              values.details[key].type == "parkings" ? 
                                            <tr key={key}>
                                                <td></td>
                                                <td><ul><li>{values.details[key].description}</li></ul></td>
                                                <td>{values.details[key].amount}</td>
                                                <td>{values.details[key].quantity}</td>
                                                <td>{values.details[key].amount * values.details[key].quantity}</td>
                                            </tr>
                                              :  ''):''}
                                            <tr>
                                                <td>7</td>
                                                <td>Accomodation Claim</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                                <td>{values.details ? values.details.map((toll, key) => 
                                              values.details[key] ? '' :'-' ) :''}</td>
                                            </tr>
                                            {values.details ? values.details.map((toll, key) => 
                                              values.details[key].type == "accomodations" ? 
                                            <tr key={key}>
                                                <td></td>
                                                <td><ul><li>{values.details[key].description}</li></ul></td>
                                                <td>{values.details[key].amount}</td>
                                                <td>{values.details[key].quantity}</td>
                                                <td>{values.details[key].amount * values.details[key].quantity}</td>
                                            </tr>
                                              :  ''):''}
                                        </tbody>
                                    </table>
                                </div>
                                <hr />
                                <div className="row clearfix">
                                    
                                    <div className="col-md-12 text-right">
                                        {/* <p className="m-b-0"><b>Sub-total:</b> 560.00</p>
                                        <p className="m-b-0">Discout: 12.9%</p>
                                        <p className="m-b-0">VAT: 12.9%</p>     */}        
                                        <h3 className="m-b-0 m-t-10">RM {totalAll =  total_mileage == 0 && total_toll == 0 && total_transportation == 0 && total_accomodation==0 && total_parking == 0 ? data.total_amount :  (total_mileage + total_toll + total_transportation + total_accomodation + total_parking + auditFee + (allowance!=0 ? allowance : (Math.round(values.outstation_day * values.allowance_per_day * 100) / 100)))  }</h3>
                                    </div>                                    
                                    <div className="col-md-12">
                                      <br /> <br />
                                       <p>I hereby declare that the information given in this form is true and correct to the best of my knowledge and belief. In case any information given in this form proves to be false or incorrect, my right to claim reimbursement of the expenses shall be forfeited.</p>
                                        <h5>Note</h5>
                                        <p>Claim submitted <b>before 7th</b>, our commitment is to make sure the payment to be remitted <b>on or before 31st every month</b> and any submission <b>after 7th</b> will be resulted the remittance in subsequent month.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                                            
                    </div>
                    </ModalBody>
                    <ModalFooter>
                    <button className="btn btn-outline-secondary" onClick={(event) => {
                                        window.print();
                                      }}><i className="icon-printer"></i></button>
                    <Button color="secondary" onClick={toggleModal}>Close</Button>
                    </ModalFooter>
                
                </Modal>      
          </FormikForm>
        )}
      </Formik>
            
    </div>
  )
}

export default Form;