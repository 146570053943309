import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  // useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
  // ListGroup,
  // ListGroupItem,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import DatePicker from 'reactstrap-date-picker';

import DateRangePicker from 'react-bootstrap-daterangepicker';

import { Editor } from '@tinymce/tinymce-react';

// import SidebarToggler from '../../components/SidebarToggler';

import moment from 'moment';

import api from '../../utils/api';

import Select from 'react-select'

import { useToasts } from 'react-toast-notifications';

const INITIAL_VALUES = {

};

const Form = (props) => {
  let { id } = useParams();
  let history = useHistory();
  const { addToast } = useToasts();
  const [data, setData] = useState(INITIAL_VALUES);
  const [users, setUsers] = useState([{}]);
  
  let { readOnly }  = props;
  
  useEffect(() => {
    const getUsers = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/users?limit=-1')
      .then(function (response) {
        // console.log(response.data);
        
        let users = [];
        response.data.data.map((user, i) => users.push({
          label: user.name,
          value: user.id,
          id: user.id,
        }));

        setUsers(users);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getUsers();
    
    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/meeting-notifications/' + id)
        .then(function (response) {
          // console.log(response.data);

          delete response.data.id;

          // if (response.data && response.data && response.data.roles && response.data.roles[0]) {
          //   response.data.role_id = response.data.roles[0].id;
          // }
          
          if (response.data.invites && response.data.invites.length) {
            let invites = [];
            response.data.invites.map((invite, i) => {
              // console.log(invite);
              return invites.push({
                label: invite.name,
                value: invite.id,
                id: invite.id,
              });
            })

            response.data.invites = invites;
          }

          setData(response.data);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, []);
  
  return (
    <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        // console.log(values);
        
        if (!id) {
          await api.post('/meeting-notifications', JSON.stringify(values), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(function (response) {
            // console.log(response.data.data.id);

            addToast('Notification successfully created', { appearance: 'success' });

            history.push(`${props.path}`);
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        } else {
          await api.put('/meeting-notifications/' + id, JSON.stringify(values), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(function (response) {
            // console.log(response);

            addToast('Notification successfully updated', { appearance: 'success' });

            history.push(`${props.path}`);
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
        <FormikForm>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <Card>
                <div className="header">
                  <h2>NOTIFICATION</h2>
                </div>
                <div className="body">
                  
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="StartDate">Date</Label>
                        <DateRangePicker 
                          disabled={readOnly}
                          initialSettings={{
                            timePicker: true
                          }}
                          timePicker={true}
                          timePickerSeconds={false}
                          containerStyles={{ display: "block" }}
                          startDate={values.start_date && values.start_date.length ? moment(values.start_date).format('MM/DD/YYYY HH:mm:ss') : moment().format('MM/DD/YYYY HH:mm:ss')}
                          endDate={values.end_date && values.start_date.length ? moment(values.end_date).format('MM/DD/YYYY HH:mm:ss') : moment().format('MM/DD/YYYY HH:mm:ss')}
                          onApply={(event, picker) => { 
                            setFieldValue('start_date', picker.startDate.format('YYYY-MM-DD HH:mm:ss'));
                            setFieldValue('end_date', picker.endDate.format('YYYY-MM-DD HH:mm:ss'));
                          }}
                        >
                          <Input 
                            autoComplete="off"
                            readOnly={readOnly}
                            type="text" 
                            // name="date_of_audit" 
                            id="StartDate"
                            // onChange={handleChange}
                            value={values.start_date && values.end_date ? moment(values.start_date).format('DD/MM/YYYY HH:mm:ss') + ' - ' + moment(values.end_date).format('DD/MM/YYYY HH:mm:ss') : ""}
                          />
                        </DateRangePicker>
                      </FormGroup>
                    </div>
                  </div>
                  
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Location">Location</Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          name="location" 
                          id="Location"
                          onChange={handleChange}
                          value={values.location}
                          // rows={10}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Descriptions">Descriptions</Label>
                        <Input 
                          readOnly={readOnly}
                          type="textarea" 
                          name="objectives" 
                          id="Objectives"
                          onChange={handleChange}
                          value={values.objectives}
                          rows={10}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Agenda">Agenda</Label>
                        <Editor 
                          disabled={readOnly}
                          apiKey={process.env.REACT_APP_TINYMCE_KEY}
                          value={values.agenda}
                          init={{
                            height: 500,
                            menubar: 'file edit view insert format tools table tc help',
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                            paste_data_images: true,
                            content_style: "body { font-family: Arial; font-size: 10pt; }",
                          }}
                          onEditorChange={(content) => {
                            setFieldValue('agenda', content);
                          }}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  
                  <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="Invites">Invites</Label>
                          <Select
                            isDisabled={readOnly}
                            inputId="Invites"
                            name="invites"
                            isMulti
                            placeholder=""
                            openMenuOnFocus={true}
                            options={users} 
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                color: '#495057',
                                backgroundColor: 'transparent', 
                                borderColor: '#ced4da !important', 
                                boxShadow: 'none',
                                outline: 'none',
                              })
                            }}
                            value={values.invites}
                            onChange={(selectedUsers) => {
                              setFieldValue('invites', selectedUsers);
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  
                  <div className="row clearfix">
                    <div className="col-sm-4">
                      {/* {readOnly === false ? 
                      <React.Fragment> */}
                      <button type="submit" className="btn btn-primary" disabled={isSubmitting || readOnly ? true : false}>
                        {isSubmitting ? 'Sending...' : 'Send'}
                      </button>&nbsp;
                      {/* </React.Fragment> : null} */}
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        tag={Link} 
                        to={props.path}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  )
}

export default Form;