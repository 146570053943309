import React, { useEffect, useState } from "react";

import {
  Link,
  useParams,
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  Input,
  // Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from "formik";

import classnames from 'classnames';

import BootstrapTable from 'react-bootstrap-table-next';

import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import api from "../../../../utils/api";

const Recertification = (props) => {
  let { id } = useParams();

  const { addToast } = useToasts();

  const [data, setData] = useState([]);

  const [activeTab, setActiveTab] = useState(1);

  const [load, reload] = useState(true);

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/audits/' + id)
      .then(function (response) {
        console.log(response);

        // delete response.data.id;

        setData(response.data.data);

        reload(false);
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getData();
  }, [id, load]);

  return (
    <React.Fragment>
      <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

          api.put('/audits/' + id, JSON.stringify(values), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(function (response) {
            addToast('Audit successfully updated', { appearance: 'success' });
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <Card>
              <div className="header">
                <h2>Re-Certification</h2>
              </div>
              <div className="body">
                <Nav pills>
                  {[{
                    id: 1,
                    label: "Use of marks",
                  },
                  {
                    id: 2,
                    label: "Improvement since previous audit",
                  },
                  {
                    id: 3,
                    label: "Changes to management system",
                  },
                ].map((tab, i) => 
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === tab.id })}
                        onClick={() => { toggle(tab.id); }}
                      >
                        8.{i + 1} {tab.label}
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <hr />
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <FormGroup>
                      <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        value={data.use_of_mark}
                        init={{
                          height: 500,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('use_of_mark', content);
                        }}
                      />
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId={2}>
                    <FormGroup>
                      <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        value={data.improvement_since_previous_audit}
                        init={{
                          height: 500,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('improvement_since_previous_audit', content);
                        }}
                      />
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId={3}>
                    <FormGroup>
                      <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        value={data.changes_to_management_system}
                        init={{
                          height: 500,
                          menubar: 'file edit view insert format tools table tc help',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('changes_to_management_system', content);
                        }}
                      />
                    </FormGroup>
                  </TabPane>

                  <div className="row clearfix">
                    <div className="col-sm-4">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>&nbsp;
                      {/* <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        tag={Link} 
                        to={props.path.replace(/:type/, props.type)}
                      >
                        Cancel
                      </Button> */}
                    </div>
                  </div>
                </TabContent>
              </div>
            </Card>
          </FormikForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default Recertification;