import React from 'react';

import {
  Link,
  // Redirect,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

// import ISO from './ISO';
// import MSPO from './MSPO';
// import MSPO_SCCS from './MSPO_SCCS';

import SidebarToggler from "../../../components/SidebarToggler";

import Listing from './Listing';
import AssignForm from './Assign';
import Form from './Form';
const PeerReview = () => {
  let { path } = useRouteMatch();
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-10 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Peer Review
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Review
              </li>
              <li className="breadcrumb-item">
                <Link to={path}>
                  Peer Review
                </Link>
              </li>
            </ul>
          </div>
          
        </div>
      </div>
      <Switch>
        <Route exact path={`${path}`}>
          <Listing />
        </Route>
        <Route 
            path={`${path}/create`} 
            render={(props) => <Form path={path} {...props} />} 
          />
          <Route 
            path={`${path}/edit/:id/:client_id`} 
            render={(props) => <Form path={path} {...props} />} 
          />
          <Route 
            path={`${path}/view/:id/:client_id`} 
            render={(props) => <Form path={path} readOnly={true} {...props} />} 
          />
          <Route 
            path={`${path}/assign/:id/:client_id`} 
            render={(props) => <AssignForm path={path} assign={true} {...props} />} 
          />
          <Route 
            path={`${path}/review/:id/:client_id`} 
            render={(props) => <Form path={path} /* readOnly={true} */ /* review={true} */ {...props} />} 
          />
          <Route 
            path={`${path}/approve/:id/:client_id`} 
            render={(props) => <Form path={path} readOnly={true} approval={true} {...props} />} 
          />
      </Switch>
    </div>
  )
}

export default PeerReview;