import React, { useEffect, useState } from "react";

import {
  Link,
  // Route,
  // Switch,
  useParams,
  // useHistory,
  // useLocation,
  // useRouteMatch,
} from "react-router-dom";

import SidebarToggler from '../../../components/SidebarToggler';

import api from '../../../utils/api';

// import { useToasts } from 'react-toast-notifications';

import moment from 'moment';

import './style.css';

import ViewMspo from './ViewMspo';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const View = (props) => {
  let { type, subtype, id } = useParams();

  const [data, setData] = useState({});

  const [token, setToken] = useState('');

  useEffect(() => {
    const getToken = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);
    }

    getToken();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;

          let reactElement = htmlToReactParser.parse(response.data.data.audit_conclusions);
          response.data.data.audit_conclusions = reactElement;

          reactElement = htmlToReactParser.parse(response.data.data.recommendation);
          response.data.data.recommendation = reactElement;

          reactElement = htmlToReactParser.parse(response.data.data.team_leader_recommendation);
          response.data.data.team_leader_recommendation = reactElement;

          reactElement = htmlToReactParser.parse(response.data.data.audit_summary);
          response.data.data.audit_summary = reactElement;

          reactElement = htmlToReactParser.parse(response.data.data.previous_audit_finding_status);
          response.data.data.previous_audit_finding_status = reactElement;

          reactElement = htmlToReactParser.parse(response.data.data.review_changes_to_the_qms);
          response.data.data.review_changes_to_the_qms = reactElement;

          reactElement = htmlToReactParser.parse(response.data.data.interview_session);
          response.data.data.interview_session = reactElement;

          setData(response.data.data);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            // addToast(error.response.data.message, { appearance: 'error' });
          } else {
            // addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, []);

  if (type.toUpperCase() === 'MSPO') {
    return <ViewMspo {...props} />
  }
  
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ')}` : ""} Audit Report
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Audit
              </li>
              <li className="breadcrumb-item">
                Audit Report
              </li>
              <li className="breadcrumb-item">
                <Link to={props.path.replace(/:type/, type).replace(/:subtype/, subtype)}>
                  {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ')}` : ""}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card invoice1">
            <div className="body">
              <div className="invoice-top clearfix">
                <div className="logo">
                  <img src={process.env.PUBLIC_URL + '/logo-niosh.png'} alt="logo" class="rounded-circle img-fluid" />
                </div>
                <div className="info">
                <h6>NIOSH CERTIFICATION SDN. BHD.</h6>
                  <p>7th Floor, NIOSH Tower, Lot 1, Jalan 15/1, Seksyen 15, 43650 Bandar Baru Bangi, Selangor<br />
                    Tel: +603 8769 2100 / +603 8911 3800   |   Fax: +603 8926 2900
                  </p>
                </div>
                <div class="title">
                  <h4>Audit Report #{data.audit_plan && data.audit_plan && data.audit_plan.audit_no ? data.audit_plan.audit_no : id}</h4>
                </div>
              </div>
              <div className="invoice-mid clearfix">&nbsp;</div>
              <hr />

              <table className="table table-bordered" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold", width: "25%" }}>Client/Company</td>
                    <td style={{ whiteSpace: "pre-wrap" }}>
                      {data.audit_plan && data.audit_plan.client && data.audit_plan.client.name ? data.audit_plan.client.name : ""}&nbsp;
                    </td>
                    <td style={{ fontWeight: "bold", width: "25%" }}>Audit No.</td>
                    <td>{data.audit_plan && data.audit_plan && data.audit_plan.audit_no ? data.audit_plan.audit_no : <span className="text-muted">N/A</span>}&nbsp;</td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold" }}>Address</td>
                    <td colSpan={3} style={{ whiteSpace: "pre-wrap" }}>
                      {data.audit_plan && data.audit_plan.client && data.audit_plan.client.address_1 ? data.audit_plan.client.address_1 : ""}{" "}
                        {data.audit_plan && data.audit_plan.client && data.audit_plan.client.address_2 ? data.audit_plan.client.address_2 : ""}{" "}
                        {data.audit_plan && data.audit_plan.client && data.audit_plan.client.address_3 ? data.audit_plan.client.address_3 : ""}{" "}
                        <br />
                        {data.audit_plan && data.audit_plan.client && data.audit_plan.client.postcode ? data.audit_plan.client.postcode : ""}{" "}
                        {data.audit_plan && data.audit_plan.client && data.audit_plan.client.state ? data.audit_plan.client.state : ""}&nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>Sites To Be Audited</td>
                    <td colSpan={3}>
                      <ol style={{ listStylePosition: "inside", paddingLeft: 0 }}>
                      {data.scope_of_certifications && data.scope_of_certifications.length ? data.scope_of_certifications.map((scope, i) => 
                        <li>{scope.site_name}</li>
                      ) : <span className="text-muted">N/A</span>}
                      </ol>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}>Audit Type</td>
                    <td colSpan={3}>
                      {data.audit_plan && data.audit_plan && data.audit_plan.stage ? data.audit_plan.stage : <span className="text-muted">N/A</span>}&nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold", width: "25%", whiteSpace: "pre-wrap" }}>Management Representative</td>
                    <td style={{ whiteSpace: "pre-wrap" }}>
                      {data.audit_plan && data.audit_plan.client && data.audit_plan.client.management_representative_name ? data.audit_plan.client.management_representative_name : <span className="text-muted">N/A</span>}&nbsp;
                    </td>
                    <td style={{ fontWeight: "bold", width: "25%" }}>Position</td>
                    <td>
                      {data.audit_plan && data.audit_plan.client && data.audit_plan.client.management_representative_designation ? data.audit_plan.client.management_representative_designation : <span className="text-muted">N/A</span>}&nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold" }}>Email Address</td>
                    <td colSpan={3}>
                      {data.audit_plan && data.audit_plan.client && data.audit_plan.client.email ? data.audit_plan.client.email : <span className="text-muted">N/A</span>}&nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold" }}>Web Page</td>
                    <td colSpan={3}>
                      {data.audit_plan && data.audit_plan.client && data.audit_plan.client.website ? data.audit_plan.client.website : <span className="text-muted">N/A</span>}&nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold" }}>Audit Standard(s)</td>
                    <td colSpan={3} style={{ whiteSpace: "pre-wrap" }}>
                      {/* <ul>
                        {data.audit_plan && data.audit_plan.client && data.audit_plan.client.certifications ? data.audit_plan.client.certifications.map((certification, i) => 
                          <li>{certification.standard}</li>
                        ) : ""}
                      </ul> */}

                      {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'ISO' && data.audit_plan.client && data.audit_plan.client.certifications.length ? data.audit_plan.client.certifications.map((cert, key)  => 
                        <p>{cert.standard}</p>
                      ) : null}

                      {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'MSPO' && data.audit_plan.subtype.toUpperCase() === 'PART-2' ?
                        <p>Malaysian Sustainable Palm Oil (MSPO) Part 2: General principles for independent smallholders (MS 2530-2:2013)</p>
                      : null}

                      {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'MSPO' && data.audit_plan.subtype.toUpperCase() === 'PART-3' ?
                        <p>Malaysian Sustainable Palm Oil (MSPO) Part 3: General principles for oil palm plantations and organized smallholders (MS 2530-3:2013)</p>
                      : null}

                      {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'MSPO' && data.audit_plan.subtype.toUpperCase() === 'PART-4' ?
                        <p>Malaysian Sustainable Palm Oil (MSPO) Part 4: General principles for palm oil mills (MS 2530-4:2013)</p>
                      : null}

                      {data.audit_plan && data.audit_plan.type && data.audit_plan.type.toUpperCase() === 'MSPO-SCCS' ?
                        <p>MSPO Supply Chain Certification Standard: MSPO-SCCS-01</p>
                      : null}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold" }}>Audit Scope</td>
                    <td colSpan={3} style={{ whiteSpace: "pre-wrap" }}>
                      {data.audit_plan && data.audit_plan.client && data.audit_plan.client.scope_of_certification ? data.audit_plan.client.scope_of_certification : ""}&nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold" }}>NACE Code</td>
                    <td colSpan={3}>
                      {data.audit_plan && data.audit_plan.client && data.audit_plan.client.nace_codes ? data.audit_plan.client.nace_codes.map((nace, i) => 
                        <p>{nace.code} <span className="text-muted">{nace.description}</span></p>
                      ) : <span className="text-muted">N/A</span>}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold" }}>Audit Date</td>
                    <td>
                    {data.audit_plan && data.audit_plan.date_of_audit_start && data.audit_plan.date_of_audit_end ? moment(data.audit_plan.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(data.audit_plan.date_of_audit_end).format('DD/MM/YYYY') : <span className="text-muted">N/A</span>}&nbsp;
                    </td>
                    <td style={{ fontWeight: "bold" }}>Total Man-Day</td>
                    <td>
                    {data.audit_plan && data.audit_plan.date_of_audit_start && data.audit_plan.date_of_audit_end ? moment(data.audit_plan.date_of_audit_end).diff(moment(data.audit_plan.date_of_audit_start), 'days') : <span className="text-muted">N/A</span>}&nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold" }}>Previous Audit Date</td>
                    <td colSpan={3}>
                    {data.audit_plan && data.audit_plan.last_audit_date_start && data.audit_plan.last_audit_date_end ? moment(data.audit_plan.last_audit_date_start).format('DD/MM/YYYY') + ' - ' + moment(data.audit_plan.last_audit_date_end).format('DD/MM/YYYY') : <span className="text-muted">N/A</span>}&nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold" }}>Client's Reference Documents</td>
                    <td colSpan={3}>
                      {data.client_reference_documents ? data.client_reference_documents : <span className="text-muted">N/A</span>}&nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontWeight: "bold" }}>Exclusions</td>
                    <td>
                    {data.exclusions ? data.exclusions : <span className="text-muted">N/A</span>}&nbsp;
                    </td>
                    <td style={{ fontWeight: "bold" }}>Total Staff</td>
                    <td>
                    {data.audit_plan && data.audit_plan.client && data.audit_plan.client.total_staffs ? data.audit_plan.client.total_staffs : ""}&nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td rowSpan={2} style={{ fontWeight: "bold" }}>Team Members</td>
                    <td>
                      LEAD AUDITOR
                    </td>
                    <td colSpan={2}>
                      <ol style={{ listStylePosition: "inside", paddingLeft: 0 }}>
                      {data.audit_plan && data.audit_plan.lead_auditors ? 
                        data.audit_plan.lead_auditors.map((auditor, i) => 
                          <li>{auditor.full_name}</li>
                      ) : ""}
                      </ol>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      AUDITOR
                    </td>
                    <td colSpan={2}>
                    <ol style={{ listStylePosition: "inside", paddingLeft: 0 }}>
                      {data.audit_plan && data.audit_plan.audit_team_members ? 
                        data.audit_plan.audit_team_members.map((auditor, i) => 
                          <li>{auditor.full_name}</li>
                      ) : ""}
                      </ol>
                    </td>
                  </tr>

                </tbody>
              </table>

              <table className="table table-bordered" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Audit Conclusion</td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "pre-wrap" }}>
                      {data.audit_conclusions ? data.audit_conclusions : ""}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-bordered" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Recommendation</td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "pre-wrap" }}>
                      {data.team_leader_recommendation ? data.team_leader_recommendation : ""}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-bordered" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Audit Objective</td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "pre-wrap" }}>
                      {data.audit_plan && data.audit_plan.audit_objective ? data.audit_plan.audit_objective.split(/(?:\r\n|\r|\n)/g).map((str, i) => <>{str}<br /></>) : ""}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-bordered" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Audit Summary</td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "pre-wrap" }}>
                      {data.audit_conclusions ? data.audit_conclusions : ""}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-bordered" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Review and follow-up on previous audit findings</td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "pre-wrap" }}>
                      {data.previous_audit_finding_status ? data.previous_audit_finding_status : ""}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-bordered" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Review changes to the QMS</td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "pre-wrap" }}>
                      {data.review_changes_to_the_qms ? data.review_changes_to_the_qms : ""}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-bordered" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Interview Session</td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "pre-wrap" }}>
                      {data.interview_session ? data.interview_session : ""}
                    </td>
                  </tr>
                </tbody>
              </table>

              {data.auditor_notes ? data.auditor_notes.map((note, i) => 
              <table className="table table-bordered" style={{ width: "100%", maxWidth: "100%" }}>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      
                      {note.section}
                      
                      {note.clause ? htmlToReactParser.parse(
                        note.clause
                        .replace(/table width="\d+"/g, "table")
                        // .replace(/td width="\d+"/g, "td style=\"white-space: pre-wrap\" ")
                      ) : null}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ whiteSpace: "pre-wrap" }}>
                      {note.notes ? htmlToReactParser.parse(
                        note.notes
                        .replace(/table width="\d+"/g, "table")
                        // .replace(/td width="\d+"/g, "td style=\"white-space: pre-wrap\" ")
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              </table>
              ) : null}
              <div className="col-md-12 text-right">
                {/* <hr /> */}
                {/* <button 
                  className="btn btn-outline-secondary" 
                  type="button"
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_API_URL}/audits/${id}?export=pdf&token=${token}`);
                  }}
                >
                  <i className="icon-printer"></i>
                </button>&nbsp; */}
                {/* <button 
                  className="btn btn-outline-secondary" 
                  type="button" 
                  onClick={sendNotificationHandler}
                >
                  <i className="icon-envelope"></i> Send Email Notification
                </button> */}
              
                {/* <button 
                  className="btn btn-outline-secondary" 
                  type="button" 
                  // onClick={sendNotificationHandler}
                >
                  <i className="icon-envelope"></i> Send Email Notification
                </button> */}
                
                <button 
                  className="btn btn-outline-secondary" 
                  type="button"
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_API_URL}/audits/view/${id}?token=${token}&publicSummaryReport=true`);
                  }}
                >
                  <i className="icon-printer"></i> Public Summary Report
                </button>
                
                &nbsp;
                
                <button 
                  className="btn btn-outline-secondary" 
                  type="button"
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_API_URL}/audits/view/${id}?token=${token}`);
                  }}
                >
                  <i className="icon-printer"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default View;