import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
} from 'react-router-dom';

import { 
  Formik,
  Form
} from 'formik';

import {
  ButtonGroup,
  ToggleButton
} from 'react-bootstrap';

import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,

} from "reactstrap"

import Select from 'react-select'


import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import paginationFactory from 'react-bootstrap-table2-paginator';
import { useToasts } from 'react-toast-notifications';

import { objectToFormData } from 'object-to-formdata';

import SidebarToggler from '../../components/SidebarToggler';
import moment from 'moment';
import api from "../../utils/api";
import classnames from 'classnames';
const INITIAL_VALUE = {
  filename: "Choose file"
};
const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
}
const ReportsImport = () => {
  let { path } = useRouteMatch();

  const { addToast } = useToasts();

 // const [data, setData] = useState(INITIAL_VALUE);
  const [data, setData] = useState([]);
  const [files, setFiles] = useState([]);
  const [auditors, setAuditor] = useState([]);
  const [clients, setClient] = useState([]);
  const [load, reload] = useState(false);

  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);

  const [activeTab1, setActiveTab1] = useState(1);

  const [activeTab2, setActiveTab2] = useState(1);
  const [category, setCategory] = useState(1);
  const toggle1 = tab => {
    if(activeTab1 !== tab) setActiveTab1(tab);
  } 
  const toggle2 = tab2 => {
    if(activeTab2 !== tab2) setActiveTab2(tab2);
  }
 
  
  
  useEffect(() => {
    const element = document.querySelector("#main-content");
    
    element.classList.add('file_manager');

    const getFiles = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/report-import')
      .then(function (response) {
        setFiles(response.data);
        reload(false);
      })
      .catch(function (error) {
        // console.log(error);
        
        // if (error && error.response && error.response.data) {
        //   addToast(error.response.data.message, { appearance: 'error' });
        // } else {
        //   addToast(error.message, { appearance: 'error' });
        // }
      });
    }

    getFiles();

    const getAuditors = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/auditors?fields[]=id&fields[]=full_name&fields[]=business_mobile&fields[]=business_email&fields[]=private_email&fields[]=private_mobile&fields[]=profile&limit=-1')
      .then(function (response) {
        // console.log(response.data);

        let auditors = [];
        response.data.data.map((auditor, i) => auditors.push({
          label: auditor.full_name +' ('+ auditor.email+')',
          value: auditor.id,
        }));

        setAuditor(auditors);

        // console.log(auditors);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getAuditors();

    const getClients = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/clients?fields[]=id&fields[]=name&limit=-1')
      .then(function (response) {

        let clients = [];
        response.data.data.map((client, i) => clients.push({
          label: client.name,
          value: client.id,
        }));

        setClient(clients);

      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getClients();

    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      let url = activeTab2 === 1  ? '/contract-review-process' : activeTab2 === 2 ? '/certification-process' : (activeTab2 === 3 || activeTab2 === 4 ) ? '/auditor-application-process?type[]=' : activeTab2 ===6 ? '/report-authority' : '/';
        
      if(activeTab2 === 3 || activeTab2 === 4 ){
        let type = activeTab2 === 3 ? 'D1' : activeTab2 === 4 ? 'D2' :'';
        api.get(url + type)
        .then(function (response) {
       //   delete response.data.id;
          console.log(response)
          setData(response.data.data);
          //reload(false);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }
      else{
        api.get(url)
        .then(function (response) {
       //   delete response.data.id;
          console.log(response)
          setData(response.data.data);
          //reload(false);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }
    //  else{
       /*  api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;
          setData(response.data.data);
          reload(false);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        }); */
   //   }
      
    }

    getData();

  }, [load]);
  const handleTableChange = (type, { page, sizePerPage }) => {

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }
  const deleteRow = async (id) => {
    if (window.confirm('Delete this record?')) {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      let url = activeTab2 === 1 ? '/contract-review-process/' : activeTab2 === 2 ? '/certification-process/' : (activeTab2 === 3 || activeTab2 === 4 ) ? '/auditor-application-process/' : activeTab2 === 6 ? '/report-authority/' : '/';
      api.delete(url + id)
      .then(function (response) {
        // console.log(response);

        // setData(response.data.data);

        addToast('Record successfully deleted', { appearance: 'success' });

        reload(true);
      })
      .catch(function (error) {
        // console.log(error.response);
        addToast(error.response.data.message, { appearance: 'error' });
      });
    }
  }
  const deleteHandler = async (url) => {
    if (window.confirm("Delete?")) {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      console.log(url);
      api.delete(url)
      .then(function (response) {
        // console.log(response);

        addToast('Data successfully deleted', { appearance: 'success' });

        reload(true);
      })
      .catch(function (error) {
        // console.log(error.response);

        if (error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }
  }
  const colspanForLastRow = (cell, row, rowIndex, colIndex) => {
    if (rowIndex === 1) {
      return { colSpan: `2` }
    }
  }
  const hiddenColForLastRow = (cell, row, rowIndex, colIndex) => {
    if (rowIndex === 1) {
      return { hidden: true }
    }
  }
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Reports Import
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={path}>
                  Reports Import
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card invoice1">
            <div className="body">
            <Nav pills>
                  {[{
                    id: 1,
                    label: "Import",
                  },
                  {
                    id: 2,
                    label: "Reports",
                  },
                ].map((tab, i) => 
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab1 === tab.id })}
                        onClick={() => { toggle1(tab.id); }}
                      >
                        {tab.label}
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <hr />
                <TabContent activeTab={activeTab1}>
                  
                  <TabPane tabId={1}>
                  <Formik
                    enableReinitialize={true}
                    initialValues={INITIAL_VALUE}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                    let token = await localStorage.getItem('auth-token');

                    token = JSON.parse(token);

                    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                    var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

                      await api({
                        method: 'POST',
                        url: '/report-import',
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                        data: data,
                      })
                      .then(function (response) {
                        // console.log(response);

                        addToast('Document uploaded successfully saved', { appearance: 'success' });

                       // setData(INITIAL_VALUE);

                      // resetForm({});

                        reload(true);
                      })
                      .catch(function (error) {
                        addToast(error.message, { appearance: 'error' });
                      });
                    }}
                  >
                  {({ isSubmitting, values, setFieldValue, handleChange }) => (
                    <Form>
                      <FormGroup>
                        <InputGroup>
                          <div className="custom-file">
                            <Input 
                              // name="document"
                              type="file" 
                              className="custom-file-input"
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              onChange={(event) => {
                                setFieldValue("file", event.currentTarget.files[0]);

                                setFieldValue("filename", event.currentTarget.files[0].name);
                              }}
                            />
                            <Label className="custom-file-label">{values.filename}</Label>
                          </div>
                          <InputGroupAddon addonType="append">
                            <Button>
                            {isSubmitting ? 'Uploading...' : 'Upload'}
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Form>
                  )}
                  </Formik>
          
                  <div className="row clearfix">
                    {files && files.length ? files.map((file, i) => 
                      <div class="col-lg-3 col-md-4 col-sm-12">
                      <div className="card">
                        <div className="file">
                          <a 
                            href="/"
                            // href={`{${process.env.REACT_APP_API_URL}api/public/dashboard/view/`} 
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <div className="hover">
                              <Button 
                                outline
                                // color="danger" 
                                type="button"
                                onClick={() => {
                                  let id = new Buffer.from(file).toString('Base64');
                                  window.open(`${process.env.REACT_APP_API_URL}/report-import/view/?file=${id}`);
                                }}
                              >
                                <i className="icon-eye"></i>
                              </Button>{' '}
                              <Button 
                                color="danger" 
                                type="button"
                                onClick={() => {
                                  // console.log(cell);
                                  let id = new Buffer.from(file).toString('Base64');

                                  deleteHandler('/report-import/' + id );
                                }}
                              /*  onClick={() => {
                                  if (window.confirm("Delete?")) {
                                    
                                  }
                                }} */
                              >
                                <i className="icon-trash"></i>
                              </Button>
                            </div>
                            <div className="icon">
                              <i className="fa fa-file text-info"></i>
                            </div>
                            <div className="file-name">
                              <p className="m-b-5 text-muted">
                                {file.replace(/report_import\//, '')}
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                      </div>
                    ) : null}
                  </div>  
                  </TabPane>

                  <TabPane tabId={2}>
                    <FormGroup>
                      {/* <Label for="Status">Show QR Code for: </Label> */}
                      <div>
                      <ButtonGroup toggle className="mb-4">
                      {[{
                          id: 1,
                          label: "CR(9)",
                        },
                        {
                          id: 2,
                          label: "CAP (9)",
                        },
                        {
                          id: 3,
                          label: "Auditor Application (D1)",
                        },
                        {
                          id: 4,
                          label: "Auditor Application (D2)",
                        },
                        {
                          id: 5,
                          label: "Performance Monitoring",
                        },
                        {
                          id: 6,
                          label: "Data Reporting To Authority",
                        },
                      ].map((tab, i) => 

                        <ToggleButton 
                          type="radio" 
                          name="sheet"
                          value={tab.id}
                          onChange={() => {
                            setCategory('{tab.id}');
                            toggle2(tab.id);
                         //   alert('Tab No'+tab.id);
                            reload(true);
                          }}
                          checked={category === tab.id}
                        >
                           {tab.label}
                        </ToggleButton>

                        )}
                      </ButtonGroup>
                      </div>
                    </FormGroup>
                   
                    
                  {activeTab2 !== 6 ?
                    <div className="table">

                      <Label for="table">{activeTab2 === 1 ? 'CR(9)' : activeTab2 === 2 ? 'CAP (9)' : activeTab2 === 3 ? 'Auditor Application (D1)' : activeTab2 === 4 ? 'Auditor Application (D2)' : 'Performance Monitoring'} </Label>
                      <BootstrapTable 
                        //remote={true}
                        bootstrap4
                        bordered={false}
                        classes="table-hover js-basic-example dataTable table-custom m-b-0"
                        headerClasses="thead-dark"
                        keyField='id' 
                        data={ data } 
                        pagination={ 
                          paginationFactory(pagingInfo)
                        }
                        columns = {[
                          {
                            editable: false,
                            text: "No",
                            formatter: (cell, row, rowIndex) => {
                             let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
                              return <span>{rowNumber}.</span>;
                            }
                          },
                          {
                            headerAttrs : activeTab2 === 6 ? colspanForLastRow() : '',
                            editable: true,
                            dataField: (activeTab2 === 1 || activeTab2 === 2) ? "client_id" : (activeTab2 === 3 || activeTab2 === 4 ) ? "auditor_id": "",
                            text: (activeTab2 === 1 || activeTab2 === 2) ? "Found Client **Please update if the displayed name isn't correct" : (activeTab2 === 3 || activeTab2 === 4 ) ? "Found Auditor **Please update if the displayed name isn't correct":  "",
                            formatter: (cell, row , rowIndex) => {
                              if(activeTab2 === 1 || activeTab2 === 2){
                                 return row.client && row.client.name ? row.client.name : '**Click to update**';
                              } 
                              if(activeTab2 === 3 || activeTab2 === 4){
                                return (
                                  <div className="editable form-control-plaintext">
                                    {row.auditor && row.auditor.full_name ? row.auditor.full_name : '**Click to update**'}&nbsp;
                                  </div>
                                )
                              }
                              
                            },
                            style: {
                              whiteSpace: "pre-wrap"
                            },
                              editor: {
                              type: (activeTab2 === 1 || activeTab2 === 2 || activeTab2 === 3 || activeTab2 === 4 ) ? Type.SELECT :'',
                              options:  (activeTab2 === 1 || activeTab2 === 2) ? clients : (activeTab2 === 3 || activeTab2 === 4) ? auditors : ''
                            },
                            footer: () => {
                              if(activeTab2 === 1 || activeTab2 === 2 || activeTab2 === 3 || activeTab2 === 4){ 
                              return (
                                <Select
                                  name={activeTab2 === 1 || activeTab2 === 2 ? "clients" : activeTab2 === 3 || activeTab2 === 4 ? "auditors" : ""}
                                  placeholder=""
                                  openMenuOnFocus={true}
                                  options={activeTab2 === 1 || activeTab2 === 2 ? clients : activeTab2 === 3 || activeTab2 === 4 ? auditors : null} 
                                  styles={{
                                    control: (provided, state) => ({
                                      ...provided,
                                      color: '#495057',
                                      backgroundColor: 'transparent', 
                                      borderColor: '#ced4da !important', 
                                      boxShadow: 'none',
                                      outline: 'none',
                                    })
                                  }}
                                />
                              )
                            }
                            },
                            },
                          {
                          editable: false,
                          text: (activeTab2 === 1 || activeTab2 === 2) ? "Client Name" : (activeTab2 === 3 || activeTab2 === 4 ) ? "Applicant Name ": "",
                          formatter: (cell, row , rowIndex) => {
                            if(activeTab2 === 1 || activeTab2 === 2){
                              return row.client_name ? row.client_name : "";
                            }
                            if(activeTab2 === 3 || activeTab2 === 4){
                              return row.auditor_name ? row.auditor_name : "";
                            }
                          },
                        }, {
                          editable: false,
                          text: activeTab2 === 1 ? "Date of Received Application" : activeTab2 === 2 ? "CAP": (activeTab2 === 3 || activeTab2 === 4 ) ? "Email address": "",
                          formatter: (cell, row, rowIndex) => {
                            if(activeTab2 === 1){
                              return row.receive_application_date ? moment(row.receive_application_date).format('DD/MM/YYYY') : ""
                            }
                            if(activeTab2 === 2){
                              return row.operation_review_id
                            }
                            if(activeTab2 === 3 || activeTab2 === 4){
                              return row.auditor_email ? row.auditor_email : "";
                            }
                           
                          }
                        }, {
                          editable: false,
                          text: activeTab2 === 1 ? "Date inform documents are incomplete - 1 day" : activeTab2 === 2 ? "Date of Panel Replied CAP" : (activeTab2 === 3 || activeTab2 === 4 ) ? "Date of Received Application ": "",
                          formatter: (cell, row, rowIndex) => {
                            if(activeTab2 === 1){
                              return row.inform_incomplete_date ? moment(row.inform_incomplete_date).format('DD/MM/YYYY') : ""
                            }
                            if(activeTab2 === 2){
                              return row.panel_replied_date ? moment(row.panel_replied_date).format('DD/MM/YYYY') : ""
                            }
                            if(activeTab2 === 3 || activeTab2 === 4){
                              return row.received_application_date ? moment(row.received_application_date).format('DD/MM/YYYY') : ""
                            }
                            
                          }
                        },{
                          editable: false,
                          text: activeTab2 === 1 ? "Date Received Complete Application" : activeTab2 === 2 ? "Date Submit CAP Claim": (activeTab2 === 3 || activeTab2 === 4 ) ? "Date of Respond Received Application (24h)":  "",
                          formatter: (cell, row, rowIndex) => {
                            if(activeTab2 === 1){
                              return row.receive_completed_application_date ? moment(row.receive_completed_application_date).format('DD/MM/YYYY') : ""
                            }
                            if(activeTab2 === 2){
                              return row.submit_claim_date ? moment(row.submit_claim_date).format('DD/MM/YYYY') : ""
                            }
                            if(activeTab2 === 3 || activeTab2 === 4){
                              return row.respond_received_application_date ? moment(row.respond_received_application_date).format('DD/MM/YYYY') : ""
                            }
                            
                          }
                        },{
                          editable: false,
                          text: activeTab2 === 1 ?  "Date of Complete" : activeTab2 === 2 ? "Date Issuance of ICAR": (activeTab2 === 3 || activeTab2 === 4 ) ? "Date Received Complete Application": "",
                          formatter: (cell, row, rowIndex) => {
                            if(activeTab2 === 1){
                              return row.completion_date ? moment(row.completion_date).format('DD/MM/YYYY') : ""
                            }
                            if(activeTab2 === 2){
                              return row.issuance_icar_date_start ? moment(row.issuance_icar_date_start).format('DD/MM/YYYY') : ""
                            }
                            if(activeTab2 === 3 || activeTab2 === 4){
                              return row.received_complete_application_date ? moment(row.received_complete_application_date).format('DD/MM/YYYY') : ""
                            }   
                            
                          }
                        },
                        {
                          editable: false,
                          text: activeTab2 === 2 ?  "To issue ICAR  to relevant units if receive any comment from CAP - within 1 day after receiving documents" : (activeTab2 === 3 || activeTab2 === 4 ) ? "Date of Complete Review notification to applicant and operation unit (3 working days)": "",
                          formatter: (cell, row, rowIndex) => {
                            if(activeTab2 === 2){
                              return row.issuance_icar_date_end ? moment(row.issuance_icar_date_end).format('DD/MM/YYYY') : ""
                            }
                            if(activeTab2 === 3 || activeTab2 === 4){
                              return row.complete_review_date ? moment(row.complete_review_date).format('DD/MM/YYYY') : ""
                            }
                           
                          }
                        },
                        {
                          editable: false,
                          text: activeTab2 === 2 ?  "Date of Draft Confimed" :  activeTab2 === 6 ? "Date Received Notification from ISO" : "",
                          formatter: (cell, row, rowIndex) => {
                            if(activeTab2 === 2){
                              return row.draft_confirmed_date ? moment(row.draft_confirmed_date).format('DD/MM/YYYY') : ""
                            }
                            
                          }
                        },
                        {
                          editable: false,
                          text: activeTab2 === 2 ?  "Date of Email Copy Original Certificate/Date of Submit Original Certificate to Operation" :  "",
                          formatter: (cell, row, rowIndex) => {
                            if(activeTab2 === 2){
                              return row.submission_cert_date ? moment(row.submission_cert_date).format('DD/MM/YYYY') : ""
                            }
                            
                          }
                        },
                        {
                          editable: false,
                          dataField: "id",
                            text: "",
                            formatter: (cell, row, rowIndex) => {
                              return (
                                <ButtonGroup size="sm">
                                  <Button outline color="danger" onClick={() => deleteRow(cell)}>
                                    <i className="icon-trash"></i>
                                  </Button>
                                </ButtonGroup>
                              )
                            },
                            style: { textAlign: 'right' }
                        }
                      
                      ]}
                        cellEdit={ 
                          cellEditFactory({ 
                            mode: "click",
                            blurToSave: true,
                            afterSaveCell: async (oldValue, newValue, row, column) => {
                              if (oldValue === null && newValue === "") return;

                              if (oldValue === newValue) return;

                              let token = await localStorage.getItem('auth-token');

                              token = JSON.parse(token);

                              api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                              let values = {
                                [column.dataField]: newValue
                              }
                              console.log(values);
                              let url = activeTab2 === 1 ? '/contract-review-process/' : activeTab2 === 2 ? '/certification-process/' : (activeTab2 === 3 || activeTab2 === 4 ) ? '/auditor-application-process/' : '/';

                              api.put(url + row.id, JSON.stringify(values), {
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                              })
                              .then(function (response) {
                                addToast('Row successfully updated', { appearance: 'success' });

                                reload(true);
                              })
                              .catch(function (error) {
                                addToast(error.message, { appearance: 'error' });
                              });
                            }
                          })
                        }
                      />
                    </div>
                      :
                    <div className="table">
                      <Label for="mpocc">Reporting to MPOCC, MSPO Trace</Label>

                      <BootstrapTable 
                        //remote={true}
                        bootstrap4
                        bordered={false}
                        classes="table-hover js-basic-example dataTable table-custom m-b-0"
                        headerClasses="thead-dark"
                        keyField='id' 
                        data={data ? data.filter(o => o.type == 'MPOCC') : []} 
                        pagination={ 
                          paginationFactory(pagingInfo)
                        }
                        columns = {[
                          {
                            editable: false,
                            text: "No",
                            formatter: (cell, row, rowIndex) => {
                             let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
                              return <span>{rowNumber}.</span>;
                            }
                          },
                          {
                            editable: true,
                            dataField:"Year",
                            formatter: (cell, row , rowIndex) => {
                                  return row.year;
                            },
                          },
                          {
                          editable: false,
                          text: "Month" ,
                          formatter: (cell, row , rowIndex) => {
                              return  row.month ?  row.month : "";
                          },
                        }, {
                          editable: false,
                          text: "Date Reported",
                          formatter: (cell, row, rowIndex) => {
                           
                              return row.start_date ? moment(row.start_date).format('DD/MM/YYYY') : ""
                          }
                        }, {
                          editable: false,
                          text: "KPI",
                          formatter: (cell, row, rowIndex) => {
                              return row.end_date ? moment(row.end_date).format('DD/MM/YYYY') : ""
                          }
                        },
                        {
                          editable: false,
                          dataField: "id",
                            text: "",
                            formatter: (cell, row, rowIndex) => {
                              return (
                                <ButtonGroup size="sm">
                                  <Button outline color="danger" onClick={() => deleteRow(cell)}>
                                    <i className="icon-trash"></i>
                                  </Button>
                                </ButtonGroup>
                              )
                            },
                            style: { textAlign: 'right' }
                        }
                      
                      ]}
                        cellEdit={ 
                          cellEditFactory({ 
                            mode: "click",
                            blurToSave: true,
                            afterSaveCell: async (oldValue, newValue, row, column) => {
                              if (oldValue === null && newValue === "") return;

                              if (oldValue === newValue) return;

                              let token = await localStorage.getItem('auth-token');

                              token = JSON.parse(token);

                              api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                              let values = {
                                [column.dataField]: newValue
                              }
                              console.log(values);
                              let url = '/report-authority/';

                              api.put(url + row.id, JSON.stringify(values), {
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                              })
                              .then(function (response) {
                                addToast('Row successfully updated', { appearance: 'success' });

                                reload(true);
                              })
                              .catch(function (error) {
                                addToast(error.message, { appearance: 'error' });
                              });
                            }
                          })
                        }
                      />   
                   
                    <Label for="dsm">Reporting via FM1101 to DSM</Label>
                    <BootstrapTable 
                        //remote={true}
                        bootstrap4
                        bordered={false}
                        classes="table-hover js-basic-example dataTable table-custom m-b-0"
                        headerClasses="thead-dark"
                        keyField='id' 
                        data={data ? data.filter(o => o.type == 'DSM') : []} 
                        pagination={ 
                          paginationFactory(pagingInfo)
                        }
                        columns = {[
                          {
                            editable: false,
                            text: "No",
                            formatter: (cell, row, rowIndex) => {
                             let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
                              return <span>{rowNumber}.</span>;
                            }
                          },
                          {
                            editable: true,
                            dataField:"Year",
                            formatter: (cell, row , rowIndex) => {
                                  return row.year;
                            },
                          },
                          {
                          editable: false,
                          text: "Month" ,
                          formatter: (cell, row , rowIndex) => {
                              return  row.month ?  row.month : "";
                          },
                        }, {
                          editable: false,
                          text: "Date Reported",
                          formatter: (cell, row, rowIndex) => {
                           
                              return row.start_date ? moment(row.start_date).format('DD/MM/YYYY') : ""
                          }
                        }, {
                          editable: false,
                          text: "Date Received Notification from DSM",
                          formatter: (cell, row, rowIndex) => {
                              return row.end_date ? moment(row.end_date).format('DD/MM/YYYY') : ""
                          }
                        },
                        {
                          editable: false,
                          dataField: "id",
                            text: "",
                            formatter: (cell, row, rowIndex) => {
                              return (
                                <ButtonGroup size="sm">
                                  <Button outline color="danger" onClick={() => deleteRow(cell)}>
                                    <i className="icon-trash"></i>
                                  </Button>
                                </ButtonGroup>
                              )
                            },
                            style: { textAlign: 'right' }
                        }
                      
                      ]}
                        cellEdit={ 
                          cellEditFactory({ 
                            mode: "click",
                            blurToSave: true,
                            afterSaveCell: async (oldValue, newValue, row, column) => {
                              if (oldValue === null && newValue === "") return;

                              if (oldValue === newValue) return;

                              let token = await localStorage.getItem('auth-token');

                              token = JSON.parse(token);

                              api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                              let values = {
                                [column.dataField]: newValue
                              }
                              console.log(values);
                              let url = '/report-authority/';

                              api.put(url + row.id, JSON.stringify(values), {
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                              })
                              .then(function (response) {
                                addToast('Row successfully updated', { appearance: 'success' });

                                reload(true);
                              })
                              .catch(function (error) {
                                addToast(error.message, { appearance: 'error' });
                              });
                            }
                          })
                        }
                      />

                    <Label for="iso">Reporting to ISO</Label>
                    <BootstrapTable 
                        //remote={true}
                        bootstrap4
                        bordered={false}
                        classes="table-hover js-basic-example dataTable table-custom m-b-0"
                        headerClasses="thead-dark"
                        keyField='id' 
                        data={data ? data.filter(o => o.type == 'ISO') : []} 
                        pagination={ 
                          paginationFactory(pagingInfo)
                        }
                        columns = {[
                          {
                            editable: false,
                            text: "No",
                            formatter: (cell, row, rowIndex) => {
                             let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
                              return <span>{rowNumber}.</span>;
                            }
                          },
                          {
                            editable: true,
                            dataField:"Year",
                            formatter: (cell, row , rowIndex) => {
                                  return row.year;
                            },
                          },
                          {
                          editable: false,
                          text: "Month" ,
                          formatter: (cell, row , rowIndex) => {
                              return  row.month ?  row.month : "";
                          },
                        }, {
                          editable: false,
                          text: "Date Reported",
                          formatter: (cell, row, rowIndex) => {
                           
                              return row.start_date ? moment(row.start_date).format('DD/MM/YYYY') : ""
                          }
                        }, {
                          editable: false,
                          text: "Date Received Notification from ISO",
                          formatter: (cell, row, rowIndex) => {
                              return row.end_date ? moment(row.end_date).format('DD/MM/YYYY') : ""
                          }
                        },
                        {
                          editable: false,
                          dataField: "id",
                            text: "",
                            formatter: (cell, row, rowIndex) => {
                              return (
                                <ButtonGroup size="sm">
                                  <Button outline color="danger" onClick={() => deleteRow(cell)}>
                                    <i className="icon-trash"></i>
                                  </Button>
                                </ButtonGroup>
                              )
                            },
                            style: { textAlign: 'right' }
                        }
                      
                      ]}
                        cellEdit={ 
                          cellEditFactory({ 
                            mode: "click",
                            blurToSave: true,
                            afterSaveCell: async (oldValue, newValue, row, column) => {
                              if (oldValue === null && newValue === "") return;

                              if (oldValue === newValue) return;

                              let token = await localStorage.getItem('auth-token');

                              token = JSON.parse(token);

                              api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                              let values = {
                                [column.dataField]: newValue
                              }
                              console.log(values);
                              let url = '/report-authority/';

                              api.put(url + row.id, JSON.stringify(values), {
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                              })
                              .then(function (response) {
                                addToast('Row successfully updated', { appearance: 'success' });

                                reload(true);
                              })
                              .catch(function (error) {
                                addToast(error.message, { appearance: 'error' });
                              });
                            }
                          })
                        }
                      />
                </div>
                  }
                    
                  </TabPane>
                </TabContent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportsImport;