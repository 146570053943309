import React, { useEffect, useState } from 'react';

import {
  Link,
  useHistory,
  // useLocation,
  useParams,
  // useRouteMatch,
} from 'react-router-dom';

// import {
//   InputGroup,
// } from 'react-bootstrap';

import {
  // ButtonGroup,
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import { useToasts } from 'react-toast-notifications';

import api from '../../utils/api';

import { objectToFormData } from 'object-to-formdata';

import DateRangePicker from 'react-bootstrap-daterangepicker';

import moment from 'moment';

const INITIAL_VALUES = {};

const Form = (props) => {
  let { id } = useParams();
  let history = useHistory();

  const [data, setData] = useState(INITIAL_VALUES);

  const [profile, setProfile] = useState({});

  const { addToast } = useToasts();

  let { readOnly }  = props;

  useEffect(() => {
    // console.log(props);
    
    const getClient = async (client_id) => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      
      await api.get('/clients/' + client_id)
      .then(function (response) {
        let oldData = {
          client_id: response.data.id,
          old_company_name: response.data.name,
          old_company_address: response.data.address_1,
          old_no_of_employees: 0,
          old_no_of_sites: 0,
          old_mr_name: response.data.management_representative_name,
          old_scope_of_certification: response.data.scope_of_certification,
          old_management_representative_name: response.data.management_representative_name,
          old_management_representative_designation: response.data.management_representative_designation,
          old_contact_person_name: response.data.contact_person_name,
          old_contact_person_email: response.data.contact_person_email,
          old_contact_person_position: response.data.contact_person_position,
          old_contact_person_phone: response.data.contact_person_phone,
        }

        setData(oldData);

      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          switch(error.response.status) {
            case 404:
              addToast(`Client Data ID #${id} does not exist`, { appearance: 'error' });
              history.push(props.path);
              break;

            default:
              addToast(error.response.data.message, { appearance: 'error' });
          }
        } else {
          addToast(error.message, { appearance: 'error' });
        }

        // reload(false);
      });
      
    }

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        setProfile(response.data);

        

        if (!id && response.data.client_id) {
         // console.log(response.data.client_id);
          getClient(response.data.client_id);
        }

        // if (!id) {
        //   if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'client_data.create') === "undefined") {
        //     history.push(props.path);
        //   }
        // } else {
        //   if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'client_data.update') === "undefined") {
        //     history.push(props.path);
        //   }
        // }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        await api.get('/client-data-details/' + id)
        .then(function (response) {
          setData(response.data);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error.response && error.response.data) {
            switch(error.response.status) {
              case 404:
                addToast(`Client Data ID #${id} does not exist`, { appearance: 'error' });
                history.push(props.path);
                break;

              default:
                addToast(error.response.data.message, { appearance: 'error' });
            }
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

        if (!id) {
          await api({
            method: 'POST',
            url: '/client-data-details',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          .then(function (response) {
            // console.log(response);

            addToast('Client data successfully saved', { appearance: 'success' });

            history.push(props.path);

            // window.location = '/client-application/iso';
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        } else {
          data.append('_method', 'PUT');

          await api({
            method: 'POST',
            url: '/client-data-details/' + id,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          // api.put('/application-forms/' + id, JSON.stringify(values), {
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          // })
          .then(function (response) {
            // console.log(response);

            addToast('Client data has been updated', { appearance: 'success' });

            history.push(props.path);

            // window.location = '/client-application/iso';
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
      <FormikForm>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>CLIENT DATA DETAILS</h2>
              </div>
              <div className="body">
                <div className="row clearfix">
                  <div className="col-md-4">

                  </div>
                  <div className="col-md-4">
                    <Label>Client data as per our record</Label>
                  </div>
                  <div className="col-md-4">
                    <Label>Current data (if different from what is stated)</Label>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <Label>Date</Label>
                  </div>
                  <div className="col-md-4">

                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                      <DateRangePicker 
                        // drops="up"
                        singleDatePicker
                        containerStyles={{ display: "block" }}
                        startDate={values.date && values.date.length ? moment(values.date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                        onApply={(event, picker) => { 
                          setFieldValue('date', picker.startDate.format('YYYY-MM-DD'));
                          // setFieldValue('date_of_audit_end', picker.endDate.format('YYYY-MM-DD'));

                          // setAuditStartDate(moment(picker.startDate));

                          // setAuditEndDate(moment(picker.endDate));
                        }}
                      >
                        <Input 
                          // disabled={values.close_notification_at && values.close_notification_at.length}
                          autoComplete="off"
                          readOnly={true}
                          type="text" 
                          // name="date_of_audit" 
                          // id="date"
                          // onChange={handleChange}
                          defaultValue={values.date ? moment(values.date).format('DD/MM/YYYY') : ""}
                        />
                      </DateRangePicker>
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <Label>Name of company</Label>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                      <Input 
                        readOnly={true}
                        type="text" 
                        name="old_company_name" 
                        // id="Postcode"
                        // onChange={handleChange}
                        // onChange={(e) => {
                        //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                        // }}
                        defaultValue={values.old_company_name}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="new_company_name" 
                      // id="Postcode"
                      onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.new_company_name}
                    />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <Label>Address</Label>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={true}
                      type="textarea" 
                      name="old_company_address" 
                      // id="Postcode"
                      // onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.old_company_address}
                    />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={readOnly}
                      type="textarea" 
                      name="new_company_address" 
                      // id="Postcode"
                      onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.new_company_address}
                    />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <Label>No. of employees</Label>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={true}
                      type="text" 
                      name="old_no_of_employees" 
                      // id="Postcode"
                      // onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.old_no_of_employees}
                    />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="new_no_of_employees" 
                      // id="Postcode"
                      onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.new_no_of_employees}
                    />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <Label>No. of sites</Label>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={true}
                      type="text" 
                      name="old_no_of_sites" 
                      // id="Postcode"
                      // onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.old_no_of_sites}
                    />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="new_no_of_sites" 
                      // id="Postcode"
                      onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.new_no_of_sites}
                    />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <Label>Name of management rep.</Label>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={true}
                      type="text" 
                      name="old_mr_name" 
                      // id="Postcode"
                      // onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.old_mr_name}
                    />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="new_mr_name" 
                      // id="Postcode"
                      onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.new_mr_name}
                    />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <Label>Scope of certification</Label>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={true}
                      type="textarea" 
                      name="old_scope_of_certification" 
                      // id="Postcode"
                      // onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.old_scope_of_certification}
                    />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={readOnly}
                      type="textarea" 
                      name="new_scope_of_certification" 
                      // id="Postcode"
                      onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.new_scope_of_certification}
                    />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <Label>Management Rep. Name</Label>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={true}
                      type="text" 
                      name="old_management_representative_name" 
                      // id="Postcode"
                      // onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.old_management_representative_name}
                    />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="new_management_representative_name" 
                      // id="Postcode"
                      onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.new_management_representative_name}
                    />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <Label>Management Rep. Designation</Label>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={true}
                      type="text" 
                      name="old_management_representative_designation" 
                      // id="Postcode"
                      // onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.old_management_representative_designation}
                    />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="new_management_representative_designation" 
                      // id="Postcode"
                      onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.new_management_representative_designation}
                    />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <Label>Contact Person Name</Label>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={true}
                      type="text" 
                      name="old_contact_person_name" 
                      // id="Postcode"
                      // onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.old_contact_person_name}
                    />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="new_contact_person_name" 
                      // id="Postcode"
                      onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.new_contact_person_name}
                    />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <Label>Contact Person Designation</Label>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={true}
                      type="text" 
                      name="old_contact_person_position" 
                      // id="Postcode"
                      // onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.old_contact_person_position}
                    />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="new_contact_person_position" 
                      // id="Postcode"
                      onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.new_contact_person_position}
                    />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <Label>Contact Person Email</Label>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={true}
                      type="text" 
                      name="old_contact_person_email" 
                      // id="Postcode"
                      // onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.old_contact_person_email}
                    />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="new_contact_person_email" 
                      // id="Postcode"
                      onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.new_contact_person_email}
                    />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <Label>Contact Person Contact No.</Label>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={true}
                      type="text" 
                      name="old_contact_person_phone" 
                      // id="Postcode"
                      // onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.old_contact_person_phone}
                    />
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup>
                    <Input 
                      readOnly={readOnly}
                      type="text" 
                      name="new_contact_person_phone" 
                      // id="Postcode"
                      onChange={handleChange}
                      // onChange={(e) => {
                      //   setFieldValue(e.currentTarget.name, e.currentTarget.value.slice(0, 6));
                      // }}
                      defaultValue={values.new_contact_person_phone}
                    />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-4">
                    {!readOnly ? 
                    <React.Fragment>
                    <button 
                      type="submit" 
                      className="btn btn-primary"
                      disabled={isSubmitting ? true : false}
                    >
                      {id ? 'Update' : 'Create'}
                    </button>&nbsp;
                    </React.Fragment>
                    : null}
                    <Button 
                      // className="btn btn-outline-secondary"
                      outline
                      color="secondary" 
                      tag={Link} 
                      to={props.path}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                
              </div>
            </Card>
          </div>
        </div>
      </FormikForm>
      )}
    </Formik>
  )
}

export default Form;