import React, { useState, useEffect } from 'react';

import {
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";

// import Menu from '../Menu';

import NCSBMenu from './NCSB';
import NCSBTopManagementMenu from './NCSBTopManagement';
import ClientMenu from './Client';
import AuditorMenu from './Auditor';
import PeerReviewPanelMenu from './PeerReviewPanel';
// import MetisMenu from 'react-metismenu';

import api from '../../utils/api';

const SidebarNavigation = (props) => {

  const [role, setRole] = useState('');

  const [user, setUser] = useState({});

  // const [content, setContent] = useState([
  //   {
  //     id: 1,
  //     icon: 'speedometer',
  //     label: <span>Dashboard</span>,
  //     to: '/',
  //   },
  //   {
  //     id: 2,
  //     icon: 'briefcase',
  //     label: <span>Technical</span>,
  //   },
  //   {
  //     id: 3,
  //     parentId: 2,
  //     label: <span>Client Application</span>,
  //   },
  //   {
  //     id: 4,
  //     parentId: 3,
  //     label: <span>ISO</span>,
  //     to: '/application-review/client-application/iso'
  //   },
  //   {
  //     id: 5,
  //     parentId: 3,
  //     label: <span>MSPO</span>,
  //     to: '/application-review/client-application/mspo'
  //   }
  // ]);

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      // api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me', null, {
        headers: {
          Authorization: 'Bearer ' + token.access_token,
        }
      })
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));
        console.log(response.data);
        setUser(response.data);

        setRole(response.data.roles[0].name.toLowerCase());
      })
      .catch(function (error) {
        
      });
    }

    getMe();
  }, []);

  return (
    <React.Fragment>
      

      <TabContent activeTab={1}>
        <TabPane tabId={1}>
          {/* <MetisMenu 
              content={content} 
              noBuiltInClassNames={true}
              iconNamePrefix="icon-"
              className="sidebar-nav"
              classNameContainer="main-menu metismenu"
              classNameLinkHasActiveChild="active"
            /> */}
            <nav className="sidebar-nav" style={{ marginBottom: '5em' }}>
              {role.includes('ncsb') && role !== 'ncsb top management' ? <NCSBMenu role={role} user={user} /> : null}
{/*               {(user.email === 'khairul.faris@nioshcert.com.my' && user.email === 'fadhil@nioshcert.com.my') ? <NCSBTopManagementMenu role={role} user={user} /> : null}
 */}              {role === 'ncsb top management' ? <NCSBTopManagementMenu role={role} /> : null}

              {role === 'auditor' ? <AuditorMenu user={user} /> : null}

              {role === 'client' ? <ClientMenu user={user} /> : null}

              {role === 'peer review panel' ? <PeerReviewPanelMenu role={role} /> : null}

            </nav>
        </TabPane>
      </TabContent>
    </React.Fragment>
  );
}

export default SidebarNavigation;