import React, { useEffect, useState } from "react";

import {
  Link,
  useHistory,
  // useLocation,
  useParams,
  // useRouteMatch,
} from 'react-router-dom';

import {
  Form as BootstrapForm,
  InputGroup,
 } from 'react-bootstrap';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import { useToasts } from 'react-toast-notifications';

import api from '../../utils/api';

//let DatePicker = require("reactstrap-date-picker");

import { objectToFormData } from 'object-to-formdata';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';

import DatePicker from 'reactstrap-date-picker';

// import SignatureCanvas from 'react-signature-canvas';
import SignaturePad from 'react-signature-pad-wrapper';

// import { Editor } from 'react-draft-wysiwyg';
// import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//import './styles.css';


const JUSTIFICATIONS = [
  'Very small site for number of employees (e.g., office complex only)',
  'Already certified by NIOSH Certification for another standard',
  'Repetitive tasks, High percentage of employees doing the same, simple tasks',
  'Already registered for this standard by another CB',
  'Combined audit with:',
  'Integrated Management System',
  'Non-design responsible',
  'Low risk processes',
  'Simple process',
  'Maturity of Management system',
];

const MD_ALLOCATIONS = [{
    'field': 'pre_transfer_review',
    'field2': 'pre_transfer_review_qty',
    'label': 'Pre-transfer Review/ Gap Analysis',
  }, {
    'field': 'stage_1_md',
    'field2': 'stage_1_qty',
    'label': 'Stage 1 : Initial Audit (ON-SITE recommended)',
  }, {
    'field': 'stage_2_md',
    'field2': 'stage_2_qty',
    'label': 'Stage 2 : Main Audit (ON-SITE)',
  }, {
    'field': 'surveillance_md',
    'field2': 'surveillance_qty',
    'label': 'Surveillance : (6-Monthly) OR (12-Monthly)',
  }, {
    'field': 'recertification_md',
    'field2': 'recertification_qty',
    'label': 'Re-Certification [2/3 (Stage 1 + Stage 2)]',
}];

const INITIAL_VALUES = {
  client: {},
  justifications: [],
  approved_at: "",
  reviewed_at: "",
};

let sigCanvas, sigCanvasApprover, sigCanvasReviewer;

const Form = (props) => {
  let { id } = useParams();
  let history = useHistory();
  // let { path } = useRouteMatch();

  // let location = useLocation();
  // let { from } = location.state || { from: { pathname: "/client-application" } };

  // const [clients, setClients] = useState([]);
  const [CERTIFICATIONS, setCertifications] = useState({});
  const [data, setData] = useState(INITIAL_VALUES);
  const [AUDITORS, setAuditor] = useState([]);
  const [NACE_CODES, setNaceCodes] = useState([]);
  const [USERS, setUser] = useState([{}]);

  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const [token, setToken] = useState('');

  const { addToast } = useToasts();

  let { readOnly, approval, review }  = props;

  useEffect(() => {
    // console.log(props);

    // const getClients = async () => {
    //   let token = await localStorage.getItem('auth-token');

    //   token = JSON.parse(token);

    //   api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    //   api.get('/clients')
    //   .then(function (response) {
    //     // console.log(response.data.data);

    //     setClients(response.data.data);
    //   })
    //   .catch((error) => {
    //     addToast(error.message, { appearance: 'error' });
    //   });
    // }

    // getClients();

    const getToken = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);
    }

    getToken();

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        // setProfile(response.data);

        if (!readOnly && response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'client.update') === "undefined") {
          history.push(props.path);
        }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getNaceCodes = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/nace-codes')
      .then(function (response) {
        // console.log(response.data);

        setNaceCodes(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getNaceCodes();

    const getAuditors = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/auditors?limit=-1')
      .then(function (response) {
        // console.log(response.data);

        setAuditor(response.data.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getAuditors();

    const getUsers = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/users?limit=-1')
      .then(function (response) {
        // console.log(response.data);

        setUser(response.data.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getUsers();

    const getCertifications = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/lookup/certifications')
      .then(function (response) {
        // console.log(response.data);

        setCertifications(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getCertifications();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        await api.get('/contract-reviews/' + id)
        .then(function (response) {
          // console.log(response.data);

          if (response.data.client.certifications) {
            let selectedCertifications = [];
            response.data.client.certifications.map((certification, key) => {
              return selectedCertifications.push(certification.id);
            });

            response.data.client.certifications = selectedCertifications;
          }

          if (response.data.client.nace_codes) {
            let selectedCodes = [];
            response.data.client.nace_codes.map((nace_code, key) => {
              return selectedCodes.push(nace_code.id);
            });

            response.data.client.nace_codes = selectedCodes;
          }

          if (response.data.auditors) {
            let selectedAuditor = [];
            response.data.auditors.map((auditor, key) => {
              return selectedAuditor.push(auditor.id);
            });

            response.data.auditors = selectedAuditor;
          }

          if (response.data.peer_reviewers) {
            let selectedAuditor = [];
            response.data.peer_reviewers.map((auditor, key) => {
              return selectedAuditor.push(auditor.id);
            });

            response.data.peer_reviewers = selectedAuditor;
          }

          if (response.data.certification_approval_panels) {
            let selectedAuditor = [];
            response.data.certification_approval_panels.map((auditor, key) => {
              return selectedAuditor.push(auditor.id);
            });

            response.data.certification_approval_panels = selectedAuditor;
          }

          // if (response.data.type === '1') {
          //   response.data.typeStr = 'ISO';
          // } else {
          //   response.data.typeStr = 'MSPO-SCCS';
          // }

          // response.data.manday_calculations = [{}];

          setData(response.data);

          // if (response.data.contract_review_signature && response.data.contract_review_signature.length > 0)
          if (response.data.contract_review_signature != null) {
            sigCanvas.fromDataURL(response.data.contract_review_signature);
          }

          if (sigCanvasApprover !== undefined && sigCanvasApprover !== null && response.data.approver_signature != null) {
            sigCanvasApprover.fromDataURL(response.data.approver_signature);
          }

          if (sigCanvasReviewer !== undefined && sigCanvasReviewer !== null && response.data.reviewer_signature != null) {
            sigCanvasReviewer.fromDataURL(response.data.reviewer_signature);
          }

          if (readOnly) {
            sigCanvas.off();
          }

          if (sigCanvasApprover !== undefined && sigCanvasApprover !== null && !approval) {
            sigCanvasApprover.off();
          }

          if (sigCanvasReviewer !== undefined && sigCanvasReviewer !== null && !review) {
            sigCanvasReviewer.off();
          }
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error.response) {
            switch(error.response.status) {
              case 404:
                addToast(`Contract review ID #${id} does not exist`, { appearance: 'error' });
                history.push(props.path);
                break;

              default:
                addToast(error.response.data.message, { appearance: 'error' });
            }
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        if (readOnly && !approval && !review) {
          return;
        }

        setFormSubmitting(true);

        // console.log(values);

        // const querystring = require('querystring');

        // console.log(JSON.stringify(values, null, 2));

        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        values.contract_review_signature = sigCanvas.toDataURL();

        if (sigCanvasApprover !== undefined && sigCanvasApprover !== null) {
          values.approver_signature = sigCanvasApprover.toDataURL();
        }

        if (sigCanvasReviewer !== undefined && sigCanvasReviewer !== null) {
          values.reviewer_signature = sigCanvasReviewer.toDataURL();
        }

        if (values.approver_signature != null) {
          values.approved_at = moment().format('YYYY-MM-DD HH:mm:ss');
        }

        if (values.reviewer_signature != null) {
          values.reviewed_at = moment().format('YYYY-MM-DD HH:mm:ss');
        }

        // delete values.client;
        
        values.iso_type = props.type;

        // if (values.approved_by && values.approved_by.id) {
        //   values.approved_by = values.approved_by.id;
        // }

        // if (values.reviewed_by && values.reviewed_by.id) {
        //   values.reviewed_by = values.reviewed_by.id;
        // }

        delete values.type;

        var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

        if (!id) {
          // switch (props.type) {
          //   default:
          //   case 'iso':
          //     values.type = 1;
          //     break;

          //   case 'mspo':
          //     values.type = 2;
          //     break;
    
          //   case 'mspo-sccs':
          //     values.type = 3;
          //     break;
          // }

          await api({
            method: 'POST',
            url: '/contract-reviews',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          // api.post('/contract-reviews', JSON.stringify(values), {
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          // })
          .then(function (response) {
            // console.log(response);

            addToast('Contract review successfully saved', { appearance: 'success' });

            history.push(props.path);

            // window.location = '/client-application/iso';
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });

            setFormSubmitting(false);
          });
        } else {
          data.append('_method', 'PUT');

          await api({
            method: 'POST',
            url: '/contract-reviews/' + id,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          // api.put('/contract-reviews/' + id, JSON.stringify(values), {
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          // })
          .then(function (response) {
            // console.log(response);

            addToast('Contract review has been updated', { appearance: 'success' });

            history.push(props.path);

            // window.location = '/client-application/iso';
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });

            setFormSubmitting(false);
          });
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
      <FormikForm>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>CONTRACT REVIEW FORM</h2>
              </div>
              <div className="body">
                {values.eligible_for_multisite_certification
                ?
                  <div className="alert alert-success" role="alert">Eligible for multi-site certification</div>
                : <div className="alert alert-danger" role="alert">Not eligible for multi-site certification</div>}

                <div className="row clearfix">
                  <div className="col-sm-8">
                    <FormGroup>
                      <Label for="QuotationReferenceNo">Quotation Reference No.</Label>
                      <Input 
                        readOnly={readOnly}
                        type="text" 
                        name="quotation_reference_no" 
                        id="QuotationReferenceNo"
                        onChange={handleChange}
                        value={values.quotation_reference_no}
                        // required
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-4">
                    <FormGroup>
                      <Label for="Date">Date</Label>
                      <DatePicker
                        autoComplete="off"
                        name="contract_review_date" 
                        id="Date"
                        value={values.contract_review_date}
                        onChange={date => setFieldValue('contract_review_date', moment(date).format('YYYY-MM-DD'))}
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix mb-4">
                  <div className="col-sm-12">
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="category"
                        value="New Application"
                        onClick={handleChange}
                        checked={values.category === 'New Application'}
                      />
                      <span>
                        <i></i> New Application
                      </span>
                    </label>
                  
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="category"
                        value="Ext. of Site/Scope"
                        onClick={handleChange}
                        checked={values.category === 'Ext. of Site/Scope'}
                      />
                      <span>
                        <i></i> Ext. of Site/Scope
                      </span>
                    </label>

                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="category"
                        value="Transfer"
                        onClick={handleChange}
                        checked={values.category === 'Transfer'}
                      />
                      <span>
                        <i></i> Transfer
                      </span>
                    </label>

                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="category"
                        value="Re-Certification"
                        onClick={handleChange}
                        checked={values.category === 'Re-Certification'}
                      />
                      <span>
                        <i></i> Re-Certification
                      </span>
                    </label>

                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="category"
                        value="Migration"
                        onClick={handleChange}
                        checked={values.category === 'Migration'}
                      />
                      <span>
                        <i></i> Migration
                      </span>
                    </label>
                  </div>
                </div>

                {props.type === 'ISO' ?
                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Standard">
                        Standard &nbsp; 
                        {/* <small 
                          className="form-text text-muted" 
                          style={{ display: "inline" }}
                        >
                          {props.type === 'ISO' ? '' : 'Please select'}
                        </small> */}
                      </Label>
                      {CERTIFICATIONS.length ? CERTIFICATIONS.map((CERTIFICATION, key) => 
                        values.client.certifications && values.client.certifications.indexOf(CERTIFICATION.id) !== -1 && CERTIFICATION.type === props.type ? 
                        <React.Fragment key={key}>
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                readOnly={readOnly}
                                type="checkbox" 
                                disabled={true}
                                // name={`certifications`}
                                // onChange={handleChange}
                                // onChange={() => {
                                //   if (values.typeStr !== 'ISO') {
                                //     let { certifications } = values;

                                //     if (certifications && certifications.length) {
                                //       var index = certifications.indexOf(CERTIFICATION.id);
                                //       if (index !== -1) {
                                //         certifications.splice(index, 1);
                                //       } else {
                                //         certifications.push(CERTIFICATION.id);
                                //       }
                                //     } else {
                                //       certifications = [];
                                //       certifications.push(CERTIFICATION.id);
                                //     }

                                //     setFieldValue('certifications', certifications);
                                //   }
                                // }}
                                // value={CERTIFICATION.id}
                                checked={values.client.certifications && values.client.certifications.length && values.client.certifications.indexOf(CERTIFICATION.id) !== -1} 
                              />
                              <span>
                                {CERTIFICATION.standard} 
                              </span>
                            </label>
                          </div>
                        </React.Fragment>
                        : null
                      ) : null}
                    </FormGroup>
                  </div>
                </div>
                : ""}

                {props.type !== 'ISO' ?
                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Standard">
                        Standard &nbsp; 
                        <small 
                          className="form-text text-muted" 
                          style={{ display: "inline" }}
                        >
                          {'Please select'}
                        </small>
                      </Label>
                      {[{
                        code: 'MSPO - Part 2',
                        standard: 'Malaysian Sustainable Palm Oil (MSPO) Part 2: General principles for independent smallholders (MS 2530-2:2013)',
                      },
                      {
                        code: 'MSPO - Part 3',
                        standard: 'Malaysian Sustainable Palm Oil (MSPO) Part 3: General principles for oil palm plantations and organized smallholders (MS 2530-3:2013)',
                      },
                      {
                        code: 'MSPO - Part 4',
                        standard: 'Malaysian Sustainable Palm Oil (MSPO) Part 4: General principles for palm oil mills (MS 2530-4:2013)',
                      },
                      {
                        code: 'MSPO - SCCS',
                        standard: 'MSPO Supply Chain Certification Standard: MSPO-SCCS-01',
                      }].map((CERTIFICATION, key) => 
                        <React.Fragment key={key}>
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                readOnly={readOnly}
                                disabled={readOnly}
                                type="checkbox" 
                                // disabled={true}
                                // name={`certifications`}
                                // onChange={handleChange}
                                onChange={() => {
                                  // if (values.typeStr !== 'ISO') {
                                    let { mspo } = values;

                                    if (mspo && mspo.length) {
                                      var index = mspo.indexOf(CERTIFICATION.code);
                                      if (index !== -1) {
                                        mspo.splice(index, 1);
                                      } else {
                                        mspo.push(CERTIFICATION.code);
                                      }
                                    } else {
                                      mspo = [];
                                      mspo.push(CERTIFICATION.code);
                                    }

                                    setFieldValue('mspo', mspo);
                                  // }
                                }}
                                value={CERTIFICATION.standard}
                                checked={values.mspo && values.mspo.length && values.mspo.indexOf(CERTIFICATION.code) !== -1} 
                              />
                              <span>
                                {CERTIFICATION.standard}
                              </span>
                            </label>
                          </div>
                        </React.Fragment>
                      )}
                    </FormGroup>
                    </div>
                </div>
                : ""}
                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="ClientName">Client Name</Label>
                      <Input 
                        readOnly={true}
                        type="text" 
                        // className="custom-select" 
                        // name="client.name" 
                        // id="ClientName"
                        // onChange={handleChange}
                        value={values.client && values.client.name ? values.client.name : null}
                      />
                        {/* <option></option>
                        {clients.map((client, key) => 
                          <option 
                            key={client.id} 
                            value={client.id}
                          >
                            {client.client_name}
                          </option>
                        )}
                      </Input> */}
                      {/* <Input 
                        readOnly={readOnly}
                        type="text" 
                        name="client_name" 
                        id="Postcode"
                        onChange={handleChange}
                        value={values.client_name}
                      /> */}
                    </FormGroup>
                    {/* {parseInt(clients.length)} */}
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Scope">Scope</Label>
                      <Input 
                        // readOnly={true}
                        type="textarea" 
                        name="client.scope_of_certification" 
                        id="Scope" 
                        onChange={handleChange}
                        value={values.client.scope_of_certification} 
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="TotalStaff">Total Staff</Label>
                      <Input 
                        readOnly={true}
                        type="number" 
                        // name="total_staffs" 
                        id="TotalStaff" 
                        // onChange={handleChange}
                        value={values.client && values.client.total_staffs ? values.client.total_staffs : null} 
                        min={0}
                        step={1}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="WorkingInShift">Working In Shift</Label>
                      <Input 
                        readOnly={true}
                        type="text" 
                        // name="working_in_shift" 
                        // id="WorkingInShift" 
                        // onChange={handleChange}
                        value={values.client && values.client.total_working_in_shifts ? values.client.total_working_in_shifts : null} 
                      />
                    </FormGroup>
                  </div>
                </div>

                {values.client.type === 'qeo' ?
                <div className="row clearfix">
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="EffectiveNoOfStaffs">Effective No. Of Staffs</Label>
                      <Input 
                        readOnly={readOnly}
                        type="number" 
                        name="effective_no_of_staffs" 
                        id="EffectiveNoOfStaffs" 
                        onChange={handleChange}
                        value={values.client && values.client.effective_no_of_staffs ? values.client.effective_no_of_staffs : null} 
                        min={0}
                        step={1}
                      />
                    </FormGroup>
                  </div>
                </div>
                : null}

                <div className="row clearfix">
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="NumberOfSites">No. Of Sites</Label>
                      <Input 
                        readOnly={true}
                        type="number" 
                        name="no_of_sites" 
                        id="NumberOfSites" 
                        onChange={handleChange}
                        value={values.no_of_sites} 
                        min={0}
                        step={1}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="WorkingNotInShift">Working Not In Shift</Label>
                      <Input 
                        readOnly={true}
                        type="text" 
                        // name="working_not_in_shift" 
                        // id="WorkingNotInShift" 
                        // onChange={handleChange}
                        value={values.client && values.client.total_working_not_in_shifts ? values.client.total_working_not_in_shifts : null} 
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="AccreditationBody">Accreditation Body</Label>
                      <Input 
                        readOnly={readOnly}
                        type="text" 
                        name="accreditation_body" 
                        id="AccreditationBody" 
                        onChange={handleChange}
                        value={values.accreditation_body} 
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="NoOfShifts">No. Of Shifts</Label>
                      <Input 
                        readOnly={true}
                        type="text" 
                        // name="no_of_shifts" 
                        // id="NoOfShifts" 
                        // onChange={handleChange}
                        value={values.client && values.client.no_of_shifts ? values.client.no_of_shifts : null} 
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="Complexity">Complexity</Label>
                      <FormGroup>
                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="complexity"
                            value="H"
                            onClick={handleChange}
                            checked={values.complexity === 'H'}
                          />
                          <span>
                            <i></i> High
                          </span>
                        </label>

                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="complexity"
                            value="M"
                            onClick={handleChange}
                            checked={values.complexity === 'M'}
                          />
                          <span>
                            <i></i> Medium
                          </span>
                        </label>

                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="complexity"
                            value="L"
                            onClick={handleChange}
                            checked={values.complexity === 'L'}
                          />
                          <span>
                            <i></i> Low
                          </span>
                        </label>
                      </FormGroup>
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="SectorCode">Sector Code</Label>
                      <Input 
                        readOnly={readOnly}
                        type="textarea" 
                        name="client.sector_code" 
                        id="SectorCode" 
                        onChange={handleChange}
                        value={values.client && values.client.sector_code ? values.client.sector_code : null} 
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="NaceCode">Nace Code</Label>
                      {/* <Input 
                        readOnly={readOnly}
                        type="text" 
                        name="client.nace_code" 
                        id="NaceCode" 
                        onChange={handleChange}
                        value={values.client && values.client.nace_code ? values.client.nace_code : null} 
                      /> */}
                      {/* <Input 
                        multiple
                        readOnly={readOnly}
                        type="select" 
                        className="custom-select" 
                        name="client.nace_code" 
                        id="NaceCode"
                        onChange={handleChange}
                        value={values.client.nace_code}
                      >
                        <option></option>
                        {NACE_CODES && NACE_CODES.map((NACE_CODE, i) => 
                          <option value={NACE_CODE.code}>{NACE_CODE.code} - {NACE_CODE.description}</option>
                        )}
                      </Input> */}
                      <div className="row">
                        <div className="col-sm-6">
                          {NACE_CODES.length ? NACE_CODES.map((NACE_CODE, i) =>
                            values.client.nace_codes && values.client.nace_codes.indexOf(NACE_CODE.id) !== -1 ? (
                            <React.Fragment key={i}>
                              <div className="fancy-checkbox">
                                <label>
                                  <Input 
                                    // readOnly={readOnly}
                                    disabled={true}
                                    type="checkbox" 
                                    // disabled={true}
                                    name={`client.nace_codes`}
                                    // onChange={handleChange}
                                    onChange={() => {
                                      // if (values.typeStr !== 'ISO') {
                                        let { nace_codes } = values.client;

                                        if (nace_codes && nace_codes.length) {
                                          var index = nace_codes.indexOf(NACE_CODE.id);
                                          if (index !== -1) {
                                            nace_codes.splice(index, 1);
                                          } else {
                                            nace_codes.push(NACE_CODE.id);
                                          }
                                        } else {
                                          nace_codes = [];
                                          nace_codes.push(NACE_CODE.id);
                                        }

                                        setFieldValue('client.nace_codes', nace_codes);
                                      // }
                                    }}
                                    // value={CERTIFICATION.id}
                                    checked={values.client.nace_codes && values.client.nace_codes.length && values.client.nace_codes.indexOf(NACE_CODE.id) !== -1} 
                                  />
                                  <span>
                                    {NACE_CODE.code} <span className="text-muted">- {NACE_CODE.description}</span>
                                  </span>
                                </label>
                              </div>
                            </React.Fragment>
                            ) : null
                          ) : null}
                        </div>
                        
                        {/* <div className="col-sm-6">
                          {NACE_CODES.length ? NACE_CODES.map((NACE_CODE, i) =>
                            i >= 20 ? (
                            <React.Fragment key={i}>
                              <div className="fancy-checkbox">
                                <label>
                                  <Input 
                                    readOnly={readOnly}
                                    disabled={readOnly}
                                    type="checkbox" 
                                    // disabled={true}
                                    name={`client.nace_codes`}
                                    // onChange={handleChange}
                                    onChange={() => {
                                      //if (values.typeStr !== 'ISO') {
                                        let { nace_codes } = values.client;

                                        if (nace_codes && nace_codes.length) {
                                          var index = nace_codes.indexOf(NACE_CODE.id);
                                          if (index !== -1) {
                                            nace_codes.splice(index, 1);
                                          } else {
                                            nace_codes.push(NACE_CODE.id);
                                          }
                                        } else {
                                          nace_codes = [];
                                          nace_codes.push(NACE_CODE.id);
                                        }

                                        setFieldValue('client.nace_codes', nace_codes);
                                      // }
                                    }}
                                    // value={CERTIFICATION.id}
                                    checked={values.client.nace_codes && values.client.nace_codes.length && values.client.nace_codes.indexOf(NACE_CODE.id) !== -1} 
                                  />
                                  <span>
                                    {NACE_CODE.code} <span className="text-muted">- {NACE_CODE.description}</span>
                                  </span>
                                </label>
                              </div>
                            </React.Fragment>
                            ) : null
                          ) : null}
                        </div> */}
                      </div>

                      {/* <BootstrapForm.Text className="text-muted">
                        Identify the lowest level NACE code
                      </BootstrapForm.Text> */}
                    </FormGroup>
                  </div>
                </div>

                {/* <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="AuditorInScope">Auditor In Scope</Label>
                      <Input 
                        readOnly={readOnly}
                        type="text" 
                        name="auditor_in_scope" 
                        id="AuditorInScope" 
                        onChange={handleChange}
                        value={values.auditor_in_scope} 
                      />
                    </FormGroup>
                  </div>
                </div> */}

                {/* <div className="row clearfix">
                  <div className="col-sm-12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>MD Allocation</th>
                          <th>Qty</th>
                        </tr>
                      </thead>
                      <tbody>
                        {MD_ALLOCATIONS.map((ALLOCATION, key) => 
                          <React.Fragment key={key}>
                            <tr key={key}>
                              <td>{ALLOCATION.label}</td>
                              <td>
                                <Input 
                                  readOnly={readOnly}
                                  type="text" 
                                  name={ALLOCATION.field}
                                  id={`MDAllocation${key}${ALLOCATION.field}`}
                                  onChange={handleChange}
                                  value={values[`${ALLOCATION.field}`]}
                                />
                              </td>
                              <td>
                                <Input 
                                  readOnly={readOnly}
                                  type="text" 
                                  name={ALLOCATION.field2}
                                  id={`MDAllocation${key}${ALLOCATION.field2}`}
                                  onChange={handleChange}
                                  value={values[`${ALLOCATION.field2}`]}
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div> */}

                <div className="row clearfix">
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="">Please indicate below on any 
                      reduction in Man day(s) reduction, justifications</Label>
                      <FormGroup>
                        {JUSTIFICATIONS.map((JUSTIFICATION, key) =>
                        <div className="fancy-checkbox" key={key}>
                          <label>
                            <Input 
                              readOnly={readOnly}
                              disabled={readOnly}
                              type="checkbox" 
                              name={`justifications.${key}`}
                              // id="MDAllocation" 
                              // onChange={handleChange}
                              onChange={() => {
                                let { justifications } = values;

                                if (justifications.length > 0) {
                                  var index = justifications.indexOf(JUSTIFICATION);
                                  if (index !== -1) {
                                    justifications.splice(index, 1);
                                  } else {
                                    justifications.push(JUSTIFICATION);
                                  }
                                } else {
                                  justifications.push(JUSTIFICATION);
                                }

                                setFieldValue('client.justifications', justifications);

                                if (!values.justifications.indexOf(JUSTIFICATION) !== -1) {
                                  setFieldValue('combined_audit', null);
                                }
                              }}
                              value={JUSTIFICATION}
                              checked={values.justifications ? values.justifications.indexOf(JUSTIFICATION) !== -1 : ""}
                            />
                            <span>
                              {JUSTIFICATION} 
                            </span>
                            
                          </label>
                          {JUSTIFICATION === 'Combined audit with:' ? 
                            <Input 
                            readOnly={readOnly ? true : (values.justifications && values.justifications.indexOf('Combined audit with:') !== -1 ? false : true)}
                              type="text"
                              className="ml-4" 
                              name="combined_audit"
                              onChange={handleChange}
                              style={{ display: "inline-block", width: "250px" }}
                              value={values.combined_audit}
                            /> 
                          : null}
                        </div>
                        )}
                      </FormGroup>
                    </FormGroup>
                  </div>
                  
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="JustificationsRemarks">Justifications Remarks</Label>
                      <Input 
                        readOnly={readOnly}
                        type="textarea" 
                        name="justifications_remarks" 
                        id="ScopeOfCertification" 
                        onChange={handleChange}
                        value={values.justifications_remarks} 
                        rows={5}
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    {/* Mandays Calculation: based on ACB – OPMC 2; medium risk due geographical 
                    factor access (travel by road not far by each operating units), already 
                    established appropriate system as per MSPO questionnaire & identified 
                    stakeholders pre-consulted). The sampling = 1.5√2 sites = 2 samples. 
                    As per questionnaire form calculation result. */}

                    <FormGroup>
                      <Label for="MandaysRemarks">Mandays Calculation Remarks</Label>
                      <Editor 
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        value={values.mandays_remarks}
                        
                        init={{
                          height: 300,
                          menubar: '', // 'file edit view insert format tools table tc help',
                          plugins: [
                            'lists table help'
                          ],
                          toolbar: 'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | table help',
                          paste_data_images: true,
                          content_style: "body { font-family: Arial; font-size: 10pt; }",
                        }}
                        onEditorChange={(content) => {
                          setFieldValue('mandays_remarks', content);
                        }}
                      />
                    </FormGroup>

                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th>Sites</th>
                          <th>Stages</th>
                          <th>Auditors</th>
                          <th>Days</th>
                          <th>Total Mandays</th>
                          <th>Travelling Allowance</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.manday_calculations && values.manday_calculations.map((CALCULATION, key) => 
                          <tr key={key}>
                            <td>
                              <Input 
                                readOnly={readOnly}
                                type="text" 
                                name={`manday_calculations.${key}.sites`}
                                // onChange={handleChange}
                                onChange={(event) => {
                                  setFieldValue(`manday_calculations.${key}.sites`, event.currentTarget.value);

                                  console.log(values);
                                }}
                                value={values.manday_calculations[key].sites}
                              />
                            </td>
                            <td>
                              <Input 
                                readOnly={readOnly}
                                type="text" 
                                name={`manday_calculations.${key}.stages`}
                                // onChange={handleChange}
                                onChange={(event) => {
                                  setFieldValue(`manday_calculations.${key}.stages`, event.currentTarget.value);
                                }}
                                value={values.manday_calculations[key].stages}
                              />
                            </td>
                            <td>
                              <Input 
                                readOnly={readOnly}
                                type="text" 
                                name={`manday_calculations.${key}.auditor`}
                                // onChange={handleChange}
                                onChange={(event) => {
                                  setFieldValue(`manday_calculations.${key}.auditor`, event.currentTarget.value);

                                  setFieldValue(`manday_calculations.${key}.total_mandays`, event.currentTarget.value * values.manday_calculations[key].days);
                                }}
                                value={values.manday_calculations[key].auditor}
                              />
                            </td>
                            <td>
                              <Input 
                                readOnly={readOnly}
                                type="text" 
                                name={`manday_calculations.${key}.days`}
                                // onChange={handleChange}
                                onChange={(event) => {
                                  setFieldValue(`manday_calculations.${key}.days`, event.currentTarget.value);

                                  setFieldValue(`manday_calculations.${key}.total_mandays`, event.currentTarget.value * values.manday_calculations[key].auditor);
                                }}
                                value={values.manday_calculations[key].days}
                              />
                            </td>
                            <td>
                              <Input 
                                readOnly={true}
                                type="text" 
                                name={`manday_calculations.${key}.total_mandays`}
                                // onChange={handleChange}
                                // onChange={(event) => {
                                //   setFieldValue(`manday_calculations.${key}.total_mandays`, event.currentTarget.value);
                                // }}
                                value={values.manday_calculations[key].total_mandays}
                              />
                            </td>
                            <td>
                              <Input 
                                readOnly={readOnly}
                                type="text" 
                                name={`manday_calculations.${key}.travelling_allowance`}
                                // onChange={handleChange}
                                onChange={(event) => {
                                  setFieldValue(`manday_calculations.${key}.travelling_allowance`, event.currentTarget.value);
                                }}
                                value={values.manday_calculations[key].travelling_allowance}
                              />
                            </td>
                            <td>
                              {!readOnly ?
                              <Button
                                type="button"
                                outline
                                color="danger"
                                onClick={() => {
                                  if (window.confirm('Delete this row?')) {
                                    let { manday_calculations } = values;

                                    delete manday_calculations[key];

                                    setFieldValue('manday_calculations', manday_calculations);
                                  }
                                }}
                              >
                                <i className="icon-trash"></i>
                              </Button>
                              : ""}
                            </td>
                          </tr>
                        )}
                        {!readOnly ?
                        <tr>
                          <td colSpan="7">
                            <Button 
                              block 
                              outline
                              onClick={(e) => {
                                e.preventDefault();

                                let { manday_calculations } = values;
                                if (!manday_calculations) {
                                  manday_calculations = [];
                                }
                                manday_calculations.push({});

                                // setData(data => {
                                //   return {...data, branches: branches}
                                // })

                                // console.log(branches);

                                setFieldValue('manday_calculations', manday_calculations);

                                // console.log(values);
                              }}
                            >
                              Add Row
                            </Button>
                          </td>
                        </tr>
                        : ""}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* {props.type === 'qeo' ?
                <div className="row clearfix">
                  <div className="col-sm-12">
                    <Editor />
                  </div>
                </div>
                : null} */}

              </div>
            </Card>

            <Card>
              <div className="header">
                <h2>THREAT TO IMPARTIALITY REVIEW</h2>
              </div>
              <div className="body">
                <div className="row clearfix pb-5">
                  <div className="col-sm-12">
                    Conflict of interests,  ownership, governance, management, 
                    personnel, shared recourses, consultant, finances, contracts, 
                    marketing, sales commission / inducement, Internal audit 
                    conducted by NIOSH Certification within 2 year period, 
                    common NIOSH Certification client, etc.
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="">Any relation to the above potential threat?</Label>
                      <FormGroup>
                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="relation_to_potential_threat"
                            value="1"
                            onClick={handleChange}
                            checked={parseInt(values.relation_to_potential_threat) === 1}
                          />
                          <span>
                            <i></i> Yes
                          </span>
                        </label>

                        <label className="fancy-radio">
                          <Input
                            type="radio"
                            name="relation_to_potential_threat"
                            value="0"
                            onClick={handleChange}
                            checked={parseInt(values.relation_to_potential_threat) === 0}
                          />
                          <span>
                            <i></i> No
                          </span>
                        </label>
                      </FormGroup>
                      <BootstrapForm.Text className="text-muted">
                        IF YES, risk assessment need to be activated.
                      </BootstrapForm.Text>
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="">Result of Risk Assessment</Label>
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Language">Language</Label>
                      <Input 
                        readOnly={readOnly}
                        type="text" 
                        name="risk_assessment_language"
                        id="Language" 
                        onChange={handleChange}
                        value={values.risk_assessment_language}
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="AuditReportLanguage">Audit Report</Label>
                      <Input 
                        readOnly={readOnly}
                        type="text" 
                        name="risk_assessment_audit_report"
                        id="AuditReportLanguage" 
                        onChange={handleChange}
                        value={values.risk_assessment_audit_report}
                      />
                    </FormGroup>
                  </div>
                </div>

                {values.type > 1 ?
                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="MSPOImplementation">MSPO Implementation</Label>
                      <Input 
                        readOnly={readOnly}
                        type="text" 
                        name="mspo_implementation"
                        id="MSPOImplementation" 
                        onChange={handleChange}
                        value={values.mspo_implementation}
                      />
                    </FormGroup>
                  </div>
                </div>
                : null}

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="OtherComment">Other/Comment</Label>
                      <Input 
                        readOnly={readOnly}
                        type="text" 
                        name="risk_assessment_others"
                        id="OtherComment" 
                        onChange={handleChange}
                        value={values.risk_assessment_others}
                      />
                    </FormGroup>
                  </div>
                </div>

              </div>
            </Card>

            <Card>
              <div className="header">
                <h2>TEAM MEMBER</h2>
              </div>
              <div className="body">
                <div className="row clearfix pb-5">
                  {/* <div className="col-sm-12">
                    <FormGroup>
                      <Label for="">Lead Auditor</Label>
                      <Input 
                        readOnly={readOnly}
                        type="select" 
                        className="custom-select" 
                        name="lead_auditor_id" 
                        id="LeadAuditor"
                        onChange={handleChange}
                        value={values.lead_auditor_id}
                      >
                        <option></option>
                        {AUDITORS && AUDITORS.map((AUDITOR, i) => 
                          <option value={AUDITOR.id}>{AUDITOR.full_name}</option>
                        )}
                      </Input>
                    </FormGroup>
                  </div> */}
                  

                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="">Auditor</Label>
                      {values.auditors && values.auditors ? values.auditors.map((MEMBER, key) => 
                        <InputGroup key={key} className="mb-3">
                          <Input 
                            readOnly={readOnly}
                            disabled={readOnly}
                            type="select" 
                            className="custom-select" 
                            name={`auditors.${key}`}
                            id="Auditors"
                            onChange={handleChange}
                            value={values.auditors[key]}
                          >
                            <option></option>
                            {AUDITORS && AUDITORS.map((AUDITOR, i) => 
                              <option value={AUDITOR.id}>{AUDITOR.full_name}</option>
                            )}
                          </Input>
                          {!readOnly ?
                          <InputGroup.Append>
                            <Button 
                              outline 
                              color="danger"
                              onClick={() => {
                                if (window.confirm('Remove auditor?')) {
                                  let { auditors } = values;

                                  delete auditors[key];

                                  setFieldValue('auditors', auditors);
                                }
                              }}
                            >
                              <i className="icon-trash"></i>
                            </Button>
                          </InputGroup.Append>
                          : ""}
                        </InputGroup>
                        ) : null}
                        {!readOnly ?
                        <Button 
                          block 
                          outline
                          onClick={(e) => {
                            e.preventDefault();

                            let { auditors } = values;
                            // if (!audit_team_members.length) {
                            //   audit_team_members = [];
                            // }
                            auditors.push([]);

                            setFieldValue('auditors', auditors);

                            // console.log(values);
                          }}
                        >
                          Add Auditor
                        </Button>
                        : ""}
                    </FormGroup>
                  </div>
                  {props.type !== 'ISO' ?
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="">Peer Reviewer</Label>
                      {values.peer_reviewers && values.peer_reviewers ? values.peer_reviewers.map((REVIEWER, key) => 
                        <InputGroup key={key} className="mb-3">
                          <Input 
                            readOnly={readOnly}
                            disabled={readOnly}
                            type="select" 
                            className="custom-select" 
                            name={`peer_reviewers.${key}`}
                            id="PeerReviewers"
                            onChange={handleChange}
                            value={values.peer_reviewers[key]}
                          >
                            <option></option>
                            {AUDITORS && AUDITORS.map((AUDITOR, i) => 
                              <option value={AUDITOR.id}>{AUDITOR.full_name}</option>
                            )}
                          </Input>
                          {!readOnly ?
                          <InputGroup.Append>
                            <Button 
                              outline 
                              color="danger"
                              onClick={() => {
                                if (window.confirm('Remove peer reviewer?')) {
                                  let { peer_reviewers } = values;

                                  delete peer_reviewers[key];

                                  setFieldValue('peer_reviewers', peer_reviewers);
                                }
                              }}
                            >
                              <i className="icon-trash"></i>
                            </Button>
                          </InputGroup.Append>
                          : ""}
                        </InputGroup>
                        ) : null}
                        {!readOnly ?
                        <Button 
                          block 
                          outline
                          onClick={(e) => {
                            e.preventDefault();

                            let { peer_reviewers } = values;
                            // if (!audit_team_members.length) {
                            //   audit_team_members = [];
                            // }
                            peer_reviewers.push([]);

                            setFieldValue('peer_reviewers', peer_reviewers);

                            // console.log(values);
                          }}
                        >
                          Add Peer Reviewer
                        </Button>
                        : ""}
                    </FormGroup>
                  </div>
                  : null}
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="">Certification Approval Panel</Label>
                      {values.certification_approval_panels && values.certification_approval_panels ? values.certification_approval_panels.map((PANEL, key) => 
                        <InputGroup key={key} className="mb-3">
                          <Input 
                            readOnly={readOnly}
                            disabled={readOnly}
                            type="select" 
                            className="custom-select" 
                            name={`certification_approval_panels.${key}`}
                            id="Panel"
                            onChange={handleChange}
                            value={values.certification_approval_panels[key]}
                          >
                            <option></option>
                            {AUDITORS && AUDITORS.map((AUDITOR, i) => 
                              <option value={AUDITOR.id}>{AUDITOR.full_name}</option>
                            )}
                          </Input>
                          {!readOnly ?
                          <InputGroup.Append>
                            <Button 
                              outline 
                              color="danger"
                              onClick={() => {
                                if (window.confirm('Remove certification approval panel?')) {
                                  let { certification_approval_panels } = values;

                                  delete certification_approval_panels[key];

                                  setFieldValue('certification_approval_panels', certification_approval_panels);
                                }
                              }}
                            >
                              <i className="icon-trash"></i>
                            </Button>
                          </InputGroup.Append>
                          : ""}
                        </InputGroup>
                        ) : null}
                        {!readOnly ?
                        <Button 
                          block 
                          outline
                          onClick={(e) => {
                            e.preventDefault();

                            let { certification_approval_panels } = values;
                            // if (!audit_team_members.length) {
                            //   audit_team_members = [];
                            // }
                            certification_approval_panels.push([]);

                            setFieldValue('certification_approval_panels', certification_approval_panels);

                            // console.log(values);
                          }}
                        >
                          Add Certification Approval Panel
                        </Button>
                        : ""}
                    </FormGroup>
                  </div>
                </div>
              </div>
            </Card>

            <Card>
              <div className="header">
                <h2>PREPARED BY</h2>
              </div>
              <div className="body">
                <div className="row clearfix pb-5">
                  <div className="col-sm-6">
                    <Label for="">Signature</Label>
                    {/* <SignatureCanvas 
                      canvasProps={{width: 600, height: 200, className: 'sigCanvas'}} 
                      ref={(ref) => { sigCanvas = ref }}
                      onEnd={() => {
                        values.contract_review_signature = sigCanvas.toDataURL();
                      }}
                    /> */}
                    <SignaturePad 
                      // width={500} 
                      // height={200}
                      ref={ref => sigCanvas = ref} 
                      // onEnd={() => {
                      //   values.declaration_signature = sigCanvas.toDataURL();
                      // }}
                    />
                    {!readOnly ?
                    <Button 
                      block 
                      outline
                      type="button"
                      onClick={() => {
                        sigCanvas.clear();

                        values.contract_review_signature = null;
                      }}>
                        Clear signature
                      </Button>
                      : ""}
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-8">
                    <FormGroup>
                      <Label for="PreparedBy">Prepared By</Label>
                      <Input 
                        readOnly={readOnly}
                        type="text" 
                        name="contract_review_prepared_by"
                        id="PreparedBy" 
                        onChange={handleChange}
                        value={values.contract_review_prepared_by}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-4">
                    <FormGroup>
                      <Label for="PreparedDate">Date</Label>
                      <DatePicker
                        autoComplete="off"
                        name="contract_review_prepared_date" 
                        id="PreparedDate"
                        value={values.contract_review_prepared_date}
                        onChange={date => setFieldValue('contract_review_prepared_date', moment(date).format('YYYY-MM-DD'))}
                      />
                    </FormGroup>
                  </div>
                </div>               
              </div>
            </Card>
            
            <Card>
              <div className="header">
                <h2>REVIEW</h2>
              </div>
              <div className="body">
                {!readOnly ?
                <React.Fragment>
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="ToBeApprovedBy">To be reviewed by</Label>
                        <Input 
                          readOnly={readOnly}
                          disabled={values.reviewed_at ? values.reviewed_at.length > 0 : ''}
                          type="select" 
                          className="custom-select" 
                          name="approver_id"
                          id="ToBeApprovedBy"
                          onChange={(e) => {
                            // console.log(e.target.name, e.target.value);

                            setFieldValue(e.target.name, e.target.value);
                            setFieldValue('approved_at', "");
                            setFieldValue('reviewed_at', "");
                          }}
                          value={values.approver_id}
                        >
                          <option></option>
                          {USERS && USERS.length ? USERS.map((USER, i) => 
                            <option value={USER.id}>{USER.name}</option>
                          ) : ""}
                        </Input>
                      </FormGroup>
                    </div>
                  </div>
                </React.Fragment>
                :
                <React.Fragment>
                  <div className="row clearfix pb-5">
                    <div className="col-sm-6">
                      <Label for="">Signature</Label>
                      {/* <SignatureCanvas 
                        canvasProps={{width: 600, height: 200, className: 'sigCanvas'}} 
                        ref={(ref) => { sigCanvas = ref }}
                        onEnd={() => {
                          values.contract_review_signature = sigCanvas.toDataURL();
                        }}
                      /> */}
                      <SignaturePad 
                        // width={500} 
                        // height={200}
                        ref={ref => sigCanvasApprover = ref} 
                        // onEnd={() => {
                        //   values.declaration_signature = sigCanvas.toDataURL();
                        // }}
                      />
                      {approval ?
                      <Button 
                        block 
                        outline
                        type="button"
                        onClick={() => {
                          if (sigCanvasApprover !== undefined) {
                            sigCanvasApprover.clear();

                            values.approver_signature = null;
                          }
                        }}>
                          Clear signature
                        </Button>
                        : ""}
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-sm-8">
                      <FormGroup>
                        <Label for="ToBeReviewedBy">
                          {values.approver_signature && values.approver_signature.length ? 'Reviewed by' : 'To be reviewed by'}
                        </Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          // className="custom-select" 
                          // name="reviewer_id"
                          // id="ToBeReviewedBy"
                          value={values.approved_by_user ? values.approved_by_user.name : ""}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-4">
                      <FormGroup>
                        <Label for="ToBeReviewedBy">Date</Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          // className="custom-select" 
                          // name="reviewer_id"
                          // id="ToBeReviewedBy"
                          value={values.approved_at && values.approved_at.length ? moment(values.approved_at).format('DD/MM/YYYY') : ""}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </React.Fragment>
                }

              </div>
            </Card>

            <Card>
              <div className="header">
                <h2>APPROVAL</h2>
              </div>
              <div className="body">
                {approval ?
                  <React.Fragment>

                  </React.Fragment>
                : ""}
                  
                {approval || !readOnly ?
                  <React.Fragment>
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="ToBeReviewedBy">To be approved by</Label>
                          <Input 
                            readOnly={readOnly}
                            disabled={values.reviewed_at ? values.reviewed_at.length > 0 : ''}
                            type="select" 
                            className="custom-select" 
                            name="reviewer_id"
                            id="ToBeReviewedBy"
                            onChange={(e) => {
                              // console.log(e.target.name, e.target.value);

                              setFieldValue(e.target.name, e.target.value);
                              setFieldValue('reviewed_at', "");
                            }}
                            value={values.reviewer_id}
                          >
                            <option></option>
                            {USERS && USERS.length ? USERS.map((USER, i) => 
                              <option value={USER.id}>{USER.name}</option>
                            ) : ""}
                          </Input>
                        </FormGroup>
                        <div className="row clearfix">
                  <div className="col-sm-6">
                    <FormGroup>
                      <Label for="">Decision to undertake the contract</Label>
                      <FormGroup>
                        <label className="fancy-radio">
                          <Input
                            disabled={readOnly}
                            type="radio"
                            name="decision_to_undertake_contract"
                            value="Y"
                            onClick={handleChange}
                            checked={values.decision_to_undertake_contract === 'Y'}
                          />
                          <span>
                            <i></i> YES
                          </span>
                        </label>

                        <label className="fancy-radio">
                          <Input
                            disabled={readOnly}
                            type="radio"
                            name="decision_to_undertake_contract"
                            value="N"
                            onClick={handleChange}
                            checked={values.decision_to_undertake_contract === 'N'}
                          />
                          <span>
                            <i></i> NO
                          </span>
                        </label>
                      </FormGroup>
                    </FormGroup>
                  </div>
                </div>

                {values.decision_to_undertake_contract === 'N' ? 
                  <div className="row clearfix">
                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="DecisionJustification">Please provide the justification</Label>
                        <Input 
                          readOnly={readOnly}
                          type="textarea" 
                          name="decision_justification" 
                          id="DecisionJustification" 
                          onChange={handleChange}
                          value={values.decision_justification} 
                          rows={5}
                        />
                      </FormGroup>
                    </div>
                  </div>
                : ""}
                      </div>
                    </div>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <div className="row clearfix pb-5">
                      <div className="col-sm-6">
                        <Label for="">Signature</Label>
                        {/* <SignatureCanvas 
                          canvasProps={{width: 600, height: 200, className: 'sigCanvas'}} 
                          ref={(ref) => { sigCanvas = ref }}
                          onEnd={() => {
                            values.contract_review_signature = sigCanvas.toDataURL();
                          }}
                        /> */}
                        <SignaturePad 
                          // width={500} 
                          // height={200}
                          ref={ref => sigCanvasReviewer = ref} 
                          // onEnd={() => {
                          //   values.declaration_signature = sigCanvas.toDataURL();
                          // }}
                        />
                        {review ?
                        <Button 
                          block 
                          outline
                          type="button"
                          onClick={() => {
                            if (sigCanvasReviewer !== undefined) {
                              sigCanvasReviewer.clear();

                              values.reviewer_signature = null;
                            }
                          }}>
                            Clear signature
                          </Button>
                          : ""}
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-8">
                      <FormGroup>
                        <Label for="ToBeReviewedBy">
                          {values.reviewer_signature && values.reviewer_signature.length ? 'Approved by' : 'To be approved by'}
                        </Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          // className="custom-select" 
                          // name="reviewer_id"
                          // id="ToBeReviewedBy"
                          value={values.reviewed_by_user ? values.reviewed_by_user.name : ""}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-4">
                      <FormGroup>
                        <Label for="ToBeReviewedBy">Date</Label>
                        <Input 
                          readOnly={readOnly}
                          type="text" 
                          // className="custom-select" 
                          // name="reviewer_id"
                          // id="ToBeReviewedBy"
                          value={values.reviewed_at && values.reviewed_at.length ? moment(values.reviewed_at).format('DD/MM/YYYY') : ""}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </React.Fragment>
                }

                <div className="row clearfix">
                  <div className="col-sm-4 mt-4">
                    {!readOnly || approval || review ?
                    <React.Fragment>
                    <button 
                      // type="submit" 
                      className="btn btn-primary"
                      disabled={isFormSubmitting ? true : false}
                    >
                      {id ? (isFormSubmitting ? 'Submitting...' : 'Submit') : 'Create'}
                    </button>&nbsp;
                    </React.Fragment>
                    : null}
                    <Button 
                      // className="btn btn-outline-secondary"
                      outline
                      color="secondary" 
                      tag={Link} 
                      to={props.path}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="col-sm-8 mt-4 text-right">
                      <button 
                        className="btn btn-outline-secondary" 
                        type="button"
                        onClick={() => {
                          window.open(`${process.env.REACT_APP_API_URL}/contract-reviews/view/report/${id}?token=${token}`);
                        }}
                      >
                        <i className="icon-printer"></i>
                      </button>&nbsp;
                  </div>
                </div>

              </div>
            </Card>

          </div>
          {/* <div class="col-sm-12">
            <div class="mt-4">
              
            </div>
          </div> */}
        </div>
      </FormikForm>
      )}
    </Formik>
  )
}

export default Form;