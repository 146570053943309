import React, { useState, useEffect } from "react";

import {
  Link,
  useParams,
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  // Input,
  Label,
  // Nav,
  // NavItem,
  // NavLink,
  // TabContent,
  // TabPane,
} from "reactstrap";

import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import BootstrapSwitchButton from "bootstrap-switch-button-react"

import { 
  Formik,
  Form as FormikForm
} from "formik";

// import moment from 'moment';

// import DatePicker from 'reactstrap-date-picker';

// import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import api from "../../../utils/api";

const Form = (props) => {
  let { id } = useParams();

  const { addToast } = useToasts();

  const [data, setData] = useState({});

  useEffect(() => {
    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;

          setData(response.data);

          // reload(false);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          // let token = await localStorage.getItem('auth-token');

          // token = JSON.parse(token);

          // api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

          // api.put('/audits/' + id, JSON.stringify(values), {
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          // })
          // .then(function (response) {
          //   addToast('Audit successfully updated', { appearance: 'success' });
          // })
          // .catch(function (error) {
          //   addToast(error.message, { appearance: 'error' });
          // });
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <Card>
                  <div className="header">
                    <h2>Audit Summary</h2>
                  </div>
                
                  <div className="body">
                    <FormGroup>
                      <Label>Organization Name</Label>
                      <p>{data.audit_plan && data.audit_plan.client && data.audit_plan.client.name ? data.audit_plan.client.name : ""}&nbsp;</p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Type Of Audit</Label>
                      <p>{data.audit_plan && data.audit_plan.stage ? data.audit_plan.stage : ''}&nbsp;</p>
                      </FormGroup>

                    <FormGroup>
                      <div className="table">
                        <BootstrapTable 
                          // remote={true}
                          bootstrap4
                          bordered={false}
                          classes="table-hover js-basic-example dataTable table-custom m-b-0"
                          headerClasses="thead-dark"
                          keyField="id"
                          data={data.document_reviews ? data.document_reviews : []} 
                          columns={[
                            {
                              dataField: "category",
                              text: "Category",
                              style: {
                                whiteSpace: "pre-wrap"
                              },
                            },
                            {
                              dataField: "document_review_item",
                              text: "Review Item",
                              style: {
                                whiteSpace: "pre-wrap"
                              },
                            },
                            {
                              dataField: "description",
                              text: "Findings",
                              style: {
                                whiteSpace: "pre-wrap"
                              },
                            },
                            {
                              dataField: "compliant",
                              text: "Compliance",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <BootstrapSwitchButton
                                    disabled={true}
                                    checked={parseInt(cell) === 1}
                                    onlabel={<i className="icon-check"></i>}
                                    offlabel={<i className="icon-close"></i>}
                                    offstyle="danger"
                                    // onChange={(checked: boolean) => {
                                        
                                    // }}
                                  />
                                )
                              },
                            },
                            {
                              dataField: "comment",
                              text: "Compliance Status",
                              style: {
                                whiteSpace: "pre-wrap"
                              },
                            }
                            // {
                            //   dataField: "document_review_item",
                            //   text: "Clauses",
                            //   style: {
                            //     whiteSpace: "pre-wrap"
                            //   },
                            // },
                            // {
                            //   dataField: "description",
                            //   text: "Description",
                            //   style: {
                            //     whiteSpace: "pre-wrap"
                            //   },
                            // },
                            // {
                            //   text: "Department/Activity/Process",
                            // },
                            // {
                            //   text: "",
                            // },
                          ]}
                          // pagination={ 
                          //   paginationFactory(pagingInfo)
                          // }
                          // onTableChange={handleTableChange}
                        />
                      </div>
                    </FormGroup>

                    <div className="row clearfix">
                      <div className="col-sm-4">
                        {/* <button type="submit" className="btn btn-primary">
                          Save
                        </button>&nbsp; */}
                        <Button 
                          // className="btn btn-outline-secondary"
                          outline
                          color="secondary" 
                          tag={Link} 
                          to={props.path}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                    
                  </div>
                </Card>
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
  )
}

export default Form;