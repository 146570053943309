import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
} from 'react-router-dom';

import { 
  Formik,
  Form
} from 'formik';

import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap"

import { useToasts } from 'react-toast-notifications';

import { objectToFormData } from 'object-to-formdata';

import SidebarToggler from '../../components/SidebarToggler';

import api from "../../utils/api";

const INITIAL_VALUE = {
  filename: "Choose file"
};

const Library = () => {
  let { path } = useRouteMatch();

  const { addToast } = useToasts();

  const [data, setData] = useState(INITIAL_VALUE);

  const [files, setFiles] = useState([]);

  const [load, reload] = useState(false);

  useEffect(() => {
    const element = document.querySelector("#main-content");
    
    element.classList.add('file_manager');

    const getFiles = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/library')
      .then(function (response) {
        // console.log(response);

        setFiles(response.data);

        reload(false);
      })
      .catch(function (error) {
        // console.log(error);
        
        // if (error && error.response && error.response.data) {
        //   addToast(error.response.data.message, { appearance: 'error' });
        // } else {
        //   addToast(error.message, { appearance: 'error' });
        // }
      });
    }

    getFiles();
  }, [load]);
  const deleteHandler = async (url) => {
    if (window.confirm("Delete?")) {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      console.log(url);
      api.delete(url)
      .then(function (response) {
        // console.log(response);

        addToast('Data successfully deleted', { appearance: 'success' });

        reload(true);
      })
      .catch(function (error) {
        // console.log(error.response);

        if (error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }
  }
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Library
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={path}>
                  Library
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Formik
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

          var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

          await api({
            method: 'POST',
            url: '/library',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          .then(function (response) {
            // console.log(response);

            addToast('Document uploaded successfully saved', { appearance: 'success' });

            setData(INITIAL_VALUE);

            resetForm({});

            reload(true);
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <Form>
            <FormGroup>
              <InputGroup>
                <div className="custom-file">
                  <Input 
                    // name="document"
                    type="file" 
                    className="custom-file-input"
                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint,image/png,image/x-png"
                    onChange={(event) => {
                      setFieldValue("file", event.currentTarget.files[0]);

                      setFieldValue("filename", event.currentTarget.files[0].name);
                    }}
                  />
                  <Label className="custom-file-label">{values.filename}</Label>
                </div>
                <InputGroupAddon addonType="append">
                  <Button>
                    Upload
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Form>
        )}
      </Formik>
      
      <div className="row clearfix">
        {files && files.length ? files.map((file, i) => 
          <div class="col-lg-3 col-md-4 col-sm-12">
          <div className="card">
            <div className="file">
              <a 
                href="/"
                // href={`{${process.env.REACT_APP_API_URL}api/public/library/view/`} 
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="hover">
                  <Button 
                    outline
                    // color="danger" 
                    type="button"
                    onClick={() => {
                      let id = new Buffer.from(file).toString('Base64');
                      window.open(`${process.env.REACT_APP_API_URL}/library/view/?file=${id}`);
                    }}
                  >
                    <i className="icon-eye"></i>
                  </Button>{' '}
                  <Button 
                    color="danger" 
                    type="button"
                    onClick={() => {
                      // console.log(cell);
                      let id = new Buffer.from(file).toString('Base64');

                      deleteHandler('/library/' + id );
                    }}
                   /*  onClick={() => {
                      if (window.confirm("Delete?")) {
                        
                      }
                    }} */
                  >
                    <i className="icon-trash"></i>
                  </Button>
                </div>
                <div className="icon">
                  <i className="fa fa-file text-info"></i>
                </div>
                <div className="file-name">
                  <p className="m-b-5 text-muted">
                    {file.replace(/libraries\//, '')}
                  </p>
                </div>
              </a>
            </div>
          </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Library;