import React, { useState, useEffect } from "react";

import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from "formik";

import classnames from 'classnames';

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import api from "../../../utils/api";

import moment from 'moment';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const INITIAL_PEER_REVIEW = {
  review_date: '',
  status_of_summary_report_comments: '',
  introduction_comments: '',
  scope_comments: '',
  information_of_audit_team_comments: '',
  management_commitment_comments: '',
  transparency_comments: '',
  compliance_to_legal_requirements_comments: '',
  social_responsibility_health_employee_comments: '',
  environment_natural_resources_comments: '',
  best_practices_comments: '',
  development_of_new_planting_comments: '',
  remarks: '',
}

const Form = (props) => {
  let { id } = useParams();

  let history = useHistory();

  const { addToast } = useToasts();

  const [load, setLoaded] = useState(false);

  const [data, setData] = useState({});

  const [peerReviewData, setPeerReviewData] = useState([]);

  const [CERTIFICATIONS, setCertifications] = useState({});
    
  const [auditors, setAuditor] = useState([]);

  const [reviewers, setReviewer] = useState([]);

  const [USERS, setUser] = useState([{}]);

  let { readOnly, assign, approval, review }  = props;

  const [activeTab, setActiveTab] = useState(0);

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        // setProfile(response.data);

        if (!readOnly && response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'peer_review.update') === "undefined") {
          history.push(props.path);
        }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getCertifications = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/lookup/certifications')
      .then(function (response) {
        // console.log(response.data);

        setCertifications(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getCertifications();

    const getAuditors = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/auditors?fields[]=id&fields[]=full_name&fields[]=business_mobile&fields[]=private_mobile&limit=-1')
      .then(function (response) {
        // console.log(response.data);

        let auditors = [];
        response.data.data.map((auditor, i) => auditors.push({
          label: auditor.full_name,
          value: auditor.id,
          contact_no: auditor.contact_no,
        }));

        setAuditor(auditors);

        // console.log(auditors);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getAuditors();

    const getReviewers = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/users?role=8&limit=-1')
      .then(function (response) {
         console.log(response.data);

        let reviewers = [];
        response.data.data.map((reviewer, i) => reviewers.push({
          label: reviewer.name,
          value: reviewer.id
        }));

        setReviewer(reviewers);

        // console.log(reviewers);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getReviewers();

    const getUsers = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/users?limit=-1')
      .then(function (response) {
        // console.log(response.data);

        setUser(response.data.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getUsers();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        await api.get('/operation-reviews/' + id)
        .then(function (response) {
          delete response.data.data.id;
          console.log(response.data.data);
          if (response.data.data.audit.audit_plan.client.certifications) {
            let selectedCertifications = [];
            response.data.data.audit.audit_plan.client.certifications.map((certification, key) => {
              return selectedCertifications.push(certification.id);
            });

            response.data.data.audit.audit_plan.client.certifications = selectedCertifications;
          }

          setData(response.data.data);

          /* if (response.data.peer_reviews) {
            response.data.peer_reviews.map((peer_review, i) => {
                peerReviewData[peer_review.peer_reviewer_id] = peer_review;
                setPeerReviewData(peerReviewData);
              }
            );
          }

          if (response.data.audit 
            && response.data.audit.audit_plan 
            && response.data.audit.audit_plan.client 
            && response.data.audit.audit_plan.client.contract_review 
            && response.data.audit.audit_plan.client.contract_review.peer_reviewers
            && response.data.audit.audit_plan.client.contract_review.peer_reviewers[0]) {
              toggle(response.data.audit.audit_plan.client.contract_review.peer_reviewers[0].id);
          }

          if (response.data.audit 
            && response.data.audit.audit_plan 
            && response.data.audit.audit_plan.client 
            && response.data.audit.audit_plan.client.contract_review 
            && response.data.audit.audit_plan.client.contract_review.peer_reviewers) {
              response.data.audit.audit_plan.client.contract_review.peer_reviewers.map((peer_reviewer, i) => {
                if (!peerReviewData[peer_reviewer.id]) {
                  INITIAL_PEER_REVIEW.operation_review_id = id;
                  INITIAL_PEER_REVIEW.peer_reviewer_id    = parseInt(peer_reviewer.id);
                  peerReviewData[peer_reviewer.id]        = INITIAL_PEER_REVIEW;
                  setPeerReviewData(peerReviewData);
                }
              })
          } */

          // console.log(peerReviewData);

          // reload(false);

          setLoaded(true);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data.data) {
            addToast(error.response.data.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }

          setLoaded(true);
        });
      }

      getData();
    }
  }, [id]);

  return (
    
  <div className="row clearfix">
    <div className="col-lg-12 col-md-12">

              <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        // if (props.review) {
        //   values.or_reviewed_at = moment().format('YYYY-MM-DD HH:mm:ss');
        // }

        // if (props.approval) {
        //   values.or_approved_at = moment().format('YYYY-MM-DD HH:mm:ss');
        // }
        values.action = "assigned"
        await api.put('/operation-reviews/' + id, JSON.stringify(values), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          addToast('Peer review successfully updated', { appearance: 'success' });
          history.push(props.path);
          //history.push(props.path + '?refresh=' + Date.now());
        })
        .catch(function (error) {
          if(error.message =='Network Error'){
            addToast('Peer review successfully updated', { appearance: 'success' });
            history.push(props.path);
          }
          else
          {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
        <FormikForm>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <Card>
                <div className="header">
                  <h2>Peer Review</h2>
                </div>
              
                <div className="body">
                <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="AuditNo">Audit No.</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="audit_no" 
                          id="AuditNo"
                          // onChange={handleChange}
                          value={values.audit && values.audit.audit_plan ? values.audit.audit_plan.audit_no : ''}
                          // required
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="ClientName">Client Name</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="client_name" 
                          id="ClientName"
                          // onChange={handleChange}
                          value={data.audit && data.audit.audit_plan && data.audit.audit_plan.client ? data.audit.audit_plan.client.name : ''}
                          // required
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="AuditStandards">Audit Standard(s)</Label>
                        {CERTIFICATIONS.length ? CERTIFICATIONS.map((CERTIFICATION, key) => 
                        data.audit && data.audit.audit_plan && data.audit.audit_plan.client.certifications && data.audit.audit_plan.client.certifications.indexOf(CERTIFICATION.id) !== -1 ? 
                        <React.Fragment key={key}>
                          <div className="fancy-checkbox">
                            <label>
                              <Input 
                                readOnly={true}
                                type="checkbox" 
                                disabled={true}
                                // name={`certifications`}
                                // onChange={handleChange}
                                // onChange={() => {
                                //   if (values.typeStr !== 'ISO') {
                                //     let { certifications } = values;

                                //     if (certifications && certifications.length) {
                                //       var index = certifications.indexOf(CERTIFICATION.id);
                                //       if (index !== -1) {
                                //         certifications.splice(index, 1);
                                //       } else {
                                //         certifications.push(CERTIFICATION.id);
                                //       }
                                //     } else {
                                //       certifications = [];
                                //       certifications.push(CERTIFICATION.id);
                                //     }

                                //     setFieldValue('certifications', certifications);
                                //   }
                                // }}
                                // value={CERTIFICATION.id}
                                checked={data.audit && data.audit.audit_plan && data.audit.audit_plan.client.certifications && data.audit.audit_plan.client.certifications.length && data.audit.audit_plan.client.certifications.indexOf(CERTIFICATION.id) !== -1} 
                              />
                              <span>
                                {CERTIFICATION.standard} 
                              </span>
                            </label>
                          </div>
                        </React.Fragment>
                         : null
                      ) : null}
                      </FormGroup>
                    </div>
                  </div>
                  
                  <div className="row clearfix">
                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="AuditType">Audit Type</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="type_of_audit" 
                          id="TypeOfAudit"
                          // onChange={handleChange}
                          value={data.audit && data.audit.audit_plan ? data.audit.audit_plan.stage : ''}
                          // required
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-6">
                      <FormGroup>
                        <Label for="AuditDate">Audit Date</Label>
                        <Input 
                          readOnly={true}
                          type="text" 
                          name="audit_date" 
                          id="AuditDate"
                          // onChange={handleChange}
                          value={data.audit && data.audit.audit_plan && data.audit.audit_plan.date_of_audit_start && data.audit.audit_plan.date_of_audit_end ? moment(data.audit.audit_plan.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(data.audit.audit_plan.date_of_audit_end).format('DD/MM/YYYY') : ""}
                          // required
                        />
                      </FormGroup>
                    </div>
                  </div>
                    
                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Name">Reviewer #1</Label>
                        <Input 
                            // readOnly={readOnly}
                            disabled={readOnly}
                            type="select" 
                            className="custom-select" 
                            name="peer_review_reviewer_id"
                            id=""
                            // onChange={(e) => {
                            //   // console.log(e.target.name, e.target.value);

                            //   setFieldValue(e.target.name, e.target.value);
                            //   // setFieldValue('reviewed_at', "");
                            // }}
                            onChange={handleChange}
                            value={values.peer_review_reviewer_id}
                            required={true}
                          >
                            <option></option>
                            {reviewers && reviewers.length ? reviewers.map((reviewer, i) => 
                              <option value={reviewer.value}>{reviewer.label}</option>
                            ) : ""}
                          </Input>
                        {/* <Input 
                          // readOnly={true}
                          type="text" 
                          name="technical_reviewer_name" 
                          id="Name"
                          // onChange={handleChange}
                          value={values.technical_reviewer_name}
                          // required
                        /> */}
                        {/* <Select
                          inputId="LeadAuditor"
                          name="lead_auditors"
                          // isMulti
                          placeholder=""
                          openMenuOnFocus={true}
                          options={auditors} 
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              color: '#495057',
                              backgroundColor: 'transparent', 
                              borderColor: '#ced4da !important', 
                              boxShadow: 'none',
                              outline: 'none',
                            })
                          }}
                          value={values.lead_auditors}
                          onChange={(selectedAuditors) => {
                            // console.log(auditors);

                            // let id = [];

                            // selectedAuditors.map((auditor, i) =>
                            //   id.push(auditor.value)
                            // );

                            setFieldValue('lead_auditors', selectedAuditors);
                          }}
                        /> */}
                        
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Name">Reviewer #2</Label>
                        <Input 
                            // readOnly={readOnly}
                            disabled={readOnly}
                            type="select" 
                            className="custom-select" 
                            name="peer_review_approver_id"
                            id=""
                            // onChange={(e) => {
                            //   // console.log(e.target.name, e.target.value);

                            //   setFieldValue(e.target.name, e.target.value);
                            //   // setFieldValue('reviewed_at', "");
                            // }}
                            onChange={handleChange}
                            value={values.peer_review_approver_id}
                            required={true}
                          >
                            <option></option>
                            {reviewers && reviewers.length ? reviewers.map((reviewer, i) => 
                              <option value={reviewer.value}>{reviewer.label}</option>
                            ) : ""}
                          </Input>
                        {/* <Input 
                          // readOnly={true}
                          type="text" 
                          name="technical_reviewer_name" 
                          id="Name"
                          // onChange={handleChange}
                          value={values.technical_reviewer_name}
                          // required
                        /> */}
                        {/* <Select
                          inputId="LeadAuditor"
                          name="lead_auditors"
                          // isMulti
                          placeholder=""
                          openMenuOnFocus={true}
                          options={auditors} 
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              color: '#495057',
                              backgroundColor: 'transparent', 
                              borderColor: '#ced4da !important', 
                              boxShadow: 'none',
                              outline: 'none',
                            })
                          }}
                          value={values.lead_auditors}
                          onChange={(selectedAuditors) => {
                            // console.log(auditors);

                            // let id = [];

                            // selectedAuditors.map((auditor, i) =>
                            //   id.push(auditor.value)
                            // );

                            setFieldValue('lead_auditors', selectedAuditors);
                          }}
                        /> */}
                        
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-4">
                      <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                      </button>&nbsp;
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        tag={Link} 
                        to={props.path}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                
                </div>
              </Card>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
    </div>
  </div>
        
  )
}

export default Form;