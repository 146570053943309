import React, { useState, useEffect } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import MetisMenu from 'metismenujs';

// import MetisMenu from 'react-metismenu';

// import MetisMenuLink from '../MetisMenuLink';

// import Menu from '../Menu';

// import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import api from '../../utils/api';

const NCSBMenu = (props) => {

  const { role } = props;
  const { user } = props;
  let { path } = useRouteMatch();
    console.log(user);
  // const content = [
  //   {
  //       icon: 'speedometer',
  //       label: 'Dashboard',
  //       to: '/',
  //   },
  //   {
  //       icon: 'briefcase',
  //       label: 'Technical',
  //       content: [
  //           {
  //               icon: 'icon-class-name',
  //               label: 'Client Application',
  //               to: '/application-review/client-application',
  //           },
  //       ],
  //   },
  // ];
  
  useEffect(() => {
     new MetisMenu(".metismenu");
  }, []);

  return (
    <React.Fragment>
      {/* <MetisMenu 
          content={content} 
          noBuiltInClassNames
          classNameContainer="main-menu metismenu"
          classNameItem=""
          classNameItemActive="active"
          classNameLink=""
          classNameLinkActive="active"
          iconNamePrefix="icon-"
          LinkComponent=<MetisMenuLink classNameActive="active" />
          activeLinkFromLocation
      /> */}
      <ul className="main-menu metismenu">
        <li className={path === '/dashboard' ? 'active' : null}>
          <Link to="/">
            <i className="icon-speedometer"></i><span>Dashboard</span>
          </Link>
        </li>
      {(user.email === 'khairul.faris@nioshcert.com.my' || user.email === 'fadhil@nioshcert.com.my' || user.email === 'asiah@nioshcert.com.my' || user.email === 'qimcs08@gmail.com') ? 
        <li className={path === '/technical' || path.includes('/analytics') || path.includes('/imports') ? 'active' : null}>
          <a href="#" className="has-arrow">
            <i className="icon-speedometer"></i><span>PIP Analytic</span>
          </a>
          <ul>
            <li className={path === '/technical' ? 'active' : null}>
              <Link to="/technical">
              Technical
              </Link>
            </li>
            <li className={path.includes('/analytics') ? 'active' : null}>
              <Link to="/analytics">
                Operation
              </Link>
            </li>
            <li className={path.includes('/imports') ? 'active' : null}>
              <Link to="/imports">
                Import Reports
              </Link>
            </li>
          </ul>
        </li>
        : null}
        <li className={path.includes('/application-review') ? 'active' : null}>
          <a href="#" className="has-arrow">
            <i className="icon-briefcase"></i><span>Technical</span>
          </a>
          <ul>
            <li className={path.includes('/application-review/client-application') ? 'active' : null}>
              <a href="#" className="has-arrow">
                Client Application
              </a>
              <ul>
                <li className={path.includes('/application-review/client-application/iso') ? 'active' : 'not-active'}>
                  <Link to="/application-review/client-application/iso">
                    ISO
                  </Link>
                </li>
                <li className={path.includes('/application-review/client-application/mspo') ? 'active' : null}>
                  <Link to="/application-review/client-application/mspo">
                    MSPO
                  </Link>
                </li>
                <li className={path.includes('/application-review/client-application/mspo-sccs') ? 'active' : null}>
                  <Link to="/application-review/client-application/mspo-sccs">
                    MSPO-SCCS
                  </Link>
                </li>
              </ul>
            </li>
            <li className={path.includes('/application-review/contract-review') ? 'active' : null}>
              <a href="#" className="has-arrow">
                Contract Review
              </a>
              <ul>
                <li className={path.includes('/application-review/contract-review/iso') ? 'active' : null}>
                  <Link to="/application-review/contract-review/iso">
                    ISO
                  </Link>
                </li>
                <li className={path.includes('/application-review/contract-review/mspo') ? 'active' : null}>
                  <Link to="/application-review/contract-review/mspo">
                    MSPO
                  </Link>
                </li>
                <li className={path.includes('/application-review/contract-review/mspo-sccs') ? 'active' : null}>
                  <Link to="/application-review/contract-review/mspo-sccs">
                    MSPO-SCCS
                  </Link>
                </li>
              </ul>
            </li>
            <li className={path.includes('/application-review/client-database') ? 'active' : null}>
              <Link to="/application-review/client-database">
                Client Database
              </Link>
            </li>
            <li className={path.includes('/application-review/client-data-details') ? 'active' : null}>
              <Link to="/application-review/client-data-details">
                Client Data
              </Link>
            </li>
            <li className={path.includes('/application-review/client-feedbacks') ? 'active' : null}>
              <Link to="/application-review/client-feedbacks">
                Client Feedback
              </Link>
            </li>
          </ul>
        </li>
        <li className={path.includes('/auditor') ? 'active' : null}>
          <a href="#" className="has-arrow">
            <i className="icon-users"></i><span>Auditor</span>
          </a>
          <ul>
            <li className={path.includes('/auditor/application-form') ? 'active' : null}>
              <Link to="/auditor/application-form">
                Application Form
              </Link>
            </li>
            <li className={path.includes('/auditor/competency-matrix') ? 'active' : null}>
              <Link to="/auditor/competency-matrix">
                Auditor Competency
              </Link>
            </li>
            <li className={path.includes('/auditor/auditor-evaluations') ? 'active' : null}>
              <Link to="/auditor/auditor-evaluations">
                Auditor Evaluation
              </Link>
            </li>
            <li className={path.includes('/auditor/auditor-database') ? 'active' : null}>
              <Link to="/auditor/auditor-database">
                Auditor Database
              </Link>
            </li>
            
              <li className={path.includes('/auditor/auditor-claim') ? 'active' : null}>
              <a href="#" className="has-arrow">
                <span>Auditor Claim</span>
              </a>
                <ul>
                <li className={path.includes('/auditor/auditor-claim') ? 'active' : null}>
                <Link to="/auditor/auditor-claim">
                  Auditor Claim List
                </Link>
                </li>
                <li className={path.includes('/auditor/auditor-claim/setting') ? 'active' : null}>
                <Link to="/auditor/auditor-claim-setting">
                  Auditor Claim Setting
                </Link>
                </li>
                </ul>
              </li>
            
            <li className={path.includes('/auditor/audit-log') ? 'active' : null}>
              <Link to="/auditor/audit-log">
                Audit Log
              </Link>
            </li>
            
          </ul>
        </li>
        <li className={path.includes('/audit-module') ? 'active' : null}>
          <a href="#" className="has-arrow">
            <i className="icon-check"></i><span>Audit</span>
          </a>
          <ul>
            <li className={path.includes('/audit-module/calendar') ? 'active' : null}>
              <Link to="/audit-module/calendar">
                Calendar
              </Link>
            </li>
            <li className={path.includes('/audit-module/audit-plan') ? 'active' : null}>
              <a href="#" className="has-arrow">
                Audit Plan
              </a>
              <ul>
                <li className={path.includes('/audit-module/audit-plan/iso/iso') ? 'active' : null}>
                  <Link to="/audit-module/audit-plan/iso/iso">
                    ISO
                  </Link>
                </li>
                <li className={path.includes('/audit-module/audit-plan/mspo/part-2') ? 'active' : null}>
                  <Link to="/audit-module/audit-plan/mspo/part-2">
                    MSPO Part 2
                  </Link>
                </li>
                <li className={path.includes('/audit-module/audit-plan/mspo/part-3') ? 'active' : null}>
                  <Link to="/audit-module/audit-plan/mspo/part-3">
                    MSPO Part 3
                  </Link>
                </li>
                <li className={path.includes('/audit-module/audit-plan/mspo/part-4') ? 'active' : null}>
                  <Link to="/audit-module/audit-plan/mspo/part-4">
                    MSPO Part 4
                  </Link>
                </li>
                <li className={path.includes('/audit-module/audit-plan/mspo-sccs/mspo-sccs') ? 'active' : null}>
                  <Link to="/audit-module/audit-plan/mspo-sccs/mspo-sccs">
                    MSPO SCCS
                  </Link>
                </li>
              </ul>
            </li>
            <li className={path.includes('/audit-module/audit-report') ? 'active' : null}>
              <a href="#" className="has-arrow">
                Audit Report
              </a>
              <ul>
                <li className={path.includes('/audit-module/audit-report/iso') ? 'active' : null}>
                  <Link to="/audit-module/audit-report/iso/iso">
                    ISO
                  </Link>
                </li>
                <li className={path.includes('/audit-module/audit-report/mspo') ? 'active' : null}>
                  <Link to="/audit-module/audit-report/mspo/part-2">
                    MSPO Part 2
                  </Link>
                </li>
                <li className={path.includes('/audit-module/audit-report/mspo') ? 'active' : null}>
                  <Link to="/audit-module/audit-report/mspo/part-3">
                    MSPO Part 3
                  </Link>
                </li>
                <li className={path.includes('/audit-module/audit-report/mspo') ? 'active' : null}>
                  <Link to="/audit-module/audit-report/mspo/part-4">
                    MSPO Part 4
                  </Link>
                </li>
                <li className={path.includes('/audit-module/audit-report/mspo-sccs') ? 'active' : null}>
                  <Link to="/audit-module/audit-report/mspo-sccs/mspo-sccs">
                    MSPO SCCS
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className={path.includes('/review') ? 'active' : null}>
          <a href="#" className="has-arrow">
            <i className="icon-book-open"></i><span>Review</span>
          </a>
          <ul>
            <li className={path.includes('/review/operation-review') ? 'active' : null}>
              <Link to="/review/operation-review">
                Operation Review
              </Link>
            </li>
            <li className={path.includes('/review/peer-review') ? 'active' : null}>
              <Link to="/review/peer-review">
                Peer Review
              </Link>
            </li>
            <li className={path.includes('/review/cap') ? 'active' : null}>
              <a href="#" className="has-arrow">
                Certification Approval Panel (CAP)
              </a>
              <ul>
                <li className={path.includes('/review/cap/iso') ? 'active' : null}>
                  <Link to="/review/cap/iso">
                    ISO
                  </Link>
                </li>
                <li className={path.includes('/review/mspo') ? 'active' : null}>
                  <Link to="/review/cap/mspo">
                    MSPO
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className={path.includes('/client-module') ? 'active' : null}>
          <a href="#" className="has-arrow">
            <i className="icon-calculator"></i><span>Revenue</span>
          </a>
          <ul>
            <li className={path.includes('/client-module/revenue') ? 'active' : null}>
              <Link to="/client-module/revenue">
                List
              </Link>
            </li>
          </ul>
        </li>
        <li className={path.includes('/boss-chart') ? 'active' : null}>
          <Link to="/boss-chart">
            <i className="icon-graph"></i><span>Boss Chart</span>
          </Link>
        </li>
        <li className={path.includes('/library') ? 'active' : null}>
          <Link to="/library">
            <i className="icon-notebook"></i><span>Library</span>
          </Link>
        </li>
        <li  className={path.includes('/meeting') ? 'active' : null}>
          <Link to="/meeting-notification">
            <i className="icon-bell"></i><span>Notification</span>
          </Link>
        </li>
        <li  className={path.includes('/user') ? 'active' : null}>
          <Link to="/user">
            <i className="icon-users"></i><span>Users</span>
          </Link>
        </li>
      </ul>
    </React.Fragment>
  );
}

export default NCSBMenu;