import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { 
  // useFormik, 
  Formik,
  Form as FormikForm,
  // FieldArray,
} from 'formik';

import { useToasts } from 'react-toast-notifications';

import moment from 'moment';

import api from '../../utils/api';

const View = (props) => {
  let { id } = useParams();

  let { path } = useRouteMatch();

  const [data, setData] = useState({});

  const { addToast } = useToasts();

  let history = useHistory();

  let [profile, setProfile] = useState({});

  let [token, setToken] = useState('');

  useEffect(() => {
    const getToken = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);
    }

    getToken();

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        setProfile(response.data);
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/boss-chart/' + id)
        .then(function (response) {
 
          // delete response.data.id;

          setData(response.data);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <Card>
          <div className="header">
            <h2>CLIENT LIST</h2>
          </div>
          <div className="body">
            <div className="row clearfix">
              <div className="col-sm-12">
                <FormGroup>
                  <Label for="Client">Client</Label>
                  <Input 
                    readOnly={true}
                    type="text" 
                    // name="audit_no" 
                    // id="AuditNo"
                    // onChange={handleChange}
                    defaultValue={data.audit && data.audit.audit_plan && data.audit.audit_plan.client ? data.audit.audit_plan.client.name : ""}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row clearfix">
              <div className="col-sm-12">
                <FormGroup>
                  <Label for="Scope">Scope Of Certification</Label>
                  <Input 
                    readOnly={true}
                    type="textarea" 
                    // name="audit_no" 
                    // id="AuditNo"
                    // onChange={handleChange}
                    defaultValue={data.audit && data.audit.audit_plan && data.audit.audit_plan.client ? data.audit.audit_plan.client.scope_of_certification : ""}
                    rows={5}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row clearfix">
              <div className="col-sm-12">
                <FormGroup>
                  <Label for="Scheme">Scheme(s)</Label>
                  <Input 
                    readOnly={true}
                    type="text" 
                    // name="audit_no" 
                    // id="AuditNo"
                    // onChange={handleChange}
                    defaultValue={data.audit && data.audit.audit_plan && data.audit.audit_plan.client && data.audit.audit_plan.client.certifications ? data.audit.audit_plan.client.certifications.map((cert, idx) => cert.standard) : null}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row clearfix">
              <div className="col-sm-12">
                <FormGroup>
                  <Label for="Stage">Stage</Label>
                  <Input 
                    readOnly={true}
                    type="text" 
                    // name="audit_no" 
                    // id="AuditNo"
                    // onChange={handleChange}
                    defaultValue={data.audit && data.audit.audit_plan ? data.audit.audit_plan.stage : ""}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row clearfix">
              <div className="col-sm-12">
                {data.technical_review_2_approved_at && data.technical_review_2_approved_at !== null ? 
                  <div className="timeline-item animated fadeIn faster green">
                    <span className="date">{moment(data.technical_review_2_approved_at).format('DD/MM/YYYY')}</span>
                    <h5>Chairman Acknowledgement</h5>
                    <div className="msg">
                      <button 
                        className="m-r-20 btn btn-outline-secondary" 
                        style={{ textDecoration: "none" }}
                        //tag={Link}
                        //to={`/audit-module/audit-report/${data.audit.audit_plan.type}/${data.audit.audit_plan.subtype}/view-draft-certificate/${data.audit.id}`}
                        //to={`${path.replace(/:type/, data.audit.audit_plan.type).replace(/:subtype/, data.audit.audit_plan.subtype)}/view-draft-certificate/${data.audit.id}`}
                        type="button"
                        onClick={() => {
                          window.open(`/audit-module/audit-report/${data.audit.audit_plan.type}/${data.audit.audit_plan.subtype}/view-draft-certificate/${data.audit.id}`);

                          // window.open(`${process.env.REACT_APP_API_URL}/audits/certificate/${data.audit.id}?token=${token}&view=boss_chart&signature=2`);
                        }}
                      >
                        <i className="icon-badge"></i> View Certificate
                      </button>&nbsp;
                      


                    </div>
                  </div>
                : null}

                {data.technical_review_1_approved_at && data.technical_review_1_approved_at !== null ? 
                  <div className="timeline-item animated fadeIn faster green">
                    <span className="date">{moment(data.technical_review_1_approved_at).format('DD/MM/YYYY')}</span>
                    <h5>CEO Approval</h5>
                    <div className="msg">
                      <button 
                        className="m-r-20 btn btn-outline-secondary" 
                        style={{ textDecoration: "none" }}
                        type="button"
                        onClick={() => {
                          window.open(`/audit-module/audit-report/${data.audit.audit_plan.type}/${data.audit.audit_plan.subtype}/view-draft-certificate/${data.audit.id}`);

                          // window.open(`${process.env.REACT_APP_API_URL}/audits/certificate/${data.audit.id}?token=${token}&view=boss_chart&signature=1`);
                        }}
                      >
                        <i className="icon-badge"></i> View Certificate
                      </button>&nbsp;
                    </div>
                  </div>
                : null}

                {data.technical_review_2_reviewed_at && data.technical_review_2_reviewed_at !== null ? 
                  <div className="timeline-item animated fadeIn faster green">
                    <span className="date">{moment(data.operation_review_approved_at).format('DD/MM/YYYY')} - {moment(data.technical_review_2_reviewed_at).format('DD/MM/YYYY')}</span>
                    <h5>CAP Review</h5>
                    <div className="msg">
                      <Button 
                        outline
                        tag={Link} 
                        to={`/review/cap/${data.audit.audit_plan.type}/view/${data.id}`}>
                        <i className="icon-doc"></i> View
                      </Button>&nbsp;
                      {data.audit.certificate_approved !== 1 ?
                      <button 
                        className="m-r-20 btn btn-outline-secondary" 
                        style={{ textDecoration: "none" }}
                        type="button"
                        onClick={() => {
                          window.open(`/audit-module/audit-report/${data.audit.audit_plan.type}/${data.audit.audit_plan.subtype}/view-draft-certificate/${data.audit.id}`);

                          // window.open(`${process.env.REACT_APP_API_URL}/audits/certificate/${data.audit.id}?token=${token}&view=boss_chart&signature=1`);
                        }}
                      >
                        <i className="icon-badge"></i> View Draft Certificate
                      </button>
                      : null}
                    </div>
                  </div>
                : null}

                {data.peer_review_approved_at && data.peer_review_approved_at !== null ? 
                  <div className="timeline-item animated fadeIn faster green">
                    <span className="date">{moment(data.peer_review_approved_at).format('DD/MM/YYYY')} - {moment(data.peer_review_approved_at).format('DD/MM/YYYY')}</span>
                    <h5>Peer Review</h5>
                    <div className="msg">
                      <Button 
                        outline
                        tag={Link} 
                        to={`/review/peer-review/view/${data.id}`} className="m-r-20">
                        <i className="icon-doc"></i> View
                      </Button>
                    </div>
                  </div>
                : null}

                {data.operation_review_approved_at && data.operation_review_approved_at !== null ? 
                  <div className="timeline-item animated fadeIn faster green">
                    <span className="date">{moment(data.created_at).format('DD/MM/YYYY')} - {moment(data.operation_review_approved_at).format('DD/MM/YYYY')}</span>
                    <h5>Operation Review</h5>
                    <div className="msg">
                      <Button 
                        outline
                        tag={Link} 
                        to={`/review/operation-review/view/${data.id}`} className="m-r-20">
                        <i className="icon-doc"></i> View
                      </Button>
                    </div>
                  </div>
                : null}

                {data.audit && data.audit.audit_plan ? 
                  <div className="timeline-item animated fadeIn faster green">
                    <span className="date">{moment(data.audit.audit_plan.date_of_audit_start).format('DD/MM/YYYY')} - {moment(data.audit.audit_plan.date_of_audit_end).format('DD/MM/YYYY')} ({moment(data.audit.send_for_operation_review_date).format('DD/MM/YYYY')})</span>
                    <h5>Audit Report</h5>
                    <div className="msg">
                      <Button 
                        outline
                        tag={Link} 
                        to={`/audit-module/audit-report/${data.audit.audit_plan.type}/${data.audit.audit_plan.subtype}/view/${data.audit.id}`} className="m-r-20">
                        <i className="icon-doc"></i> View
                      </Button>
                    </div>
                  </div>
                : null}

                {data.audit && data.audit.audit_plan && data.audit.audit_plan.date !== null ? 
                  <div className="timeline-item animated fadeIn faster green">
                    <span className="date">{moment(data.audit.audit_plan.date).format('DD/MM/YYYY')}</span>
                    <h5>Audit Plan</h5>
                    <div className="msg">
                      <Button 
                        outline
                        tag={Link} 
                        to={`/audit-module/audit-plan/${data.audit.audit_plan.type}/${data.audit.audit_plan.subtype}/view/${data.audit.audit_plan.id}`} className="m-r-20">
                        <i className="icon-doc"></i> View
                      </Button>
                    </div>
                  </div>
                : null}

                {data.audit && data.audit.audit_plan && data.audit.audit_plan.client && data.audit.audit_plan.client.application && data.audit.audit_plan.client.application.reviewed_at !== null ? 
                  <div className="timeline-item animated fadeIn faster green">
                    <span className="date">{moment(data.audit.audit_plan.client.application.reviewed_at).format('DD/MM/YYYY')}</span>
                    <h5>Contract Review</h5>
                    <div className="msg">
                      <Button 
                        outline
                        tag={Link} 
                        to={`/application-review/contract-review/${data.audit.audit_plan.type}/view/${data.audit.audit_plan.client.application.id}`} className="m-r-20">
                        <i className="icon-doc"></i> View
                      </Button>
                    </div>
                  </div>
                : null}

                {data.audit && data.audit.audit_plan && data.audit.audit_plan.client && data.audit.audit_plan.client.application ? 
                  <div className="timeline-item animated fadeIn faster green">
                    <span className="date">{moment(data.audit.audit_plan.client.application.declaration_date).format('DD/MM/YYYY')}</span>
                    <h5>Application</h5>
                    <div className="msg">
                      <Button 
                        outline
                        tag={Link} 
                        to={`/application-review/client-application/${data.audit.audit_plan.type}/view/${data.audit.audit_plan.client.id}`} 
                        className="m-r-20"
                      >
                        <i className="icon-doc"></i> View
                      </Button>
                    </div>
                  </div>
                : null}
              </div>
            </div>

            {/* <div className="timeline-item animated fadeIn faster green">
              <span className="date">01-01-2020</span>
              <h5>ED Approval</h5>
            </div>
            <div className="timeline-item animated fadeIn faster green">
              <span className="date">01-01-2020</span>
              <h5>GM Approval</h5>
            </div>
            <div className="timeline-item animated fadeIn faster green">
              <span className="date">01-01-2020</span>
              <h5>Technical Review</h5>
            </div>
            <div className="timeline-item animated fadeIn faster green">
              <span className="date">01-01-2020</span>
              <h5>Peer Review</h5>
            </div>
            <div className="timeline-item animated fadeIn faster green">
              <span className="date">01-01-2020</span>
              <h5>Operation Review</h5>
            </div>
            <div className="timeline-item animated fadeIn faster green">
              <span className="date">01-01-2020</span>
              <h5>Audit Report</h5>
            </div>
            <div className="timeline-item animated fadeIn faster green">
              <span className="date">01-01-2020</span>
              <h5>Audit Plan</h5>
            </div>
            <div className="timeline-item animated fadeIn faster green">
              <span className="date">01-01-2020</span>
              <h5>Contract Review</h5>
            </div>
            <div className="timeline-item animated fadeIn faster green">
              <span className="date">01-01-2020</span>
              <h5>Application</h5> 
            </div>*/}

        {/* <Formik 
          enableReinitialize={true}
          initialValues={data}
          onSubmit={async (values, { setSubmitting }) => {
            let token = await localStorage.getItem('auth-token');

            token = JSON.parse(token);

            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

            //var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

            if (values.technical_review_1_approved_at !== null) {
              values.action = 'tr_approved';
              values.technical_review_2_approved_at = moment().format('YYYY-MM-DD HH:mm:ss');
            }

            if (values.technical_review_1_approved_at === null) {
              values.action = 'send_for_approval_2';
              values.technical_review_1_approved_at = moment().format('YYYY-MM-DD HH:mm:ss');
            }

            if (id) {
              values._method = 'PUT';

              await api({
                method: 'POST',
                url: '/boss-chart/approve/' + id,
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                data: values,
              })
              .then(function (response) {
                // console.log(response);

                addToast('Boss chart has been approved', { appearance: 'success' });

                history.push(props.path);

                // window.location = '/client-application/iso';
              })
              .catch(function (error) {
                if (error && error.response && error.response.data) {
                  addToast(error.response.data.message, { appearance: 'error' });
                } else {
                  addToast(error.message, { appearance: 'error' });
                }
              });
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <div className="row clearfix">
              <div className="col-sm-4">
                
                {profile.permissions && profile.permissions.find(o => o.name.toLowerCase() === 'boss_chart.approval') ?
                <React.Fragment>
                  <button 
                    disabled={data.technical_review_state_text !== 'Pending CEO Approval' && data.technical_review_state_text !== 'Pending Chairman Approval' ? true : false}
                    type="submit" 
                    className="btn btn-primary"
                  >
                    {data.technical_review_1_approved_at == null ? 'CEO Approval' : 'Chairman Approval'}
                  </button>&nbsp;
                </React.Fragment>
                : null}
                <Button 
                  outline
                  color="secondary" 
                  tag={Link} 
                  to={props.path}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </FormikForm>
          )}
          </Formik> */}
          </div>
        </Card>
      </div>
    </div>
  )
}

export default View;