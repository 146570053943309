import React, { useEffect, useState } from "react";

import {
  // Link,
  useParams,
} from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";

import Select from 'react-select'

import DateRangePicker from 'react-bootstrap-daterangepicker';

import BootstrapSwitchButton from "bootstrap-switch-button-react"

import { objectToFormData } from 'object-to-formdata';

import classnames from 'classnames';

import { 
  Formik,
  Form as FormikForm
} from "formik";

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import moment from 'moment';

import DatePicker from 'reactstrap-date-picker';

import api from "../../../../utils/api";

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

// const CATEGORIES = [
//   {
//     value: "Context",
//     label: "Context",
//   },
//   {
//     value: "Leadership",
//     label: "Leadership",
//   },
//   {
//     value: "Planning",
//     label: "Planning",
//   },
//   {
//     value: "Support",
//     label: "Support",
//   },
//   {
//     value: "Operation Management",
//     label: "Operation Management",
//   },
//   {
//     value: "Performance Evaluation",
//     label: "Performance Evaluation",
//   },
//   {
//     value: "Improvement",
//     label: "Improvement",
//   },
// ];

const AuditProcess = () => {
  let { id, type, subtype } = useParams();

  const { addToast } = useToasts();

  const [data, setData] = useState({});

  const [load, reload] = useState(false);

  const [tabIsLoaded, loadTab] = useState(false);

  const [auditors, setAuditor] = useState([]);

  const [certifications, setCertifications] = useState([]);

  const [auditorNotes, filterAuditorNotes] = useState("");

  const [activeTab, setActiveTab] = useState(1);

  const [activeAuditTrailTab, setActiveAuditTrailTab] = useState('NewAuditTrail');

  const [activeNCRTab, setActiveNCRTab] = useState('NCR-0');

  const [activePrincipleTab, setActivePrincipleTab] = useState(0);

  const [auditNote, setAuditNote] = useState({});

  const [isSubmitting, setSubmitting] = useState(false);

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const toggleAuditTrailTab = tab => {
    if(activeAuditTrailTab !== tab) setActiveAuditTrailTab(tab);
  }

  const toggleNCRTab = tab => {
    if(activeNCRTab !== tab) setActiveNCRTab(tab);
  }

  const togglePrincipleTab = tab => {
    if(activePrincipleTab !== tab) setActivePrincipleTab(tab);
  }

  const [tabAuditNotes, setTabAuditNotes] = useState([]);
  
  const [submenu, setSubmenu] = useState([{
    id: 1,
    label: "Audit Notes",
  }, 
  // {
  //   id: 2,
  //   label: "Compliance Review",
  // }, 
  // {
  //   id: 3,
  //   label: "Audit Summary",
  // }, 
  // {
  //   id: 3,
  //   label: "Non Conformity Report",
  // }, {
  //   id: 4,
  //   label: "Opportunity For Improvement",
  // }
  ]);

  useEffect(() => {
    if (type.toLowerCase() !== 'iso') {
      // setActiveTab(2);
      
      // setSubmenu([
      // {
      //   id: 1,
      //   label: "Audit Notes",
      // }, 
      // {
      //   id: 2,
      //   label: "Compliance Review",
      // }, 
      // {
      //   id: 3,
      //   label: "Audit Summary",
      // }, 
      // {
      //   id: 3,
      //   label: "Non Conformity Report",
      // }, {
      //   id: 4,
      //   label: "Opportunity For Improvement",
      // }
      // ]);
    }

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audits/' + id)
        .then(function (response) {
          delete response.data.id;

          // console.log(response.data.data.audit_plan.audit_team_members);

          // if (response.data.auditor_notes && response.data.auditor_notes.standards && response.data.auditor_notes.standards.length) {
          //   let selected = [];
          //   response.data.auditor_notes.standards.map((standard, i) => {
          //     return selected.push({
          //       label: standard.standard,
          //       value: standard.id,
          //     });
          //   })

          //   response.data.auditor_notes.standards = selected;
          // }

          setData(response.data.data);

          if (response.data.data.audit_note && response.data.data.audit_note.url) {
            let config = {
              type: response.data.data.audit_note && response.data.data.audit_note.originalExtension ? response.data.data.audit_note.originalExtension : 'pdf',
              height: window.innerHeight - 110,
                document: {
                    fileType: 'pdf',
                    key: `${id}-${Date.now()}`,
                    title: 'Audit Notes',
                    url: response.data.data.audit_note.url,
                    permissions: {
                        download: true,
                        edit: false,
                        print: true,
                        review: false,
                    }
                },
                documentType: 'text',
                editorConfig: {
                    callbackUrl: '',
                    mode: 'view',
                    customization: {
                        zoom: 70,
                        customer: {
                            address: '',
                            info: '',
                            logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                            mail: 'iskandar@greentagpro.com',
                            name: 'Greentagpro Solution',
                            www: 'greentagpro.com',
                        },
                        chat: false,
                        forcesave: true,
                        comments: false,
                    }
                },
            }
    
            new window.DocsAPI.DocEditor("placeholder", config);
          }

          if(subtype.toLowerCase() === 'mspo-sccs'){
            setTabAuditNotes([
              '4.1 Principle 1: Test',
              '4.2 Principle 2: Test 2',
              '4.3 Principle 3: Compliance to legal requirements',
              '4.4 Principle 4: Social responsibility, health, safety and employment condition',
              '4.5 Principle 5: Environment, natural resources, biodiversity and ecosystem services',
              '4.6 Principle 6: Best practices',
              '4.7 Principle 7: Development of new planting',
            ]);
          }
          else{
            setTabAuditNotes([
              '4.1 Principle 1 Management Commitment & Responsibility',
              '4.2 Principle 2: Transparency',
              '4.3 Principle 3: Compliance to legal requirements',
              '4.4 Principle 4: Social responsibility, health, safety and employment condition',
              '4.5 Principle 5: Environment, natural resources, biodiversity and ecosystem services',
              '4.6 Principle 6: Best practices',
              '4.7 Principle 7: Development of new planting',
            ]);    }
          // if (response.data.auditor_notes && response.data.auditor_notes.length) {
          //   // console.log(response.data.auditor_notes[0]);
          //   toggleAuditTrailTab(`AuditTrail-${response.data.auditor_notes[0].id}`); 
          // }

          // if (
          //   response.data.client &&
          //   response.data.client.audit_plan  &&
          //   response.data.client.audit_plan.lead_auditor &&
          //   response.data.client.audit_plan.lead_auditor.full_name
          // ) {
          //     auditors[response.data.client.audit_plan.lead_auditor.id] = {
          //       value: response.data.client.audit_plan.lead_auditor.id,
          //       label: `${response.data.client.audit_plan.lead_auditor.full_name} (Lead Auditor)`,
          //     };

          //     // auditors.push({
          //     //   value: response.data.client.contract_review.lead_auditor.id,
          //     //   label: response.data.client.contract_review.lead_auditor.full_name,
          //     // });
              
          //     setAuditor(auditors);
          //   }

          // {data.audit_plan && data.audit_plan.client && data.audit_plan.client.certifications ? 
          //   data.audit_plan.client.certifications.map((certification, i) => 
          //   <option key={i} value={certification.id}>{certification.standard}</option>
          //   ) : ""}

          if (
            response.data.data.audit_plan &&
            response.data.data.audit_plan.client &&
            response.data.data.audit_plan.client.certifications &&
            response.data.data.audit_plan.client.certifications.length
          ) {
            let c = [];

            response.data.data.audit_plan.client.certifications.map((cert, i) => 
              c[i] = {
                value: cert.id,
                label: cert.standard
              }
            );

            setCertifications(c);
          }

          if (
            response.data.data.audit_plan &&
            response.data.data.audit_plan.lead_auditors &&
            response.data.data.audit_plan.lead_auditors.length
          ) {
            response.data.data.audit_plan.lead_auditors.map((auditor, i) => 
              auditors[auditor.id] = {
                value: auditor.id,
                label: auditor.full_name,
                initial: auditor.initial,
              }
            );

            setAuditor(auditors);
          }

            if (
              response.data.data.audit_plan &&
              response.data.data.audit_plan.audit_team_members &&
              response.data.data.audit_plan.audit_team_members.length
            ) {
              response.data.data.audit_plan.audit_team_members.map((auditor, i) => 
                auditors[auditor.id] = {
                  value: auditor.id,
                  label: auditor.full_name,
                  initial: auditor.initial,
                }
              );

              setAuditor(auditors);
            }

          reload(false);

          loadTab(true);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id, load, activePrincipleTab]);

  const create = async (url, values) => {
    // console.log(values);

    let token = await localStorage.getItem('auth-token');

    token = JSON.parse(token);

    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    await api.post(url, JSON.stringify(values), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function (response) {
      addToast('Audit successfully updated', { appearance: 'success' });

      reload(true);
    })
    .catch(function (error) {
      addToast(error.message, { appearance: 'error' });
    });

    // resetForm();
  }

  const update = async (url, values) => {
    // console.log(values);

    let token = await localStorage.getItem('auth-token');

    token = JSON.parse(token);

    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    await api.put(url, JSON.stringify(values), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function (response) {
      addToast('Audit successfully updated', { appearance: 'success' });

      reload(true);
    })
    .catch(function (error) {
      addToast(error.message, { appearance: 'error' });
    });

    // resetForm();
  }

  const deleteHandler = async (url) => {
    if (window.confirm("Delete this row?")) {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.delete(url)
      .then(function (response) {
        // console.log(response);

        addToast('Row successfully deleted', { appearance: 'success' });

        reload(true);
      })
      .catch(function (error) {
        // console.log(error.response);

        if (error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }
  }

  const sendNotificationHandler = async (url) => {
    setSubmitting(true);

    let token = await localStorage.getItem('auth-token');

    token = JSON.parse(token);

    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    await api.post(url)
    .then(function (response) {
      // console.log(response.data);

      addToast('Notification has been sent', { appearance: 'success' });
      
      setSubmitting(false);
    })
    .catch(function (error) {
      // console.log(error.response);
      if (error && error.response && error.response.data) {
        addToast(error.response.data.message, { appearance: 'error' });
      } else {
        addToast(error.message, { appearance: 'error' });
      }

      setSubmitting(false);
    });
  }

  return (
    <React.Fragment>
      <Card>
        <div className="header">
          <h2>Audit Process</h2>
        </div>
        <div className="body">
          <Nav pills>
            {submenu.map((tab, i) => 
              <NavItem key={i}>
                <NavLink
                  className={classnames({ active: activeTab === tab.id })}
                  onClick={() => { toggle(tab.id); }}
                >
                  4.{i + 1} {tab.label}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <hr />
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              <Formik 
                // key={note.id}
                // enableReinitialize={true}
                initialValues={data}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                  values._method = 'PUT';

                  var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

                  await api({
                    method: 'POST',
                    url: '/audits/' + id,
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                    data: data,
                  })
                  .then(function (response) {
                    addToast('Audit notes successfully uploaded', { appearance: 'success' });

                    window.location.reload();
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });
                }}
              >
              {({ isSubmitting, values, setFieldValue, handleChange }) => (
                <FormikForm>

                  <FormGroup>
                    <Label for="">Audit Notes</Label>
                    <div className="input-group">
                      <div className="custom-file">
                        <Input
                          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          className="custom-file-input"
                          type="file" 
                          name="audit_note" 
                          // id="ROCCrtificate"
                          onChange={(event) => {
                            setFieldValue(`audit_note`, event.currentTarget.files[0])
                          }}
                        />
                        <Label className="custom-file-label">
                          {values.audit_note && values.audit_note.name ? values.audit_note.name : 'No file chosen'}
                        </Label>
                      </div>
                      <div className="input-group-append">
                        <button 
                          disabled={isSubmitting}
                          className="btn btn-outline-secondary" 
                          type="submit"
                          >
                            {isSubmitting ? 'Uploading...' : 'Upload'}
                          </button>
                      </div>
                    </div>

                    {/* {data.audit_note && data.audit_note.url ? <div><a className="btn btn-link" href={data.audit_note.url}>{data.audit_note.originalName}</a></div> : null} */}
                  </FormGroup>

                  <div id="placeholder"></div>
                </FormikForm>
              )}
              </Formik>
            </TabPane>
            <TabPane tabId={2}>
              {type.toLowerCase() !== 'iso' ?
              <React.Fragment>
                <Nav pills>
                  {
                    /* [
                    '4.1 Principle 1 Management Commitment & Responsibility',
                    '4.2 Principle 2: Transparency',
                    '4.3 Principle 3: Compliance to legal requirements',
                    '4.4 Principle 4: Social responsibility, health, safety and employment condition',
                    '4.5 Principle 5: Environment, natural resources, biodiversity and ecosystem services',
                    '4.6 Principle 6: Best practices',
                    '4.7 Principle 7: Development of new planting',
                  ] */tabAuditNotes.map((k, i) => 
                    <NavItem key={i}>
                      <NavLink
                        className={classnames({ active: activePrincipleTab === i })}
                        onClick={() => { togglePrincipleTab(i); }}
                      >
                        {k}
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <br />
              </React.Fragment>
              : null}
              <Formik 
                enableReinitialize={true}
                initialValues={data}
                onSubmit={async (values, { setSubmitting }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                  if (type.toLowerCase() !== 'iso') {
                    values.category = activePrincipleTab;
                  }

                  api.post('/audits/' + id + '/document-reviews', JSON.stringify(values), {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                  .then(function (response) {
                    addToast('Row successfully updated', { appearance: 'success' });

                    reload(true);
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });
                }}
              >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup>
                      <div className="table">
                        <BootstrapTable 
                          // remote={true}
                          bootstrap4
                          bordered={false}
                          classes="table-hover js-basic-example dataTable table-custom m-b-0"
                          headerClasses="thead-dark"
                          keyField="id"
                          data={data.document_reviews ? (type.toLowerCase() !== 'iso' ? data.document_reviews.filter(o => parseInt(o.category) === activePrincipleTab).map(filtered => filtered) : data.document_reviews) : []} 
                          columns={[
                            // {
                            //   dataField: "category",
                            //   text: type.toUpperCase() === 'ISO' ? "Category" : "Principal",
                            //   formatter: (cell, row, rowIndex) => {
                            //     return (
                            //       <div className="editable form-control-plaintext">
                            //         {cell}&nbsp;
                            //       </div>
                            //     )
                            //   },
                            //   style: {
                            //     whiteSpace: "pre-wrap"
                            //   },
                            //   editor: {
                            //     type: Type.TEXTAREA
                            //   },
                            //   footer: () => {
                            //     return (
                            //       <Input 
                            //         type="textarea" 
                            //         // className="custom-select" 
                            //         name="category" 
                            //         onChange={handleChange}
                            //         value={values.category || ""}
                            //       >
                            //         {/* <option></option>
                            //         {CATEGORIES.map((CATEGORY, i) => 
                            //           <option key={i} value={CATEGORY.value}>{CATEGORY.label}</option>
                            //         )} */}
                            //       </Input>
                            //     )
                            //   },
                            //   // editor: {
                            //   //   type: Type.SELECT,
                            //   //   options: CATEGORIES,
                            //   // }
                            // },
                            {
                              hidden: type.toUpperCase() === 'ISO' ? true : false,
                              dataField: "clause_no",
                              text: "Clause No.",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              style: (cell, row, rowIndex, colIndex) => {
                                if (parseInt(row.sub) === 1) {
                                  return {
                                    textAlign: "right",
                                    backgroundColor: "#ccc",
                                    whiteSpace: "pre-wrap",
                                    fontWeight: "bold",
                                  }
                                }
                                return {
                                  whiteSpace: "pre-wrap"
                                }
                              },
                              editable: false,
                              // editCellStyle: {
                              //   minHeight: "500px",
                              //   minWidth: "300px",
                              // },
                              // footer: () => {
                              //   return (
                              //     <Input 
                              //       type="textarea"
                              //       name="clause_no"
                              //       onChange={handleChange}
                              //       value={values.clause_no || ""}
                              //     />
                              //   )
                              // },
                              // editor: {
                              //   type: Type.TEXTAREA
                              // },
                            },
                            {
                              dataField: "document_review_item",
                              text: type.toUpperCase() === 'ISO' ? "Review Item" : "Criteria",
                              // attrs: (cell, row, rowIndex) => {
                              //   if (row.sub == '1') {
                              //     return { colSpan: 3 }
                              //   }
                              // },
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              style: (cell, row, rowIndex, colIndex) => {
                                if (parseInt(row.sub) === 1) {
                                  return {
                                    backgroundColor: "#ccc",
                                    whiteSpace: "pre-wrap",
                                    fontWeight: "bold",
                                  }
                                }
                                return {
                                  whiteSpace: "pre-wrap"
                                }
                              },
                              editable: false,
                              // editCellStyle: {
                              //   minWidth: "300px",
                              // },
                              // footer: () => {
                              //   // if (type.toUpperCase() === 'ISO') {
                              //     return (
                              //       <Input 
                              //         type="textarea"
                              //         name="document_review_item"
                              //         onChange={handleChange}
                              //         value={values.document_review_item || ""}
                              //       />
                              //     )
                              //   // }

                              //   // return false;
                              // },
                              // editor: {
                              //   type: Type.TEXTAREA
                              // },
                            },
                            {
                              hidden: true, // type.toUpperCase() === 'ISO' ? true : false,
                              dataField: "clause",
                              text: "Description",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              style: (cell, row, rowIndex, colIndex) => {
                                if (parseInt(row.sub) === 1) {
                                  return {
                                    backgroundColor: "#ccc",
                                    whiteSpace: "pre-wrap",
                                    fontWeight: "bold",
                                  }
                                }
                                return {
                                  whiteSpace: "pre-wrap"
                                }
                              },
                              editable: (cell, row, rowIndex) => {
                                return parseInt(row.sub) === 1 ? false : true
                              },
                              editCellStyle: {
                                minHeight: "500px",
                                minWidth: "300px",
                              },
                              // footer: () => {
                              //   return (
                              //     <Input 
                              //       type="textarea"
                              //       name="clause"
                              //       onChange={handleChange}
                              //       value={values.clause || ""}
                              //     />
                              //   )
                              // },
                              editor: {
                                type: Type.TEXTAREA
                              },
                            },
                            {
                              // hidden: (cell, row, rowIndex) => {
                              //   return row.sub == '1' ? true : false;
                              // },
                              dataField: "description",
                              text: "Findings",
                              formatter: (cell, row, rowIndex) => {
                                cell = htmlToReactParser.parse(cell);
                                
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              style: (cell, row, rowIndex, colIndex) => {
                                if (parseInt(row.sub) === 1) {
                                  return {
                                    backgroundColor: "#ccc",
                                    whiteSpace: "pre-wrap",
                                    fontWeight: "bold",
                                  }
                                }
                                return {
                                  whiteSpace: "pre-wrap"
                                }
                              },
                              editable: (cell, row, rowIndex) => {
                                return parseInt(row.sub) === 1 ? false : true
                              },
                              editCellStyle: {
                                minHeight: "500px",
                                minWidth: "300px",
                              },
                              // footer: () => {
                              //   return (
                              //     <Input 
                              //       type="textarea"
                              //       name="description"
                              //       onChange={handleChange}
                              //       value={values.description || ""}
                              //     />
                              //   )
                              // },
                              // editor: {
                              //   type: Type.TEXTAREA
                              // },
                              editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                                <Editor 
                                  apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                  value={value}
                                  init={{
                                    height: 500,
                                    menubar: 'file edit view insert format tools table tc help',
                                    plugins: [
                                      'advlist autolink lists link charmap print preview anchor',
                                      'searchreplace visualblocks code fullscreen',
                                      'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | a11ycheck ltr rtl | showcomments addcomment',
                                  }}
                                  onBlur={(e, editor) => {
                                    // console.log(editor.getContent());
                                     editorProps.onUpdate(editor.getContent());
                                  }}
                                  // onEditorChange={(content) => {
                                      // e.preventDefault();
                                     // editorProps.onUpdate(content);
                                  //}}
                                />
                              )
                            },
                            {
                              hidden: type.toUpperCase() === 'ISO' ? false : true,
                              dataField: "compliant",
                              text: "Compliance",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <BootstrapSwitchButton
                                    disabled={true}
                                    checked={parseInt(cell) === 1}
                                    onlabel={<i className="icon-check"></i>}
                                    offlabel={<i className="icon-close"></i>}
                                    offstyle="danger"
                                    // onChange={(checked: boolean) => {
                                        
                                    // }}
                                  />
                                )
                              },
                              // editable: false,
                              editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                                <BootstrapSwitchButton
                                  checked={parseInt(value) === 1}
                                  onlabel={<i className="icon-check"></i>}
                                  offlabel={<i className="icon-close"></i>}
                                  offstyle="danger"
                                  onChange={(checked) => {
                                    // console.log(value, row, column);
                                    // console.log(checked.toString());
                                    editorProps.onUpdate(checked);
                                  }}
                                  value={value}
                                />
                              ),
                              // footer: () => {
                              //   return (
                              //     <BootstrapSwitchButton
                              //       checked={false}
                              //       onlabel={<i className="icon-check"></i>}
                              //       offlabel={<i className="icon-uncheck"></i>}
                              //       onChange={(checked: boolean) => {
                              //           setFieldValue("compliant", checked)
                              //       }}
                              //     />
                              //   )
                              // },
                            },
                            {
                              hidden: type.toUpperCase() === 'ISO' ? false : true,
                              dataField: "comment",
                              text: "Compliance Status",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              style: (cell, row, rowIndex, colIndex) => {
                                if (parseInt(row.sub) === 1) {
                                  return {
                                    backgroundColor: "#ccc",
                                    whiteSpace: "pre-wrap",
                                    fontWeight: "bold",
                                  }
                                }
                                return {
                                  whiteSpace: "pre-wrap"
                                }
                              },
                              // footer: () => {
                              //   return (
                              //     <Input 
                              //       type="textarea"
                              //       name="comment"
                              //       onChange={handleChange}
                              //       value={values.comment || ""}
                              //     />
                              //   )
                              // },
                              editable: (cell, row, rowIndex) => {
                                return parseInt(row.sub) === 1 ? false : true
                              },
                              editCellStyle: {
                                minHeight: "500px",
                                minWidth: "300px",
                              },
                              editor: {
                                type: Type.TEXTAREA
                              },
                            },
                            {
                              // hidden: (cell, row, rowIndex) => {
                              //   if (type.toUpperCase() === 'ISO') {
                              //     return true;
                              //   }
                              //   return row.sub == '1' ? true : false;
                              // },
                              // editable: (cell, row, rowIndex) => {
                              //   return row.sub === '1' ? true : false;
                              // },
                              hidden: type.toUpperCase() === 'ISO' ? true : false,
                              dataField: "mspo_status",
                              text: "Compliance Status",
                              formatter: (cell, row, rowIndex) => {
                                if (row.sub === '1') {
                                  return '';
                                }
                                
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              style: (cell, row, rowIndex, colIndex) => {
                                if (parseInt(row.sub) === 1) {
                                  return {
                                    backgroundColor: "#ccc",
                                    whiteSpace: "pre-wrap",
                                    fontWeight: "bold",
                                  }
                                }
                                return {
                                  whiteSpace: "pre-wrap"
                                }
                              },
                              // footer: () => {
                              //   return (
                              //     <Input 
                              //       // required
                              //       type="select" 
                              //       className="custom-select" 
                              //       name="mspo_status" 
                              //       onChange={handleChange}
                              //       value={values.mspo_status || ""}
                              //     >
                              //       <option></option>
                              //       <option value="Conformance">Conformance</option>
                              //       <option value="Non Conformance">Non Conformance</option>
                              //       <option value="Not Applicable">Not Applicable</option>
                              //     </Input>
                              //   )
                              // },
                              editCellStyle: {
                                minHeight: "500px",
                                minWidth: "300px",
                              },
                              editor: {
                                type: Type.SELECT,
                                options: [{
                                  value: 'Conformance',
                                  label: 'Conformance',
                                }, {
                                  value: 'Non Conformance',
                                  label: 'Non Conformance',
                                }, {
                                  value: 'Not Applicable',
                                  label: 'Not Applicable',
                                }],
                              }
                            },
                            // {
                            //   dataField: "id",
                            //   text: "",
                            //   formatter: (cell, row, rowIndex) => {
                            //     return null;
                                
                            //     // return (
                            //     //   <Button 
                            //     //     color="danger" 
                            //     //     type="button"
                            //     //     onClick={() => {
                            //     //       // console.log(cell);
                            //     //       deleteHandler('/audits/' + id + '/document-reviews/' + cell);
                            //     //     }}
                            //     //   >
                            //     //     <i className="icon-trash"></i>
                            //     //   </Button>
                            //     // )
                            //   },
                            //   // footer: () => {
                            //   //   return (
                            //   //     <Button color="primary">
                            //   //       <i className="icon-plus"></i>
                            //   //     </Button>
                            //   //   )
                            //   // }
                            // },
                          ]}
                          cellEdit={ 
                            cellEditFactory({ 
                              mode: "click",
                              blurToSave: true,
                              afterSaveCell: async (oldValue, newValue, row, column) => {
                                // console.log(oldValue.toString(), newValue)

                                if (oldValue === null && newValue === "") return;

                                if (oldValue === newValue) return;

                                let token = await localStorage.getItem('auth-token');

                                token = JSON.parse(token);

                                api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                values = {
                                  [column.dataField]: newValue
                                }

                                api.put('/audits/' + id + '/document-reviews/' + row.id, JSON.stringify(values), {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                })
                                .then(function (response) {
                                  addToast('Audit successfully updated', { appearance: 'success' });

                                  reload(true);
                                })
                                .catch(function (error) {
                                  addToast(error.message, { appearance: 'error' });
                                });
                              }
                            })
                          }
                          // pagination={ 
                          //   paginationFactory(pagingInfo)
                          // }
                          // onTableChange={handleTableChange}
                        />
                      </div>
                    </FormGroup>
                  </FormikForm>
                )}
              </Formik>
            </TabPane>
            {/* <TabPane tabId={3}>
              <Formik 
                enableReinitialize={true}
                initialValues={data}
                onSubmit={async (values, { setSubmitting }) => {
                  // let token = await localStorage.getItem('auth-token');

                  // token = JSON.parse(token);

                  // api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                  // api.post('/audits/' + id + '/opportunity-for-improvements', JSON.stringify(values), {
                  //   headers: {
                  //     'Content-Type': 'application/json',
                  //   },
                  // })
                  // .then(function (response) {
                  //   addToast('Row successfully updated', { appearance: 'success' });

                  //   reload(true);
                  // })
                  // .catch(function (error) {
                  //   addToast(error.message, { appearance: 'error' });
                  // });
                }}
              >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup>
                      <div className="table">
                        <BootstrapTable 
                          // remote={true}
                          bootstrap4
                          bordered={false}
                          classes="table-hover js-basic-example dataTable table-custom m-b-0"
                          headerClasses="thead-dark"
                          keyField="id"
                          data={data.document_reviews ? data.document_reviews : []} 
                          columns={[
                            {
                              dataField: "document_review_item",
                              text: "Clauses",
                              style: {
                                whiteSpace: "pre-wrap"
                              },
                            },
                            {
                              dataField: "description",
                              text: "Description",
                              style: {
                                whiteSpace: "pre-wrap"
                              },
                            },
                            {
                              text: "Department/Activity/Process",
                            },
                            {
                              text: "",
                            },
                          ]}
                          // pagination={ 
                          //   paginationFactory(pagingInfo)
                          // }
                          // onTableChange={handleTableChange}
                        />
                      </div>
                    </FormGroup>
                  </FormikForm>
                )}
              </Formik>
            </TabPane> */}
            <TabPane tabId={3}>

              <Nav tabs>
                {data.non_conformances && data.non_conformances.length ?
                  data.non_conformances.map((ncr, i) => 
                    <NavItem key={ncr.id}>
                      <NavLink
                        key={ncr.id}
                        id={ncr.id}
                        className={classnames({ active: activeNCRTab === `NCR-${ncr.id}` })}
                        onClick={() => { 
                          toggleNCRTab(`NCR-${ncr.id}`); 
                          reload(true); 
                        }}
                      >
                        {ncr.ncr_close === 'Y' ? <span className="badge badge-success">Closed</span> : <span className="badge badge-danger">Open</span>} {ncr.no}
                      </NavLink>
                    </NavItem>
                  )
                : null}

                {tabIsLoaded === false ?
                  <NavItem>
                    <NavLink>
                      <Spinner size="sm" color="primary" />
                    </NavLink>
                  </NavItem>
                : null}

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeNCRTab === 'NCR-0' })}
                    onClick={() => { toggleNCRTab('NCR-0'); }}
                  >
                    <i className="icon-plus"></i> New NCR
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeNCRTab}>
                {data.non_conformances && data.non_conformances.length ?
                  data.non_conformances.map((ncr, i) => 
                    <TabPane key={i} tabId={`NCR-${ncr.id}`}>
                      <Formik 
                        enableReinitialize={true}
                        initialValues={ncr}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                          let token = await localStorage.getItem('auth-token');

                          token = JSON.parse(token);

                          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                          // values.status = 1;

                          await api.put('/audits/' + ncr.audit_id + '/non-conformances/' + ncr.id , JSON.stringify(values), {
                            headers: {
                              'Content-Type': 'application/json',
                            },
                          })
                          .then(function (response) {
                            addToast('Non conformance report successfully verified', { appearance: 'success' });

                            reload(true);
                          })
                          .catch(function (error) {
                            addToast(error.message, { appearance: 'error' });
                          });
                        }}
                      >
                        {({ isSubmitting, values, setFieldValue, handleChange }) => (
                          <FormikForm>
                            <h5>Section A</h5>

                            <FormGroup>
                              <Label for="no">NCR No.</Label>
                              <Input 
                                readOnly={values.client_notified_at !== null}
                                type="text" 
                                // className="custom-select" 
                                name="no" 
                                id="no"
                                onChange={handleChange}
                                value={values.no}
                              />
                            </FormGroup>

                            <div className="row clearfix">
                              <div className="col-md-6">
                                <FormGroup>
                                  <Label for="location">Location</Label>
                                  <Input 
                                    readOnly={values.client_notified_at !== null}
                                    type="text" 
                                    // className="custom-select" 
                                    name="location" 
                                    id="location"
                                    onChange={handleChange}
                                    value={values.location}
                                  />
                                </FormGroup>
                              </div>
                              
                              <div className="col-md-6">
                                <FormGroup>
                                  <Label for="area_department">Area/Department</Label>
                                  <Input 
                                    readOnly={values.client_notified_at !== null}
                                    type="text" 
                                    // className="custom-select" 
                                    name="area_department" 
                                    id="area_department"
                                    onChange={handleChange}
                                    value={values.area_department}
                                  />
                                </FormGroup>
                              </div>
                            </div>

                            <div className="row clearfix">
                              <div className="col-md-4">
                                <FormGroup>
                                  <Label for="standard_id">Standard</Label>
                                  <Input 
                                    // key={note.id}
                                    disabled={values.client_notified_at !== null}
                                    type="select" 
                                    className="custom-select" 
                                    name="standard_id" 
                                    // id="Organization"
                                    onChange={handleChange}
                                    value={values.standard_id}
                                  >
                                    <option></option>
                                    {/* {data.scope_of_certifications && data.scope_of_certifications.length ? data.scope_of_certifications.map((STANDARD, i) => 
                                      <option key={i} value={STANDARD.certification.id}>{STANDARD.certification.standard}</option>
                                    ) : null} */}
                                    {data.audit_plan && data.audit_plan.client && data.audit_plan.client.certifications ? 
                                    data.audit_plan.client.certifications.map((certification, i) => 
                                    <option key={i} value={certification.id}>{certification.standard}</option>
                                    ) : ""}
                                  </Input>
                                </FormGroup>
                              </div>
                              <div className="col-md-4">
                                <FormGroup>
                                  <Label for="clause">Clause</Label>
                                  <Input 
                                    readOnly={values.client_notified_at !== null}
                                    type="text" 
                                    // className="custom-select" 
                                    name="clause" 
                                    id="clause"
                                    onChange={handleChange}
                                    value={values.clause}
                                  />
                                </FormGroup>
                              </div>
                              <div className="col-md-4">
                                <FormGroup>
                                  <Label for="">Grade</Label>
                                  <div>
                                <label className="fancy-radio">
                                  <Input
                                    disabled={values.client_notified_at !== null}
                                    type="radio"
                                    name="grade"
                                    value="1"
                                    onClick={() => {
                                      setFieldValue('grade', 'Major')
                                    }}
                                    checked={values.grade && values.grade ? values.grade === 'Major' : ""}
                                  />
                                  <span>
                                    <i></i> Major
                                  </span>
                                </label>

                                <label className="fancy-radio">
                                  <Input
                                    disabled={values.client_notified_at !== null}
                                    type="radio"
                                    name="grade"
                                    value="0"
                                    onClick={() => {
                                      setFieldValue('grade', 'Minor')
                                    }}
                                    checked={values.grade && values.grade ? values.grade === 'Minor' : ""}
                                  />
                                  <span>
                                    <i></i> Minor
                                  </span>
                                </label>
                              </div>
                                </FormGroup>
                              </div>
                            </div>

                            <FormGroup>
                              <Label for="details">Details/Objective Evidence Of Nonconformity</Label>
                              <Input 
                                readOnly={values.client_notified_at !== null}
                                type="textarea" 
                                // className="custom-select" 
                                name="details" 
                                id="details"
                                onChange={handleChange}
                                value={values.details}
                                rows={5}
                              />
                            </FormGroup>

                            <div className="row clearfix">
                              <div className="col-md-8">
                                <FormGroup>
                                  <Label for="issued_by">Issued By</Label>
                                  <Input 
                                    // key={note.id}
                                    disabled={values.client_notified_at !== null}
                                    type="select" 
                                    className="custom-select" 
                                    name="auditor_id" 
                                    // id="Organization"
                                    onChange={handleChange}
                                    value={values.auditor_id}
                                  >
                                    <option></option>
                                    {auditors.map((auditor, i) => 
                                      <option key={i} value={auditor.value}>{auditor.label}</option>
                                    )}
                                  </Input>
                                </FormGroup>
                              </div>
                              <div className="col-md-4">
                                <FormGroup>
                                  <Label for="issued_date">Date</Label>
                                  <Input 
                                    autoComplete="off"
                                    readOnly={values.client_notified_at !== null}
                                    type="text" 
                                    // name="date_of_audit" 
                                    id="issued_date"
                                    // onChange={handleChange}
                                    value={values.issued_date ? moment(values.issued_date).format('DD/MM/YYYY') : ""}
                                  />
                                </FormGroup>
                              </div>
                            </div>

                            {values.section_b_date !== null ?
                            <React.Fragment>
                            <h5>Section B</h5>

                            <FormGroup>
                              <Label for="immediate_action">Correction Taken/Immediate Action</Label>
                              <Input 
                                readOnly={true}
                                type="textarea" 
                                // className="custom-select" 
                                name="immediate_action" 
                                id="immediate_action"
                                onChange={handleChange}
                                value={values.immediate_action}
                                rows={5}
                              />
                            </FormGroup>

                            <FormGroup>
                              <Label for="root_cause">Identify The Root Cause</Label>
                              <Input 
                                readOnly={true}
                                type="textarea" 
                                // className="custom-select" 
                                name="root_cause" 
                                id="root_cause"
                                onChange={handleChange}
                                value={values.root_cause}
                                rows={5}
                              />
                            </FormGroup>

                            <FormGroup>
                              <Label for="corrective_action_plan">Corrective Action Plan Including Completion Date</Label>
                              <Input 
                                readOnly={true}
                                type="textarea" 
                                // className="custom-select" 
                                name="corrective_action_plan" 
                                id="corrective_action_plan"
                                onChange={handleChange}
                                value={values.corrective_action_plan}
                                rows={5}
                              />
                            </FormGroup>

                            <div className="row clearfix">
                              <div className="col-md-8">
                                <FormGroup>
                                  <Label for="">Name</Label>
                                  <Input 
                                    readOnly={true}
                                    type="text" 
                                    // className="custom-select" 
                                    // name="area_department" 
                                    // id="area_department"
                                    // onChange={handleChange}
                                    value={values.mr_name}
                                  />
                                </FormGroup>
                              </div>
                              <div className="col-md-4">
                                <FormGroup>
                                  <Label for="">Date</Label>
                                  <Input 
                                    readOnly={true}
                                    type="text" 
                                    // className="custom-select" 
                                    // name="area_department" 
                                    // id="area_department"
                                    // onChange={handleChange}
                                    value={values.section_b_date}
                                  />
                                </FormGroup>
                              </div>
                            </div>

                            <h5>Section C</h5>

                            <FormGroup>
                              <Label for="verification">Verification/Comments</Label>
                              <Input 
                                readOnly={values.close_notification_at && values.close_notification_at.length}
                                type="textarea" 
                                // className="custom-select" 
                                name="verification" 
                                id="verification"
                                onChange={handleChange}
                                value={values.verification}
                                rows={5}
                              />
                            </FormGroup>

                            <FormGroup>
                              <Label for="">Correction/Corrective Actions Taken</Label>
                              <div>
                                <label className="fancy-radio">
                                  <Input
                                    disabled={values.close_notification_at && values.close_notification_at.length}
                                    type="radio"
                                    name="corrective_action_taken"
                                    value="1"
                                    onClick={() => {
                                      setFieldValue('corrective_action_taken', 'E')
                                    }}
                                    checked={values.corrective_action_taken && values.corrective_action_taken ? values.corrective_action_taken === 'E' : ""}
                                  />
                                  <span>
                                    <i></i> Effective
                                  </span>
                                </label>

                                <label className="fancy-radio">
                                  <Input
                                    disabled={values.close_notification_at && values.close_notification_at.length}
                                    type="radio"
                                    name="corrective_action_taken"
                                    value="0"
                                    onClick={() => {
                                      setFieldValue('corrective_action_taken', 'N')
                                    }}
                                    checked={values.corrective_action_taken && values.corrective_action_taken ? values.corrective_action_taken === 'N' : ""}
                                  />
                                  <span>
                                    <i></i> Not Effective
                                  </span>
                                </label>
                              </div>
                            </FormGroup>

                            <div className="row clearfix">
                              <div className="col-md-6">
                                <FormGroup>
                                  <Label for="">NCR Close?</Label>
                                  <div>
                                    <label className="fancy-radio">
                                      <Input
                                        disabled={values.close_notification_at && values.close_notification_at.length}
                                        type="radio"
                                        name="ncr_close"
                                        value="1"
                                        onClick={() => {
                                          setFieldValue('ncr_close', 'Y')
                                        }}
                                        checked={values.ncr_close && values.ncr_close ? values.ncr_close === 'Y' : ""}
                                      />
                                      <span>
                                        <i></i> Yes
                                      </span>
                                    </label>

                                    <label className="fancy-radio">
                                      <Input
                                        disabled={values.close_notification_at && values.close_notification_at.length}
                                        type="radio"
                                        name="ncr_close"
                                        value="0"
                                        onClick={() => {
                                          setFieldValue('ncr_close', 'N')
                                        }}
                                        checked={values.ncr_close && values.ncr_close ? values.ncr_close === 'N' : ""}
                                      />
                                      <span>
                                        <i></i> No
                                      </span>
                                    </label>
                                  </div>
                                </FormGroup>
                              </div>
                              <div className="col-md-6">
                                <FormGroup>
                                  <Label for="">Closed Out Date</Label>
                                  <DateRangePicker 
                                    drops="up"
                                    singleDatePicker
                                    containerStyles={{ display: "block" }}
                                    startDate={values.closed_date && values.closed_date.length ? moment(values.closed_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                    // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                    onApply={(event, picker) => { 
                                      setFieldValue('closed_date', picker.startDate.format('YYYY-MM-DD'));
                                      // setFieldValue('date_of_audit_end', picker.endDate.format('YYYY-MM-DD'));

                                      // setAuditStartDate(moment(picker.startDate));

                                      // setAuditEndDate(moment(picker.endDate));
                                    }}
                                  >
                                    <Input 
                                      disabled={values.close_notification_at && values.close_notification_at.length}
                                      autoComplete="off"
                                      // readOnly={true}
                                      type="text" 
                                      // name="date_of_audit" 
                                      id="closed_date"
                                      // onChange={handleChange}
                                      defaultValue={values.closed_date ? moment(values.closed_date).format('DD/MM/YYYY') : ""}
                                    />
                                  </DateRangePicker>
                                </FormGroup>
                              </div>
                            </div>

                            <div className="row clearfix">
                              <div className="col-md-6">
                                <FormGroup>
                                  <Label for="">Verified By</Label>
                                  <Input 
                                    // key={note.id}
                                    // readOnly={readOnly}
                                    disabled={values.close_notification_at !== null}
                                    type="select" 
                                    className="custom-select" 
                                    name="verifier_id" 
                                    // id="Organization"
                                    onChange={handleChange}
                                    value={values.verifier_id}
                                  >
                                    <option></option>
                                    {auditors.map((auditor, i) => 
                                      <option key={i} value={auditor.value}>{auditor.label}</option>
                                    )}
                                  </Input>
                                </FormGroup>
                              </div>
                              <div className="col-md-6">
                                <FormGroup>
                                  <Label for="">Date</Label>
                                  <DateRangePicker 
                                    drops="up"
                                    singleDatePicker
                                    containerStyles={{ display: "block" }}
                                    startDate={values.verified_date && values.verified_date.length ? moment(values.verified_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                    // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                    onApply={(event, picker) => { 
                                      setFieldValue('verified_date', picker.startDate.format('YYYY-MM-DD'));
                                      // setFieldValue('date_of_audit_end', picker.endDate.format('YYYY-MM-DD'));

                                      // setAuditStartDate(moment(picker.startDate));

                                      // setAuditEndDate(moment(picker.endDate));
                                    }}
                                  >
                                    <Input 
                                      disabled={values.close_notification_at && values.close_notification_at.length}
                                      autoComplete="off"
                                      // readOnly={true}
                                      type="text" 
                                      // name="date_of_audit" 
                                      id="verified_date"
                                      // onChange={handleChange}
                                      value={values.verified_date ? moment(values.verified_date).format('DD/MM/YYYY') : ""}
                                    />
                                  </DateRangePicker>
                                </FormGroup>
                              </div>
                            </div>
                            </React.Fragment>
                            : null}

                            <div className="row clearfix">
                              <div className="col-sm-4">
                                {values.client_notified_at !== null && values.section_b_date !== null ?
                                <React.Fragment>
                                  <button 
                                    disabled={isSubmitting}
                                    type="submit" 
                                    className="btn btn-primary"
                                  >
                                    {isSubmitting ? 'Verifying...' : 'Verify'}
                                  </button>&nbsp;
                                </React.Fragment>
                                : 
                                <React.Fragment>
                                  <button 
                                    disabled={isSubmitting || values.client_notified_at !== null}
                                    type="submit" 
                                    className="btn btn-primary"
                                  >
                                    {isSubmitting ? 'Saving...' : 'Save'}
                                  </button>&nbsp;
                                </React.Fragment>
                                }
                              </div>
                              <div className="col-sm-8 text-right">
                                <button 
                                  disabled={isSubmitting || values.client_notified_at !== null}
                                  className="btn btn-outline-secondary" 
                                  type="button" 
                                  onClick={() => {
                                    sendNotificationHandler('/audits/' + ncr.audit_id + '/non-conformances/notification/' + ncr.id);

                                    setFieldValue('client_notified_at', Date.now());
                                  }}
                                >
                                  <i className="icon-envelope"></i> {isSubmitting ? 'Notifying Client...' : 'Notify Client'}
                                </button>
                              </div>
                            </div>
                            
                          </FormikForm>
                        )}
                      </Formik>
                    </TabPane>
                  )
                : null}
                <TabPane tabId="NCR-0">
                  <Formik 
                    enableReinitialize={true}
                    initialValues={auditNote}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      await create('/audits/' + id + '/non-conformances', values);

                      setAuditNote({
                        no: "",
                        location: "",
                        area_department: "",
                        standard_id: "",
                        clause: "",
                        grade: "",
                        details: "",
                        issued_by: "",
                        issued_date: "",
                      });

                      resetForm({
                        no: "",
                        location: "",
                        area_department: "",
                        standard_id: "",
                        clause: "",
                        grade: "",
                        details: "",
                        issued_by: "",
                        issued_date: "",
                      });
                    }}
                  >
                    {({ isSubmitting, values, setFieldValue, handleChange }) => (
                      <FormikForm>
                        <FormGroup>
                          <Label for="no">NCR No.</Label>
                          <Input 
                            // readOnly={readOnly}
                            type="text" 
                            // className="custom-select" 
                            name="no" 
                            id="no"
                            onChange={handleChange}
                            value={values.no}
                          />
                        </FormGroup>

                        <div className="row clearfix">
                          <div className="col-md-6">
                            <FormGroup>
                              <Label for="location">Location</Label>
                              <Input 
                                // readOnly={readOnly}
                                type="text" 
                                // className="custom-select" 
                                name="location" 
                                id="location"
                                onChange={handleChange}
                                value={values.location}
                              />
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label for="area_department">Area/Department</Label>
                              <Input 
                                // readOnly={readOnly}
                                type="text" 
                                // className="custom-select" 
                                name="area_department" 
                                id="area_department"
                                onChange={handleChange}
                                value={values.area_department}
                              />
                            </FormGroup>
                          </div>
                        </div>

                        <div className="row clearfix">
                          <div className="col-md-4">
                            <FormGroup>
                              <Label for="standard_id">Standard</Label>
                              <Input 
                                // key={note.id}
                                // readOnly={readOnly}
                                type="select" 
                                className="custom-select" 
                                name="standard_id" 
                                // id="Organization"
                                onChange={handleChange}
                                value={values.standard_id}
                              >
                                <option></option>
                                {/* {data.scope_of_certifications && data.scope_of_certifications.length ? data.scope_of_certifications.map((STANDARD, i) => 
                                  <option key={i} value={STANDARD.certification.id}>{STANDARD.certification.standard}</option>
                                ) : null} */}
                                {data.audit_plan && data.audit_plan.client && data.audit_plan.client.certifications ? 
                                data.audit_plan.client.certifications.map((certification, i) => 
                                <option key={i} value={certification.id}>{certification.standard}</option>
                                ) : ""}
                              </Input>
                            </FormGroup>
                          </div>
                          <div className="col-md-4">
                            <FormGroup>
                              <Label for="clause">Clause</Label>
                              <Input 
                                // readOnly={readOnly}
                                type="text" 
                                // className="custom-select" 
                                name="clause" 
                                id="clause"
                                onChange={handleChange}
                                value={values.clause}
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-4">
                            <FormGroup>
                              <Label for="">Grade</Label>
                              <div>
                            <label className="fancy-radio">
                              <Input
                                type="radio"
                                name="grade"
                                value="1"
                                onClick={() => {
                                  setFieldValue('grade', 'Major')
                                }}
                                checked={values.grade && values.grade ? values.grade === 'Major' : ""}
                              />
                              <span>
                                <i></i> Major
                              </span>
                            </label>

                            <label className="fancy-radio">
                              <Input
                                type="radio"
                                name="grade"
                                value="0"
                                onClick={() => {
                                  setFieldValue('grade', 'Minor')
                                }}
                                checked={values.grade && values.grade ? values.grade === 'Minor' : ""}
                              />
                              <span>
                                <i></i> Minor
                              </span>
                            </label>
                          </div>
                            </FormGroup>
                          </div>
                        </div>

                        <FormGroup>
                          <Label for="details">Details/Objective Evidence Of Nonconformity</Label>
                          <Input 
                            // readOnly={readOnly}
                            type="textarea" 
                            // className="custom-select" 
                            name="details" 
                            id="details"
                            onChange={handleChange}
                            value={values.details}
                            rows={5}
                          />
                        </FormGroup>

                        <div className="row clearfix">
                          <div className="col-md-8">
                            <FormGroup>
                              <Label for="issued_by">Issued By</Label>
                              <Input 
                                // key={note.id}
                                // readOnly={readOnly}
                                type="select" 
                                className="custom-select" 
                                name="auditor_id" 
                                // id="Organization"
                                onChange={handleChange}
                                value={values.issued_by}
                              >
                                <option></option>
                                {auditors.map((auditor, i) => 
                                  <option key={i} value={auditor.value}>{auditor.label}</option>
                                )}
                              </Input>
                            </FormGroup>
                          </div>
                          <div className="col-md-4">
                            <FormGroup>
                              <Label for="issued_date">Date</Label>
                              <DateRangePicker 
                                drops="up"
                                singleDatePicker
                                containerStyles={{ display: "block" }}
                                startDate={values.issued_date && values.issued_date.length ? moment(values.issued_date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                onApply={(event, picker) => { 
                                  setFieldValue('issued_date', picker.startDate.format('YYYY-MM-DD'));
                                  // setFieldValue('date_of_audit_end', picker.endDate.format('YYYY-MM-DD'));

                                  // setAuditStartDate(moment(picker.startDate));

                                  // setAuditEndDate(moment(picker.endDate));
                                }}
                              >
                                <Input 
                                  autoComplete="off"
                                  readOnly={true}
                                  type="text" 
                                  // name="date_of_audit" 
                                  id="issued_date"
                                  // onChange={handleChange}
                                  defaultValue={values.issued_date ? moment(values.issued_date).format('DD/MM/YYYY') : ""}
                                />
                              </DateRangePicker>
                            </FormGroup>
                          </div>
                        </div>

                        <div className="row clearfix">
                          <div className="col-sm-4">
                            {/* {readOnly === false ? 
                            <React.Fragment> */}
                            <button 
                              disabled={isSubmitting}
                              type="submit" 
                              className="btn btn-primary"
                            >
                              {isSubmitting ? 'Saving...' : 'Save'}
                            </button>&nbsp;
                          </div>
                          <div className="col-sm-8">
                            {/* <button 
                              // disabled={isSubmitting}
                              type="button" 
                              className="btn btn-primary"
                            >
                              {isSubmitting ? 'Creating...' : 'Create'}
                            </button>&nbsp; */}
                          </div>
                        </div>

                      </FormikForm>
                    )}
                  </Formik>
                </TabPane>

              </TabContent>

            </TabPane>

            <TabPane tabId={4}>
            <Formik 
                enableReinitialize={true}
                initialValues={data}
                onSubmit={async (values, { setSubmitting }) => {
                  let token = await localStorage.getItem('auth-token');

                  token = JSON.parse(token);

                  api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                  api.post('/audits/' + id + '/opportunity-for-improvements', JSON.stringify(values), {
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                  .then(function (response) {
                    addToast('Row successfully updated', { appearance: 'success' });

                    reload(true);
                  })
                  .catch(function (error) {
                    addToast(error.message, { appearance: 'error' });
                  });
                }}
              >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup>
                      <div className="table">
                        <BootstrapTable 
                          // remote={true}
                          bootstrap4
                          bordered={false}
                          classes="table-hover js-basic-example dataTable table-custom m-b-0"
                          headerClasses="thead-dark"
                          keyField="id"
                          data={data.opportunity_for_improvements ? data.opportunity_for_improvements : []} 
                          columns={[
                            {
                              dataField: 'date',
                              text: "Date",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell ? moment(cell).format('DD/MM/YY') : ''}&nbsp;
                                  </div>
                                )
                              },
                              editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                                <DateRangePicker
                                  singleDatePicker
                                  containerStyles={{ display: "block" }}
                                  startDate={values.date && values.date.length ? moment(values.date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                  // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                  onApply={(event, picker) => { 
                                    // setFieldValue('date', picker.startDate.format('YYYY-MM-DD'));

                                    editorProps.onUpdate(picker.startDate.format('YYYY-MM-DD'));
                                  }}
                                >
                                  <Input 
                                    // disabled={values.close_notification_at && values.close_notification_at.length}
                                    autoComplete="off"
                                    readOnly={true}
                                    type="text" 
                                    // name="date_of_audit" 
                                    // id="date"
                                    // onChange={handleChange}
                                    defaultValue={value ? moment(value).format('DD/MM/YYYY') : ""}
                                  />
                                </DateRangePicker>
                              ),
                              footer: () => {
                                return (
                                  <DateRangePicker
                                    singleDatePicker
                                    containerStyles={{ display: "block" }}
                                    startDate={values.date && values.date.length ? moment(values.date).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                    // endDate={values.date_of_audit_end && values.date_of_audit_end.length ? moment(values.date_of_audit_end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                                    onApply={(event, picker) => { 
                                      setFieldValue('date', picker.startDate.format('YYYY-MM-DD'));
                                    }}
                                  >
                                    <Input 
                                      // disabled={values.close_notification_at && values.close_notification_at.length}
                                      autoComplete="off"
                                      readOnly={true}
                                      type="text" 
                                      // name="date_of_audit" 
                                      // id="date"
                                      // onChange={handleChange}
                                      defaultValue={values.date ? moment(values.date).format('DD/MM/YYYY') : ""}
                                    />
                                  </DateRangePicker>
                                )
                              }
                            },
                            {
                              dataField: "number",
                              text: "Number",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="text"
                                    name="number"
                                    onChange={handleChange}
                                    value={values.number || ""}
                                  />
                                )
                              }
                            },
                            {
                              dataField: "process",
                              text: "Process",
                              style: {
                                whiteSpace: "pre-wrap"
                              },
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="text"
                                    name="process"
                                    onChange={handleChange}
                                    value={values.process || ""}
                                  />
                                )
                              }
                            },
                            {
                              dataField: "ofi",
                              text: "OFI",
                              style: {
                                whiteSpace: "pre-wrap"
                              },
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <div className="editable form-control-plaintext">
                                    {cell}&nbsp;
                                  </div>
                                )
                              },
                              editCellStyle: {
                                minHeight: "500px",
                                minWidth: "300px",
                              },
                              editor: {
                                type: Type.TEXTAREA
                              },
                              footer: () => {
                                return (
                                  <Input 
                                    type="textarea"
                                    name="ofi"
                                    onChange={handleChange}
                                    value={values.ofi || ""}
                                  />
                                )
                              }
                            },
                            {
                              dataField: "id",
                              text: "",
                              formatter: (cell, row, rowIndex) => {
                                return (
                                  <Button 
                                    color="danger" 
                                    type="button"
                                    onClick={() => {
                                      // console.log(cell);
                                      deleteHandler('/audits/' + id + '/opportunity-for-improvements/' + cell);
                                    }}
                                  >
                                    <i className="icon-trash"></i>
                                  </Button>
                                )
                              },
                              footer: () => {
                                return (
                                  <Button color="primary">
                                    <i className="icon-plus"></i>
                                  </Button>
                                )
                              },
                            }
                          ]}
                          cellEdit={ 
                            cellEditFactory({ 
                              mode: "click",
                              blurToSave: true,
                              afterSaveCell: async (oldValue, newValue, row, column) => {
                                // console.log(oldValue.toString(), newValue)

                                if (oldValue === null && newValue === "") return;

                                if (oldValue === newValue) return;

                                let token = await localStorage.getItem('auth-token');

                                token = JSON.parse(token);

                                api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                                values = {
                                  [column.dataField]: newValue
                                }

                                api.put('/audits/' + id + '/opportunity-for-improvements/' + row.id, JSON.stringify(values), {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                })
                                .then(function (response) {
                                  addToast('Row successfully updated', { appearance: 'success' });

                                  reload(true);
                                })
                                .catch(function (error) {
                                  addToast(error.message, { appearance: 'error' });
                                });
                              }
                            })
                          }
                        />
                      </div>
                    </FormGroup>
                  </FormikForm>
                )}
              </Formik>
            </TabPane>
            
            {/* <div className="row clearfix">
              <div className="col-sm-4">
                <button type="submit" className="btn btn-primary">
                  Save
                </button>&nbsp;
                <Button 
                  // className="btn btn-outline-secondary"
                  outline
                  color="secondary" 
                  tag={Link} 
                  to="/audit/audit"
                >
                  Cancel
                </Button>
              </div>
            </div> */}
          </TabContent>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default AuditProcess;