import React, { useEffect, useState } from "react";

import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  Input,
  // Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from "formik";

import { useToasts } from 'react-toast-notifications';

import api from "../../utils/api";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AttendanceForm = (props) => {
  let { id } = useParams();
  
  let history = useHistory();

  let queryString = useQuery();

  const { addToast } = useToasts();

  const [INITIAL_VALUES, setValues] = useState({});

  const [readOnly, setReadOnly] = useState(false);

  return (
    <React.Fragment>
      <Formik 
        enableReinitialize={true}
        initialValues={INITIAL_VALUES}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // let token = await localStorage.getItem('auth-token');

          // token = JSON.parse(token);

          // api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

          values.audit_id = id;

          // values.updated_at = new Date().getTime();

          await api.post(`/attendances/${id}?${queryString.toString()}`, JSON.stringify(values), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(function (response) {
            addToast('Attendance recorded', { appearance: 'success' });

            history.push("/");
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });

          setValues({
            name: "",
            email: "",
            organization: "",
            department: ""
          });

          resetForm();
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <FormikForm>
            <Card className="invoice1">
              <div className="body">
                <div className="invoice-top clearfix">
                  <div className="logo">
                    <img src={process.env.PUBLIC_URL + '/logo-niosh.png'} alt="logo" className="rounded-circle img-fluid" />
                  </div>
                  <div className="info">
                  <h6>NIOSH CERTIFICATION SDN. BHD.</h6>
                    <p>7th Floor, NIOSH Tower, Lot 1, Jalan 15/1, Seksyen 15, 43650 Bandar Baru Bangi, Selangor<br />
                      Tel: +603 8769 2100 / +603 8911 3800   |   Fax: +603 8926 2900
                    </p>
                  </div>
                  <div class="title">
                    <h4></h4>
                  </div>
                </div>
                <div className="invoice-mid clearfix">&nbsp;</div>
              </div>
              <hr />
              <div className="header">
                <h2>Attendance</h2>
              </div>
              <div className="body">
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={async (e) => {
                      if (values.email === '') {
                        setValues({
                          name: "",
                          email: "",
                          organization: "",
                          department: ""
                        });

                        return setReadOnly(false);
                      }

                      let token = await localStorage.getItem('auth-token');

                      token = JSON.parse(token);

                      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

                      api.get('/attendances/' + id + '?email=' + values.email)
                      .then(function (response) {
                        // console.log(response);

                        // delete response.data.id;

                        if (response.data && response.data[0]) {
                          setValues(response.data[0]);

                          // setReadOnly(true);
                        }

                        // reload(false);
                      })
                      .catch(function (error) {
                        // if (error && error.response && error.response.data) {
                        //   addToast(error.response.data.message, { appearance: 'error' });
                        // } else {
                        //   addToast(error.message, { appearance: 'error' });
                        // }
                      });
                    }}
                    required={true}
                    autoComplete={true}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    readOnly={readOnly}
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    required={true}
                    autoComplete={true}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Organization</Label>
                  <Input
                    readOnly={readOnly}
                    type="text"
                    name="organization"
                    value={values.organization}
                    onChange={handleChange}
                    autoComplete={true}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Department</Label>
                  <Input
                    readOnly={readOnly}
                    type="text"
                    name="department"
                    value={values.department}
                    onChange={handleChange}
                    autoComplete={true}
                  />
                </FormGroup>

                <div className="row clearfix">
                  <div className="col-sm-4">
                    <button 
                      type="submit" 
                      className="btn btn-primary"
                      disabled={isSubmitting ? true : false}
                    >
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>&nbsp;
                    {/* <Button 
                      // className="btn btn-outline-secondary"
                      outline
                      color="secondary" 
                      tag={Link} 
                      to={props.path}
                    >
                      Cancel
                    </Button> */}
                  </div>
                </div>
              </div>
            </Card>
          </FormikForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default AttendanceForm;