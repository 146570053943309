import React, { 
  Fragment, 
  useContext, 
  useEffect,
  useState,
} from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  Link,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import {
  // Button,
  Navbar,
} from 'reactstrap';

import api from "./utils/api";
import { db } from "./utils/firebase";

import { Helmet } from 'react-helmet';

import AuthContext from './authContext';

import { ToastProvider,useToasts } from 'react-toast-notifications';

import Dashboard from './containers/Dashboard';
// import AuditorDashboard from './containers/Dashboard/AuditorDashboard';
// import ClientDashboard from './containers/Dashboard/ClientDashboard';
// import NCSBDashboard from './containers/Dashboard/NCSBDashboard';
import Auditor from './containers/Auditor';
import AuditorApplication from './containers/AuditorApplication';
import AuditorRegistrationForm from './containers/Auditor/RegistrationForm';
import AuditorRegistrationLink from './containers/Auditor/RegistrationLink';
import AuditorLog from './containers/AuditorLog';
import AuditNotes from './containers/AuditNotes';
import AuditPlan from './containers/Audit/AuditPlan';
import AuditReport from './containers/Audit/Audit';
import AuthenticateCertificate from './containers/Audit/Audit/ViewAuthenticateCertificate';
import Client from './containers/Client';
import ClientDataDetails from './containers/ClientDataDetails';
import ClientApplication from './containers/ClientApplication';
import ClientRegistrationLink from './containers/Client/RegistrationLink';
import ClientRegistrationForm from './containers/Client/RegistrationForm';
import ClientRevenue from './containers/ClientRevenue';
import ClientFeedbacks from './containers/ClientFeedbacks';
import AuditorEvaluations from './containers/AuditorEvaluations';
import ContractReview from './containers/ContractReview';
import Login from './containers/Login';
// import Review from './containers/Review';
import CAP from './containers/Review/CAP/index';
import AuditSummary from './containers/Review/AuditSummary/index';
import OperationReview from './containers/Review/OperationReview/index';
import PeerReview from './containers/Review/PeerReview/index';
import Schedule from './containers/Schedule';
import User from './containers/User';
import AttendanceForm from './containers/Attendance';
import NonConformance from './containers/NonConformance';
import MeetingNotification from './containers/MeetingNotifications';
import BasicDataInformation from './containers/BasicDataInformation';
import BossChart from './containers/BossChart';
import StakeholderList from './containers/StakeholderList';
// import Projects from './containers/Projects';

import UserAccount from './components/UserAccount';
// import SidebarToggler from './components/SidebarToggler';
import SidebarNavigation from './components/SidebarNavigation';

import Chat from './containers/Chat';
import Library from './containers/Library';
import Notification from './containers/Notification';

import Profile from './containers/Profile';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ForgotPassword/reset';

import moment from 'moment';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import CompetencyMatrix from "./containers/AuditorApplication/CompetencyMatrix";
import AnalyticDashboard from './containers/Dashboard/AnalyticDashboard';
import BODDashboard from './containers/Dashboard/BODDashboard';
import ReportsImport from './containers/Dashboard/ReportsImport';

import AuditorClaim from './containers/AuditorClaim';
import AuditorClaimSetting from './containers/AuditorClaimSetting';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authState, setAuthState } = useContext(AuthContext);

  const { pathname } = useLocation();

  let { path } = useRouteMatch();

  let [profile, setProfile] = useState({});

  let [newMessages, hasNewMessages] = useState(false);
  
  let [newNotification, hasNewNotification] = useState(false);

  // let [offline, isOffline] = useState(true);

  let [since, setSince] = useState(Date.now());
  const { addToast } = useToasts();
  useEffect(() => {
    if (pathname !== '/login') {
      const getMe = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        if (token === null) {
          return;
        }

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
        
        await api.post('/auth/me')
        .then(function (response) {
          setProfile(response.data);

          if(response.data.roles[0].name.toLowerCase() === 'client'){
            if(response.data.auditor_evaluation >= 1){
              addToast('Please submit your Auditor Evaluation', { appearance: 'error',placement: 'top-center' });
            }
            if(response.data.client_feedbacks >= 1){
              addToast('Please submit your Client Feedback', { appearance: 'error',placement: 'top-center' });          
            }
          }

          db.ref(`users/${response.data.id}`).on("value", snapshot => {
            let user = snapshot.val();

            // console.log(user.since);
            
            if (user !== null) {
              setSince(user.since);
            } else {
              setSince(moment());
            }

            // console.log(pathname);
            
            db.ref("notifications/" + window.btoa(response.data.email)).orderByChild('isNew').equalTo(1).on("value", (snapshot) => {
              if (snapshot.val() !== null) {
                hasNewNotification(true);
              } else {
                hasNewNotification(false);
              }
            });
            
            if (pathname !== '/chat') {
              if (since) {
                db.ref("chats").orderByChild("timestamp").startAt(user !== null ? user.since : null).on("child_added", (snapshot) => {
                  // console.log(snapshot.val());
                  if (snapshot.val() !== null) {
                    hasNewMessages(true);
                  } else {
                    hasNewMessages(false);
                  }
                });
              }
            } else {
              hasNewMessages(false);
            }
          });
        })
        .catch(function (error) {
          
        });
        
        
      }

      getMe();
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  const logout = () => {
    localStorage.removeItem('auth-token');
    localStorage.removeItem('auth-user');
    setAuthState('');
  }

  const toggler = () => {
    const element = document.querySelector('body');
    
    element.classList.toggle('offcanvas-active');
  }

  return (
    <AuthContext.Consumer>
      {({ authState }) => (
        <Route 
          render={
            props => 
            authState === 'signIn'
            ? <Fragment>
                <Navbar className="navbar-fixed-top">
                  <div className="container-fluid">
                    <div className="navbar-btn">
                      <button type="button" className="btn-toggle-offcanvas" onClick={() => toggler()}>
                        <i className="lnr lnr-menu fa fa-bars"></i>
                      </button>
                    </div>
                    <div className="navbar-brand">
                      <Link to="/">
                        <img 
                          src="/logo-niosh.png" alt="" 
                          className="img-responsive logo" 
                          style={{ 
                            float: "left",
                            height: "50px", 
                            marginTop: "-10px",
                            paddingRight: "10px",
                            width: "50px"
                          }}
                        />

                        <div 
                          className="d-lg-none"
                          style={{ 
                            color: "#000", 
                            float: "left",
                            // display: "inline-block !important",
                            fontFamily: "Josefin Sans", 
                            fontSize: "20px", 
                            // marginLeft: "15px", 
                            // position: "relative",
                          }}
                        >
                          SCUDS
                        </div>

                        <div 
                          className="d-none d-lg-block"
                          style={{ 
                            color: "#000", 
                            float: "left",
                            // display: "inline-block !important",
                            fontFamily: "Josefin Sans", 
                            fontSize: "15px", 
                            // marginLeft: "15px", 
                            // position: "relative",
                          }}
                        >
                          Sustainable Certification<br />Unify Dedicated System
                        </div>
                      </Link>
                    </div>
                    <div className="navbar-right">
                      <div id="navbar-menu">
                        <ul className="nav navbar-nav">
                          {profile.roles && profile.roles[0].name.toLowerCase() !== 'client' ?
                          <li>
                            <Link to="/chat" className="icon-menu">
                              <i className="icon-bubbles"></i>
                              {newMessages ? <span className="notification-dot"></span> : null}
                            </Link>
                          </li>
                          : null}
                          <li>
                            <Link to="/notification" className="icon-menu">
                              <i className="icon-bell"></i>
                              {newNotification ? <span className="notification-dot"></span> : null}
                            </Link>
                          </li>
                          <li>
                            <a href="/" className="icon-menu" onClick={(e) => {
                              e.preventDefault();

                              logout();
                            }}>
                              <i className="icon-login"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Navbar>
                <div id="left-sidebar" className="sidebar">
                  <div className="sidebar-scroll">
                    <UserAccount />
                    <SidebarNavigation />
                  </div>
                </div>
                <div id="main-content">
                  <Component {...props} />
                  <p className="text-center">Powered by Greentagpro</p>
                </div>
              </Fragment>
            : <Redirect 
                to={{
                  pathname: '/login',
                  state: { from: props.location }
                }}
              />
          }
          {... rest}
        />
      )}
    </AuthContext.Consumer>
  )
}

const NoMatch = () => {
  // let location = useLocation();

  return (
    <div id="wrapper">
      <div className="vertical-align-wrap">
        <div className="vertical-align-middle maintenance">
          <div className="text-center">
            <article>
              <h1>404 Not Found</h1>
              <div>
                <p>Sorry for the inconvenience</p>
              </div>
            </article>
            <div className="margin-top-30">
                <Link to="/">Home</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// const Dashboard = () => {
//   return (
//     <div className="container-fluid">
//       <div className="block-header">
//         <div className="row">
//           <div className="col-lg-6 col-md-8 col-sm-12">
//             <h2>
//               <SidebarToggler />
//               Dashboard
//             </h2>
//             <ul className="breadcrumb">
//               <li className="breadcrumb-item">
//                 <Link to="/">
//                   <i className="icon-home"></i>
//                 </Link>
//               </li>
//               <li className="breadcrumb-item">Dashboard</li>
//               {/* <li className="breadcrumb-item active">Departments List</li> */}
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  );
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value, localStorageKey]);

  return [value, setValue];
}

function App() {
  const [authState, setAuthState] = useStateWithLocalStorage('auth-state');
  const value = { authState, setAuthState };

  useEffect(() => {
    const element = document.querySelector('body');
    
    element.classList.add('theme-green');

    window.addEventListener('load', () => {
      document.getElementsByClassName('page-loader-wrapper')[0].style = 'display: none';
    });

  }, []);
  
  const api_regex = /^\/api\/.*/
  if (api_regex.test(window.location.pathname)) {
    return <div />
  }
  
  

  return (
    <ToastProvider autoDismiss={true}>
      <AuthContext.Provider value={value}>
        <Helmet>
          <title>Sustainable Certification Unify Dedicated System &bull; NIOSH CERTIFICATION SDN BHD</title>
        </Helmet>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password/:token" component={ResetPassword} />

            <Route path="/attendance/:id" component={AttendanceForm} />

            <Route path="/auditor/register" component={AuditorRegistrationForm} />

            <Route path="/client/register" component={ClientRegistrationForm} />

            <Route path="/client/register-link" component={ClientRegistrationLink} />

            <Route path="/:id/certificate/:certificate_no*" component={AuthenticateCertificate} />

            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute exact path="/technical" component={BODDashboard} />
            <PrivateRoute exact path="/analytics" component={AnalyticDashboard} />
            <PrivateRoute exact path="/imports" component={ReportsImport} />

            <PrivateRoute exact path="/application-review">
              <Redirect
                to={{
                    pathname: "/application-review/client-application/iso",
                    // state: { from: props.location }
                }}
              />
            </PrivateRoute>
            <PrivateRoute exact path="/auditor">
              <Redirect
                to={{
                    pathname: "/auditor/application-form",
                    // state: { from: props.location }
                }}
              />
            </PrivateRoute>
            <PrivateRoute exact path="/audit-module">
              <Redirect
                to={{
                    pathname: "/audit-module/audit-plan/iso/iso",
                    // state: { from: props.location }
                }}
              />
            </PrivateRoute>
            <PrivateRoute exact path="/client-module">
              <Redirect
                to={{
                    pathname: "/client-module/revenue",
                    // state: { from: props.location }
                }}
              />
            </PrivateRoute>
            <PrivateRoute exact path="/review">
              <Redirect
                to={{
                    pathname: "/review/operation-review",
                    // state: { from: props.location }
                }}
              />
            </PrivateRoute>
            <PrivateRoute path="/application-review/client-application" component={ClientApplication} />
            <PrivateRoute path="/application-review/client-database" component={Client} />
            <PrivateRoute path="/application-review/client-data-details" component={ClientDataDetails} />
            <PrivateRoute path="/application-review/contract-review" component={ContractReview} />
            <PrivateRoute path="/application-review/client-feedbacks" component={ClientFeedbacks} />
            <PrivateRoute path="/application-review/auditor-evaluations" component={AuditorEvaluations} />
            <PrivateRoute path="/audit-module/audit-notes" component={AuditNotes} />
            <PrivateRoute path="/audit-module/audit-plan" component={AuditPlan} />
            <PrivateRoute path="/audit-module/audit-report/non-conformance" component={NonConformance} />
            <PrivateRoute path="/audit-module/audit-report" component={AuditReport} />
            <PrivateRoute path="/audit-module/calendar" component={Schedule} />
            <PrivateRoute path="/auditor/application-form" component={AuditorApplication} />
            <PrivateRoute path="/auditor/auditor-database" component={Auditor} />
            <PrivateRoute path="/auditor/competency-matrix" component={CompetencyMatrix} />
            <PrivateRoute path="/auditor/audit-log" component={AuditorLog} />
            
            <PrivateRoute path="/auditor/auditor-evaluations" component={AuditorEvaluations} />
            <PrivateRoute path="/auditor/auditor-claim" component={AuditorClaim} />
            <PrivateRoute path="/auditor/auditor-claim-setting" component={AuditorClaimSetting} />

            <PrivateRoute path="/client-module/revenue" component={ClientRevenue} />
            <PrivateRoute path="/review/cap" component={CAP} />
            <PrivateRoute path="/review/audit-summary" component={AuditSummary} />
            <PrivateRoute path="/review/operation-review" component={OperationReview} />
            <PrivateRoute path="/review/peer-review" component={PeerReview} />
            {/* <PrivateRoute path="/schedule" component={Schedule} /> */}
            <PrivateRoute path="/boss-chart" component={BossChart} />
            <PrivateRoute path="/library" component={Library} />
            <PrivateRoute path="/user" component={User} />
            <PrivateRoute path="/chat" component={Chat} />
            <PrivateRoute path="/notification" component={Notification} />
            <PrivateRoute path="/meeting-notification" component={MeetingNotification} />
            <PrivateRoute path="/basic-data-information" component={BasicDataInformation} />
            <PrivateRoute path="/stakeholder-list" component={StakeholderList} />
            <PrivateRoute path="/profile" component={Profile} />
            
            {/* <PrivateRoute path="/admin/permissions" component={User} /> */}
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Router>
      </AuthContext.Provider>
    </ToastProvider>
  );
}

export default App;
