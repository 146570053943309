import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import {
  Link,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { useToasts } from 'react-toast-notifications';

import AuthContext from './../../authContext';

import api from '../../utils/api';

import './style.css';

const ForgotPassword = () => {
  let [login, setLogin] = useState({});
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  const { setAuthState } = useContext(AuthContext);

  const { addToast } = useToasts();
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    document.getElementsByClassName('page-loader-wrapper')[0].style = 'display: block';

    const querystring = require('querystring');

    await api.post('/auth/forgot-password', querystring.stringify(login), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(function (response) {
      console.log(response);
      addToast('We have e-mailed your password reset link!', { appearance: 'success' });

    })
    .catch(function (error) {
      addToast(error.message, { appearance: 'error' });
    })
    .then(function () {
      document.getElementsByClassName('page-loader-wrapper')[0].style = 'display: none';
    });

    // setTimeout(() => {
    //   setAuthState('signIn');

    //   history.replace(from);
    // }, 100);
  }

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    login[name] = value;
    setLogin(login);

    // console.log(login);
  }

  return (
    <div className="vertical-align-wrap">
      <div className="vertical-align-middle auth-main">
        <div className="auth-box">
          <div className="top">
            <img src="/logo-niosh.png" alt="" />
          </div>
          <Card>
            <div className="header">
              <p className="lead">Reset my password</p>
            </div>
            <div className="body">
            <p>Please enter your email address below to receive instructions for resetting password.</p>
              <form className="form-auth-small" onSubmit={handleSubmit}>
                <FormGroup>
                  <Label className="control-label sr-only">Email</Label>
                  <Input type="email" name="email" placeholder="Email" onChange={handleInputChange} />
                </FormGroup>
                <Button block color="primary">RESET PASSWORD</Button>
                <div className="bottom">
                  <span className="helper-text m-b-10">
                  <i className="fa fa-lock"></i> Know your password?   <Link to="/login">Login</Link>
                  </span>
                </div>
              </form>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword;