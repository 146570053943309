import React from 'react';

import {
  // Link,
  Switch,
  Redirect,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import Listing from './Listing';
import Form from './Form';
import View from './View';
import ViewDocument from './ViewDocument';
import InvitationForStakeholderConsultation from './InvitationForStakeholderConsultation'

const AuditPlan = (props) => {
  let { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route path={`${path}/invitation-for-stakeholder-consultation/:id`}>
          <InvitationForStakeholderConsultation readOnly={true} {...props} />
        </Route>
        <Route exact path={path}>
          <Redirect
            to={{
                pathname: `${path}/iso/iso`
                // state: { from: props.location }
            }}
          />
        </Route>
        <Route exact path={`${path}/mspo`}>
          <Redirect
            to={{
                pathname: `${path}/mspo/part-2`
                // state: { from: props.location }
            }}
          />
        </Route>
        <Route exact path={`${path}/:type/:subtype`}>
          <Listing path={path} {...props} />
        </Route>
        <Route path={`${path}/:type/:subtype/create`}>
          <Form path={`${path}/:type/:subtype`} {...props} />
        </Route>
        <Route path={`${path}/:type/:subtype/edit/:id`}>
          <Form path={`${path}/:type/:subtype`} {...props} />
        </Route>
        <Route path={`${path}/:type/:subtype/view/:id`}>
          <ViewDocument path={`${path}/:type/:subtype`} readOnly={true} {...props} />
        </Route>
      </Switch>
    </React.Fragment>
  )
}

export default AuditPlan;