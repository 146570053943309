import React, { useEffect, useState } from 'react';

import {
  // Link,
  useHistory,
  // useLocation,
  useParams,
} from 'react-router-dom';

import {
  // Button,
  // Card,
  Input,
} from 'reactstrap';

import SignaturePad from 'react-signature-pad-wrapper';

import { useToasts } from 'react-toast-notifications';

import moment from 'moment';

import api from '../../utils/api';

let sigCanvas;

const INITIAL_VALUES = {
  client: {}
};

const View = (props) => {
  let { id } = useParams();
  let history = useHistory();
  // let location = useLocation();
  // let { from } = location.state || { from: { pathname: "/client-application" } };

  const { addToast } = useToasts();

  const [CERTIFICATIONS, setCertifications] = useState({});
  const [NACE_CODES, setNaceCodes] = useState([]);
  const [INDUSTRIES, setIndustries] = useState({});
  const [data, setData] = useState(INITIAL_VALUES);

  useEffect(() => {
    const getCertifications = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/lookup/certifications?type=iso')
      .then(function (response) {
        // console.log(response.data);

        setCertifications(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response) {
          addToast(error.response.data.application.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getCertifications();

    const getNaceCodes = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/nace-codes')
      .then(function (response) {
        // console.log(response.data);

        setNaceCodes(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getNaceCodes();    
    
    const getIndustries = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/lookup/industries')
      .then(function (response) {
        // console.log(response.data);

        setIndustries(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getIndustries();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/application-forms/' + id)
        .then(function (response) {
         console.log(response.data);


          // delete response.data.application.id;

          if (response.data && response.data.industries) {
            let selectedIndustries = [];
            response.data.industries.map((industry, key) => {
              return selectedIndustries.push(industry.id);
            });

            response.data.industries = selectedIndustries;
          }
          if (response.data && response.data.nace_codes) {
            let selectedCodes = [];
            response.data.nace_codes.map((nace_code, key) => {
              return selectedCodes.push(nace_code.id);
            });

            response.data.nace_codes = selectedCodes;
          }
          console.log(response.data);


          if (response.data && response.data.certifications) {
            let selectedCertifications = [];
            response.data.certifications.map((certification, key) => {
              return selectedCertifications.push(certification.id);
            });

            response.data.certifications = selectedCertifications;
          }

          if (response.data.application && response.data.application.declaration_signature) {
            // console.log(response.data.application.declaration_signature);
            sigCanvas.fromDataURL(response.data.application.declaration_signature);
          }
          sigCanvas.off();

          setData(response.data);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error.response && error.response.data) {
            switch(error.response.status) {
              case 403:
                addToast("Unauthorized", { appearance: 'error' });
                history.push(props.path);
                break;

              case 404:
                addToast(`Client application ID #${id} does not exist`, { appearance: 'error' });
                history.push(props.path);
                break;

              default:
                addToast(error.response.data.message, { appearance: 'error' });
            }
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    }
  }, [id]);

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
        <div className="card invoice1">
          <div className="body">
            <div className="invoice-top clearfix">
              <div className="logo">
                <img src={process.env.PUBLIC_URL + '/logo-niosh.png'} alt="logo" className="rounded-circle img-fluid" />
              </div>
              <div className="info">
                <h6>NIOSH CERTIFICATION SDN. BHD.</h6>
                <p>7th Floor, NIOSH Tower, Lot 1, Jalan 15/1, Seksyen 15, 43650 Bandar Baru Bangi, Selangor<br />
                  Tel: +603 8769 2100 / +603 8911 3800   |   Fax: +603 8926 2900
                </p>
              </div>
              <div class="title">
                {/* <h4>Certification Application Form</h4> */}
              </div>
            </div>
            <div className="invoice-mid clearfix">&nbsp;</div>
            <hr />

            <table className="table table-bordered mt-4 mb-4">
              <thead className="thead-light">
                <tr>
                  <th>COMPANY DETAILS</th>
                </tr>
              </thead>
            </table>

            <table>
              <tbody>
                <tr>
                  <td style={{ verticalAlign: "top", width: "50px" }}>
                    <h6>1.</h6>
                  </td>
                  <td>
                    <h6>Name of Company/Organization</h6>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {data && data.name ? data.name : null}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "top", width: "50px" }}>
                    <h6>2.</h6>
                  </td>
                  <td>
                    <div className="row clearfix">
                      <div className="col-sm-6">
                        <h6>Address</h6>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {data && data.address_1 ? <React.Fragment>{data.address_1}<br /></React.Fragment> : ''}
                          {data && data.address_2 ? <React.Fragment>{data.address_2}<br /></React.Fragment> : ''}
                          {data && data.address_3 ? <React.Fragment>{data.address_3}<br /></React.Fragment> : ''}
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <h6>Tel. No.</h6>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {data && data.tel ? data.tel : ''}
                        </p>

                        <h6>Fax. No.</h6>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {data && data.fax ? data.fax : ''}
                        </p>

                        <h6>Webmail</h6>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {data && data.email ? data.email : ''}
                        </p>

                        <h6>Website</h6>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {data && data.website ? data.website : ''}
                        </p>
                      </div>
                    </div>

                    <div className="row clearfix">                      
                      <div className="col-sm-6">
                        <h6>Legal Status (R.O.C or R.O.B No.)</h6>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                        {data && data.legal_status ? data.legal_status : null}
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <h6>Copy of R.O.C Certificate</h6>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {data && data.roc_certificate && data.roc_certificate.path && data.roc_certificate.url ?
                          <a className="btn btn-link" href={data.roc_certificate.url} target="_blank" >{data.roc_certificate.originalName}</a>  
                          : data.roc_certificate && data.roc_certificate.path ?
                          <a className="btn btn-link" href={data.roc_certificate.path} target="_blank" >
                            {data.roc_certificate.originalName}</a> : '-' }
                        </p>
                      </div>
                    </div>

                    <div className="row clearfix">

                      <div className="col-sm-6">
                        <h6>Name of Management Representative</h6>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {data && data.management_representative_name ? data.management_representative_name : '-'}
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <h6>Management Representative Designation</h6>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {data && data.management_representative_designation ? data.management_representative_designation : '-'}
                        </p>
                      </div>
                    </div>

                    <h6>Name of Contact Person</h6>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {data && data.contact_person_name ? data.contact_person_name : null}
                    </p>

                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <h6>Position</h6>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {data && data.contact_person_position ? data.contact_person_position : null}
                        </p>
                      </div>
                      <div className="col-sm-4">
                        <h6>H/Phone No.</h6>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {data && data.contact_person_phone ? data.contact_person_phone : null}
                        </p>
                      </div>
                      <div className="col-sm-4">
                        <h6>Email</h6>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {data && data.contact_person_email ? data.contact_person_email : null}
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
                {props.type !== 'mspo-sccs' ? 
                <tr>
                  <td style={{ verticalAlign: "top", width: "50px" }}>
                    <h6>3.</h6>
                  </td>
                  <td>
                    <h6>Size</h6>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {[{
                        'label': 'Small/Medium',
                        'help': '(Annual sales turnover < 25 mil, No of employee < 150)',
                        'value': 'S',
                      }, {
                        'label': 'Large',
                        'help': '(Annual sales turnover > 25 mil, No of employee > 150)',
                        'value': 'L',
                      }].map((SIZE, i) => 
                        <div key={i} className="fancy-checkbox mr-5" style={{ display: "inline-block"}}>
                          <label>
                            <Input 
                              disabled
                              type="checkbox" 
                              // name={PRODUCT.field}
                              // onClick={handleChange}
                              // onChange={() => {
                              //   setFieldValue(`client.${PRODUCT.field}`, !values.client[PRODUCT.field]);

                              //   if (!values.client[PRODUCT.field]) {
                              //     setFieldValue(`client.${PRODUCT.field}_products`, null);
                              //   }
                              // }}
                              // value="1"
                              checked={data && data.company_size ? data.company_size === SIZE.value : null} 
                            />
                            <span>
                              {SIZE.label}
                            </span>
                          </label>
                          <small className="form-text text-muted">{SIZE.help}</small>
                        </div>
                      )}
                    </p>
                  </td>
                </tr>
                : null}

                {props.type === 'mspo' ?
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>4.</h6>
                    </td>
                    <td>
                      <h6>Please indicate the Oil Palm Plantation / Palm Oil Mills details below or continue on a separate sheet if necessary.</h6>
                      <h6 style={{ fontSize: "0.9rem" }}>Types of MSPO Certification</h6>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                      {[{
                        'label': 'Individual Certification',
                        'value': 'I',
                      }, {
                        'label': 'Group Certification',
                        'value': 'G',
                      }].map((TYPE, i) => 
                        <div className="fancy-checkbox mr-5" style={{ display: "inline-block"}}>
                          <label>
                            <Input 
                              disabled
                              type="checkbox" 
                              // name={PRODUCT.field}
                              // onClick={handleChange}
                              // onChange={() => {
                              //   setFieldValue(`client.${PRODUCT.field}`, !values.client[PRODUCT.field]);

                              //   if (!values.client[PRODUCT.field]) {
                              //     setFieldValue(`client.${PRODUCT.field}_products`, null);
                              //   }
                              // }}
                              // value="1"
                              checked={data.application && data.application.type_of_mspo_certification ? data.application.type_of_mspo_certification === TYPE.value : null} 
                            />
                            <span>
                              {TYPE.label}
                            </span>
                          </label>
                        </div>
                      )}
                      </p>

                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Sites</th>
                            <th>Full Address Of Estates / Mill</th>
                            <th>Area of Plantation (Ha) / Mill Capacity (Mt/Hr)</th>
                            <th>Travel Hours from Nearest Town</th>
                            <th>Travel Hours from Nearest Airport</th>
                            <th>Years of Planting</th>
                          </tr>
                        </thead>
                        <tbody>
                        {data.application && data.application.branches && data.application.branches.length && data.application.branches.map((BRANCH, key) => 
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{BRANCH.address}</td>
                            <td>{BRANCH.area_or_capacity}</td>
                            <td>{BRANCH.travel_hour_town}</td>
                            <td>{BRANCH.travel_hour_airport}</td>
                            <td>{BRANCH.years_planting}</td>
                          </tr>
                        )}
                        </tbody>
                      </table>

                      <p style={{ whiteSpace: "pre-wrap" }}>No. Of Sites: {data.application && data.application.branches && data.application.branches.length ? data.application.branches.length : 0}</p>
                    </td>
                  </tr>
                : null}

                {props.type === 'iso' ?
                <React.Fragment>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>4.</h6>
                    </td>
                    <td>
                      <h6>Number of Employees: (For Head Office &amp; Site)</h6>
                      <div className="row clearfix">
                        <div className="col-sm-6">
                          <h6 style={{ fontSize: "0.9rem" }}>Management</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {data && data.num_of_employee_management ? parseInt(data.num_of_employee_management) : 0}
                          </p>
                        </div>
                        <div className="col-sm-6">
                          <h6 style={{ fontSize: "0.9rem" }}>Contract Workers</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {data && data.num_of_employee_contract_workers ? parseInt(data.num_of_employee_contract_workers) : 0}
                          </p>
                        </div>
                      </div>
                      <div className="row clearfix">
                        <div className="col-sm-6">
                          <h6 style={{ fontSize: "0.9rem" }}>Staff</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {data && data.num_of_employee_staff ? parseInt(data.num_of_employee_staff) : 0}
                          </p>
                        </div>
                        <div className="col-sm-6">
                          <h6 style={{ fontSize: "0.9rem" }}>Others</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {data && data.num_of_employee_others ? parseInt(data.num_of_employee_others) : 0}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>5.</h6>
                    </td>
                    <td>
                      <h6>Detail of Shift</h6>
                      <h6 style={{ fontSize: "0.9rem" }}>Shift Operation</h6>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {[{
                          'label': 'Yes (please indicate the number of employee per shift on the following)',
                          'value': 'Y',
                        }, {
                          'label': 'No',
                          'value': 'N',
                        }].map((OPTION, i) => 
                        <label className="fancy-radio">
                          <Input
                            disabled
                            type="radio"
                            // name="eligibility_for_multisite_sampling_audit_1a"
                            // value="1"
                            // onClick={() => {
                            //   setFieldValue('eligibility_for_multisite_sampling_audit_1a', 1)
                            // }}
                            checked={data.shift_operation === OPTION.value}
                          />
                          <span>
                            <i></i> {OPTION.label}
                          </span>
                        </label>
                        )}
                      </p>

                      {data.shift_operation === 'Y' ? 
                      <div className="row clearfix">
                        <div className="col-sm-4">
                          <h6 style={{ fontSize: "0.9rem" }}>1<sup>st</sup> Shift</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {data && data.num_of_employee_per_shift ? parseInt(data.num_of_employee_per_shift[0]) : 0}
                          </p>
                        </div>
                        <div className="col-sm-4">
                          <h6 style={{ fontSize: "0.9rem" }}>2<sup>nd</sup> Shift</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {data && data.num_of_employee_per_shift ? parseInt(data.num_of_employee_per_shift[1]) : 0}
                          </p>
                        </div>
                        <div className="col-sm-4">
                          <h6 style={{ fontSize: "0.9rem" }}>3<sup>rd</sup> Shift</h6>
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {data && data.num_of_employee_per_shift ? parseInt(data.num_of_employee_per_shift[2]) : 0}
                          </p>
                        </div>
                      </div>
                      : null}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>6.</h6>
                    </td>
                    <td>
                      <h6>Do you want to include the certification in the same registration to cover your network of local offices or branches?</h6>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {[{
                          'label': 'Yes',
                          'value': 'Y',
                        }, {
                          'label': 'No',
                          'value': 'N',
                        }].map((OPTION, i) => 
                        <label className="fancy-radio">
                          <Input
                            disabled
                            type="radio"
                            // name="eligibility_for_multisite_sampling_audit_1a"
                            // value="1"
                            // onClick={() => {
                            //   setFieldValue('eligibility_for_multisite_sampling_audit_1a', 1)
                            // }}
                            checked={data.application && data.application.cover_branches ? data.application.cover_branches === OPTION.value : ""}
                          />
                          <span>
                            <i></i> {OPTION.label}
                          </span>
                        </label>
                        )}
                      </p>

                      {data.application && data.application.cover_branches && parseInt(data.application.cover_branches) === 'Y' ?
                      <React.Fragment>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Sites</th>
                            <th>Address</th>
                            <th>No. Of Employees</th>
                            <th>No. Of Shifts</th>
                          </tr>
                        </thead>
                        <tbody>
                        {data.application.branches && data.application.branches.length && data.application.branches.map((BRANCH, key) => 
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{BRANCH.address}</td>
                            <td>{BRANCH.no_of_employees}</td>
                            <td>{BRANCH.no_of_shifts}</td>
                          </tr>
                        )}
                        </tbody>
                      </table>
                      <p style={{ whiteSpace: "pre-wrap" }}>No. Of Sites: {data.application.branches && data.application.branches.length ? data.application.branches.length : 0}</p>
                      </React.Fragment>
                      : null}
                    </td>
                  </tr>
                </React.Fragment>
                : null}
              </tbody>
            </table>

            {props.type !== 'mspo-sccs' ?
              <React.Fragment>
              <table className="table table-bordered mt-4 mb-4">
                <thead className="thead-light">
                  <tr>
                    <th>ELIGIBILITY OF AN ORGANIZATION FOR MULTI-SITE SAMPLING AUDIT</th>
                  </tr>
                </thead>
              </table>

              <p style={{ whiteSpace: "pre-wrap" }}>
                Please tick the following situations to determine the eligibility for certification of multiple-sites based on sampling:
              </p>

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>NO</th>
                    <th>SITUATIONS</th>
                    <th>YES</th>
                    <th>NO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1a</td>
                    <td style={{ whiteSpace: 'normal' }}>
                      The processes at all sites have to be substantially of 
                      the same kind and have to be operated to similar 
                      methods and procedures. <b>AND/OR</b>
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Label check>
                          <Input 
                            type="radio" 
                            name="eligibility_for_multisite_sampling_audit_1a" 
                            value="Y" 
                            onClick={handleChange}
                            checked={data.application.eligibility_for_multisite_sampling_audit_1a === 'Y'}
                          />
                        </Label>
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="application.eligibility_for_multisite_sampling_audit_1a"
                          value="1"
                          // onClick={() => {
                          //   setFieldValue('eligibility_for_multisite_sampling_audit_1a', 1)
                          // }}
                          checked={data.application && data.application.eligibility_for_multisite_sampling_audit_1a ? data.application.eligibility_for_multisite_sampling_audit_1a === 'Y' : ""}
                        />
                        <span>
                          <i></i>
                        </span>
                      </label>
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Label check>
                          <Input 
                            type="radio" 
                            name="eligibility_for_multisite_sampling_audit_1a" 
                            value="N"
                            onClick={handleChange}
                            checked={data.application.eligibility_for_multisite_sampling_audit_1a === 'N'}
                          />
                        </Label>
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="eligibility_for_multisite_sampling_audit_1a"
                          value="0"
                          // onClick={() => {
                          //   setFieldValue('eligibility_for_multisite_sampling_audit_1a', '0')
                          // }}
                          checked={data.application && data.application.eligibility_for_multisite_sampling_audit_1a ? data.application.eligibility_for_multisite_sampling_audit_1a === 'N' : ""}
                        />
                        <span>
                          <i></i>
                        </span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>1b</td>
                    <td style={{ whiteSpace: 'normal' }}>
                      Processes in each location 
                      are not similar but are clearly linked (e.g. fabrication 
                      in one location, assembly in several other locations
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Label check>
                          <Input 
                            type="radio" 
                            name="eligibility_for_multisite_sampling_audit_1b" 
                            value="Y"
                            onClick={handleChange}
                            checked={data.application.eligibility_for_multisite_sampling_audit_1b === 'Y'} 
                          />
                        </Label>
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="eligibility_for_multisite_sampling_audit_1b"
                          value="1"
                          // onClick={() => {
                          //   setFieldValue('eligibility_for_multisite_sampling_audit_1b', 1)
                          // }}
                          checked={data.application && data.application.eligibility_for_multisite_sampling_audit_1b ? data.application.eligibility_for_multisite_sampling_audit_1b === 'Y' : ""}
                        />
                        <span>
                          <i></i>
                        </span>
                      </label>
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Label check>
                          <Input 
                            type="radio" 
                            name="eligibility_for_multisite_sampling_audit_1b" 
                            value="N"
                            onClick={handleChange}
                            checked={data.application.eligibility_for_multisite_sampling_audit_1b === 'N'} 
                          />
                        </Label>
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="eligibility_for_multisite_sampling_audit_1b"
                          value="0"
                          // onClick={() => {
                          //   setFieldValue('eligibility_for_multisite_sampling_audit_1b', '0')
                          // }}
                          checked={data.application && data.application.eligibility_for_multisite_sampling_audit_1b ? data.application.eligibility_for_multisite_sampling_audit_1b === 'N' : ""}
                        />
                        <span>
                          <i></i>
                        </span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td style={{ whiteSpace: 'normal' }}>
                      The organization’s management system shall be under a 
                      centrally controlled and administered plan and be subject 
                      to central management review.
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Label check>
                          <Input 
                            type="radio" 
                            name="eligibility_for_multisite_sampling_audit_2" 
                            value="Y"
                            onClick={handleChange}
                            checked={data.application.eligibility_for_multisite_sampling_audit_2 === 'Y'} 
                          />
                        </Label>
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="eligibility_for_multisite_sampling_audit_2"
                          value="1"
                          // onClick={() => {
                          //   setFieldValue('eligibility_for_multisite_sampling_audit_2', 1)
                          // }}
                          checked={data.application && data.application.eligibility_for_multisite_sampling_audit_2 ? data.application.eligibility_for_multisite_sampling_audit_2 === 'Y' : ""}
                        />
                        <span>
                          <i></i>
                        </span>
                      </label>
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Label check>
                          <Input 
                            type="radio" 
                            name="eligibility_for_multisite_sampling_audit_2" 
                            value="N"
                            onClick={handleChange}
                            checked={data.application.eligibility_for_multisite_sampling_audit_2 === 'N'} 
                          />
                        </Label>
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="eligibility_for_multisite_sampling_audit_2"
                          value="0"
                          // onClick={() => {
                          //   setFieldValue('eligibility_for_multisite_sampling_audit_2', '0')
                          // }}
                          checked={data.application && data.application.eligibility_for_multisite_sampling_audit_2 ? data.application.eligibility_for_multisite_sampling_audit_2 === 'N' : ""}
                        />
                        <span>
                          <i></i>
                        </span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td style={{ whiteSpace: 'normal' }}>
                      All the relevant sites shall be subject to the organization’s internal 
                      audit program and all shall have been audited in accordance with that 
                      program prior to the certification body, NIOSH Certification starting 
                      its audit.
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Label check>
                          <Input type="radio" 
                            name="eligibility_for_multisite_sampling_audit_3" 
                            value="Y" 
                            onClick={handleChange}
                            checked={data.application.eligibility_for_multisite_sampling_audit_3 === 'Y'} 
                          />
                        </Label>
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="eligibility_for_multisite_sampling_audit_3"
                          value="1"
                          // onClick={() => {
                          //   setFieldValue('eligibility_for_multisite_sampling_audit_3', 1)
                          // }}
                          checked={data.application && data.application.eligibility_for_multisite_sampling_audit_3 ? data.application.eligibility_for_multisite_sampling_audit_3 === 'Y' : ""}
                        />
                        <span>
                          <i></i>
                        </span>
                      </label>
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Label check>
                          <Input 
                            type="radio" 
                            name="eligibility_for_multisite_sampling_audit_3" 
                            value="N"
                            onClick={handleChange}
                            checked={data.application.eligibility_for_multisite_sampling_audit_3 === 'N'} 
                          />
                        </Label>
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="eligibility_for_multisite_sampling_audit_3"
                          value="0"
                          // onClick={() => {
                          //   setFieldValue('eligibility_for_multisite_sampling_audit_3', '0')
                          // }}
                          checked={data.application && data.application.eligibility_for_multisite_sampling_audit_3 ? data.application.eligibility_for_multisite_sampling_audit_3 === 'N' : ""}
                        />
                        <span>
                          <i></i>
                        </span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td style={{ whiteSpace: 'normal' }}>
                      The organization’s is able to collect and analyze data from all sites and 
                      authorize to initiate organizational change if required.
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Label check>
                          <Input 
                            type="radio" 
                            name="eligibility_for_multisite_sampling_audit_4" 
                            value="Y"
                            onClick={handleChange}
                            checked={data.application.eligibility_for_multisite_sampling_audit_4 === 'Y'} 
                          />
                        </Label>
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="eligibility_for_multisite_sampling_audit_4"
                          value="1"
                          // onClick={() => {
                          //   setFieldValue('eligibility_for_multisite_sampling_audit_4', 1)
                          // }}
                          checked={data.application && data.application.eligibility_for_multisite_sampling_audit_4 ? data.application.eligibility_for_multisite_sampling_audit_4 === 'Y' : ""}
                        />
                        <span>
                          <i></i>
                        </span>
                      </label>
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Label check>
                          <Input 
                            type="radio" 
                            name="eligibility_for_multisite_sampling_audit_4" 
                            value="N"
                            onClick={handleChange}
                            checked={data.application.eligibility_for_multisite_sampling_audit_4 === 'N'} 
                          />
                        </Label>
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="eligibility_for_multisite_sampling_audit_4"
                          value="0"
                          // onClick={() => {
                          //   setFieldValue('eligibility_for_multisite_sampling_audit_4', '0')
                          // }}
                          checked={data.application && data.application.eligibility_for_multisite_sampling_audit_4 ? data.application.eligibility_for_multisite_sampling_audit_4 === 'N' : ""}
                        />
                        <span>
                          <i></i>
                        </span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td style={{ whiteSpace: 'normal' }}>
                      Use of temporary sites which are to be included within the scope of 
                      certification.
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Label check>
                          <Input 
                            type="radio" 
                            name="eligibility_for_multisite_sampling_audit_5" 
                            value="Y" 
                            onClick={handleChange}
                            checked={data.application.eligibility_for_multisite_sampling_audit_5 === 'Y'} 
                          />
                        </Label>
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="eligibility_for_multisite_sampling_audit_5"
                          value="1"
                          // onClick={() => {
                          //   setFieldValue('eligibility_for_multisite_sampling_audit_5', 1)
                          // }}
                          checked={data.application && data.application.eligibility_for_multisite_sampling_audit_5 ? data.application.eligibility_for_multisite_sampling_audit_5 === 'Y' : ""}
                        />
                        <span>
                          <i></i>
                        </span>
                      </label>
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'top'}}>
                      {/* <FormGroup check>
                        <Label check>
                          <Input 
                            type="radio" 
                            name="eligibility_for_multisite_sampling_audit_5" 
                            value="N"
                            onClick={handleChange}
                            checked={data.application.eligibility_for_multisite_sampling_audit_5 === 'N'} 
                          />
                        </Label>
                      </FormGroup> */}
                      <label className="fancy-radio">
                        <Input
                          type="radio"
                          name="eligibility_for_multisite_sampling_audit_5"
                          value="0"
                          // onClick={() => {
                          //   setFieldValue('eligibility_for_multisite_sampling_audit_5', '0')
                          // }}
                          checked={data.application && data.application.eligibility_for_multisite_sampling_audit_5 ? data.application.eligibility_for_multisite_sampling_audit_5 === 'N' : ""}
                        />
                        <span>
                          <i></i>
                        </span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-bordered mt-4 mb-4">
                <thead className="thead-light">
                  <tr>
                    <th>SCOPE/PROCESS</th>
                  </tr>
                </thead>
              </table>

              <table>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>1.</h6>
                    </td>
                    <td>
                      <h6>Scope Of Certification</h6>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {data && data.scope_of_certification ? data.scope_of_certification : null}
                      </p>
                    </td>
                  </tr>

                  {props.type === 'iso' ?
                    <tr>
                      <td style={{ verticalAlign: "top", width: "50px" }}>
                        <h6>2.</h6>
                      </td>
                      <td>
                        <h6>Nace Code</h6>
                        <div className="row clearfix">
                          <div className="col-sm-6">
                          {NACE_CODES.length ? NACE_CODES.map((NACE_CODE, i) =>
                          i < 20 ? (
                              <React.Fragment key={i}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      disabled
                                      type="checkbox" 
                                      checked={data.nace_codes && data.nace_codes.length && data.nace_codes.indexOf(NACE_CODE.id) !== -1} 
                                      />
                                    <span>{NACE_CODE.code} <span className="text-muted">- {NACE_CODE.description}</span>
                                    </span>
                                  </label>
                                </div>
                              </React.Fragment>
                            ) : null
                            ) : null}
                          </div>
                          <div className="col-sm-6">
                          {NACE_CODES.length ? NACE_CODES.map((NACE_CODE, i) =>
                          i >= 20 ? (
                              <React.Fragment key={i}>
                                <div className="fancy-checkbox">
                                  <label>
                                    <Input 
                                      disabled
                                      type="checkbox" 
                                      checked={data.nace_codes && data.nace_codes.length && data.nace_codes.indexOf(NACE_CODE.id) !== -1} 
                                      />
                                      <span>
                                        {NACE_CODE.code} <span className="text-muted">- {NACE_CODE.description}</span>
                                      </span>
                                  </label>
                                </div>
                              </React.Fragment>
                            ) : null
                            ) : null}
                          </div>
                        </div>
                      </td>
                    </tr>
                  : null}
                </tbody>
              </table>

              {props.type === 'iso' ?
              <React.Fragment>
                <table className="table table-bordered mt-4 mb-4">
                  <thead className="thead-light">
                    <tr>
                      <th>CERTIFICATION(S) REQUIRED</th>
                    </tr>
                  </thead>
                </table>

                {CERTIFICATIONS.length ? CERTIFICATIONS.map((CERTIFICATION, key) => 
                  <React.Fragment key={key}>
                    <div className="fancy-checkbox">
                      <label>
                        <Input 
                          disabled
                          type="checkbox" 
                          checked={data.certifications && data.certifications.length && data.certifications.indexOf(CERTIFICATION.id) !== -1} 
                        />
                        <span>
                          {CERTIFICATION.standard}
                        </span>
                      </label>
                    </div>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
              : null}

              <table className="table table-bordered mt-4 mb-4">
                <thead className="thead-light">
                  <tr>
                    <th>ADDITIONAL INFORMATION</th>
                  </tr>
                </thead>
              </table>

              <table>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>1.</h6>
                    </td>
                    <td>
                      <h6>When do you plan to be certified</h6>
                      
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>2.</h6>
                    </td>
                    <td>
                      <h6>Has your management system documentation is ready?</h6>
                      <label className="fancy-radio">
                        <Input
                          disabled
                          type="radio"
                          checked={data.application && data.application.documentation_ready ? data.application.documentation_ready === 'Y' : ""}
                        />
                        <span>
                          <i></i> Yes
                        </span>
                      </label>

                      <label className="fancy-radio">
                        <Input
                          disabled
                          type="radio"
                          checked={data.application && data.application.documentation_ready ? data.application.documentation_ready === 'N' : ""}
                        />
                        <span>
                          <i></i> No
                        </span>
                      </label>

                      {data.application && data.application.documentation_ready && data.application.documentation_ready === 'Y' ?
                        <div className="col-sm-6 pl-0">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td style={{ borderTop: 'none' }}>Is it implemented?</td>
                                <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                                  {/* <FormGroup check>
                                    <Input 
                                      type="radio" 
                                      name="documentation_implemented" 
                                      value="Y" 
                                      onClick={handleChange}
                                      checked={values.documentation_implemented === 'Y'}
                                    />
                                  </FormGroup> */}
                                  <label className="fancy-radio">
                                    <Input
                                      type="radio"
                                      checked={data.application && data.application.documentation_implemented ? data.application.documentation_implemented === 'Y' : ""}
                                    />
                                    <span>
                                      <i></i> Yes
                                    </span>
                                  </label>
                                </td>
                                <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                                  {/* <FormGroup check>
                                    <Input 
                                      type="radio" 
                                      name="documentation_implemented" 
                                      value="N" 
                                      onClick={handleChange}
                                      checked={values.documentation_implemented === 'N'}
                                    />
                                  </FormGroup> */}
                                  <label className="fancy-radio">
                                    <Input
                                      type="radio"
                                      checked={data.application && data.application.documentation_implemented ? data.application.documentation_implemented === 'N' : ""}
                                    />
                                    <span>
                                      <i></i> No
                                    </span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ borderTop: 'none' }}>Have you conducted Internal Audit?</td>
                                <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                                  {/* <FormGroup check>
                                    <Input 
                                      type="radio" 
                                      name="internal_audit_conducted" 
                                      value="Y"
                                      onClick={handleChange}
                                      checked={values.internal_audit_conducted === 'Y'}
                                    />
                                  </FormGroup> */}
                                  <label className="fancy-radio">
                                    <Input
                                      type="radio"
                                      checked={data.application && data.application.internal_audit_conducted ? data.application.internal_audit_conducted === 'Y' : ""}
                                    />
                                    <span>
                                      <i></i> Yes
                                    </span>
                                  </label>
                                </td>
                                <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                                  {/* <FormGroup check>
                                    <Input 
                                      type="radio" 
                                      name="internal_audit_conducted" 
                                      value="N"
                                      onClick={handleChange}
                                      checked={values.internal_audit_conducted === 'N'}
                                    />
                                  </FormGroup> */}
                                  <label className="fancy-radio">
                                    <Input
                                      type="radio"
                                      checked={data.application && data.application.internal_audit_conducted ? data.application.internal_audit_conducted === 'N' : ""}
                                    />
                                    <span>
                                      <i></i> No
                                    </span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ borderTop: 'none' }}>Have you conducted management review?</td>
                                <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                                  {/* <FormGroup check>
                                    <Input 
                                      type="radio" 
                                      name="management_review_conducted" 
                                      value="Y" 
                                      onClick={handleChange}
                                      checked={values.management_review_conducted === 'Y'}
                                    />
                                  </FormGroup> */}
                                  <label className="fancy-radio">
                                    <Input
                                      type="radio"
                                      checked={data.application && data.application.management_review_conducted ? data.application.management_review_conducted === 'Y' : ""}
                                    />
                                    <span>
                                      <i></i> Yes
                                    </span>
                                  </label>
                                </td>
                                <td style={{ borderTop: 'none', textAlign: 'center', verticalAlign: 'top'}}>
                                  {/* <FormGroup check>
                                    <Input 
                                      type="radio" 
                                      name="management_review_conducted" 
                                      value="N"
                                      onClick={handleChange}
                                      checked={values.management_review_conducted === 'N'}
                                    />
                                  </FormGroup> */}
                                  <label className="fancy-radio">
                                    <Input
                                      type="radio"
                                      checked={data.application && data.application.management_review_conducted ? data.application.management_review_conducted === 'N' : ""}
                                    />
                                    <span>
                                      <i></i> No
                                    </span>
                                  </label>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        : null}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>3.</h6>
                    </td>
                    <td>
                      <h6>Has the company been certified to any other standards?</h6>
                      <label className="fancy-radio">
                        <Input
                          disabled
                          type="radio"
                          checked={data.application && data.application.has_other_cert ? data.application.has_other_cert === 'Y' : ""}
                        />
                        <span>
                          <i></i> Yes
                        </span>
                      </label>

                      <label className="fancy-radio">
                        <Input
                          disabled
                          type="radio"
                          checked={data.application && data.application.has_other_cert ? data.application.has_other_cert === 'N' : ""}
                        />
                        <span>
                          <i></i> No
                        </span>
                      </label>

                      {data.application && data.application.has_other_cert && data.application.has_other_cert === 'Y' ? <p style={{ whiteSpace: "pre-wrap" }}>{data.application && data.application.other_standard ? data.application.other_standard : ""}</p> : ''}

                      {data.application && data.application.has_other_cert && data.application.has_other_cert === 'Y' &&
                      
                       data.application.certificate_copy && data.application.certificate_copy.path && data.application.certificate_copy.url ? 
                      <span>Copy of Certificate <a className="btn btn-link" href={data.application.certificate_copy.url} target="_blank" >{data.application.certificate_copy.originalName}</a></span>   
                      : null}
                      {data.application && data.application.has_other_cert && data.application.has_other_cert === 'Y' &&
                      data.application.certificate_copy && data.application.certificate_copy.path && !data.application.certificate_copy.url ?
                      <span>Copy of Certificate <a className="btn btn-link" href={data.application.certificate_copy.path} target="_blank" >{data.application.certificate_copy.originalName}</a></span>
                      : null}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>4.</h6>
                    </td>
                    <td>
                      <h6>The company has consultant?</h6>
                      <label className="fancy-radio">
                        <Input
                          disabled
                          type="radio"
                          checked={data.application && data.application.has_consultant ? data.application.has_consultant === 'Y' : ""}
                        />
                        <span>
                          <i></i> Yes
                        </span>
                      </label>

                      <label className="fancy-radio">
                        <Input
                          disabled
                          type="radio"
                          checked={data.application && data.application.has_consultant ? data.application.has_consultant === 'N' : ""}
                        />
                        <span>
                          <i></i> No
                        </span>
                      </label>

                      {data.application && data.application.has_consultant && data.application.has_consultant === 'Y' ? <p style={{ whiteSpace: "pre-wrap" }}>{data.application && data.application.has_consultant ? data.application.consultant : ""}</p> : ''}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>5.</h6>
                    </td>
                    <td>
                      <h6>How did you hear of NIOSH Certification?</h6>
                      <div className="fancy-checkbox">
                        <label>
                          <Input 
                            disabled
                            type="checkbox" 
                            value="1"
                            checked={data.application && data.application.how_did_you_hear_newspaper ? data.application.how_did_you_hear_newspaper : ""} 
                          />
                          <span>
                            Newspaper
                          </span>
                        </label>
                      </div>

                      <div className="fancy-checkbox">
                        <label>
                          <Input 
                            disabled
                            type="checkbox" 
                            value="1"
                            checked={data.application && data.application.how_did_you_hear_website ? data.application.how_did_you_hear_website : ""} 
                          />
                          <span>
                            Website
                          </span>
                        </label>
                      </div>

                      <div className="fancy-checkbox">
                        <label>
                          <Input 
                            disabled
                            type="checkbox" 
                            value="1"
                            checked={data.application && data.application.how_did_you_hear_facebook ? data.application.how_did_you_hear_facebook : ""} 
                          />
                          <span>
                            Facebook/Blog
                          </span>
                        </label>
                      </div>

                      <div className="fancy-checkbox">
                        <label>
                          <Input 
                            disabled
                            type="checkbox" 
                            value="1"
                            checked={data.application && data.application.how_did_you_hear_others ? data.application.how_did_you_hear_others : ""} 
                          />
                          <span>
                            Others
                          </span>
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </React.Fragment>
            : null}

            <table className="table table-bordered mt-4 mb-4">
              <thead className="thead-light">
                <tr>
                  <th>DECLARATION</th>
                </tr>
              </thead>
            </table>

            <p style={{ whiteSpace: "pre-wrap" }}>
              I/We hereby declared that the information given in this application is true.
            </p>

            <div className="row clearfix pb-5">
              <div className="col-sm-6">
                <SignaturePad 
                  // width={500} 
                  // height={200}
                  ref={ref => sigCanvas = ref} 
                  // onEnd={() => {
                  //   values.declaration_signature = sigCanvas.toDataURL();
                  // }}
                />
                <h6>{data.application && data.application.declaration_name ? data.application.declaration_name : ""}</h6>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {data.application && data.application.declaration_position ? data.application.declaration_position : ""}
                  <br />
                  {data.declaration_date && data.application.declaration_date ? moment(data.application.declaration_date).format('DD/MM/YYYY') : null}
                </p>
              </div>
            </div>

            <table className="table table-bordered mt-4 mb-4">
              <thead className="thead-light">
                <tr>
                  <th>DISCLAIMER</th>
                </tr>
              </thead>
            </table>

            <p className="p-2" style={{ whiteSpace: "pre-wrap" }}>
              Disclaimer regarding use of consultancy services. NIOSH Certification does not 
              warrant or agree with any statement or suggestion that certification would be 
              simpler or easier if any particular consultancy service were used in the creation 
              or preparation of a management system.
            </p>

            <div className="col-md-12 text-right">
              <hr />
              <button className="btn btn-outline-secondary" type="button">
                <i className="icon-printer"></i>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default View;