import React, { useEffect, useState } from 'react';

import {
  Link,
  useHistory,
  // useLocation,
  useParams,
  // useRouteMatch,
} from 'react-router-dom';

// import {
//   InputGroup,
// } from 'react-bootstrap';

import {
  // ButtonGroup,
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import { useToasts } from 'react-toast-notifications';

import api from '../../utils/api';

import { objectToFormData } from 'object-to-formdata';

import DateRangePicker from 'react-bootstrap-daterangepicker';

import moment from 'moment';

const INITIAL_VALUES = {};

const Form = (props) => {
  let { id } = useParams();
  let history = useHistory();

  const [data, setData] = useState(INITIAL_VALUES);

  // const [profile, setProfile] = useState({});

  const { addToast } = useToasts();

  let { readOnly }  = props;

  useEffect(() => {
    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/client-feedbacks/' + id)
        .then(function (response) {
          // console.log(response.data);
          setData(response.data);
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error.response && error.response.data) {
            switch(error.response.status) {
              case 404:
                addToast(`Feedback #${id} does not exist`, { appearance: 'error' });
                history.push(props.path);
                break;

              default:
                addToast(error.response.data.message, { appearance: 'error' });
            }
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();
    } else {
      const getClient = async (client_id) => {
        let token = await localStorage.getItem('auth-token');
  
        token = JSON.parse(token);
  
        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
  
        await api.get('/clients/' + client_id)
        .then(function (response) {
          
          // let oldData = {
          //   client_id: response.data.id,
          //   old_company_name: response.data.name,
          //   old_company_address: response.data.address_1,
          //   old_no_of_employees: 0,
          //   old_no_of_sites: 0,
          //   old_mr_name: response.data.management_representative_name,
          //   old_scope_of_certification: response.data.scope_of_certification,
          // }
  
          // setData(oldData);

          setData({
            client: {
              sector: response.data.sector,
              name: response.data.name,
            }
          });
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            switch(error.response.status) {
              case 404:
                addToast(`Client Data ID #${id} does not exist`, { appearance: 'error' });
                history.push(props.path);
                break;
  
              default:
                addToast(error.response.data.message, { appearance: 'error' });
            }
          } else {
            addToast(error.message, { appearance: 'error' });
          }
  
          // reload(false);
        });
        
      }

      const getMe = async () => {
        let token = await localStorage.getItem('auth-token');
  
        token = JSON.parse(token);
  
        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
  
        await api.post('/auth/me')
        .then(function (response) {
          // localStorage.setItem('auth-user', JSON.stringify(response.data));
  
          // setProfile(response.data);
  
          // console.log(response.data.client_id);
  
          if (!id && response.data.client_id) {
            getClient(response.data.client_id);
          }
  
          // if (!id) {
          //   if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'client_data.create') === "undefined") {
          //     history.push(props.path);
          //   }
          // } else {
          //   if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'client_data.update') === "undefined") {
          //     history.push(props.path);
          //   }
          // }
        })
        .catch(function (error) {
          // if (error && error.response && error.response.data) {
          //   if (error.response.data.message === 'Unauthenticated.') {
          //     localStorage.removeItem('auth-token');
          //     localStorage.removeItem('auth-user');
          //     setAuthState('');
          //   }
          // } else {
          //   console.log(error.message);
          // }
        });
      }
  
      getMe();
    }
  }, [id]);

  return (
    <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

        if (!id) {
          await api({
            method: 'POST',
            url: '/client-feedbacks',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          .then(function (response) {
            // console.log(response);

            addToast('Client feedback form successfully saved', { appearance: 'success' });

            history.push(props.path);

            // window.location = '/client-application/iso';
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        } else {
          data.append('_method', 'PUT');
          data.append('date', moment().format('YYYY-MM-DD'));
          await api({
            method: 'POST',
            url: '/client-feedbacks/' + id,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          .then(function (response) {
            // console.log(response);

            addToast('Client feedback form has been updated', { appearance: 'success' });

            history.push(props.path);
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
      <FormikForm>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>CLIENT FEEDBACK DETAILS</h2>
              </div>
              <div className="body">
                <div className="alert alert-info" role="alert">
                  Please fill in all the fields
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <FormGroup>
                      <Label for="">Organization</Label>
                      <Input
                        readOnly={true}
                        type="text"
                        defaultValue={values.client && values.client.sector ? values.client.sector : ""}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-8">
                    <FormGroup>
                      <Label for="">Company Name</Label>
                      <Input
                        readOnly={true}
                        type="text"
                        defaultValue={values.client && values.client.name ? values.client.name : ""}
                      />
                    </FormGroup>
                  </div>
                </div>

                <FormGroup>
                  <Label for="">How do you first hear about NIOSH Certification?</Label>
                  <div>
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="first_hear_about_niosh_cert"
                        value="EX"
                        onClick={() => {
                          setFieldValue('first_hear_about_niosh_cert', 'EX')
                        }}
                        checked={values.first_hear_about_niosh_cert ? values.first_hear_about_niosh_cert === 'EX' : ""}
                        required
                      />
                      <span>
                        <i></i> Exhibition
                      </span>
                    </label>
                    {" "}
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="first_hear_about_niosh_cert"
                        value="TV"
                        onClick={() => {
                          setFieldValue('first_hear_about_niosh_cert', 'TV')
                        }}
                        checked={values.first_hear_about_niosh_cert ? values.first_hear_about_niosh_cert === 'TV' : ""}
                      />
                      <span>
                        <i></i> TV/Radio
                      </span>
                    </label>
                    {" "}
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="first_hear_about_niosh_cert"
                        value="NEWS"
                        onClick={() => {
                          setFieldValue('first_hear_about_niosh_cert', 'NEWS')
                        }}
                        checked={values.first_hear_about_niosh_cert ? values.first_hear_about_niosh_cert === 'NEWS' : ""}
                      />
                      <span>
                        <i></i> Newspaper/Magazine
                      </span>
                    </label>
                    {" "}
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="first_hear_about_niosh_cert"
                        value="DOSH"
                        onClick={() => {
                          setFieldValue('first_hear_about_niosh_cert', 'DOSH')
                        }}
                        checked={values.first_hear_about_niosh_cert ? values.first_hear_about_niosh_cert === 'DOSH' : ""}
                      />
                      <span>
                        <i></i> DOSH
                      </span>
                    </label>
                    {" "}
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="first_hear_about_niosh_cert"
                        value="WEB"
                        onClick={() => {
                          setFieldValue('first_hear_about_niosh_cert', 'WEB')
                        }}
                        checked={values.first_hear_about_niosh_cert ? values.first_hear_about_niosh_cert === 'WEB' : ""}
                      />
                      <span>
                        <i></i> Web
                      </span>
                    </label>
                    {" "}
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="first_hear_about_niosh_cert"
                        value="EMP"
                        onClick={() => {
                          setFieldValue('first_hear_about_niosh_cert', 'EMP')
                        }}
                        checked={values.first_hear_about_niosh_cert ? values.first_hear_about_niosh_cert === 'EMP' : ""}
                      />
                      <span>
                        <i></i> Employer/Colleagues
                      </span>
                    </label>
                    <br />
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="first_hear_about_niosh_cert"
                        value="OTH"
                        onClick={() => {
                          setFieldValue('first_hear_about_niosh_cert', 'OTH')
                        }}
                        checked={values.first_hear_about_niosh_cert ? values.first_hear_about_niosh_cert === 'OTH' : ""}
                      />
                      <span>
                        <i></i> Other: 
                      </span>
                    </label>
                    <Input
                      disabled={readOnly}
                      name="first_hear_about_niosh_cert_other"
                      onChange={handleChange}
                      type="text"
                      value={values.first_hear_about_niosh_cert_other}
                    />
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label for="">The nature of dealing at NIOSH Certification?</Label>
                  <div>
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="nature_of_dealing_at_niosh_cert"
                        value="C"
                        onClick={() => {
                          setFieldValue('nature_of_dealing_at_niosh_cert', 'C')
                        }}
                        checked={values.nature_of_dealing_at_niosh_cert ? values.nature_of_dealing_at_niosh_cert === 'C' : ""}
                        required
                      />
                      <span>
                        <i></i> Certification
                      </span>
                    </label>
                    {" "}
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="nature_of_dealing_at_niosh_cert"
                        value="T"
                        onClick={() => {
                          setFieldValue('nature_of_dealing_at_niosh_cert', 'T')
                        }}
                        checked={values.nature_of_dealing_at_niosh_cert ? values.nature_of_dealing_at_niosh_cert === 'T' : ""}
                      />
                      <span>
                        <i></i> Training
                      </span>
                    </label>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label for="">Frequency of dealing with NIOSH Certification (Average in a year)</Label>
                  <div>
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="frequency_of_dealing_at_niosh_cert"
                        value="1"
                        onClick={() => {
                          setFieldValue('frequency_of_dealing_at_niosh_cert', '1')
                        }}
                        checked={values.frequency_of_dealing_at_niosh_cert ? values.frequency_of_dealing_at_niosh_cert === '1' : ""}
                        required
                      />
                      <span>
                        <i></i> Once
                      </span>
                    </label>
                    {" "}
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="frequency_of_dealing_at_niosh_cert"
                        value="2"
                        onClick={() => {
                          setFieldValue('frequency_of_dealing_at_niosh_cert', '2')
                        }}
                        checked={values.frequency_of_dealing_at_niosh_cert ? values.frequency_of_dealing_at_niosh_cert === '2' : ""}
                      />
                      <span>
                        <i></i> 2 - 5 times
                      </span>
                    </label>
                    {" "}
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="frequency_of_dealing_at_niosh_cert"
                        value="3"
                        onClick={() => {
                          setFieldValue('frequency_of_dealing_at_niosh_cert', '3')
                        }}
                        checked={values.frequency_of_dealing_at_niosh_cert ? values.frequency_of_dealing_at_niosh_cert === '3' : ""}
                      />
                      <span>
                        <i></i> 6 - 9 times
                      </span>
                    </label>
                    {" "}
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="frequency_of_dealing_at_niosh_cert"
                        value="4"
                        onClick={() => {
                          setFieldValue('frequency_of_dealing_at_niosh_cert', '4')
                        }}
                        checked={values.frequency_of_dealing_at_niosh_cert ? values.frequency_of_dealing_at_niosh_cert === '4' : ""}
                      />
                      <span>
                        <i></i> &gt;10 times
                      </span>
                    </label>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label for="">Would you be interested in obtaining NIOSH Certification services in the future?</Label>
                  <div>
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="interested_in_obtaining_cert_in_future"
                        value="Y"
                        onClick={() => {
                          setFieldValue('interested_in_obtaining_cert_in_future', 'Y')
                        }}
                        checked={values.interested_in_obtaining_cert_in_future ? values.interested_in_obtaining_cert_in_future === 'Y' : ""}
                        required
                      />
                      <span>
                        <i></i> Yes
                      </span>
                    </label>
                    {" "}
                    <label className="fancy-radio">
                      <Input
                        disabled={readOnly}
                        type="radio"
                        name="interested_in_obtaining_cert_in_future"
                        value="N"
                        onClick={() => {
                          setFieldValue('interested_in_obtaining_cert_in_future', 'N')
                        }}
                        checked={values.interested_in_obtaining_cert_in_future ? values.interested_in_obtaining_cert_in_future === 'N' : ""}
                      />
                      <span>
                        <i></i> No
                      </span>
                    </label>
                  </div>
                </FormGroup>

                {values.interested_in_obtaining_cert_in_future && values.interested_in_obtaining_cert_in_future === 'Y' ?
                <FormGroup>
                  <Label for="">Please state</Label>
                  <Input
                    disabled={readOnly}
                    name="cert"
                    onChange={handleChange}
                    type="text"
                    value={values.interested_in_obtaining_cert_in_future_state}
                  />
                </FormGroup>
                : null }
                
                <table className="table">
                  <thead>
                    <tr>
                      <th>Service</th>
                      <th>Excellent</th>
                      <th>Good</th>
                      <th>Need Improvement</th>
                      <th>Poor</th>
                      <th>N/A</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      {
                        field: '',
                        label: 'NIOSH Certification personnel are friendly, polite and helpful.',
                      },
                      {
                        field: '',
                        label: 'NIOSH Certification personnel are skillful and efficient in performing their tasks.'
                      },
                      {
                        field: '',
                        label: 'The level of knowledge and professionalism NIOSH Certification personnel\'s',
                      },
                      {
                        field: '',
                        label: 'How do you rate your satisfaction with the NIOSH Certification services that you received.',
                      }
                    ].map((item, i) =>
                      <tr>
                        <td>{item.label}</td>
                        {['4', '3', '2', '1', '0'].map((score, j) =>
                        <td style={{ textAlign: "center" }}>
                          <label className="fancy-radio">
                            <Input
                              disabled={readOnly}
                              type="radio"
                              name={`service_${i}`}
                              value={score}
                              onClick={() => {
                                setFieldValue(`service_${i}`, score)
                              }}
                              checked={values[`service_${i}`] ? parseInt(values[`service_${i}`]) === parseInt(score) : ""}
                              required
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>

                <table className="table">
                  <thead>
                    <tr>
                      <th>Audit</th>
                      <th>Excellent</th>
                      <th>Good</th>
                      <th>Need Improvement</th>
                      <th>Poor</th>
                      <th>N/A</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      {
                        field: '',
                        label: 'Auditors are knowledgeable on the auditing process.',
                      },
                      {
                        field: '',
                        label: 'The provided auditing will increase client’s understanding on the tasks.'
                      },
                      {
                        field: '',
                        label: 'The audit report clearly presented the results of the audit.',
                      },
                      {
                        field: '',
                        label: 'The recommendations in the report were reasonable and meaningful.',
                      },
                      {
                        fields: '',
                        label: 'Overall, you received benefits from this audit that will assist you in the future.',
                      }
                    ].map((item, i) =>
                      <tr>
                        <td>{item.label}</td>
                        {['4', '3', '2', '1', '0'].map((score, j) =>
                        <td style={{ textAlign: "center" }}>
                          <label className="fancy-radio">
                            <Input
                              disabled={readOnly}
                              type="radio"
                              name={`audit_${i}`}
                              value={score}
                              onClick={() => {
                                setFieldValue(`audit_${i}`, score)
                              }}
                              checked={values[`audit_${i}`] ? parseInt(values[`audit_${i}`]) === parseInt(score) : ""}
                              required
                            />
                            <span>
                              <i></i>
                            </span>
                          </label>
                        </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>

                <FormGroup>
                  <Label for="Suggestion">Suggestions to further improve the services provided by NIOSH Certification</Label>
                  <Input 
                    // readOnly={readOnly}
                    disabled={readOnly}
                    type="textarea" 
                    name="suggestion" 
                    id="Suggestion" 
                    onChange={handleChange}
                    value={values.suggestion} 
                    rows={5}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="Date">Date</Label>
                    <Input 
                      autoComplete="off"
                      disabled={true}
                      readOnly={true}
                      type="text" 
                      name="date" 
                      // id="LastAudit"
                      // onChange={handleChange}
                      value={values.date ? moment(values.date).format('DD/MM/YYYY') : (!readOnly ? moment().format('DD/MM/YYYY') : '')}
                      required
                    />
                </FormGroup>

                <div className="row clearfix">
                  <div className="col-sm-4">
                    {!readOnly ? 
                    <React.Fragment>
                    <button 
                      type="submit" 
                      className="btn btn-primary"
                      disabled={isSubmitting ? true : false}
                    >
                      {id ? 'Update' : 'Submit'}
                    </button>&nbsp;
                    </React.Fragment>
                    : null}
                    <Button 
                      // className="btn btn-outline-secondary"
                      outline
                      color="secondary" 
                      tag={Link} 
                      to={props.path}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                
              </div>
            </Card>
          </div>
        </div>
      </FormikForm>
      )}
    </Formik>
  )
}

export default Form;