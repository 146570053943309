import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import {
  Link,
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { useToasts } from 'react-toast-notifications';

import AuthContext from './../../authContext';

import api from '../../utils/api';

import './style.css';

const ResetPassword = () => {
  let { token } = useParams();

  let [login, setLogin] = useState({});
  let [formVals, setFormVals] = useState({});
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  const { setAuthState } = useContext(AuthContext);

  const { addToast } = useToasts();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const inputs = Object.values(e.target)
    .filter(c => typeof c.tagName === 'string' && c.tagName.toLowerCase() === 'input')
    .reduce((acc, curr) => ({ ...acc, [curr.name]: curr.value }), {});

    setFormVals({ ...formVals, ...inputs });
//    document.getElementsByClassName('page-loader-wrapper')[0].style = 'display: block';

    const querystring = require('querystring');

     await api.post('/auth/reset-password', querystring.stringify(inputs), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(function (response) {
      addToast('Password successfully reset. Please login with new password.', { appearance: 'success' });
      history.push('/login' + '?refresh=' + Date.now());
    })
    .catch(function (error) {
      if (error && error.response && error.response.data) {
        addToast(error.response.data.error.message, { appearance: 'error' });
      } else {
        addToast(error.message, { appearance: 'error' });
      }
     
    })
    /* .then(function () {
      document.getElementsByClassName('page-loader-wrapper')[0].style = 'display: none';
    }) */;

    // setTimeout(() => {
    //   setAuthState('signIn');

    //   history.replace(from);
    // }, 100);
  }

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    login[name] = value;
    setLogin(login);

     //console.log(login);
  }

  return (
    <div className="vertical-align-wrap">
      <div className="vertical-align-middle auth-main">
        <div className="auth-box">
          <div className="top">
            <img src="/logo-niosh.png" alt="" />
          </div>
          <Card>
            <div className="header">
              <p className="lead">Reset my password</p>
            </div>
            <div className="body">
              <form className="form-auth-small" onSubmit={handleSubmit}>
              <FormGroup>
              <Input type="hidden" name="token" value={token} />
              </FormGroup>
                <FormGroup>
                  <Label className="control-label sr-only">Email</Label>
                  <Input type="email" name="email" placeholder="Email" onChange={handleInputChange} />
                </FormGroup>
                <FormGroup>
                  <Label className="control-label sr-only">Password</Label>
                  <Input type="password" name="password" placeholder="Password" onChange={handleInputChange} />
                </FormGroup>
                <FormGroup>
                  <Label className="control-label sr-only">Confirm Password</Label>
                  <Input type="password" name="password_confirmation" placeholder="Confirm Password" onChange={handleInputChange} />
                </FormGroup>
                <Button block color="primary">RESET PASSWORD</Button>
     
              </form>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword;