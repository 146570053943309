import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  // Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  // TabContent,
  // TabPane,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import { Editor } from '@tinymce/tinymce-react';

import classnames from 'classnames';

import api from '../../../utils/api';

import moment from 'moment';

import { useToasts } from 'react-toast-notifications';

import SidebarToggler from '../../../components/SidebarToggler';

import { objectToFormData } from 'object-to-formdata';

import './style.css';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const INITIAL_VALUES = {

};

const View = (props) => {
  let { id, type, subtype } = useParams();

  let { path } = useRouteMatch();

  const { addToast } = useToasts();

  const [data, setData] = useState(INITIAL_VALUES);

  const [permissions, setPermissions] = useState([]);

  const [token, setToken] = useState('');

  const [isSubmitting, setSubmitting] = useState(false);
  
  const [isSubmitting1, setSubmitting1] = useState(false);

  const [activeTab, setActiveTab] = useState('1');
  
  // const [profile, setProfile] = useState();

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  let i = 1;
  
  const [body, setFieldValue] = useState('');

  useEffect(() => {
    const getToken = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);
    }

    getToken();

    if (id) {
      const getData = async (profile) => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/audit-plans/' + id)
        .then(function (response) {
          // console.log(response.data);

          let reactElement;
          if (response.data.data.appendix_a) {
            reactElement = htmlToReactParser.parse(response.data.data.appendix_a.replace(/table width="\d+"/g, "table"));
            response.data.data.appendix_a = reactElement;
          }

          if (response.data.data.audit_plan) {
            reactElement = htmlToReactParser.parse(response.data.data.audit_plan.replace(/table width="\d+"/g, "table"));
            response.data.data.audit_plan = reactElement;
          }

          delete response.data.data.id;

          setPermissions(response.data.meta.permissions);
          
          // let mandays = response.data.data.audit_plan.mandays && response.data.data.audit_plan.mandays.length ? response.data.data.audit_plan.mandays.filter(function (el) {
          //   return el != null;
          // }) : [];
          
          // let audit_dates = response.data.data.audit_plan.audit_dates && response.data.data.audit_plan.audit_dates.length ? response.data.data.audit_plan.audit_dates.filter(function (el) {
          //   return el != null;
          // }) : [];
          
          let tblLeadAuditors = '';
          if (response.data.data.lead_auditors && response.data.data.lead_auditors.length) {
            for (i = 0; i < response.data.data.lead_auditors.length; i++) {
              tblLeadAuditors += '<tr>';
              tblLeadAuditors += '<td style="padding: 3px">Lead Auditor:</td>';
              tblLeadAuditors += '<td style="padding: 3px">' + response.data.data.lead_auditors[i].full_name + '</td>';
              tblLeadAuditors += '</tr>';
            }
          } else {
              tblLeadAuditors += '<tr>';
              tblLeadAuditors += '<td style="padding: 3px">Lead Auditor:</td><td></td>';
              tblLeadAuditors += '</tr>';
          }
          
          let tblAuditors = '';
          if (response.data.data.audit_team_members && response.data.data.audit_team_members.length) {
            for (i = 0; i < response.data.data.audit_team_members.length; i++) {
              tblAuditors += '<tr>';
              tblAuditors += '<td style="padding: 3px">Auditor:</td>';
              tblAuditors += '<td style="padding: 3px">' + response.data.data.audit_team_members[i].full_name + '</td>';
              tblAuditors += '</tr>';
            }
          } else {
              tblAuditors += '<tr>';
              tblAuditors += '<td style="padding: 3px">Auditor:</td><td></td>';
              tblAuditors += '</tr>';
          }
          
          let estates = '';
          let part3 = '';
          
          if (subtype.toLowerCase() === 'part-2') {
            estates += '<tr>';
            estates += '<td style="padding: 3px">Total Smallholders:</td><td style="padding: 3px"></td>';
            estates += '</tr>';
            
            estates += '<tr>';
            estates += '<td style="padding: 3px">Sampled Smallholders:</td><td style="padding: 3px">' + response.data.data.no_of_smallholders_sampled + '</td>';
            estates += '</tr>';
          }
          
          if (subtype.toLowerCase() === 'part-3') {
            estates += '<tr>';
/*             estates += '<td style="padding: 3px">Total Estate(s):</td><td style="padding: 3px">' + (response.data.data.client.sampled_units && response.data.data.client.sampled_units.length ? response.data.data.client.sampled_units.length : 0) + '</td>';
 */            estates += '<td style="padding: 3px">Total Estate(s):</td><td style="padding: 3px">' + (response.data.data.sampled_units && response.data.data.sampled_units.length ? response.data.data.sampled_units.length : 0) + '</td>';
            estates += '</tr>';
            
            estates += '<tr>';
/*             estates += '<td style="padding: 3px">Sampled Unit(s):</td><td style="padding: 3px">' + (response.data.data.client.sampled_units && response.data.data.client.sampled_units.length ? response.data.data.client.sampled_units.filter(o => o.to_be_sampled === true).length : 0) + '</td>';
 */            estates += '<td style="padding: 3px">Sampled Unit(s):</td><td style="padding: 3px">' + (response.data.data.sampled_units && response.data.data.sampled_units.length ? response.data.data.sampled_units.filter(o => o.to_be_sampled === true).length : 0) + '</td>';
            estates += '</tr>';
            
            part3 = '<tr> \
              <td colspan="2" style="padding: 3px"> \
                <table> \
                  <thead> \
                    <tr> \
                      <th style="padding: 3px">Management Unit</th> \
                      <th style="padding: 3px">Initial Certification</th> \
                      <th style="padding: 3px">Anual Surveillance 1</th> \
                      <th style="padding: 3px">Anual Surveillance 2</th> \
                      <th style="padding: 3px">Anual Surveillance 3</th> \
                      <th style="padding: 3px">Anual Surveillance 4</th> \
                      <th style="padding: 3px">Re-Certification</th> \
                    </tr> \
                  </thead> \
                  <tbody>';
            /* if (response.data.data.client.sampled_units && response.data.data.client.sampled_units.length) {
              response.data.data.client.sampled_units.map((item, i) => { */
            if (response.data.data.sampled_units && response.data.data.sampled_units.length) {
              response.data.data.sampled_units.map((item, i) => {
                part3 += '<tr>';
                part3 += '<td style="padding: 3px">' + item.management_units + '</td>';
                part3 += '<td style="text-align: center; padding: 3px">' + (item.initial_certification === true ? '&#9746;' : '&#9744;') + '</td>';
                part3 += '<td style="text-align: center; padding: 3px">' + (item.annual_surveillance_1 === true ? '&#9746;' : '&#9744;') + '</td>';
                part3 += '<td style="text-align: center; padding: 3px">' + (item.annual_surveillance_2 === true ? '&#9746;' : '&#9744;') + '</td>';
                part3 += '<td style="text-align: center; padding: 3px">' + (item.annual_surveillance_3 === true ? '&#9746;' : '&#9744;') + '</td>';
                part3 += '<td style="text-align: center; padding: 3px">' + (item.annual_surveillance_4 === true ? '&#9746;' : '&#9744;') + '</td>';
                part3 += '<td style="text-align: center; padding: 3px">' + (item.re_certification === true ? '&#9746;' : '&#9744;') + '</td>';
                part3 += '</tr>';
              });
            }
            
            part3 += '</tbody> \
                </table> \
              </td> \
            </tr>';
          }
          
          
          response.data.data.body = '<p style="text-decoration: underline; font-weight: bold; text-align: center;">Audit Confirmation and Auditor Appointment</p> \
<p>Dear Sir / Madam,<p> \
<p>Below is the audit details and appointed lead auditor and auditor(s) for the <b>' + response.data.data.stage + ' Audit</b> at <b>' + response.data.data.client.name + '</b> for your perusal.</p> \
<p>Kindly refer the details below:- </p> \
<p style="font-weight: bold; text-decoration: underline">' + response.data.data.stage + ' Audit ' + (subtype.toLowerCase() !== 'sccs' ? 'MS 2530-' + subtype.substring(5) + ':2013' : 'MSPO-SCCS') + ' - ' + response.data.data.client.name + '</p> \
<table border="1" cellpadding="0" cellspacing="0"> \
  <tbody> \
    <tr> \
      <td style="width: 112.5px; padding: 3px">Audit Type:</td> \
      <td style="width: 514px; padding: 3px">' + response.data.data.stage + '</td> \
    </tr> \
    <tr> \
      <td style="padding: 3px">Audit Date:</td> \
      <td style="padding: 3px">' + (response.data.data.date_of_audit_start && response.data.data.date_of_audit_end ? moment(response.data.data.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(response.data.data.date_of_audit_end).format('DD/MM/YYYY') : "") + '</td> \
    </tr>' + tblLeadAuditors + tblAuditors + '<tr> \
      <td style="padding: 3px">Auditor in Training:</td> \
      <td style="padding: 3px"></td> \
    </tr> \
    <tr> \
      <td style="padding: 3px">Audit No.:</td> \
      <td style="padding: 3px">' + response.data.data.audit_no + '</td> \
    </tr> \
    <tr> \
      <td style="padding: 3px">Audit Mandays:</td> \
      <td style="padding: 3px">' + response.data.data.audit_mandays + '</td> \
    </tr> \
    <tr> \
      <td style="padding: 3px">Risk Category:</td> \
      <td style="padding: 3px">' + (response.data.data.sample_unit_risk_category_text ? response.data.data.sample_unit_risk_category_text : '') + '  Risk</td> \
    </tr>' + estates + part3 + ' \
  </tbody> \
</table> \
<p>Be ensure to revert back to me if there are any objections to the audit team composition due to conflict of interest within 1 working day upon receiving this email.</p> \
<p>Attached are the <a href="https://' + window.location.host + '/application-review/client-feedbacks">Client Feedback Form</a>, <a href="https://' + window.location.host + '/auditor/auditor-evaluations">Auditor Evaluation Form</a>, Invitation Letter for Stakeholder and <a href="https://' + window.location.host + '/basic-data-information/edit/' + response.data.data.client.id + '">Basic Data Information</a> for your attention and kind action.</p> \
<p>Kindly forward the invitation letter to internal and external stakeholder and fill the Client Feedback Form and Auditor Evaluation Form for our improvement in the future. Also, fill Basic Data Information for completing the audit report. Your cooperation is highly appreciated.</p> \
<p style="font-weight: bold">Please fill the Questionnaire in Relation Onsite Audit Covid-19 and submit to us prior audit date.</p> \
<p>Do not hesitate to contact us if you need further clarification.</p> \
<p>Upon receiving this email, the Lead Auditor Cc copied in this email will contact you and submit the audit plan. Kindly contact <b>' + (response.data.data.lead_auditors && response.data.data.lead_auditors.length ? response.data.data.lead_auditors[0].prefix.toUpperCase() + ' ' : '') + (response.data.data.lead_auditors && response.data.data.lead_auditors.length ? response.data.data.lead_auditors[0].full_name : '') + ' (Lead auditor) (' + (response.data.data.lead_auditors && response.data.data.lead_auditors.length ? response.data.data.lead_auditors[0].business_mobile : '') + ')</b> for further arrangement.</p> \
<p>&nbsp;</p> \
<p>Dear Lead Auditor,</p> \
<p>Attached Previous Audit Report for your reference and kindly provide the audit plan as per given template from NIOSH Certification.</p> \
<p>&nbsp;</p> \
<p>Thanks & Regards,</p> \
<p>&nbsp;</p> \
<p style="font-weight: bold">Pekerja dan Cabaran Budaya Norma Baru</p>' + profile.name + ' \
<p><b>NIOSH CERTIFICATION SDN. BHD.</b><br> \
<i>(A wholly owned subsidiary of <b>NIOSH Malaysia</b>)</i><br> \
7<sup>th</sup> Floor NIOSH Tower, Lot 1 Jalan 15/1 Section 15,<br> \
43650 Bandar Baru Bangi, Selangor Darul Ehsan, Malaysia<br> \
www.nioshcert.com.my | www.linkedin.com/company/nioshcertification \
</p> \
<p>Disclaimer: <span style="font-size: 7.5pt"><span style="color: rgb(97,189,109); font-weight: bold">Please consider the environment before printing this email</span> \
"This message (including any attachments) is intended for the recipient\'s use only. They may contain proprietary statement or legal private and privileged information. \
No confidentiality or privilege waived or lost due to any transmission errors. If you are not the intended recipient, please immediately delete all copies from your \
system, destroy any copies and notify the sender immediately. You may not, directly or indirectly, use, disclose, distribute, print, copy or rely on any part of this \
message if you are not the intended recipient. Any views expressed in this message (including any attachments) are those of the individual sender and not those of any \
member of NIOSHCert, unless other States message and the sender is allowed to state their views of any such entity. "</span></p>';
          
          setData(response.data.data);
          
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data) {
            addToast(error.response.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      const getMe = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        await api.post('/auth/me')
        .then(function (response) {
          console.log(response.data);
          
          // localStorage.setItem('auth-user', JSON.stringify(response.data));

          let profile = response.data;

          // setProfile(profile => response.data);

          getData(profile);
          
          // console.log(response.data.name);

          // if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'audit_report.update') === "undefined") {
          //   history.push(props.path.replace(/:type/, type));
          // }
        })
        .catch(function (error) {
          // if (error && error.response && error.response.data) {
          //   if (error.response.data.message === 'Unauthenticated.') {
          //     localStorage.removeItem('auth-token');
          //     localStorage.removeItem('auth-user');
          //     setAuthState('');
          //   }
          // } else {
          //   console.log(error.message);
          // }
        });
      }

      getMe();
    }
  }, [id]);

  const sendNotificationHandler = async () => {
    if (!window.confirm('Send notification to client?')) {
      return;
    }
    
    setSubmitting(true);

    let token = await localStorage.getItem('auth-token');

    token = JSON.parse(token);

    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    await api.post('/audit-plans/notification/' + id)
    .then(function (response) {
      // console.log(response.data);

      addToast('Notification has been sent', { appearance: 'success' });

      setSubmitting(false);
      
      // toggleModal();
    })
    .catch(function (error) {
      // console.log(error.response);
      if (error && error.response && error.response.data) {
        addToast(error.response.data.message, { appearance: 'error' });
      } else {
        addToast(error.message, { appearance: 'error' });
      }

      setSubmitting(false);
    });
  }
  
  // const sendAuditConfirmationHandler = async () => {
  //   if (!window.confirm('Send audit confirmation to client?')) {
  //     return;
  //   }
    
  //   setSubmitting1(true);
    
  //   // setTimeout(() => {
  //   //   setSubmitting1(false);
  //   // }, 5000);

  //   let token = await localStorage.getItem('auth-token');

  //   token = JSON.parse(token);

  //   api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

  //   await api.post('/audit-plans/send-audit-confirmation/' + id)
  //   .then(function (response) {
  //     // console.log(response.data);

  //     addToast('Audit confirmation has been sent', { appearance: 'success' });

  //     setSubmitting1(false);
      
  //     toggleModal();
  //   })
  //   .catch(function (error) {
  //     // console.log(error.response);
  //     if (error && error.response && error.response.data) {
  //       addToast(error.response.data.message, { appearance: 'error' });
  //     } else {
  //       addToast(error.message, { appearance: 'error' });
  //     }

  //     setSubmitting1(false);
  //   });
  // }

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              {type.toUpperCase()} Audit Plan
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Audit
              </li>
              <li className="breadcrumb-item">
                Audit Plan
              </li>
              <li className="breadcrumb-item">
                <Link to={path}>
                  {type.toUpperCase()}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card invoice1">
            <div className="body">
              <div className="invoice-top clearfix">
                <div className="logo">
                  <img src={process.env.PUBLIC_URL + '/logo-niosh.png'} alt="logo" className="rounded-circle img-fluid" />
                </div>
                <div className="info">
                <h6>NIOSH CERTIFICATION SDN. BHD.</h6>
                  <p>7th Floor, NIOSH Tower, Lot 1, Jalan 15/1, Seksyen 15, 43650 Bandar Baru Bangi, Selangor<br />
                    Tel: +603 8769 2100 / +603 8911 3800   |   Fax: +603 8926 2900
                  </p>
                </div>
                <div className="title">
                  <h4>Audit Plan #{data.audit_no}</h4>
                </div>
              </div>
              <div className="invoice-mid clearfix">&nbsp;</div>

              <Nav pills>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    Main
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    Appendix A
                  </NavLink>
                </NavItem>
              </Nav>
              <hr />

              {activeTab === '1' ?
              <React.Fragment>
              <table className="table table-bordered mt-4 mb-4">
                <thead className="thead-light">
                  <tr>
                    <th style={{ width: "33%" }}>Audit No.</th>
                    <th style={{ width: "33%" }}>Issue</th>
                    <th style={{ width: "33%" }}>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.audit_no}</td>
                    <td>{data.issue}</td>
                    <td>{data.date ? moment(data.date).format('DD/MM/YYYY') : ""}</td>
                  </tr>
                </tbody>
              </table>
              
              <table>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Audit Objectives</h6>
                      <p style={{ whiteSpace: "pre-wrap" }}>{data.audit_objective}</p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Scope Of Certification</h6>
                      <p>{data.client && data.client.scope_of_certification ? data.client.scope_of_certification : null}</p>
                    </td>
                  </tr>
                  {subtype.toLowerCase() === 'part-2' || subtype.toLowerCase() === 'part-3' || subtype.toLowerCase() === 'part-4' ? null :
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Scope No.</h6>
                      <p>{data.scope_no}</p>
                    </td>
                  </tr>
                  }
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Audit Date</h6>
                      <p>{data.date_of_audit_start && data.date_of_audit_end ? moment(data.date_of_audit_start).format('DD/MM/YYYY') + ' - ' + moment(data.date_of_audit_end).format('DD/MM/YYYY') : ""}</p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Previous Audit Date</h6>
                      <p>{data.last_audit_date_start && data.last_audit_date_end ? moment(data.last_audit_date_start).format('DD/MM/YYYY') + ' - ' + moment(data.last_audit_date_end).format('DD/MM/YYYY') : ""}</p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Organization Name And Address</h6>
                      <p>{data.client && data.client.name ? data.client.name : ''}</p>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {data.client && data.client.address_1 ? <React.Fragment>{data.client.address_1}<br /></React.Fragment> : ''}
                        {data.client && data.client.address_2 ? <React.Fragment>{data.client.address_2}<br /></React.Fragment> : ''}
                        {data.client && data.client.address_3 ? <React.Fragment>{data.client.address_3}<br /></React.Fragment> : ''}
                        
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Audit Reference Standards</h6>
                      {data.type && data.type.toUpperCase() === 'ISO' && data.client && data.client.certifications.length ? data.client.certifications.map((cert, key)  => 
                        <p key={key}>{cert.standard}</p>
                      ) : null}

                      {data.type && data.type.toUpperCase() === 'MSPO' && data.subtype.toUpperCase() === 'PART-2' ?
                        <p>Malaysian Sustainable Palm Oil (MSPO) Part 2: General principles for independent smallholders (MS 2530-2:2013)</p>
                      : null}

                      {data.type && data.type.toUpperCase() === 'MSPO' && data.subtype.toUpperCase() === 'PART-3' ?
                        <p>Malaysian Sustainable Palm Oil (MSPO) Part 3: General principles for oil palm plantations and organized smallholders (MS 2530-3:2013)</p>
                      : null}

                      {data.type && data.type.toUpperCase() === 'MSPO' && data.subtype.toUpperCase() === 'PART-4' ?
                        <p>Malaysian Sustainable Palm Oil (MSPO) Part 4: General principles for palm oil mills (MS 2530-4:2013)</p>
                      : null}

                      {data.type && data.type.toUpperCase() === 'MSPO-SCCS' ?
                        <p>MSPO Supply Chain Certification Standard: MSPO-SCCS-01</p>
                      : null}
                    </td>
                  </tr>
                  {subtype.toLowerCase() === 'part-3' ? null :
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Client Documentation To Be Audit</h6>
                      <p style={{ whiteSpace: "pre-wrap" }}>{data.client_doc_to_be_audit}</p>
                    </td>
                  </tr>
                  }
                  {type.toLowerCase() === 'mspo' ? 
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Plantation site</h6>
                    </td>
                  </tr>
                  :
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Audit Venue</h6>
                      <p>{data.audit_venue ? data.audit_venue.split('\n').map((item, key) => {
                        return <React.Fragment key={key}>{item}<br/></React.Fragment>
                      }) : ''}</p>
                    </td>
                  </tr>
                  }
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Contact Person</h6>
                      <p>{data.client ? data.client.contact_person_name : null}</p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Audit Team Member</h6>
                      <table className="table table-bordered" style={{ width: "auto" }}>
                        <tbody>
                          {data.lead_auditors && data.lead_auditors.length ?
                            data.lead_auditors.map((member, key) => 
                              <tr key={key}>
                                <td style={{ fontWeight: "bold" }}>{key === 0 ? 'Lead Auditor' : ''}</td>
                                <td> : </td> 
                                <td>{member.full_name}</td>
                              </tr>
                            )
                          : null}
                          {data.audit_team_members && data.audit_team_members.length ?
                            data.audit_team_members.map((member, key) => 
                              <tr key={key}>
                                <td style={{ fontWeight: "bold" }}>{key === 0 ? 'Audit Team Member' : ''}</td>
                                <td> : </td> 
                                <td>{member.full_name}</td>
                              </tr>
                            )
                          : null}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Conflict Of Interest</h6>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {/* {data.conflict_of_interest} */}
                        Each team member has declared No Conflict of Interest against the certification unit. Kindly be informed that we have an Issue Resolution process in place if there are any objections towards the audit team members due to potential, actual or perceived conflict of interest.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Confidentiality Requirements</h6>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {/* {data.confidentiality_requirements} */}
                        The audit members from NIOSH Certification undertake not to disclose information or documents obtained during the audit, and the final report, to any third party, without the expressed approval of the client and where appropriate the approval of auditee unless required by law.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Facilities And Assistance Needed</h6>
                      {/* <p style={{ whiteSpace: "pre-wrap" }}> */}
                        {/* {data.facilities_and_assistance_needed} */}
                        <ol type="a" style={{ listStylePosition: "inside", paddingLeft: 0, whiteSpace: "pre-wrap" }}>
                          <li>A meeting room;</li>
                          <li>A representative of your organization, acting as a guide (e.g. Safety & Health Officer(s), Safety and Health Committee member) to accompany / assist each auditor;</li>
                          <li>Personal Protective Equipment (if applicable);</li>
                          <li>OH&amp;S MS documentation; and</li>
                          <li>Availability of appropriate auditee(s) according to the audit schedule.</li>
                        </ol>
                      {/* </p> */}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Working Language</h6>
                      <p>{data.working_language}</p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Audit Plan</h6>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {/* {data.audit_plan} */}
                        Refer to Appendix A<br />
                        Please note the audit plan merely serves as a guide and will need to be somewhat flexible, as activities may vary, depending on the situations the auditors uncover.
                      </p>
                    </td>
                  </tr>
                  {data.type && data.type.toUpperCase() === 'ISO' ?
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Non-Conformance (NC) &amp; Reporting</h6>
                      <p>{data.nc_reporting}</p>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>a.</td>
                            <td style={{ fontWeight: "bold" }}>Major NC</td>
                            <td> : </td>
                            <td style={{ whiteSpace: "pre-wrap" }}>NC to be respond within 60 days from the closing meeting</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>b.</td>
                            <td style={{ fontWeight: "bold" }}>Minor NC</td>
                            <td> : </td>
                            <td style={{ whiteSpace: "pre-wrap" }}>Action Plan to be submitted within 30 days from the closing meeting and action plans to be verified in the next audit upon closure</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>c.</td>
                            <td style={{ fontWeight: "bold" }}>Opportunity for Improvement (OFI)</td>
                            <td> : </td>
                            <td style={{ whiteSpace: "pre-wrap" }}>The opportunity for improvement and the effectiveness on the nonconformity taken for the NCR shall be reviewed by NIOSH Certification’s auditors during the next surveillance audit.</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>d.</td>
                            <td style={{ fontWeight: "bold" }}>Areas of Concerns (AOC)</td>
                            <td> : </td>
                            <td style={{ whiteSpace: "pre-wrap" }}>The Areas of Concerns (AOC) raised has been take action prior to stage 2 audit.</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold" }}>e.</td>
                            <td style={{ fontWeight: "bold" }}>Expected date of issue</td>
                            <td> : </td>
                            <td style={{ whiteSpace: "pre-wrap" }}>Within 14 (fourteen) working days after closing meeting.</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  : null}
                  <tr>
                    <td style={{ verticalAlign: "top", width: "50px" }}>
                      <h6>{i, i++}.</h6>
                    </td>
                    <td>
                      <h6>Others</h6>
                      <p style={{ whiteSpace: "pre-wrap" }}>{data.others}</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h6>Prepared By</h6>
              <p>Lead Auditor<br />NIOSH Certification</p>

              </React.Fragment>
              : null}
              
              {activeTab === '2' ?
              <React.Fragment>
              <h6>Appendix A</h6>
              <br />
              {data.appendix_a}
              </React.Fragment>
              : null}
            
              <div className="col-md-12 text-right">
                <hr />
                {/* <button 
                  className="btn btn-outline-secondary" 
                  type="button"
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_API_URL}/audit-plans/${id}?export=true&token=${token}`);
                  }}
                >
                  <i className="icon-printer"></i>
                </button>&nbsp; */}
                
                {data.type && data.type.toUpperCase() !== 'ISO' ?
                <button 
                  // disabled={isSubmitting}
                  className="btn btn-outline-secondary" 
                  type="button" 
                  onClick={toggleModal}
                >
                  <i className="icon-envelope"></i> {'Send Audit Confirmation'}
                </button>
                  : null}
                &nbsp;
                {permissions && permissions.find(o => o.name.toLowerCase() === 'audit_plan.notify_client') ?
                <button 
                  disabled={isSubmitting}
                  className="btn btn-outline-secondary" 
                  type="button" 
                  onClick={sendNotificationHandler}
                >
                  <i className="icon-envelope"></i> {isSubmitting ? 'Sending Audit Plan...' : 'Send Audit Plan'}
                </button>
                : null}
                &nbsp;
                <button 
                  className="btn btn-outline-secondary" 
                  type="button"
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_API_URL}/audit-plans/view/${id}?token=${token}`);
                  }}
                >
                  <i className="icon-printer"></i>
                </button>&nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Modal className="modal-lg" isOpen={modal} toggle={toggleModal}>
        <Formik 
          enableReinitialize={true}
          initialValues={data}
          onSubmit={async (values, { setSubmitting }) => {
            // console.log(values);
            
            setSubmitting1(true);
            
            let data = {
              id: id,
              to: values.to,
              cc: values.cc,
              bcc: values.bcc,
              body: values.body,
              invitation_for_stakeholder_ref_no: values.invitation_for_stakeholder_ref_no,
              attachments: values.attachments,
            };

            // throw new Error();

            // return;

            let token = await localStorage.getItem('auth-token');

            token = JSON.parse(token);

            api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

            data = objectToFormData(data, {indices: true, booleansAsIntegers: true});
            
            await api({
              method: 'POST',
              url: '/audit-plans/send-audit-confirmation/' + id,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              data: data,
            })
            .then(function (response) {
              addToast('Audit confirmation has been sent', { appearance: 'success' });

              setSubmitting1(false);
              
              toggleModal();
            })
            .catch(function (error) {
              addToast(error.message, { appearance: 'error' });
              
              setSubmitting1(false);
            });

            // values.type = type;

            // values.subtype = subtype;

            // // console.log(values);

            // if (!id) {
            //   api.post('/audit-plans', JSON.stringify(values), {
            //     headers: {
            //       'Content-Type': 'application/json',
            //     },
            //   })
            //   .then(function (response) {
            //     // console.log(response);

            //     // addToast('Audit plan successfully saved', { appearance: 'success' });

            //     // history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));

            //     // window.location = '/client-application/iso';
            //   })
            //   .catch(function (error) {
            //     addToast(error.message, { appearance: 'error' });
            //   });
            // } else {
            //   api.put('/audit-plans/' + id, JSON.stringify(values), {
            //     headers: {
            //       'Content-Type': 'application/json',
            //     },
            //   })
            //   .then(function (response) {
            //     // console.log(response);

            //     addToast('Audit plan successfully updated', { appearance: 'success' });

            //     // history.push(props.path.replace(/:type/, type).replace(/:subtype/, subtype));

            //     // window.location = '/client-application/iso';
            //   })
            //   .catch(function (error) {
            //     addToast(error.message, { appearance: 'error' });
            //   });
            // }
          }}
        >
          {({ isSubmitting, values, setFieldValue, handleChange }) => (
            <FormikForm>
              <ModalHeader toggle={toggleModal}>Auditor Confirmation</ModalHeader>
              <ModalBody className="p-0">
                {/*<iframe 
                  src={`${process.env.REACT_APP_API_URL}/audit-plans/preview-audit-confirmation/${id}?token=${token}`}
                  style={{ border: 0, height: "320px", width: "100%" }}
                >
                </iframe> */}
                
                <FormGroup row className="m-2">
                  <Label sm={1} for="To">To</Label>
                  <Col sm={11}>
                    <Input 
                      // readOnly
                      type="text" 
                      name="to" 
                      id="To"
                      defaultValue={[data.client.email, data.client.contact_person_email, (data.lead_auditors.business_email ? data.lead_auditors.business_email : data.lead_auditors.private_email )].join(',').replace(/^,+|,+$/g, '')}
                      onChange={handleChange}
                      // value={values.invitation_for_stakeholder_ref_no}
                      // rows={10}
                      required
                    />
                  </Col>
                </FormGroup>
                
                <FormGroup row className="m-2">
                  <Label sm={1} for="CC">CC</Label>
                  <Col sm={11}>
                    <Input 
                      // readOnly
                      type="text" 
                      name="cc" 
                      id="CC"
                      defaultValue=""
                      onChange={handleChange}
                      value={values.cc}
                      // rows={10}
                    />
                  </Col>
                </FormGroup>
                
                <FormGroup row className="m-2">
                  <Label sm={1} for="BCC">BCC</Label>
                  <Col sm={11}>
                    <Input 
                      // readOnly
                      type="text" 
                      name="bcc" 
                      id="BCC"
                      defaultValue=""
                      onChange={handleChange}
                      value={values.bcc}
                      // rows={10}
                    />
                  </Col>
                </FormGroup>
                
                <Editor 
                  apiKey={process.env.REACT_APP_TINYMCE_KEY}
                  // disabled={true}
                  value={values.body}
                  init={{
                    height: 500,
                    menubar: 'file edit view insert format tools table tc help',
                    menubar: 'file edit view insert format tools table tc help',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                    paste_data_images: false,
                    content_style: "body { font-family: Arial; font-size: 10pt; }",
                  }}
                  onEditorChange={(content) => {
                   setFieldValue('body', content);
                  }}
                />
                <div className="m-2 row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="Ruj">Invitation for Stakeholder Consultation Ref. No.</Label>
                      <Input 
                        // readOnly={readOnly}
                        type="text" 
                        name="invitation_for_stakeholder_ref_no" 
                        id="Ruj"
                        onChange={handleChange}
                        value={values.invitation_for_stakeholder_ref_no}
                        // rows={10}
                      />
                    </FormGroup>

                    <FormGroup>
                      <input
                        type="checkbox"
                      />
                      <Button 
                        outline
                        // className="m-2"
                        // disabled={isSubmitting1}
                        // color="primary" 
                        onClick={() => {
                          window.open(`https://${window.location.host}/audit-module/audit-plan/invitation-for-stakeholder-consultation/${id}`);
                        }}
                      >
                      <i className="icon-doc"></i> Invitation for Stakeholder Consultation
                    </Button>
                    </FormGroup>
                    
                    <FormGroup>
                      <Label for="">Upload attachments</Label>
                      {values && values.attachments ? values.attachments.map((attachment, i) => 
                      <React.Fragment key={i}>
                        <br />
                        <Input
                          readOnly
                          type="text"
                          defaultValue={values.attachments[i].name}
                        />
                      </React.Fragment>
                      )
                    : ""}
                      <br />
                      <Input 
                        type="file" 
                        multiple
                        name="attachments" 
                        id="attachments"
                        onChange={(event) => {
                          const files = event.currentTarget.files;

                          let attachments = values.attachments;
                          if (typeof attachments === 'undefined') {
                            attachments = [];
                          }
                          
                          for (let i = 0; i < files.length; i++) {
                            attachments.push(files[i]);
                            setFieldValue('attachments', attachments);
                            
                            // console.log(attachments);
                          }
                          
                          // console.log(event.currentTarget.files[0]);
                          // setFieldValue('attachments', event.currentTarget.files)
                          
                          // let attachments = values.attachments;
                          // // console.log(attachments);
                          // if (typeof attachments === "undefined") {
                          //   attachments = [];
                          // }
                          // // attachments.push(event.currentTarget.files);
                          
                          // for (var i; i < event.currentTarget.files.length; i++) {
                          //   attachments.push(event.currentTarget.files[i]);
                          // }
                          
                          // setFieldValue('attachments', values.attachments);
                        }}
                      />
                    </FormGroup>

                  </div>
                </div>

              </ModalBody>
              <ModalFooter>
                <Button 
                    type="submit"
                    // disabled={true}
                    color="primary" 
                    // onClick={sendAuditConfirmationHandler}
                  >
                  {isSubmitting1 ? 'Sending...' : 'Send'}
                </Button>{' '}
                <Button color="secondary" onClick={toggleModal}>Cancel</Button>
              </ModalFooter>
            </FormikForm>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

export default View;