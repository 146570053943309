import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom';

import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import DateRangePicker from 'react-bootstrap-daterangepicker';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import SidebarToggler from '../../../components/SidebarToggler';

import api from '../../../utils/api';
import './style.css';
import { useToasts } from 'react-toast-notifications';

import qs from 'query-string';
import DatePicker from 'reactstrap-date-picker';
import moment from 'moment';
import { objectToFormData } from 'object-to-formdata';

const INITIAL_QUERY = {}

const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Listing = (props) => {
  let { path } = useRouteMatch();
  let { type, subtype } = useParams();
  let history = useHistory();
  let queryString = useQuery();
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);
  let [profile, setProfile] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [refresh, setRefresh] = useState(false);
  let [token, setToken] = useState('');

  const [data, setData] = useState([]);
  const [dataCsv, setDataCsv] = useState([]);

  const [query, setQuery] = useState(INITIAL_QUERY);
  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);

  const { addToast } = useToasts();

  const [activeObject, setActiveObject] = useState(null);
  const [total,setTotal]=useState(0);
  
  function getClass(index) {
    return index === activeObject?.id ? "active" : "inactive";
  }
  // let propType = props.type;

  const columns = [
    {
      editable: false,
      text: "No",
      csvFormatter: (cell, row, rowIndex) => {
        let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
         return rowNumber
       },
      formatter: (cell, row, rowIndex) => {
       let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
        return <span>{rowNumber}.</span>;
      }
    },
  {
    text: profile && profile.roles && profile.roles[0].name.toLowerCase() === 'ncsb admin'? 'Date' : 'Date of Audit',
    csvFormatter: (cell, row, rowIndex) => {
      if(profile && profile.roles && profile.roles[0].name.toLowerCase() === 'ncsb admin'){
        let invoice = '';
        let cert_received = '';
        let courier = '';
        if(row.invoice_date != null){
          invoice = 'Invoice:\n '+ moment(row.invoice_date).format('DD/MM/YY');
        } 
        if(row.courier_date!=null){
          courier = 'Courier:\n '+ moment(row.courier_date).format('DD/MM/YY');
        }
        if(row.certificate_received_date!=null){
          cert_received = 'Cert. Received:\n '+ moment(row.certificate_received_date).format('DD/MM/YY');
        }
        //|| row.courier_date!=null || row.certificate_received_date!=null) && row.audit_plan && row.audit_plan.date_of_audit_start && row.audit_plan.date_of_audit_end ){
       
          return  row.audit_plan && row.audit_plan.date_of_audit_start && row.audit_plan.date_of_audit_end ? 'Audit: '+moment(row.audit_plan.date_of_audit_start).format('DD/MM/YY') + ' - ' + moment(row.audit_plan.date_of_audit_end).format('DD/MM/YY') + invoice + courier + cert_received : ""
        
            
      }
      else{
        return row.audit_plan && row.audit_plan.date_of_audit_start && row.audit_plan.date_of_audit_end ? moment(row.audit_plan.date_of_audit_start).format('DD/MM/YY') + ' - ' + moment(row.audit_plan.date_of_audit_end).format('DD/MM/YY') : ""
      }
    },    
    formatter: (cell, row, rowIndex) => {
      if(profile && profile.roles && profile.roles[0].name.toLowerCase() === 'ncsb admin'){
        let invoice = '';
        let cert_received = '';
        let courier = '';
        if(row.invoice_date != null){
          invoice = '\n\n Invoice:\n '+ moment(row.invoice_date).format('DD/MM/YY');
        } 
        if(row.courier_date!=null){
          courier = '\n\n Courier:\n '+ moment(row.courier_date).format('DD/MM/YY');
        }
        if(row.certificate_received_date!=null){
          cert_received = '\n\n Cert. Received:\n '+ moment(row.certificate_received_date).format('DD/MM/YY');
        }
        //|| row.courier_date!=null || row.certificate_received_date!=null) && row.audit_plan && row.audit_plan.date_of_audit_start && row.audit_plan.date_of_audit_end ){
       
          return  row.audit_plan && row.audit_plan.date_of_audit_start && row.audit_plan.date_of_audit_end ? 'Audit:\n'+moment(row.audit_plan.date_of_audit_start).format('DD/MM/YY') + ' - ' + moment(row.audit_plan.date_of_audit_end).format('DD/MM/YY') + invoice + courier + cert_received : ""
        
            
      }
      else{
        return row.audit_plan && row.audit_plan.date_of_audit_start && row.audit_plan.date_of_audit_end ? moment(row.audit_plan.date_of_audit_start).format('DD/MM/YY') + ' - ' + moment(row.audit_plan.date_of_audit_end).format('DD/MM/YY') : ""
      }
    },
    style: {
      whiteSpace: "pre-wrap",
      fontSize: 12
    },
  },
  {
    text: 'Type of Audit',
    csvFormatter: (cell, row, rowIndex) => {
      return row.audit_plan && row.audit_plan.stage ? row.audit_plan.stage : "";
    },
    formatter: (cell, row, rowIndex) => {
      return row.audit_plan && row.audit_plan.stage ? row.audit_plan.stage : "";
    },
    style: {
      whiteSpace: "pre-wrap"
    },
  },
  {
    text: 'Organization',

    csvFormatter: (cell, row, rowIndex) => {
      if (row.audit_plan && row.audit_plan.client && row.audit_plan.client.name) {
          return row.audit_plan.client.name;
      }
    },
    formatter: (cell, row, rowIndex) => {
        if (row.audit_plan && row.audit_plan.client && row.audit_plan.client.name) {
            return row.audit_plan.client.name;
        }
    },
    style: {
      whiteSpace: "pre-wrap"
    },
  }, {
    text: 'Standards',

    csvFormatter: (cell, row, rowIndex) => {
      if (row.audit_plan && row.audit_plan.type.toUpperCase() !== 'ISO') {
        return (
          row.audit_plan.type.toUpperCase()+' '+row.audit_plan.subtype.replace(/-/, ' ').replace(row.audit_plan.subtype.charAt(0), row.audit_plan.subtype.charAt(0).toUpperCase())
        );
      }
      return (
        row.audit_plan && row.audit_plan.client && row.audit_plan.client.certifications ? 
          row.audit_plan.client.certifications.map((certification, i) => 
           certification.code
           ) : ""
      )
    },
    formatter: (cell, row, rowIndex) => {
      if (row.audit_plan && row.audit_plan.type.toUpperCase() !== 'ISO') {
        return (
          <ul>{row.audit_plan.type.toUpperCase()} <span className="text-muted">{row.audit_plan.subtype.replace(/-/, ' ').replace(row.audit_plan.subtype.charAt(0), row.audit_plan.subtype.charAt(0).toUpperCase())}</span></ul>
        );
      }

      return (
        <ul>{row.audit_plan && row.audit_plan.client && row.audit_plan.client.certifications ? 
          row.audit_plan.client.certifications.map((certification, i) => 
            <li>{certification.code}</li>
           ) : ""}&nbsp;</ul>
      )
    }
  }, {
    text: 'Team Leader',
    csvFormatter: (cell, row, rowIndex) => {
      return (
        row.audit_plan && row.audit_plan.lead_auditors ? 
          row.audit_plan.lead_auditors.map((auditor, i) => 
           auditor.full_name 
           ) : "" 
      )
    },
    formatter: (cell, row, rowIndex) => {
      return (
        <ol>{row.audit_plan && row.audit_plan.lead_auditors ? 
          row.audit_plan.lead_auditors.map((auditor, i) => 
            <li>{auditor.full_name}</li>
           ) : ""}&nbsp;</ol>
      )
    },
    style: {
      whiteSpace: "pre-wrap"
    },
  }, {
    dataField: 'status',
    text: 'Status',
    csvFormatter: (cell, row, rowIndex) => { 
      
      if(row.review && row.review.status && row.review.status=="3"){
        return (row.review.status_text+' '+row.review.technical_review_state_text)
      }
      if(row.review && row.review.status && row.review.status=="2"){
        return (row.review.status_text+' '+row.review.peer_review_state_text)
      }
      if(row.review && row.review.status && row.review.status!="2" && row.review.status!="3"){
        return (row.review.status_text+' '+row.review.state_text)
      } 
      if(row.audit_plan && row.audit_plan.stage && (row.audit_plan.stage.toLowerCase() === "surveillance 1" || row.audit_plan.stage.toLowerCase() === "surveillance 2" || row.audit_plan.stage.toLowerCase() === "surveillance 3" || row.audit_plan.stage.toLowerCase() === "surveillance 4")){
        if(row.non_conformances && row.non_conformances.length){
          const ncrclose = row.non_conformances.filter(ncr=>ncr.ncr_close=='Y');
          if(ncrclose.length === row.non_conformances.length){
            return ('Completed')
          }
          else{
            return "";
          }
        }
        else{
          return "";
        }
      }
    },
    formatter: (cell, row, rowIndex) => { 
      
      if(row.review && row.review.status && row.review.status=="3"){
        return (<span><Badge color="success">{row.review.status_text}</Badge> 
        <Badge color="warning">{row.review.technical_review_state_text}</Badge></span>)
      }
      if(row.review && row.review.status && row.review.status=="2"){
        return (<span><Badge color="primary">{row.review.status_text}</Badge>
        <Badge color="warning">{row.review.peer_review_state_text}</Badge></span>)
      }
      if(row.review && row.review.status && row.review.status!="2" && row.review.status!="3"){
        return (<span><Badge color="info">{row.review.status_text}</Badge>
        <Badge color="warning">{row.review.state_text}</Badge></span>)
      } 
      if(row.audit_plan && row.audit_plan.stage && (row.audit_plan.stage.toLowerCase() === "surveillance 1" || row.audit_plan.stage.toLowerCase() === "surveillance 2" || row.audit_plan.stage.toLowerCase() === "surveillance 3" || row.audit_plan.stage.toLowerCase() === "surveillance 4")){
        if(row.non_conformances && row.non_conformances.length){
          const ncrclose = row.non_conformances.filter(ncr=>ncr.ncr_close=='Y');
          if(ncrclose.length === row.non_conformances.length){
            return (<span>
            <Badge color="warning">Completed</Badge></span>)
          }
         /*  return (
           
           row.non_conformances.map((ncr, i) =>  ncr.ncr_close ))
          }  */
        }
      }
    },
    style: {
      whiteSpace: "pre-wrap"
    },
  }, 
  {
    text: 'Attendance',

    csvFormatter: (cell, row, rowIndex) => {
      let opening = '';
      let closing = '';
       
         if(row.audit_plan && row.audit_plan.attendances && row.audit_plan.attendances.length > 0){
          row.audit_plan.attendances.filter(at=>at.opening!==null).map((ATT, key) => 
           key == 0 ? ATT.opening != null ?
           opening = moment(ATT.opening).format('DD/MM/YY'): '' : ''
           );
           row.audit_plan.attendances.filter(at=>at.closing!==null).map((ATT, key) => 
            key == 0 ? ATT.closing != null ?
            closing = moment(ATT.closing).format('DD/MM/YY'): '' : ''
            );
           
            return('Opening : '+opening+' Closing : '+closing)
         } 
 
     },
    formatter: (cell, row, rowIndex) => {
      let opening = '';
      let closing = '';
       
         if(row.audit_plan && row.audit_plan.attendances && row.audit_plan.attendances.length > 0){
          row.audit_plan.attendances.filter(at=>at.opening!==null).map((ATT, key) => 
           key == 0 ? ATT.opening != null ?
           opening = moment(ATT.opening).format('DD/MM/YY'): '' : ''
           );
           row.audit_plan.attendances.filter(at=>at.closing!==null).map((ATT, key) => 
            key == 0 ? ATT.closing != null ?
            closing = moment(ATT.closing).format('DD/MM/YY'): '' : ''
            );
           
            return('Opening : '+opening+' \n Closing : '+closing)
         } 
 
     },
    style: {
      whiteSpace: "pre-wrap"
    },
  },
  
  
  
  
  {
    dataField: 'id',
    text: ' ',
    csvExport: false,
    formatter: (cell, row, rowIndex) => {
      let count_feedback=0;
      let count_evaluation=0;
        if(row.audit_plan ){
            row.audit_plan.auditor_evaluation.map((ae, key) => {       
              if(ae.date === null){
                count_evaluation++;
              }
            })
            if(row.audit_plan.client_feedback){
                if(row.audit_plan.client_feedback.date === null){
                  count_feedback++;
                }
            }
          }
          
      return (
        <ButtonGroup size="sm">
          {profile && profile.roles && profile.roles[0].name.toLowerCase() === 'client' ?
          
            <React.Fragment>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-1`}>
                  Audit Plan 
                </Tooltip>
              }
            >
              <Button outline tag={Link} to={`${path.replace('-report', '-plan').replace(/:type/, type).replace(/:subtype/, subtype)}/view/${row.audit_plan_id}`}>
                <i className="icon-docs"></i>
              </Button>
            </OverlayTrigger>
            
            {/*  {row.send_for_operation_review_date && row.send_for_operation_review_date !== null ?  */}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-2`}>
                    Audit Report
                  </Tooltip>
                }
              >
            {/* display after sent for operation review */}

            
              
            {count_feedback >= 1 ? 
            //profile.client_feedbacks === false  ?   
              <Button 
                outline 
                tag={Link} 
                onClick={() => {
                  alertClientFeedbackHandler(row.audit_plan.client_feedback.id);
              }}>
                <i className="icon-eye"></i>
              </Button>
              : 
              
              count_evaluation >=1 ?
             // row.audit_plan && row.audit_plan.auditor_evaluation.map((eva, i) =>  
               <Button outline tag={Link} onClick={() => {
                alertAuditorEvaluationHandler();
              }}>
              <i className="icon-eye"></i>
              </Button> 
             // )
              : 
              <Button outline tag={Link} to={`${path.replace(/:type/, type).replace(/:subtype/, subtype)}/view/${cell}?tab=introduction`}>
              <i className="icon-eye"></i>
              </Button>
              }
              
              
            
            
            </OverlayTrigger>
          
            {row.audit_plan && row.audit_plan.stage && row.audit_plan.stage.toLowerCase() !== 'stage 1' && row.review && row.review.technical_review_2_approved_at  !== null ?
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-3`}>
                    {/* HIDE AT 18 JAN 2022 FROM SHOW REAL CERT TO CLIENT */}
                    {/* row.certificate_approved !== null ? "Certificate" : "Draft Certificate" */}
                    {row.certificate_approved_admin !== null ? "Certificate" : "Draft Certificate"}
                  </Tooltip>
                }
              >
                {/* display after panel 2 approve */}
                {/* AMEND AT 18 JAN 2022 SHOW COLOR SAME AS DRAFT BUTTON REMOVE THIS ONCE color="danger" */}
                { row.certificate_approved_admin !== null ? 
               
                <Button 
                  outline
                  tag={Link}
                  to={`${path.replace(/:type/, type).replace(/:subtype/, subtype)}/view-draft-certificate/${row.id}`}
                > 
                  <i className="icon-badge"></i>
                </Button>
                :
                  <Button 
                    outline
                    color="danger"
                    tag={Link}
                    to={`${path.replace(/:type/, type).replace(/:subtype/, subtype)}/view-draft-certificate/${row.id}`}
                  > 
                    <i className="icon-badge"></i>
                  </Button>
                }
              </OverlayTrigger>
            : null
            }

            {row.status==="Draft Audit Report Confirmation" || row.status==="Draft Audit Report Confirmed For Auditor" ?
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-1`}>
                    Audit Report Draft Confirmation
                  </Tooltip>
                }
              >
                <Button outline tag={Link} to={`${path.replace(/:type/, type).replace(/:subtype/, subtype)}/draft-confirmation/${cell}`}>
                  <i className="icon-list"></i>
                </Button>
              </OverlayTrigger>
            : null}

            </React.Fragment>
          : 
            <React.Fragment>
              {row.send_for_draft_audit_report_date && row.send_for_draft_audit_report_date !==null ?
                <OverlayTrigger
                  
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-1`}>
                    Audit Report Draft Confirmation
                  </Tooltip>
                }
              >
                <Button outline tag={Link} to={`${path.replace(/:type/, type).replace(/:subtype/, subtype)}/draft-confirmation/${cell}`}>
                  <i className="icon-list"></i>
                </Button>
                </OverlayTrigger>
              : null}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-2`}>
                    Audit Report 
                  </Tooltip>
                }
              >              
              <Button outline tag={Link} to={`${path.replace(/:type/, type).replace(/:subtype/, subtype)}/view/${cell}?tab=introduction`}>
                <i className="icon-eye"></i>
              </Button>
              </OverlayTrigger>

              {permissions && permissions.find(o => o.name.toLowerCase() === 'audit_report.update') ?
              <Button outline tag={Link} to={`${path.replace(/:type/, type).replace(/:subtype/, subtype)}/edit/${cell}?tab=introduction`}>
                <i className="icon-pencil"></i>
              </Button>
              : null}

              {permissions && permissions.find(o => o.name.toLowerCase() === 'audit_report.delete') ?
              <Button outline color="danger" onClick={() => deleteHandler(cell)}>
                <i className="icon-trash"></i>
              </Button>
              : null}

              {row.audit_plan && row.audit_plan.stage && row.audit_plan.stage.toLowerCase() !== 'stage 1' && row.review && row.review.technical_review_2_approved_at  !== null ?
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-3`}>
                    {row.certificate_approved_admin !== null ? "Certificate" : "Draft Certificate"}
                  </Tooltip>
                }
              >
                {/* display after panel 2 approve */}
                {row.certificate_approved_admin !== null ?
                <Button 
                  outline
                  tag={Link}
                  to={`${path.replace(/:type/, type).replace(/:subtype/, subtype)}/view-draft-certificate/${row.id}`}
                >
                  <i className="icon-badge"></i>
                </Button>
                : 
                <Button 
                  outline
                  color="danger"
                  tag={Link}
                  to={`${path.replace(/:type/, type).replace(/:subtype/, subtype)}/view-draft-certificate/${row.id}`}
                >
                  <i className="icon-badge"></i>
                </Button>
                }
              </OverlayTrigger>
            : null
            }
          {profile && profile.roles && profile.roles[0].name.toLowerCase() === 'ncsb admin' ? 
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-3`}>
                  Click to update Invoice Date
                </Tooltip>
              }
            >
              <Button 
                outline
                color="info"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveObject( {id: row.id, invoice_date : row.invoice_date, invoice_no:row.invoice_no,update :'Invoice'});
                  toggleModal();
                }}
                className={getClass(row.id)}>
                <i className="fa fa-dollar (alias)"></i>
              </Button>
            </OverlayTrigger>
              : null
          } 
          {profile && profile.roles && profile.roles[0].name.toLowerCase() === 'ncsb admin' ? 
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-3`}>
                  Click to update Courier Certificate Info
                </Tooltip>
              }
            >
              <Button 
                outline
                color="info"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveObject( {id: row.id, courier_date : row.courier_date, certificate_received_date:row.certificate_received_date,update :'Courier'});
                  toggleModal();
                }}
                className={getClass(row.id)}>
                <i className="fa fa-plane"></i>
              </Button>
            </OverlayTrigger>

            
              
            
            : null
          } 
            </React.Fragment>
          }
        </ButtonGroup>
        
       
      )
    },
    style: { textAlign: 'center' }
  }];

  useEffect(() => {
    const getToken = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);
    }

    getToken();

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));
        setProfile(response.data);
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    // if (!profile.permissions) {
      getMe();
    // }

    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/audits?type[]=' + type + '&subtype[]=' + subtype + '&' + '&month=' + queryString.get('month') + '&year=' + queryString.get('year') + '&stage=' + queryString.get('stage') , {
        params: query,
      })
      .then(function (response) {

        console.log(response.data.data);
        console.log(response.data.meta);
        setPermissions(response.data.meta.permissions);
        setData(response.data.data);
        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
        }));
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
      setRefresh(false);

    }

    getData();

    const getDataCsv = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/audits?type[]=' + type + '&subtype[]=' + subtype + '&' + '&month=' + queryString.get('month') + '&year=' + queryString.get('year') + '&limit=-1&stage=' + queryString.get('stage') , {
        params: query,
      })
      .then(function (response) {

        setPermissions(response.data.meta.permissions);
        setDataCsv(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
      setRefresh(false);
    }

    getDataCsv();

  }, [props, type, query]);

  const MyExportCSV = (props) => {
    const handleClick = () => {
     
      // passing my custom data
      props.onExport(dataCsv);
    };
    return (
      
        <OverlayTrigger
          placement="top"
          overlay={
          <Tooltip id={`tooltip-1`}>
            Export to CSV
          </Tooltip>
          }
        >
           <Button className="btn btn-success"  onClick={ handleClick }>
              <i className="fa fa-file-excel-o"></i>
            </Button>
            </OverlayTrigger>
    );
  };
  const deleteHandler = async (id) => {
    if (window.confirm('Delete this record?')) {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.delete('/audits/' + id )
      .then(function (response) {
        // console.log(response);

        // setData(response.data.data);

        addToast('Audit successfully deleted', { appearance: 'success' });

        history.push(path.replace(/:type/, type));
      })
      .catch(function (error) {
        // console.log(error.response);
        addToast(error.response.data.message, { appearance: 'error' });
      });
    }
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }

  const updateHandler = async (id) => {

  }
  const alertClientFeedbackHandler = async(id)=>{
    if (window.confirm("To have an access to Audit Report & Certificate, please submit feedback at Client Feedback. Click OK to proceed")) {
      window.open(`/application-review/client-feedbacks/edit/${id}`);
    }
  }
  const alertAuditorEvaluationHandler = async()=>{
    if (window.confirm("To have an access to Audit Report & Certificate, please evaluate auditors at Auditor Evaluation. Click OK to proceed")) {
      window.open(`/application-review/auditor-evaluations`);
    }
  }
  return (
    <ToolkitProvider
  keyField="id"
  data={data}
  columns={ columns }
  exportCSV
>{
    props => (
    <React.Fragment>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <h2 style={{ textTransform: "capitalize" }}>
                <SidebarToggler />
                {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ')}` : ""} Audit Report
              </h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="icon-home"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  Audit
                </li>
                <li className="breadcrumb-item">
                  Audit Report
                </li>
                <li className="breadcrumb-item" style={{ textTransform: "capitalize" }}>
                  <Link to={path.replace(/:type/, type).replace(/:subtype/, subtype)}>
                    {type.toUpperCase()}{type.toUpperCase() === 'MSPO' && subtype ? ` ${subtype.replace(/-/, ' ')}` : ""}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>AUDIT REPORT LIST</h2>
                {profile.permissions && profile.permissions.find(o => o.name.toLowerCase() === 'audit_report.create') ?
                <ul className="header-dropdown">
                  <li>
                    <Button 
                      className="m-t-10 m-l-20"
                      color="primary" 
                      tag={Link} 
                      to={`${path.replace(/:type/, type).replace(/:subtype/, subtype)}/create`}
                    >
                      Add New
                    </Button>
                  </li>
                </ul>
                : null}
              </div>
              <div className="body">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    client_name: queryString.get('client_name') ? queryString.get('client_name') : "",
                    stage: queryString.get('stage') && queryString.get('stage') != null ? queryString.get('stage') : '',
                    start: queryString.get('start') ? queryString.get('start') : "",
                    end: queryString.get('end') ? queryString.get('end') : "",
                    attendance: queryString.get('attendance') && queryString.get('attendance') != null ? queryString.get('attendance') : '',
                    // region: queryString.get('region') ? queryString.get('region') : "",
                    // state: queryString.get('state') ? queryString.get('state') : "",
                    // certifications: queryString.get('certifications') ? queryString.get('certifications') : "",
                    // status: queryString.get('status') ? queryString.get('status') : "",
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    var searchString = qs.stringify(values); // objectToFormData(values, {indices: true, booleansAsIntegers: true});

                    setQuery(values);

                    history.push({
                      pathname: path.replace(/:type/, type).replace(/:subtype/, subtype),
                      search: searchString
                    });

                    setQuery(values);
                  }}
                >
                  {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <div className="row clearfix">
                      <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Name">Organization</Label>
                        <Input 
                          type="text" 
                          // className="custom-select" 
                          name="client_name" 
                          id="Name"
                          onChange={handleChange}
                          value={values.client_name}
                        />
                      </FormGroup>
                      </div>
                      <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Issue">Type</Label>
                          <Input 
                            type="select" 
                            name="stage" 
                            id="Stage"
                            onChange={(e) => {
                              let stage = e.currentTarget.options[e.currentTarget.selectedIndex].value;
                              setFieldValue('stage', stage);
                            }}
                            value={values.stage}
                          >
                            <option></option>
                            <option value="Follow Up Audit">Migration</option>
                            <option value="Re-Certification Cum Migration">Re-Certification Cum Migration</option>
                            <option value="Re-Certification">Re-Certification</option>
                            <option value="Initial Audit">Initial Audit</option>
                            <option value="Stage 1">Stage 1</option>
                            <option value="Stage 2">Stage 2</option>
                            <option value="Surveillance 1">1st Surveillance</option>
                            <option value="Surveillance 2">2nd Surveillance</option>
                            <option value="Surveillance 3">3rd Surveillance</option>
                            <option value="Surveillance 4">4th Surveillance</option>
                          </Input>
                      </FormGroup>
                      </div>
                      <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Date">Date of Audit</Label>
                        <DateRangePicker 
                         // disabled={readOnly}
                          initialSettings={{
                            timePicker: false
                          }}
                          timePicker={false}
                          timePickerSeconds={false}
                          containerStyles={{ display: "block" }}
                          start={values.start && values.start.length ? moment(values.start).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                          end={values.end && values.start.length ? moment(values.end).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')}
                          onApply={(event, picker) => { 
                            setFieldValue('start', picker.startDate.format('YYYY-MM-DD'));
                            setFieldValue('end', picker.endDate.format('YYYY-MM-DD'));
                          }}
                        >
                          <Input 
                            autoComplete="off"
                          //  readOnly={readOnly}
                            type="text" 
                            // name="date_of_audit" 
                            id="date"
                            // onChange={handleChange}
                            value={values.start && values.end ? moment(values.start).format('DD/MM/YYYY') + ' - ' + moment(values.end).format('DD/MM/YYYY') : ""}
                          />
                        </DateRangePicker>
                      </FormGroup>
                      </div>
                     <div className="col-sm-12">
                      <FormGroup>
                        <Label for="Issue">Attendance</Label>
                          <Input 
                            type="select" 
                            name="attendance" 
                            id="attendance"
                            onChange={(e) => {
                              let attendance = e.currentTarget.options[e.currentTarget.selectedIndex].value;
                              setFieldValue('attendance', attendance);
                            }}
                            value={values.attendance}
                          >
                            <option value="ALL">All</option>
                            <option value="TRUE">Yes</option>
                            <option value="FALSE">No</option>
                          </Input>
                      </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-4">
                        <Label>&nbsp;</Label>
                        <FormGroup>
                          <Button 
                            // className="btn btn-outline-secondary"
                            outline
                            color="secondary" 
                            // tag={Link} 
                            // to="/client-database"
                          >
                            Search
                          </Button>
                          <Button 
                            // className="btn btn-outline-secondary"
                            type="button"
                            outline
                            color="link" 
                            // tag={Link} 
                            // to={`${path}`}
                            onClick={() => {
                              setQuery(INITIAL_QUERY);

                              history.push({
                                pathname: path.replace(/:type/, type).replace(/:subtype/, subtype),

                               // pathname: path.replace(/:type/, type),
                              });
                             // setFieldValue('stage', '');
                              setQuery(INITIAL_QUERY);
                            }}
                          >
                            Reset
                          </Button>
                        </FormGroup>
                      </div>
                    </div>
                  </FormikForm>
                  )}
                </Formik>

                <hr />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-right"><MyExportCSV { ...props.csvProps } />
              </div>
              <div className="body project_report">
                <div className="table-responsive">
                <BootstrapTable  { ...props.baseProps } 
                    remote={true}
                    bootstrap4
                    bordered={false}
                    classes="table table-hover js-basic-example dataTable table-custom"
                    headerClasses="thead-dark"
                    keyField="id"
                   // data={data ? data.filter(o => o.audit_plan !== null) : []}
                   data={data}
                    columns={ columns }
                    pagination={ 
                      paginationFactory(pagingInfo)
                    }
                    onTableChange={handleTableChange}
                    
                  />
                </div>
              </div>
            </Card>
            <Modal className="modal-md" object={activeObject} isOpen={modal} toggle={toggleModal}>
                <Formik
                  enableReinitialize={true}
                  initialValues={activeObject}
                  onSubmit={async (values, { setSubmitting }) => {
                    let token = await localStorage.getItem('auth-token');
                    token = JSON.parse(token);
                    api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
                    var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
                      
                      data.append('_method', 'PUT');
                      await api({
                        method: 'POST',
                        url: '/audits/' + values.id,
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                        data: data,
                      })
                      .then(function (response) {
                        if(activeObject.update== "Courier"){
                        addToast('Courier Info successfully updated', { appearance: 'success' });
                        }
                        else{
                        addToast('Invoice Info successfully updated', { appearance: 'success' });
                    }
                        
                        toggleModal();
                        history.push(props.path + '?refresh=' + Date.now());
                      })
                      .catch(function (error) {
                        addToast(error.message, { appearance: 'error' });
                      });
                  }}
              >
                {({ isSubmitting, values, setFieldValue, handleChange }) => (
                  <FormikForm>
                    <FormGroup>
                      
                    </FormGroup>
                    <ModalHeader toggle={toggleModal}>Update {values.update} Details</ModalHeader>
                    <ModalBody className="p-0">
                      <div className="m-2 row clearfix">
                        <div className="col-sm-12">
                          <div className="input-group">
                            <FormGroup>
                              <Label for="InvoiceDate">{values.update} Date </Label>
                              <DatePicker
                                // key={note.id}
                                autoComplete="off"
                                name={values.update == "Courier" ? "courier_date":"invoice_date"}
                                id="Date"
                                value={values.update == "Courier" ? values.courier_date : values.invoice_date}
                                onChange={values.update == "Courier" ? date => setFieldValue('courier_date', moment(date).format('YYYY-MM-DD')) : date => setFieldValue('invoice_date', moment(date).format('YYYY-MM-DD'))}
                              />
                            </FormGroup>
                          </div>           
                        </div>
                      </div>
                      <div className="m-2 row clearfix">
                        <div className="col-sm-12">
                          <FormGroup>
                            <Label for="InvoiceNo">{values.update == "Courier" ? "Certification Received Date" : "Invoice No."}</Label>
                            {values.update == "Courier" ? 
                            <DatePicker
                            // key={note.id}
                            autoComplete="off"
                            name="certificate_received_date" 
                            id="Date"
                            value={values.certificate_received_date}
                            onChange={date2 => setFieldValue('certificate_received_date', moment(date2).format('YYYY-MM-DD'))}
                          />
                            
                            :
                            <Input 
                            readOnly={false}
                            type="text" 
                            name="invoice_no"
                            id={`InvoiceNo${values.id}`}
                            defaultValue={values.invoice_no}
                            onChange={handleChange}
                            />
                            }
                          </FormGroup> 
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button 
                          type="submit"
                          disabled={isSubmitting}
                          color="primary" 
                        >
                        {isSubmitting ? 'Saving...' : 'Save'}
                      </Button>{' '}
                      <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>

                  </FormikForm>
                )}
                </Formik>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
   )
  }
  </ToolkitProvider>
  )
}

export default Listing;