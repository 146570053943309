import React, { useEffect, useState, useRef } from 'react';

import {
  Link,
  useRouteMatch,
} from 'react-router-dom';

import SidebarToggler from '../../components/SidebarToggler';

import { 
  Formik,
  Form
} from 'formik';

import { Input } from "reactstrap";

import moment from 'moment';

import api from "../../utils/api";
import { db } from "../../utils/firebase";

const Chat = () => {
  let { path } = useRouteMatch();

  let [formValues, setFormValues] = useState({});

  let [profile, setProfile] = useState({});

  let [chats, setChats] = useState([]);

  let [users, setUsers] = useState([]);

  const myRef = useRef(null);

  useEffect(() => {
    let userId = null;

    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        setProfile(response.data);

        userId = response.data.id;

        db.ref(`users/${response.data.id}`).set({
          id: response.data.id,
          name: response.data.name,
          status: 'online',
          since: Date.now(),
        });

        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        // setProfile(response.data);

        // if (response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'audit_report.update') === "undefined") {
        //   history.push(props.path.replace(/:type/, type));
        // }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    db.ref("chats").limitToLast(500).on("value", snapshot => {
      let chats = [];

      snapshot.forEach((snap) => {
        chats.push(snap.val());
      });

      setChats(chats);

      try {
        myRef.current.scrollIntoView({ behavior: "smooth" });
      } catch (error) {

      }
    });

    db.ref("users").orderByChild("name").on("value", snapshot => {
      let users = [];

      snapshot.forEach((snap) => {
        users.push(snap.val());
      });

      setUsers(users);
    });

    return () => {
      // db.ref(`users/${userId}`).remove();

      db.ref(`users/${userId}/status`).set('offline');
      db.ref(`users/${userId}/since`).set(Date.now());

      db.ref("chats").off();
      db.ref("users").off();
    }
  }, []);

  // const offline = (profile) => {
  //   console.log(JSON.stringify(profile));
  // }

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Chat
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={path}>
                  Chat
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="card chat_app">
            <div className="people_list" style={{ height: "425px", overflowY: "scroll"}}>
              <ul className="list-unstyled chat-list mt-2 mb-0">
              {users.map((user, i) => 
                user.status === 'online' ?
                  <React.Fragment>
                    <li key={i}>
                      <img src={`https://ui-avatars.com/api/?name=${user.name}`} alt="avatar" />
                      <div className="about" style={{ width: "180px" }}>
                        <div className="name">{user.name}</div>
                        <div className="status">
                          <i className={`fa fa-circle ${user.status === 'online' ? 'text-success' : ''}`}></i> {user.status}
                        </div>
                      </div>
                    </li>
                  </React.Fragment>
                  : null
                )}
                {users.map((user, i) => 
                  user.status !== 'online' ?
                  <React.Fragment>
                    <li key={i}>
                      <img src={`https://ui-avatars.com/api/?name=${user.name}`} alt="avatar" />
                      <div className="about" style={{ width: "180px" }}>
                        <div className="name">{user.name}</div>
                        <div className="status">
                          <i className={`fa fa-circle ${user.status === 'online' ? 'text-success' : ''}`}></i> {user.status}
                        </div>
                      </div>
                    </li>
                  </React.Fragment>
                  : null
                )}
              </ul>
            </div>
            <div className="chat">
              {/* <div className="chat-header clearfix">

              </div> */}
              <div className="chat-history clearfix" style={{ height: "350px", overflowY: "scroll"}}>
                <ul className="m-b-0">
                  {chats.map((chat, i) => 
                    <React.Fragment>
                      <li className="clearfix" key={i}>
                        {chat.user.id === profile.id ?
                        <React.Fragment>
                          <div className="message-data">
                            <span className="message-data-time">{moment(chat.timestamp).fromNow()}&nbsp;</span>
                          </div>
                          <div className="message my-message">
                            {chat.message}
                          </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <div className="message-data text-right">
                            <span className="message-data-time">{moment(chat.timestamp).fromNow()}&nbsp;</span>
                            <img src={`https://ui-avatars.com/api/?name=${chat.user.name}`} alt="avatar" />
                          </div>
                          <div className="message other-message float-right">
                            {chat.message}
                          </div>
                        </React.Fragment>
                        }
                      </li>
                      <li ref={myRef}></li>
                    </React.Fragment>
                  )}
                </ul>
              </div>
              <div className="chat-message clearfix">
                <Formik 
                  enableReinitialize={true}
                  initialValues={formValues}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    await db.ref("chats").push({
                      message: values.message,
                      timestamp: Date.now(),
                      user: {
                        id: profile.id,
                        name: profile.name,
                      },
                    });

                    resetForm({
                      message: ""
                    });

                    setFormValues({
                      message: ""
                    });
                  }}
                >
                  {({ isSubmitting, values, setFieldValue, handleChange }) => (
                    <Form>
                      <div className="input-group mb-0">
                        <Input 
                          name="message" 
                          placeholder="Type a message..." 
                          onChange={handleChange}
                          value={values.message}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;