import React from 'react';

import {
  // Link,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import Listing from './Listing';
import FormCreate from './FormCreate';
import Form from './Form';
import View from './View';
// import SidebarToggler from '../../components/SidebarToggler';

const AuditorClaim = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Listing />
      </Route>
      <Route path={`${path}/create`}>
        <FormCreate auditor={true} path={path} readOnly={false} />
      </Route>
      <Route path={`${path}/edit/:id`}>
          <Form auditor={true} path={path} readOnly={true} />
      </Route>
      <Route 
        path={`${path}/review/:id`} 
        render={(props) => <View path={path} readOnly={true}  review={true} {...props} />} 
      />
      <Route 
        path={`${path}/approval/:id`} 
        render={(props) => <View path={path} readOnly={true} approval={true} {...props} />} 
      />
      <Route path={`${path}/view/:id`}>
        <View path={path} readOnly={true} />
      </Route> 
    </Switch>
  )
}

export default AuditorClaim;