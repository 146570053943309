import React, { useState, useEffect } from "react";

import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom";

import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  // Nav,
  // NavItem,
  // NavLink,
  // TabContent,
  // TabPane,
} from "reactstrap";

import { 
  Formik,
  Form as FormikForm
} from "formik";

import moment from 'moment';

import DatePicker from 'reactstrap-date-picker';

import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

import api from "../../../utils/api";

import SidebarToggler from "../../../components/SidebarToggler";

import { objectToFormData } from 'object-to-formdata';

const Assign = (props) => {
  let { id, type, client_id } = useParams();
  let history = useHistory();

  const { addToast } = useToasts();

  const [data, setData] = useState({});
  const [reference, setReference] = useState([]);
  const [auditors, setAuditor] = useState([]);

  const [USERS, setUser] = useState([]);

  let { readOnly, approval, review }  = props;

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        // setProfile(response.data);

        // if (!readOnly && response.data.permissions && typeof response.data.permissions.find(o => o.name.toLowerCase() === 'technical_review.update') === "undefined") {
        //   history.push(props.path);
        // }
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getAuditors = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/auditors?fields[]=id&fields[]=full_name&fields[]=business_mobile&fields[]=private_mobile&limit=-1')
      .then(function (response) {
        // console.log(response.data);

        let auditors = [];
        response.data.data.map((auditor, i) => auditors.push({
          label: auditor.full_name,
          value: auditor.id,
          contact_no: auditor.contact_no,
        }));

        setAuditor(auditors);

        // console.log(auditors);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getAuditors();

    const getUsers = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      
      api.get('/users?limit=-1&role[]=4&role[]=5')
      .then(function (response) {
        //console.log(response.data.data);
        let USERS = [];
        response.data.data.map((user, i) => USERS.push({
          label: user.name,
          value: user.id
        }));
        setUser(USERS);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getUsers();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/operation-reviews/' + id)
        .then(function (response) {
          delete response.data.data.id;

          setData(response.data.data);

          // reload(false);
        })
        .catch(function (error) {
          if (error && error.response && error.response.data.data) {
            addToast(error.response.data.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }

      getData();

      const getReferences = async () => {
     
        console.log(JSON. stringify(data));
        let token = await localStorage.getItem('auth-token');
  
        token = JSON.parse(token);
  
        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
        api.get('/operation-reviews?client[]='+client_id)
        .then(function (response) {
           let references = [];
           response.data.data.map((reference, i) => references.push({
             label: reference.audit.audit_plan.stage,
             value: reference.id,
           }));
   
           setReference(references);
        
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error && error.response && error.response.data.data) {
            addToast(error.response.data.data.message, { appearance: 'error' });
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }
      getReferences();
    }
  }, [id]);

  return (
    <Formik 
        enableReinitialize={true}
        initialValues={data}
        onSubmit={async (values, { setSubmitting }) => {
          
          values.action ='assigned';

          if (props.approval) {
            if (values.tr_approved_1_at && !values.tr_approved_2_at) {
              values.tr_approved_2_at = moment().format('YYYY-MM-DD HH:mm:ss');
            }

            if (!values.tr_approved_1_at) {
              values.tr_approved_1_at = moment().format('YYYY-MM-DD HH:mm:ss');
            }
          }
          console.log(values);
          var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});

          let token = await localStorage.getItem('auth-token');

          token = JSON.parse(token);

          api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

          data.append('_method', 'PUT');

          await api({
            method: 'POST',
            url: '/operation-reviews/' + id,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })

          // await api.put('/operation-reviews/' + id, JSON.stringify(values), {
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          // })
          .then(function (response) {
            addToast('CAP review successfully updated', { appearance: 'success' });

            history.push(props.path.replace(/:type/, type) + '?refresh=' + Date.now());
          })
          .catch(function (error) {
            if(error.message =='Network Error'){
              addToast('CAP review successfully updated', { appearance: 'success' });
              history.push(props.path.replace(/:type/, type) + '?refresh=' + Date.now());
            }
            else
            {
              addToast(error.message, { appearance: 'error' });
            }
          });
        }}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => (
          <div className="container-fluid">
            <div className="block-header">
              <div className="row">
                <div className="col-lg-10 col-md-8 col-sm-12">
                  <h2>
                    <SidebarToggler />
                    {type.toUpperCase()} Certification Approval Panel (CAP)
                  </h2>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="icon-home"></i>
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      Review
                    </li>
                    <li className="breadcrumb-item">
                      Certification Approval Panel (CAP)
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={props.path.replace(/:type/, type)}>
                        {type.toUpperCase()}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12">
                <Input 
                outline
                color="primary" 
                onChange={(e) => {
                  let id = e.currentTarget.options[e.currentTarget.selectedIndex].value;
                  window.open(`/boss-chart/view/`+id);
                }}
                type="select" 
                className="custom-select" 
                name="reference"
                id="opr_id"
                style={{ width: "3cm" }}
              >
                <option>Reference</option>
                {reference && reference.length ? reference.map((refer, i) => 
                <option value={refer.value}>{refer.label.toUpperCase()}</option>
              ) : ""}
              </Input>
        {/*         <Button 
                    outline
                    color="primary" 
                    onClick={() => {
                      window.open(`/boss-chart/view/`+id);
                    }}>
                  <i className="icon-graph"></i> Reference
                  </Button>   */}              
                  </div>
              </div>
            </div>
            <FormikForm>
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12">
                  <Card>
                    <div className="header">
                      <h2>REVIEW</h2>
                    </div>
                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <FormGroup>
                            <Label for="ToBeReviewedBy">Panel 1</Label>
                            <Input 
                              // readOnly={readOnly}
                              // disabled={true}
                              type="select" 
                              className="custom-select" 
                              name="technical_review_reviewer_1_id"
                              id="ToBeReviewedBy"
                              // onChange={(e) => {
                              //   // console.log(e.target.name, e.target.value);
    
                              //   setFieldValue(e.target.name, e.target.value);
                              //   // setFieldValue('reviewed_at', "");
                              // }}
                              onChange={handleChange}
                              value={values.technical_review_reviewer_1_id}
                              required={true}
                            >
                              <option></option>
                              {auditors && auditors.length ? auditors.map((auditor, i) => 
                              <option value={auditor.value}>{auditor.label.toUpperCase()}</option>
                            ) : ""}
                            </Input>
                          </FormGroup>
                        </div>
                      </div>

                      <div className="row clearfix">
                        <div className="col-sm-12">
                          <FormGroup>
                            <Label for="ToBeReviewedBy">Panel 2</Label>
                            <Input 
                              // readOnly={readOnly}
                              // disabled={true}
                              type="select" 
                              className="custom-select" 
                              name="technical_review_reviewer_2_id"
                              id="ToBeReviewedBy"
                              // onChange={(e) => {
                              //   // console.log(e.target.name, e.target.value);
    
                              //   setFieldValue(e.target.name, e.target.value);
                              //   // setFieldValue('reviewed_at', "");
                              // }}
                              onChange={handleChange}
                              value={values.technical_review_reviewer_2_id}
                              required={true}
                            >
                              <option></option>
                              {USERS && USERS.length ? USERS.map((USER, i) => 
                              <option value={USER.value}>{USER.label.toUpperCase()}</option>
                            ) : ""}
                            </Input>
                          </FormGroup>
                        </div>
                      </div>         

                    </div>
                  </Card>

                  {!props.review ? 
                  <Card>
                  <div className="header">
                    <h2>APPROVE</h2>
                  </div>
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="ToBeReviewedBy">CEO</Label>
                          <Input 
                            // readOnly={readOnly}
                            // disabled={true}
                            type="select" 
                            className="custom-select" 
                            name="technical_review_approver_1_id"
                            id="ToBeReviewedBy"
                            // onChange={(e) => {
                            //   // console.log(e.target.name, e.target.value);

                            //   setFieldValue(e.target.name, e.target.value);
                            //   // setFieldValue('reviewed_at', "");
                            // }}fexc
                            onChange={handleChange}
                            value={values.technical_review_approver_1_id}
                            required={true}
                          >
                            <option></option>
                            {USERS && USERS.length ? USERS.map((USER, i) => 
                              <option value={USER.value}>{USER.label.toUpperCase()}</option>
                              ) : ""}
                          </Input>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <FormGroup>
                          <Label for="ToBeReviewedBy">Chairman</Label>
                          <Input 
                            // readOnly={readOnly}
                            // disabled={true}
                            type="select" 
                            className="custom-select" 
                            name="technical_review_approver_2_id"
                            id="ToBeReviewedBy"
                            // onChange={(e) => {
                            //   // console.log(e.target.name, e.target.value);

                            //   setFieldValue(e.target.name, e.target.value);
                            //   // setFieldValue('reviewed_at', "");
                            // }}
                            onChange={handleChange}
                            value={values.technical_review_approver_2_id}
                            required={true}
                          >
                            <option></option>
                            {USERS && USERS.length ? USERS.map((USER, i) => 
                              <option value={USER.value}>{USER.label.toUpperCase()}</option>
                              ) : ""}
                          </Input>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-sm-4">
                        {!readOnly ?
                        <button 
                          type="submit" 
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </button> : ""}
                        {props.approval ? 
                          <button 
                          type="submit" 
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Saving...' : 'Approve'}
                        </button> : ""}
                        &nbsp;<Button 
                          // className="btn btn-outline-secondary"
                          disabled={isSubmitting}
                          outline
                          color="secondary" 
                          tag={Link} 
                          to={props.path.replace(/:type/, type)}
                        >
                          Cancel
                        </Button>
                      </div>
                      
                    </div>
                  </div>
                </Card>
                : null}

                </div>
              </div>
            </FormikForm>
          </div>
        )}
      </Formik>
  )
}

export default Assign;