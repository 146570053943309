import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  // useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
  // ListGroup,
  // ListGroupItem,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

// import SidebarToggler from '../../components/SidebarToggler';

import api from '../../utils/api';

// import moment from 'moment';

// import DatePicker from 'reactstrap-date-picker';

// import { Editor } from '@tinymce/tinymce-react';

import { useToasts } from 'react-toast-notifications';

const INITIAL_VALUES = {

};

const FormCreate = (props) => {
  let { type, id } = useParams();

  // let { path } = useRouteMatch();
  let history = useHistory();
  // let location = useLocation();
  // let { from } = location.state || { from: { pathname: "/client" } };

  const { addToast } = useToasts();

  const [data, setData] = useState(INITIAL_VALUES);
  const [clients, setClient] = useState([]);
//   const [auditors, setAuditor] = useState([]);

  // let { readOnly }  = props;

  useEffect(() => {
    // console.log(path);

    const getClients = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/clients?limit=-1')
      .then(function (response) {
        // console.log(response.data);

        setClient(response.data.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getClients();

    //   const getAuditors = async () => {
    //     let token = await localStorage.getItem('auth-token');

    //     token = JSON.parse(token);

    //     api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

    //     api.get('/auditors?limit=-1')
    //     .then(function (response) {
    //       // console.log(response.data);

    //       setClient(response.data.data);
    //     })
    //     .catch(function (error) {
    //       // console.log(error.response);
    //       if (error && error.response && error.response.data) {
    //         addToast(error.response.data.message, { appearance: 'error' });
    //       } else {
    //         addToast(error.message, { appearance: 'error' });
    //       }
    //     });
    //   }

    //   getAuditors();

      // const getData = async () => {
      //   let token = await localStorage.getItem('auth-token');

      //   token = JSON.parse(token);

      //   api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      //   api.get('/audits/' + id)
      //   .then(function (response) {
      //     // console.log(response.data);

      //     delete response.data.id;

      //     setData(response.data);
      //   })
      //   .catch(function (error) {
      //     // console.log(error.response);
      //     if (error && error.response && error.response.data) {
      //       addToast(error.response.data.message, { appearance: 'error' });
      //     } else {
      //       addToast(error.message, { appearance: 'error' });
      //     }
      //   });
      // }

      // getData();
  }, []);

  return (
    <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
        
        if (!id) {
          api.post('/client-revenues', JSON.stringify(values), {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(function (response) {
            // console.log(response.data.data.id);

            addToast('Revenue successfully saved', { appearance: 'success' });

            // history.push('/audit/audits');

            // window.location = '/client-application/iso';

            if (response.data.data.id) {
              history.push(`${props.path.replace(/:type/, type)}/edit/${response.data.data.id}`);
            }
          })
          .catch(function (error) {
            addToast(error.message, { appearance: 'error' });
          });
        } else {
          // api.put('/audits/' + id, JSON.stringify(values), {
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          // })
          // .then(function (response) {
          //   // console.log(response);

          //   addToast('Audit successfully updated', { appearance: 'success' });

          //   history.push('/audit/audits');

          //   // window.location = '/client-application/iso';
          // })
          // .catch(function (error) {
          //   addToast(error.message, { appearance: 'error' });
          // });
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
        <FormikForm>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <Card>
                <div className="header">
                  <h2>REVENUE</h2>
                </div>
                <div className="body">
                    <div className="row clearfix">
                    <div className="col-sm-12">
                      <FormGroup>
                        <Label for="ClientName">Client Name</Label>
                        <Input 
                          // readOnly={readOnly}
                          type="select" 
                          className="custom-select" 
                          name="client_id" 
                          id="Organization"
                          onChange={handleChange}
                          value={values.client_id}
                        >
                          <option></option>
                          {clients && clients.map((client, i) => 
                            <option key={i} value={client.id}>{client.name}</option>
                          )}
                        </Input>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-sm-4">
                      {/* {readOnly === false ? 
                      <React.Fragment> */}
                      <button type="submit" className="btn btn-primary">
                        {id ? 'Update' : 'Create'}
                      </button>&nbsp;
                      {/* </React.Fragment> : null} */}
                      <Button 
                        // className="btn btn-outline-secondary"
                        outline
                        color="secondary" 
                        tag={Link} 
                        to={props.path}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  )
}

export default FormCreate;