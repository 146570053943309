import React, { useEffect, useState } from 'react';

import {
  Link,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import DatePicker from 'reactstrap-date-picker';

import { objectToFormData } from 'object-to-formdata';

import api from '../../utils/api';

import { 
  // useFormik, 
  Formik,
  Form as FormikForm,
  // FieldArray,
} from 'formik';

import { useToasts } from 'react-toast-notifications';

import moment from 'moment';
import SignaturePad from 'react-signature-pad-wrapper';

import Recaptcha from "react-recaptcha";
//import SignatureCanvas from 'react-signature-canvas';

// const COMPETENCIES = [
//   'Occupational Safety & Health MS',
//   'Environmental MS',
//   'Quality MS',
//   'Information Security MS',
//   'Energy MS',
//   'Food Safety MS',
// ];

const STATES = [
  'Johor',
  'Kedah',
  'Kelantan',
  'Melaka',
  'Negeri Sembilan',
  'Pahang',
  'Perak',
  'Perlis',
  'Pulau Pinang',
  'Sabah',
  'Sarawak',
  'Selangor',
  'Terengganu',
  'Wilayah Persekutuan Kuala Lumpur',
  'Wilayah Persekutuan Labuan',
  'Wilayah Persekutuan Putrajaya',
];

const FORMAL_EDUCATION = {
  year_completed: '',
  institution: '',
  qualification: '',
};

const RELEVANT_WORK_EXPERIENCE = {
  job_tenure: '',
  organization: '',
  position: '',
  total_duration: '',
}

const COURSE_ATTENDED = {
  year_completed: '',
  institution: '',
  qualification: '',
}

const AUDITOR_REGISTRATION_NO = {
  body: '',
  no: '',
  grade: '',
}

const AUDITING_TRAINING = {
  year_completed: '',
  institution: '',
  standards: '',
}

const EXPERIENCE = {
  industry: ''
}

const INITIAL_VALUES = {
  industries_experience: [],
  formal_educations: [],
  relevant_work_experiences: [],
  courses_attended: [],
  auditor_registration_nos: [],
  auditing_trainings: [],
};
let sigCanvas;
const recaptchaLoaded = async () => {
  /* if (!window.confirm('Send for draft review?')) {
    return;
  } */
  console.log('Captcha successfully loaded!');
}


const RegistrationForm = (props) => {
  let history = useHistory();
  let { path } = useRouteMatch();
  const [isVerified, setVerified] = useState(false);
  const [CERTIFICATIONS, setCertifications] = useState({});
  const [NACE_CODES, setNaceCodes] = useState([]);
  const { addToast } = useToasts();
  const [auditor, setAuditor] = useState({});
  
  const verifyCallback = async(response)=>{
    if(response){
      setVerified(true);
    }
  }  
  useEffect(() => {
    
    // console.log(props);
    const getNaceCodes = async () => {
     // let token = await localStorage.getItem('auth-token');

     // token = JSON.parse(token);

      //api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('auditors/application/nace-codes')
      .then(function (response) {
        // console.log(response.data);

        setNaceCodes(response.data);
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getNaceCodes();

    const getCertifications = async () => {
     // let token = await localStorage.getItem('auth-token');

    //  token = JSON.parse(token);

      //api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      api.get('/lookup/certifications')
      .then(function (response) {
         console.log(response.data);
         if (response.data.length) {
          let letCert = [];
          response.data.map((cert, i) => {
            if(cert.id!=12 && cert.id!=13){
              return letCert.push({
                id: cert.id,
                standard: cert.standard
              });            }
            
          })
          setCertifications(letCert);
        }
        
      })
      .catch(function (error) {
        // console.log(error.response);
        if (error.response) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    getCertifications();
    
    
  }, [props]);

  return (
    <React.Fragment>
      <div className="row clearfix">
      <div className="col-lg-12 col-md-12">
      <Card className="invoice1">
        <div className="body">
          <div className="invoice-top clearfix">
            <div className="logo">
              <img src={process.env.PUBLIC_URL + '/logo-niosh.png'} alt="logo" className="rounded-circle img-fluid" />
            </div>
            <div className="info">
            <h6>NIOSH CERTIFICATION SDN. BHD.</h6>
              <p>7th Floor, NIOSH Tower, Lot 1, Jalan 15/1, Seksyen 15, 43650 Bandar Baru Bangi, Selangor<br />
                Tel: +603 8769 2100 / +603 8911 3800   |   Fax: +603 8926 2900
              </p>
            </div>
            <div class="title">
              <h4>AUDITOR APPLICATION FORM</h4>
            </div>
          </div>
          <hr />
          <div className="invoice-mid clearfix">&nbsp;</div>
          <div className="invoice-mid clearfix">

          
          <Formik 
          enableReinitialize={true}
          initialValues={auditor}
          onSubmit={async (values, { setSubmitting }) => {
        // console.log(sigCanvas.toData());

        // const querystring = require('querystring');

        // console.log(JSON.stringify(values, null, 2));

//        let token = await localStorage.getItem('auth-token');

//        token = JSON.parse(token);

//        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        // console.log(values.position_applied);

        // values.position_applied.map((POSITION, key) => {
        //   console.log(POSITION, key);

        //   // values['position_applied[' + key + ']'] = POSITION;
        // });

        // console.log(values);

       // if (!props.auditor) {
          //values.signature = sigCanvas.toData();
          values.signature = sigCanvas.toDataURL();
       // }
        if(isVerified){
              
        // if (props.auditor) {
        //   values.approved = 1;
        // }
       // values._method = 'PUT';
        var data = objectToFormData(values, {indices: true, booleansAsIntegers: true});
       // await create('/auditors/application', values);
       await api({
        method: 'POST',
        url: '/auditors/application',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: data,
      })
/* 

        await api.post('/auditors/application', JSON.stringify(values), {
          headers: {
            'Content-Type': 'application/json',
          },
        }) */
        .then(function (response) {
          addToast('Data successfully created', { appearance: 'success' });
      
          window.location.reload();
        })
        .catch(function (error) {
          addToast(error.message, { appearance: 'error' });
        });
      }else
      {
        alert('Please verify that you are human!');
      }
       /*  setAuditor({
          no: "",
          location: "",
          area_department: "",
          standard_id: "",
          standards: "",
          clause: "",
          grade: "",
          details: "",
          issued_by: "",
          issued_date: "",
        });

        resetForm({
          no: "",
          location: "",
          area_department: "",
          standard_id: "",
          standards: "",
          clause: "",
          grade: "",
          details: "",
          issued_by: "",
          issued_date: "",
        }); */
       /*  await api({
            method: 'POST',
            url: '/auditors',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: data,
          })
          // api.post('/auditors', JSON.stringify(values), {
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          // })
          .then(function (response) {
            // console.log(response);

            if (props.auditor) {
              addToast('Auditor successfully saved', { appearance: 'success' });
              history.push(props.path);
            } else {
              addToast('Application form successfully saved', { appearance: 'success' });

              history.push(props.path);
            }

            // window.location = '/client-application/iso';
          })
          .catch(function (error) {
            if (error && error.response && error.response.data) {
              addToast(error.response.data.message, { appearance: 'error' });
            } else {
              addToast(error.message, { appearance: 'error' });
            }
          });
        } */
          }}
          >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
      <FormikForm>
        <div className="row clearfix">
        <div className="col-lg-12">
          <h5>PERSONAL DETAILS</h5>
        </div>
            <div className="col-lg-8">
              <FormGroup>
                <Label for="FullName">Full Name</Label>
                <Input 
                  type="text" 
                  name="full_name" 
                  id="FullName"
                  onChange={handleChange}
                  value={values.full_name}
                  required
                  
                />
              </FormGroup>
            </div>
            <div className="col-lg-2">
              <FormGroup>
                <Label for="PrefixTitle">Prefix/Title</Label>
                <Input 
                  type="text" 
                  name="prefix" 
                  id="PrefixTitle"
                  onChange={handleChange}
                  value={values.prefix}
                  
                />
              </FormGroup>
            </div>
            <div className="col-lg-2">
              <FormGroup>
                <Label for="Initial">Initial</Label>
                <Input 
                  type="text" 
                  name="initial" 
                  id="Initial"
                  onChange={handleChange}
                  value={values.initial}
                  
                />
              </FormGroup>
            </div>
            <div className="col-sm-12">
              <FormGroup>
                <Label for="ICNo">I/C No.</Label>
                <Input 
                  type="text" 
                  name="ic_no" 
                  id="ICNo"
                  onChange={handleChange}
                  value={values.ic_no}
                  maxLength="12"
                  required
                />
              </FormGroup>
            </div>

            <div className="col-sm-6">
              <FormGroup>
                <Label for="Company">Company</Label>
                <Input 
                  type="text" 
                  name="company" 
                  id="Company"
                  onChange={handleChange}
                  value={values.company}
                  required
                />
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup>
                <Label for="Position">Position</Label>
                <Input 
                  type="text" 
                  name="position" 
                  id="Position"
                  onChange={handleChange}
                  value={values.position}
                  
                />
              </FormGroup>
            </div>

            <div className="col-sm-6">
              <Label for="">Business Address</Label>

              <FormGroup>
                <Label for="BusinesAddress1">Line 1</Label>
                <Input 
                  type="text" 
                  name="business_address_1" 
                  id="BusinesAddress1"
                  onChange={handleChange}
                  value={values.business_address_1}
                  
                />
              </FormGroup>

              <FormGroup>
                <Label for="BusinesAddress1">Line 2</Label>
                <Input 
                  type="text" 
                  name="business_address_2" 
                  id="BusinesAddress2"
                  onChange={handleChange}
                  value={values.business_address_2}
                  
                />
              </FormGroup>

              <FormGroup>
                <Label for="BusinesAddress3">Line 3</Label>
                <Input 
                  type="text" 
                  name="business_address_3" 
                  id="BusinesAddress3"
                  onChange={handleChange}
                  value={values.business_address_3}
                  
                />
              </FormGroup>

              <FormGroup>
                <Label for="BusinesCountry">Country</Label>
                <Input 
                  type="text" 
                  name="business_country" 
                  id="BusinesCountry"
                  onChange={handleChange}
                  value={values.business_country}
                  
                />
              </FormGroup>

              <FormGroup>
                <Label for="BusinessState">State</Label>
                <Input 
                  type="select" 
                  className="custom-select" 
                  name="business_state" 
                  id="BusinessState"
                  onChange={handleChange}
                  value={values.business_state}
                  
                >
                  <option></option>
                  {STATES.map((STATE, key) => <option key={key} value={STATE}>{STATE}</option>)}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="BusinesPostcode">Postcode</Label>
                <Input 
                  type="text" 
                  name="business_postcode" 
                  id="BusinesPostcode"
                  onChange={handleChange}
                  value={values.business_postcode}
                  maxLength="5"
                  pattern="\d*"
                  
                />
              </FormGroup>

              <FormGroup>
                <Label for="BusinesFax">Fax</Label>
                <Input 
                  type="text" 
                  name="business_fax" 
                  id="BusinesFax"
                  onChange={handleChange}
                value={values.business_fax}
                  
                />
              </FormGroup>

              <FormGroup>
                <Label for="BusinesMobile">Mobile</Label>
                <Input 
                  type="text" 
                  name="business_mobile" 
                  id="BusinesMobile"
                  onChange={handleChange}
                  value={values.business_mobile}
                  required
                />
              </FormGroup>

              <FormGroup>
                <Label for="BusinesEmail">Email</Label>
                <Input 
                  type="email" 
                  name="business_email" 
                  id="BusinesEmail"
                  onChange={handleChange}
                  value={values.business_email}
                  
                />
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <Label for="PrivateAddress1">Private Address</Label>

              <FormGroup>
                <Label for="PrivateAddress1">Line 1</Label>
                <Input 
                  type="text" 
                  name="private_address_1" 
                  id="PrivateAddress1"
                  onChange={handleChange}
                  value={values.private_address_1}
                  
                />
              </FormGroup>

              <FormGroup>
                <Label for="PrivateAddress2">Line 2</Label>
                <Input 
                  type="text" 
                  name="private_address_2" 
                  id="PrivateAddress2"
                  onChange={handleChange}
                  value={values.private_address_2}
                  
                />
              </FormGroup>

              <FormGroup>
                <Label for="PrivateAddress3">Line 3</Label>
                <Input 
                  type="text" 
                  name="private_address_3" 
                  id="PrivateAddress3"
                  onChange={handleChange}
                value={values.private_address_3}
                  
                />
              </FormGroup>

              <FormGroup>
                <Label for="PrivateCountry">Country</Label>
                <Input 
                  type="text" 
                  name="private_country" 
                  id="PrivateCountry"
                  onChange={handleChange}
                value={values.private_country}
                  
                />
              </FormGroup>

              <FormGroup>
                <Label for="PrivateState">State</Label>
                <Input 
                  type="select" 
                  className="custom-select" 
                  name="private_state" 
                  id="PrivateState"
                  onChange={handleChange}
                value={values.private_state}
                  
                >
                  <option></option>
                  {STATES.map((STATE, key) => <option key={key} value={STATE}>{STATE}</option>)}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="PrivatePostcode">Postcode</Label>
                <Input 
                  type="text" 
                  name="private_postcode" 
                  id="PrivatePostcode"
                  onChange={handleChange}
                  value={values.private_postcode}
                  maxLength="5"
                  pattern="\d*"
                  
                />
              </FormGroup>

              <FormGroup>
                <Label for="PrivateFax">Fax</Label>
                <Input 
                  type="text" 
                  name="private_fax" 
                  id="PrivateFax"
                  onChange={handleChange}
                  value={values.private_fax}
                  
                />
              </FormGroup>

              <FormGroup>
                <Label for="PrivateMobile">Mobile</Label>
                <Input 
                  type="text" 
                  name="private_mobile" 
                  id="PrivateMobile"
                  onChange={handleChange}
                  value={values.private_mobile}
                  
                />
              </FormGroup>

              <FormGroup>
                <Label for="PrivateEmail">Email</Label>
                <Input 
                  type="email" 
                  name="private_email" 
                  id="PrivateEmail"
                  onChange={handleChange}
                  value={values.private_email}
                  
                />
              </FormGroup>
            </div>

            <div className="col-sm-6">
              <FormGroup>
                <Label for="PreferredContactPoint">Preferred Contact Point</Label>
                <Input 
                  type="select" 
                  className="custom-select" 
                  name="preferred_contact_point" 
                  id="PreferredContactPoint"
                  onChange={handleChange}
                  value={values.preferred_contact_point}
                  
                >
                  <option></option>
                  <option value="B">Business Address</option>
                  <option value="P">Private Address</option>
                </Input>
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup>
                <Label for="NaceCode">Nace Code</Label>
                <Input 
                  //
                  type="select" 
                  className="custom-select" 
                  name="nace_code" 
                  id="NaceCode"
                  onChange={handleChange}
                  value={values.nace_code && values.nace_code ? values.nace_code : ""}
                  
                >
                  <option></option>
                  {NACE_CODES && NACE_CODES.map((NACE_CODE, i) => 
                    <option value={NACE_CODE.code}>{NACE_CODE.code} - {NACE_CODE.description}</option>
                  )}
                </Input>
                <small className="text-muted form-text">
                  Identify the lowest level NACE code
                </small>
              </FormGroup>
            </div>
            <div className="col-sm-12">
              <FormGroup>
                <Label for="">Position Applied</Label>

                {['Auditor', 'Technical Expert'].map((POSITION, key) => 
                  <div className="fancy-checkbox" key={key}>
                    <label>
                      <Input 
                        type="checkbox" 
                        name="position_applied" 
                        key={key} 
                        value={POSITION}
                        onClick={handleChange}
                        checked={values.position_applied && values.position_applied.includes(POSITION)} 
                        
                      /> 
                      <span>{POSITION}</span>
                    </label>
                  </div>
                )}
              </FormGroup>
            </div>

            

            <div className="col-sm-12">
              <FormGroup>
                <Label for="AuditorProfile">Auditor Profile</Label>
                <Input 
                  type="textarea" 
                  name="profile" 
                  id="AuditorProfile"
                  onChange={handleChange}
                  value={values.profile}
                  
                  rows={10}
                />
              </FormGroup>
            </div>
              
          </div>          
          <hr />
          <div className="row clearfix">

            <div className="col-lg-12">
              <h5>CORE COMPETENCIES</h5>
            </div>

            
              <div className="col-lg-12" style={{ paddingBottom: '20px' }}>
                  {/* {COMPETENCIES.map((COMPETENCY, key) => 
                    <FormGroup key={key} check>
                      <Label key={key} check>
                        <Input 
                          type="checkbox" 
                          name="core_competencies" 
                          key={key} 
                          value={COMPETENCY}
                          onClick={handleChange}
                          checked={values.core_competencies && values.core_competencies.includes(COMPETENCY)} 
                        /> {COMPETENCY}
                      </Label>
                    </FormGroup>
                  )} */}

                  {CERTIFICATIONS.length ? CERTIFICATIONS.map((CERTIFICATION, key) => 
                    <React.Fragment key={key}>
                      <div className="fancy-checkbox">
                        <label>
                          <Input 
                            // 
                            type="checkbox" 
                            name={`certifications`}
                            // onChange={handleChange}
                            
                            onChange={() => {
                              let { certifications } = values;

                              if (certifications && certifications.length) {
                                var index = certifications.indexOf(CERTIFICATION.id);
                                if (index !== -1) {
                                  certifications.splice(index, 1);
                                } else {
                                  if(CERTIFICATION.id!=12 || CERTIFICATION.id!=13){
                                    certifications.push(CERTIFICATION.id);
                                  }
                                }
                              } else {
                                certifications = [];
                                certifications.push(CERTIFICATION.id);
                              }

                              setFieldValue('certifications', certifications);
                            }}
                            value={CERTIFICATION.id!=12 && CERTIFICATION.id!=13 ? CERTIFICATION.id : null}
                            checked={values.certifications && values.certifications.length && values.certifications.indexOf(CERTIFICATION.id) !== -1} 
                          />
                          <span>
                            {CERTIFICATION.standard!='OHSAS 18001' && CERTIFICATION.standard!='MS 1722:2011' ? CERTIFICATION.standard : ''}
                          </span>
                        </label>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>

              <div className="col-sm-6" style={{ paddingBottom: '20px' }}>
                  <Label for="">Please state the industries in which you have more than 3 years of experience</Label>
                  
                  <FormGroup>
                    <table className="table">
                      <tbody>
                        {values.industries_experience && values.industries_experience.map((EXPERIENCE, key) => 
                          <tr key={key}>
                            <td>
                              <Input 
                                type="text" 
                                name={`industries_experience.${key}.industry`}
                                onChange={handleChange}
                                value={values.industries_experience[key].industry}
                              />
                            </td>
                            <td>
                              <Button
                                type="button"
                                outline
                                color="danger"
                                
                                onClick={() => {
                                  if (window.confirm('Delete this row?')) {
                                    let { industries_experience } = values;

                                    delete industries_experience[key];

                                    setFieldValue('industries_experience', industries_experience);
                                  }
                                }}
                              >
                                <i className="icon-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan="2">
                            <Button 
                              block 
                              outline
                              
                              onClick={(e) => {
                                e.preventDefault();

                                let { industries_experience } = values;
                                if (!industries_experience) {
                                  industries_experience = [];
                                }
                                industries_experience.push(EXPERIENCE);

                                // setData(data => {
                                //   return {...data, industries_experience: industries_experience}
                                // })

                                setFieldValue('industries_experience', industries_experience);
                              }}
                            >
                              Add Row
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </FormGroup>
              </div>

                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="">Formal Education</Label>
                    <small> Tertiary level only i.e. degree, diploma, masters</small>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Year Completed</th>
                          <th>Institution</th>
                          <th>Qualification</th>
                          <th>Certificates Attached</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.formal_educations && values.formal_educations.map((EDUCATION, key) => 
                          <tr key={key}>
                            <td>
                              <Input 
                                type="text" 
                                name={`formal_educations.${key}.year_completed`}
                                onChange={handleChange}
                                value={values.formal_educations[key].year_completed}
                                maxLength="4"
                                pattern="\d*"
                                
                              />
                            </td>
                            <td>
                              <Input 
                                type="text" 
                                name={`formal_educations.${key}.institution`}
                                onChange={handleChange}
                                value={values.formal_educations[key].institution}
                                
                              />
                            </td>
                            <td>
                              <Input 
                                type="text" 
                                name={`formal_educations.${key}.qualification`}
                                onChange={handleChange}
                                value={values.formal_educations[key].qualification}
                                
                              />
                            </td>
                            <td>
                              <Input 
                                type="file" 
                                name={`formal_educations.${key}.attachment`} 
                                // id="ROCCrtificate"
                                onChange={(event) => {
                                  // console.log(event.currentTarget.files[0]);
                                  setFieldValue(`formal_educations.${key}.attachment`, event.currentTarget.files[0])
                                }}
                                
                              />
                            </td>
                            <td>
                              <Button
                                type="button"
                                outline
                                color="danger"
                                
                                onClick={() => {
                                  if (window.confirm('Delete this row?')) {
                                    let { formal_educations } = values;

                                    delete formal_educations[key];

                                    setFieldValue('formal_educations', formal_educations);
                                  }
                                }}
                              >
                                <i className="icon-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan="5">
                            <Button 
                              block 
                              outline
                              
                              onClick={(e) => {
                                e.preventDefault();

                                let { formal_educations } = values;
                                if (!formal_educations) {
                                  formal_educations = [];
                                }
                                formal_educations.push(FORMAL_EDUCATION);

                                // setData(data => {
                                //   return {...data, formal_educations: formal_educations}
                                // })

                                setFieldValue('formal_educations', formal_educations);
                              }}
                            >
                              Add Row
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </FormGroup>
                </div>

                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="">Relevant Work Experience</Label>
                    <small> That of which in regards to OHS, EMS or QMS in a technical, professional or management position</small>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Job Tenure (Year)</th>
                          <th>Organization</th>
                          <th>Position</th>
                          <th>Total Duration</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.relevant_work_experiences && values.relevant_work_experiences.map((EXPERIENCE, key) => 
                          <tr key={key}>
                            <td>
                              <Input 
                                type="text" 
                                name={`relevant_work_experiences.${key}.job_tenure`}
                                onChange={handleChange}
                                value={values.relevant_work_experiences[key].job_tenure}
                                maxLength="4"
                                pattern="\d*"
                                
                              />
                            </td>
                            <td>
                              <Input 
                                type="text" 
                                name={`relevant_work_experiences.${key}.organization`}
                                onChange={handleChange}
                                value={values.relevant_work_experiences[key].organization}
                                
                              />
                            </td>
                            <td>
                              <Input 
                                type="text" 
                                name={`relevant_work_experiences.${key}.position`}
                                onChange={handleChange}
                                value={values.relevant_work_experiences[key].position}
                                
                              />
                            </td>
                            <td>
                              <Input 
                                type="text" 
                                name={`relevant_work_experiences.${key}.total_duration`}
                                onChange={handleChange}
                                value={values.relevant_work_experiences[key].total_duration}
                                
                              />
                            </td>
                            <td>
                              <Button
                                type="button"
                                outline
                                color="danger"
                                
                                onClick={() => {
                                  if (window.confirm('Delete this row?')) {
                                    let { relevant_work_experiences } = values;

                                    delete relevant_work_experiences[key];

                                    setFieldValue('relevant_work_experiences', relevant_work_experiences);
                                  }
                                }}
                              >
                                <i className="icon-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan="5">
                            <Button 
                              block 
                              outline
                              
                              onClick={(e) => {
                                e.preventDefault();

                                let { relevant_work_experiences } = values;
                                if (!relevant_work_experiences) {
                                  relevant_work_experiences = [];
                                }
                                relevant_work_experiences.push(RELEVANT_WORK_EXPERIENCE);

                                // setData(data => {
                                //   return {...data, relevant_work_experiences: relevant_work_experiences}
                                // })

                                setFieldValue('relevant_work_experiences', relevant_work_experiences);
                              }}
                            >
                              Add Row
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </FormGroup>
                </div>

                <div className="col-sm-12">
                  <FormGroup>
                    <Label for="">Course Attended</Label> 
                    <small> That of which in regards to OHS, EMS or QMS only</small>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Year Completed</th>
                          <th>Institution</th>
                          <th>Qualification</th>
                          <th>Certificates Attached</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.courses_attended && values.courses_attended.map((COURSE, key) => 
                          <tr key={key}>
                            <td>
                              <Input 
                                type="text" 
                                name={`courses_attended.${key}.year_completed`}
                                onChange={handleChange}
                                value={values.courses_attended[key].year_completed}
                                maxLength="4"
                                pattern="\d*"
                                
                              />
                            </td>
                            <td>
                              <Input 
                                type="text" 
                                name={`courses_attended.${key}.institution`}
                                onChange={handleChange}
                                value={values.courses_attended[key].institution}
                              />
                            </td>
                            <td>
                              <Input 
                                type="text" 
                                name={`courses_attended.${key}.qualification`}
                                onChange={handleChange}
                                value={values.courses_attended[key].qualification}
                                
                              />
                            </td>
                            <td>
                              <Input 
                                type="file" 
                                name={`courses_attended.${key}.attachment`} 
                                // id="ROCCrtificate"
                                onChange={(event) => {
                                  // console.log(event.currentTarget.files[0]);
                                  setFieldValue(`courses_attended.${key}.attachment`, event.currentTarget.files[0])
                                }}
                                
                              />
                            </td>
                            <td>
                              <Button
                                type="button"
                                outline
                                color="danger"
                                
                                onClick={() => {
                                  if (window.confirm('Delete this row?')) {
                                    let { courses_attended } = values;

                                    delete courses_attended[key];

                                    setFieldValue('courses_attended', courses_attended);
                                  }
                                }}
                              >
                                <i className="icon-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan="5">
                            <Button 
                              block 
                              outline
                              
                              onClick={(e) => {
                                e.preventDefault();

                                let { courses_attended } = values;
                                if (!courses_attended) {
                                  courses_attended = [];
                                }
                                courses_attended.push(COURSE_ATTENDED);

                                // setData(data => {
                                //   return {...data, courses_attended: courses_attended}
                                // })

                                setFieldValue('courses_attended', courses_attended);
                              }}
                            >
                              Add Row
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            <hr />
          <div className="row clearfix">
            <div className="col-sm-12">
              <h5>FILL IN THIS SECTION IF YOU ARE APPLYING FOR AUDITOR GRADE</h5>
            </div>
            <div className="col-sm-12">
              <FormGroup>
                <Label for="">Auditor Registration Number</Label>
                <small> IRCA/QSA or other auditor registration body</small>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Body</th>
                      <th>Number</th>
                      <th>Grade</th>
                      <th>Copy Attached</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.auditor_registration_nos && values.auditor_registration_nos.map((REGISTRATION, key) => 
                      <tr key={key}>
                        <td>
                          <Input 
                            type="text" 
                            name={`auditor_registration_nos.${key}.body`}
                            onChange={handleChange}
                            value={values.auditor_registration_nos[key].body}
                            
                          />
                        </td>
                        <td>
                          <Input 
                            type="text" 
                            name={`auditor_registration_nos.${key}.no`}
                            onChange={handleChange}
                            value={values.auditor_registration_nos[key].no}
                            
                          />
                        </td>
                        <td>
                          <Input 
                            type="text" 
                            name={`auditor_registration_nos.${key}.grade`}
                            onChange={handleChange}
                            value={values.auditor_registration_nos[key].grade}
                            
                          />
                        </td>
                        <td> 
                          <Input 
                            type="file" 
                            name={`auditor_registration_nos.${key}.attachment`} 
                            // id="ROCCrtificate"
                            onChange={(event) => {
                              // console.log(event.currentTarget.files[0]);
                              setFieldValue(`auditor_registration_nos.${key}.attachment`, event.currentTarget.files[0])
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            type="button"
                            outline
                            color="danger"
                            
                            onClick={() => {
                              if (window.confirm('Delete this row?')) {
                                let { auditor_registration_nos } = values;

                                delete auditor_registration_nos[key];

                                setFieldValue('auditor_registration_nos', auditor_registration_nos);
                              }
                            }}
                          >
                            <i className="icon-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan="5">
                        <Button 
                          block 
                          outline
                          
                          onClick={(e) => {
                            e.preventDefault();

                            let { auditor_registration_nos } = values;
                            if (!auditor_registration_nos) {
                              auditor_registration_nos = [];
                            }
                            auditor_registration_nos.push(AUDITOR_REGISTRATION_NO);

                            // setData(data => {
                            //   return {...data, auditor_registration_nos: auditor_registration_nos}
                            // })

                            setFieldValue('auditor_registration_nos', auditor_registration_nos);
                          }}
                        >
                          Add Row
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </FormGroup>
            </div>
            <div className="col-sm-12">
              <FormGroup>
                <Label for="">Auditing Training</Label>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Year Completed</th>
                      <th>Institution</th>
                      <th>Standards</th>
                      <th>Certificates Attached</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.auditing_trainings && values.auditing_trainings.map((TRAINING, key) => 
                      <tr key={key}>
                        <td>
                          <Input 
                            type="text" 
                            name={`auditing_trainings.${key}.year_completed`}
                            onChange={handleChange}
                            value={values.auditing_trainings[key].year_completed}
                            maxLength="4"
                            pattern="\d*"
                            
                          />
                        </td>
                        <td>
                          <Input 
                            type="text" 
                            name={`auditing_trainings.${key}.institution`}
                            onChange={handleChange}
                            value={values.auditing_trainings[key].institution}
                            
                          />
                        </td>
                        <td>
                          <Input 
                            type="text" 
                            name={`auditing_trainings.${key}.standards`}
                            onChange={handleChange}
                            value={values.auditing_trainings[key].standards}
                            
                          />
                        </td>
                        <td>
                          <Input 
                            
                            type="file" 
                            name={`auditing_trainings.${key}.attachment`} 
                            // id="ROCCrtificate"
                            onChange={(event) => {
                              // console.log(event.currentTarget.files[0]);
                              setFieldValue(`auditing_trainings.${key}.attachment`, event.currentTarget.files[0])
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            type="button"
                            outline
                            color="danger"
                            
                            onClick={() => {
                              if (window.confirm('Delete this row?')) {
                                let { auditing_trainings } = values;

                                delete auditing_trainings[key];

                                setFieldValue('auditing_trainings', auditing_trainings);
                              }
                            }}
                          >
                            <i className="icon-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan="5">
                        <Button 
                          block 
                          outline
                          
                          onClick={(e) => {
                            e.preventDefault();

                            let { auditing_trainings } = values;
                            if (!auditing_trainings) {
                              auditing_trainings = [];
                            }
                            auditing_trainings.push(AUDITING_TRAINING);

                            // setData(data => {
                            //   return {...data, auditing_trainings: auditing_trainings}
                            // })

                            setFieldValue('auditing_trainings', auditing_trainings);
                          }}
                        >
                          Add Row
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </FormGroup>
            </div>
          </div>
          <hr />
          <div className="row clearfix">
            <div className="col-sm-12">
              <h5>DECLARATION</h5>
            </div>
            <div className="col-sm-12 pb-5">
              All information provided in this form is correct and true to the best of my knowledge.
            </div>
              <div className="col-sm-6 pb-5">
                <Label for="">Signature</Label>
                {/* <SignatureCanvas 
                  canvasProps={{width: 600, height: 200, className: 'sigCanvas'}} 
                  ref={(ref) => { sigCanvas = ref }}
                /> */}
                <SignaturePad 
                    // width={500} 
                    // height={200}
                    ref={ref => sigCanvas = ref} 
                    // onEnd={() => {
                    //   values.declaration_signature = sigCanvas.toDataURL();
                    // }}
                  />
                <Button 
                  block 
                  outline
                  type="button"
                  onClick={() => {
                    sigCanvas.clear()
                  }}>
                    Clear signature
                  </Button>
              </div>
              <div className="col-sm-6 pb-5">&nbsp;
              </div>
              <div className="col-sm-6 pb-5">
                <FormGroup>
                  <Label for="DeclarationDate">Date</Label>
                  <DatePicker
                    autoComplete="off"
                    name="declaration_date" 
                    id="DeclarationDate"
                    value={values.declaration_date}
                    onChange={date => setFieldValue('declaration_date', moment(date).format('YYYY-MM-DD'))}
                    required
                  />
                </FormGroup>
              </div>

              <div className="col-sm-6 pb-5">&nbsp;
              </div>
              <div className="col-sm-6 pb-5">
              <Recaptcha
                sitekey="6LfPkjMdAAAAAChGz4Ics5wquwpyCiG6cq30CTrK"
                render="explicit"
                verifyCallback={verifyCallback}
                onloadCallback={recaptchaLoaded}
              />    
              
              </div>
              <div className="col-sm-6 pb-5">&nbsp;</div>
              <div className="col-sm-4">
                <button 
                  type="submit" 
                  className="btn btn-primary"
                  disabled={isSubmitting ? true : false}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>&nbsp;
                <Button 
                  // className="btn btn-outline-secondary"
                  outline
                  color="secondary" 
                  // tag={Link} 
                  //to={props.path}
                  onClick={() => {

                    history.push({
                      pathname: path,
                    });

                  }}
                >
                  Reset
                </Button>
              </div>
            </div>

          </FormikForm>

          )} 
        </Formik>
        
        </div>
        </div>
        
      </Card>
      </div>
      </div>
    </React.Fragment>
  )
}

export default RegistrationForm;