import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom';


import api from '../../utils/api';
import { useToasts } from 'react-toast-notifications';

import './style.css';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const INITIAL_VALUES = {

};

const ViewDocument = (props) => {
  let { id} = useParams();

  //let { path } = useRouteMatch();

  const { addToast } = useToasts();

  const [data, setData] = useState(INITIAL_VALUES);

 // const [permissions, setPermissions] = useState([]);

  const [token, setToken] = useState('');


 // const [activeTab, setActiveTab] = useState('1');
  
  // const [profile, setProfile] = useState();

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);
      setToken(token.access_token);
      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        console.log(response.data);

      })
      .catch(function (error) {

      });
    }

    getMe();

    const getToken = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);
      
      if (token) {
        // console.log(`${process.env.REACT_APP_API_URL}/audit-plans/${id}?token=${token.access_token}&time=${Date.now()}`);
        let config = {
          type: 'pdf',
          height: window.innerHeight - 110,
            document: {
                fileType: 'pdf',
                key: `${id}-${Date.now()}`,
                title: 'View Certicate Document',
                url: `${process.env.REACT_APP_API_URL}/application-forms/view/${id}?&token=${token.access_token}&time=${Date.now()}`,

             //   url: response.data.certificate_copy.url,
                permissions: {
                    download: true,
                    edit: false,
                    print: true,
                    review: false,
                }
            },
            documentType: 'text',
            editorConfig: {
                callbackpath: '',
                mode: 'view',
                customization: {
                    zoom: 70,
                    customer: {
                        address: '',
                        info: '',
                        logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                        mail: 'iskandar@greentagpro.com',
                        name: 'Greentagpro Solution',
                        www: 'greentagpro.com',
                    },
                    chat: false,
                    forcesave: true,
                    comments: false,
                }
            },
        }

        new window.DocsAPI.DocEditor("placeholder", config);
      }
    }
    getToken();

    if (id) {
      const getData = async () => {
        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        api.get('/application-forms/' + id)
          .then(function (response) {
            setData(response.data);

            if (response.data && response.data.application && response.data.application.certificate_copy && response.data.application.certificate_copy.url) {
               console.log(response.data.application.certificate_copy.url);
              let config = {
                type: 'pdf',
                height: window.innerHeight - 110,
                  document: {
                      fileType: 'pdf',
                      key: `${id}-${Date.now()}`,
                      title: 'Basic Data Information',
                      url: response.data.application.certificate_copy.url,
                      permissions: {
                          download: true,
                          edit: false,
                          print: true,
                          review: false,
                      }
                  },
                  documentType: 'text',
                  editorConfig: {
                      callbackpath: '',
                      mode: 'view',
                      customization: {
                          zoom: 70,
                          customer: {
                              address: '',
                              info: '',
                              logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                              mail: 'iskandar@greentagpro.com',
                              name: 'Greentagpro Solution',
                              www: 'greentagpro.com',
                          },
                          chat: false,
                          forcesave: true,
                          comments: false,
                      }
                  },
              }

              new window.DocsAPI.DocEditor("placeholder", config);
            }
          
          //  reload(false);
          })
          .catch(function (error) {
           console.log(error.response);
            if (error && error.response && error.response.data) {
              addToast(error.response.data.message, { appearance: 'error' });
            } else {
              addToast(error.message, { appearance: 'error' });
            }
          });
      }

      getData();
    }
  }, [id]);
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              {/* <Card> */}
                <div className="header">
                  <h2>DOCUMENT</h2>
                </div>
                <div className="body">
                  <div id="placeholder"></div>
                </div>
              {/* </Card> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default ViewDocument;