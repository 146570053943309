import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams,
  useHistory,
  // useLocation,
  // useRouteMatch,
} from 'react-router-dom';

import { useToasts } from 'react-toast-notifications';

import {
  Button,
  Card,
  // Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
  // ListGroup,
  // ListGroupItem,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import api from '../../utils/api';

const INITIAL_VALUES = {

};

const ChangePassword = (props) => {
  let history = useHistory();

  const { addToast } = useToasts();

  const [data, setData] = useState(INITIAL_VALUES);

  const [isSubmit, setSubmit] = useState(false);

  return (
    <Formik 
      enableReinitialize={true}
      initialValues={data}
      onSubmit={async (values, { setSubmitting }) => {
        console.log(values);

        let token = await localStorage.getItem('auth-token');

        token = JSON.parse(token);

        api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

        await api.post('/profile/change-password', JSON.stringify(values), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          if (response.data.success) {
            addToast('Password has been successfully changed', { appearance: 'success' });
          } else {
            
          }
        })
        .catch(function (error) {
          if (error.response && error.response.data && error.response.data.data && error.response.data.data.messages) {
            var messages = error.response.data.data.messages;

            if (messages.current_password) {
              messages.current_password.forEach(function(message) {
                addToast(message, { appearance: 'error' });
              });
            }

            if (messages.password) {
              messages.password.forEach(function(message) {
                addToast(message, { appearance: 'error' });
              });
            }

            if (messages.password_confirmation) {
              messages.password_confirmation.forEach(function(message) {
                addToast(message, { appearance: 'error' });
              });
            }
          } else {
            addToast(error.message, { appearance: 'error' });
          }
        });
      }}
    >
      {({ isSubmitting, values, setFieldValue, handleChange }) => (
      <FormikForm>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <Card>
              <div className="header">
                <h2>CHANGE PASSWORD</h2>
              </div>
              <div className="body">
                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="CurrentPassword">Current Password</Label>
                      <Input 
                        // readOnly={readOnly}
                        type="password" 
                        // className="custom-select" 
                        name="current_password" 
                        id="CurrentPassword"
                        onChange={handleChange}
                        value={values.current_password}
                        required
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="NewPassword">New Password</Label>
                      <Input 
                        // readOnly={readOnly}
                        type="password" 
                        // className="custom-select" 
                        name="password" 
                        id="NewPassword"
                        onChange={handleChange}
                        value={values.password}
                        required
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-12">
                    <FormGroup>
                      <Label for="PasswordConfirmation">Password Confirmation</Label>
                      <Input 
                        // readOnly={readOnly}
                        type="password" 
                        // className="custom-select" 
                        name="password_confirmation" 
                        id="PasswordConfirmation"
                        onChange={handleChange}
                        value={values.password_confirmation}
                        required
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-sm-4">
                    {/* {readOnly === false ? 
                    <React.Fragment> */}
                    <button type="submit" className="btn btn-primary" disabled={isSubmit ? true : false}>
                      {isSubmitting ? 'Updating...' : 'Update'}
                    </button>&nbsp;
                    {/* </React.Fragment> : null} */}
                    
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </FormikForm>
      )}
    </Formik>
  )
}

export default ChangePassword;