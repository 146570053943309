import React, { useEffect, useState } from 'react';

import {
  Link,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  // Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import { 
  Formik,
  Form as FormikForm
} from 'formik';

import qs from 'query-string';

import SidebarToggler from '../../components/SidebarToggler';
import paginationFactory from 'react-bootstrap-table2-paginator';

import BootstrapTable from 'react-bootstrap-table-next';

import { useToasts } from 'react-toast-notifications';

import api from '../../utils/api';

import Form from './Form';
import moment from 'moment';

const INITIAL_QUERY = {}

const INITIAL_PAGE = {
  page: 1,
  sizePerPage: 50,
  totalSize: 1,
  hideSizePerPage: true,
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Listing = (props) => {
  let { path } = useRouteMatch();

  let history = useHistory();

  const { addToast } = useToasts();

  let [profile, setProfile] = useState({});
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');

  const [query, setQuery] = useState(INITIAL_QUERY);
  const [pagingInfo, setPagingInfo] = useState(INITIAL_PAGE);

  let queryString = useQuery();

  let { type }  = props;

  const columns = [
    {
      editable: false,
      text: "No",
      formatter: (cell, row, rowIndex) => {
       let rowNumber = (pagingInfo.page - 1) * pagingInfo.sizePerPage + (rowIndex + 1);
        return <span>{rowNumber}.</span>;
      }
    },
    {
    dataField: 'contract_review_date',
    text: 'Date',
    formatter: (cell, row, rowIndex) => {
      if (cell && cell.length) {
        return moment(cell).format('DD/MM/YYYY');
      }
    }
  }, {
    dataField: 'quotation_reference_no',
    text: 'Quotation Ref. No.',
  }, {
    dataField: 'client.name',
    text: 'Client Name',
    style: {
      whiteSpace: "pre-wrap",
    }
  }, {
    dataField: 'client.scope_of_certification',
    text: 'Scope',
    style: {
      whiteSpace: "pre-wrap",
    }
  }, {
    dataField: 'client.certifications',
    text: "Cert(s) Required",
    formatter: (cell, row, rowIndex) => {
      return (
        <ul>
          {cell && cell.length > 0 && cell.map((CERT, key) => 
            <li>{CERT.code} {CERT.code === 'MSPO' ? (row.mspo && row.mspo.length ? <span className="text-muted">{row.mspo.join(", ").replace(/MSPO -/g, '')}</span> : "") : ""}</li>
          )}
        </ul>
      )
    },
    // style: {
    //   whiteSpace: 'pre-wrap',
    // },
  }, {
    dataField: 'status_label',
    text: 'Status',
    formatter: (cell, row ,rowIndex) => {
      if (cell === 'REVIEWED') {
        return <Badge color="warning">APPROVED</Badge>
      }

      if (cell === 'APPROVED') {
        return <Badge color="success">REVIEWED</Badge>
      }
    }
  }, {
    dataField: 'id',
    text: ' ',
    formatter: (cell, row, rowIndex) => {
      // let type;
  
      // switch (parseInt(row.type)) {
      //   default:
      //   case 1:
      //     type = 'iso';
      //     break;
  
      //   case 2:
      //     type = 'mspo';
      //     break;
  
      //   case 3:
      //     type = 'mspo-sccs';
      //     break;
      // }
      
      return (
        <ButtonGroup size="sm">
          <Button outline tag={Link} to={`${path}/view/${cell}`} >
            <i className="icon-eye"></i>
          </Button>
          {profile.permissions && profile.permissions.find(o => o.name.toLowerCase() === 'contract_review.update') ?
          <Button outline tag={Link} to={`${path}/edit/${cell}`}>
            <i className="icon-pencil"></i>
          </Button>
          : null}
          {/* <Button outline color="danger" onClick={() => deleteHandler(cell)}>Delete</Button> */}
        </ButtonGroup>
      )
    },
    style: { textAlign: 'center' }
  }];

  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.post('/auth/me')
      .then(function (response) {
        // localStorage.setItem('auth-user', JSON.stringify(response.data));

        setProfile(response.data);
      })
      .catch(function (error) {
        // if (error && error.response && error.response.data) {
        //   if (error.response.data.message === 'Unauthenticated.') {
        //     localStorage.removeItem('auth-token');
        //     localStorage.removeItem('auth-user');
        //     setAuthState('');
        //   }
        // } else {
        //   console.log(error.message);
        // }
      });
    }

    getMe();

    const getData = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      await api.get('/contract-reviews?type=' + props.type, {
        params: query,
      })
      .then(function (response) {
        // console.log(response);

        setData(response.data.data);

        setPagingInfo(pagingInfo => ({
          ...pagingInfo,
          page: response.data.meta.current_page,
          sizePerPage: response.data.meta.per_page,
          totalSize: response.data.meta.total,
        }));
      })
      .catch(function (error) {
        // console.log(error);
        
        if (error && error.response && error.response.data) {
          addToast(error.response.data.message, { appearance: 'error' });
        } else {
          addToast(error.message, { appearance: 'error' });
        }
      });
    }

    setTitle(props.type);

    getData();
  }, [props, query]);

  // const deleteHandler = async (id) => {
  //   if (window.confirm('Delete this record?')) {
  //     let token = await localStorage.getItem('auth-token');

  //     token = JSON.parse(token);

  //     api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

  //     api.delete('/contract-reviews/' + id)
  //     .then(function (response) {
  //       // console.log(response);

  //       // setData(response.data.data);

  //       addToast('Contract review successfully deleted', { appearance: 'success' });

  //       history.push(`${path}`);
  //     })
  //     .catch(function (error) {
  //       // console.log(error.response);
  //       addToast(error.response.data.message, { appearance: 'error' });
  //     });
  //   }
  // }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setQuery(query => ({
      ...query, 
      page: page,
    }));

    setPagingInfo(pagingInfo => ({
      ...pagingInfo,
      page: page,
      sizePerPage: sizePerPage,
    }));
  }

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              {title} Contract Review
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                Technical
              </li>
              <li className="breadcrumb-item">Contract Review</li>
              <li className="breadcrumb-item active">
                <Link to={path}>
                  {title}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Switch>
        <Route exact path={path}>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <Card>
                <div className="header">
                  <h2>CONTRACT REVIEW LIST</h2>
                  <ul className="header-dropdown">
                    <li>
                      {/* <Button 
                      className="m-t-10 m-l-20"
                      color="primary" 
                      tag={Link} 
                      to={`${path}/create`}
                      >
                      Add New
                      </Button> */}
                    </li>
                  </ul>
                </div>

                {profile && profile.roles && profile.roles[0].name && profile.roles[0].name.toLowerCase() !== 'client' ? 
                <div className="body">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      name: queryString.get('name') ? queryString.get('name') : "",
                      quotation_reference_no: queryString.get('quotation_reference_no') ? queryString.get('quotation_reference_no') : "",
                      // state: queryString.get('state'),
                      // certifications: queryString.get('certifications'),
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      var searchString = qs.stringify(values); // objectToFormData(values, {indices: true, booleansAsIntegers: true});

                      history.push({
                        pathname: path,
                        search: searchString
                      });

                      setQuery(values);
                    }}
                  >
                    {({ isSubmitting, values, setFieldValue, handleChange }) => (
                    <FormikForm>
                      <FormGroup>
                        <Label for="QuotationRefNo">Quotation Ref. No.</Label>
                        <Input 
                          type="text" 
                          // className="custom-select" 
                          name="quotation_reference_no" 
                          id="QuotationRefNo"
                          onChange={handleChange}
                          value={values.quotation_reference_no}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="Name">Name</Label>
                        <Input 
                          type="text" 
                          // className="custom-select" 
                          name="name" 
                          id="Name"
                          onChange={handleChange}
                          value={values.name}
                        />
                      </FormGroup>

                      <div className="row clearfix">
                        <div className="col-sm-4">
                          <Label>&nbsp;</Label>
                          <FormGroup>
                            <Button 
                              // className="btn btn-outline-secondary"
                              outline
                              color="secondary" 
                              // tag={Link} 
                              // to="/client-database"
                            >
                              Search
                            </Button>
                            <Button 
                              // className="btn btn-outline-secondary"
                              type="button"
                              outline
                              color="link" 
                              // tag={Link} 
                              // to={`${path}`}
                              onClick={() => {
                                history.push({
                                  pathname: path,
                                });
                
                                setQuery(INITIAL_QUERY);
                              }}
                            >
                              Reset
                            </Button>
                          </FormGroup>
                        </div>
                      </div>
                    </FormikForm>
                    )}
                  </Formik>

                  <hr />
                </div>
                : null}
                
                <div className="body project_report">
                  <div className="table">
                    <BootstrapTable 
                      bootstrap4
                      remote={true}
                      bordered={false}
                      classes="table-hover js-basic-example dataTable table-custom m-b-0"
                      headerClasses="thead-dark"
                      keyField='id' 
                      data={ data } 
                      columns={ columns }
                      pagination={ 
                        paginationFactory(pagingInfo)
                      }
                      onTableChange={handleTableChange}
                    />
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Route>
        <Route 
          path={`${path}/create`} 
          render={(props) => <Form path={path} type={type} {...props} />} 
        />
        <Route 
          path={`${path}/edit/:id`} 
          render={(props) => <Form path={path} type={type} {...props} />} 
        />
        <Route 
          path={`${path}/view/:id`} 
          render={(props) => <Form path={path} type={type} readOnly={true} {...props} />} 
        />
        <Route 
          path={`${path}/approval/:id`} 
          render={(props) => <Form path={path} type={type} readOnly={true} approval={true} {...props} />} 
        />
        <Route 
          path={`${path}/review/:id`} 
          render={(props) => <Form path={path} type={type} readOnly={true} review={true} {...props} />} 
        />
      </Switch>
    </div>
  )
}

export default Listing;