import React, { useEffect, useState } from "react";

import api from './../../utils/api';

import NCSBDashboard from "./NCSBDashboard";
import AuditorDashboard from "./AuditorDashboard";
import ClientDashboard from "./ClientDashboard";
import BODDashboard from "./BODDashboard";
import { useToasts } from 'react-toast-notifications';

const Dashboard = () => {
  // let [loaded, hasLoaded] = useState(false);
  const { addToast } = useToasts();
  let [role, setRole] = useState('no-role');
  let [user, setUser] = useState('');
  useEffect(() => {
    const getMe = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      // api.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;

      if (token) {
        await api.post('/auth/me', null, {
          headers: {
            Authorization: 'Bearer ' + token.access_token,
          }
        })
        .then(function (response) {
          // localStorage.setItem('auth-user', JSON.stringify(response.data));
          setRole(response.data.roles[0].name.toLowerCase());
          setUser(response.data.id);

          /* if(response.data.roles[0].name.toLowerCase() === 'client'){
            if(response.data.auditor_evaluation >= 1){
              addToast('Please submit your Auditor Evaluation', { appearance: 'error',placement: 'top-center' });
            }
            if(response.data.client_feedbacks >= 1){
              addToast('Please submit your Client Feedback', { appearance: 'error',placement: 'top-center' });          
            }
          } */
          // hasLoaded(true);
        })
        .catch(function (error) {
          // if (error && error.response && error.response.data) {
          //   if (error.response.data.message === 'Unauthenticated.') {
          //     localStorage.removeItem('auth-token');
          //     localStorage.removeItem('auth-user');
          //     setAuthState('');
          //   }
          // } else {
          //   console.log(error.message);
          // }

          // hasLoaded(true);
        });
      }
    }

    getMe();
  }, []);

  return (
    <React.Fragment>
      {role === 'ncsb top management' ? <BODDashboard /> : role.includes('ncsb') ? <NCSBDashboard /> : null}

      {role === 'auditor' ? <AuditorDashboard /> : null}

      {role === 'client' ? <ClientDashboard /> : null}
    </React.Fragment>
  )
}

export default Dashboard;