import React, { useEffect, useState } from 'react';

import {
  // Link,
  useParams,
} from "react-router-dom";

const InvitationForStakeholderConsultation = (props) => {
  let { id } = useParams();
  
  const [token, setToken] = useState('');
  
  useEffect(() => {
    const getToken = async () => {
      let token = await localStorage.getItem('auth-token');

      token = JSON.parse(token);

      setToken(token.access_token);
    }

    getToken();

    if (token) {
      let config = {
        type: 'desktop',
        height: window.innerHeight - 110,
          document: {
              fileType: 'pdf',
              key: `${id}-${Date.now()}`,
              title: 'Invitation For Stakeholder Consultation',
              url: `${process.env.REACT_APP_API_URL}/audit-plans/${id}?export=invitation-for-stakeholder-consultation&token=${token}&time=${Date.now()}`,
              permissions: {
                  download: true,
                  edit: false,
                  print: true,
                  review: false,
              }
          },
          documentType: 'text',
          editorConfig: {
              callbackUrl: '',
              mode: 'view',
              customization: {
                  // zoom: 70,
                  customer: {
                      address: '',
                      info: '',
                      logo: 'https://demo.greentagpro.com/uploads/demo.greentagpro.com/client/logo/1/logo_greentagprov2.png',
                      mail: 'iskandar@greentagpro.com',
                      name: 'Greentagpro Solution',
                      www: 'greentagpro.com',
                  },
                  chat: false,
                  forcesave: true,
                  comments: false,
              }
          },
      }

      new window.DocsAPI.DocEditor("placeholder", config);
    }
  });
  
  return (
    <div>
      <div id="placeholder"></div>
    </div>
  )
}

export default InvitationForStakeholderConsultation;