import React from 'react';

import {
  // Link,
  Redirect,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

// import ISO from './ISO';
// import MSPO from './MSPO';
// import MSPO_SCCS from './MSPO_SCCS';

import Listing from './Listing';
import Assign from './Assign';
import Form from './Form';
import FormReview from './FormReview';

const CAP = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Redirect
          to={{
              pathname: `${path}/iso`,
              // state: { from: props.location }
          }}
        />
      </Route>
      <Route exact path={`${path}/:type`}>
        <Listing path={`${path}/:type`} />
      </Route>
        {/* <Route 
          path={`${path}/:type/create`} 
          render={(props) => <Form path={`${path}/:type`} {...props} />} 
        /> */}
        <Route 
          path={`${path}/:type/edit/:id/:client_id`} 
          render={(props) => <Form path={`${path}/:type`} {...props} />} 
        />
        <Route 
          path={`${path}/:type/view/:id/:client_id`} 
          render={(props) => <Form path={`${path}/:type`} readOnly={true} view={true} {...props} />} 
        />
        {/* <Route 
          path={`${path}/:type/view/:id`} 
          render={(props) => <Form path={`${path}/:type`} readOnly={true} {...props} />} 
        /> */}
        <Route 
          path={`${path}/:type/assign/:id/:client_id`} 
          render={(props) => <Assign path={path} {...props} />} 
        />
        <Route 
          path={`${path}/:type/review/:id/:client_id`} 
          render={(props) => <Form path={`${path}/:type`} readOnly={true}  review={true} {...props} />} 
        />
        {/* <Route 
          path={`${path}/:type/review2/:id`} 
          render={(props) => <FormReview path={`${path}/:type`}  readOnly={true} review2={true} {...props} />} 
        /> */}
        <Route 
          path={`${path}/:type/approve/:id/:client_id`} 
          render={(props) => <Form path={`${path}/:type`} readOnly={true} approval={true} {...props} />} 
        />
        
    </Switch>
  )
}

export default CAP;