import React from 'react';

import {
  Link,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

// import {
//   Button,
//   Card,
// } from 'reactstrap';

// import BootstrapTable from 'react-bootstrap-table-next';

import SidebarToggler from '../../../components/SidebarToggler';

import Listing from './Listing';
import Form from './Form';
// import View from './View';

const CompetencyMatrix = () => {
  let { path } = useRouteMatch();

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <h2>
              <SidebarToggler />
              Auditor Competency
            </h2>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="icon-home"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">Auditor Application</li>
              <li className="breadcrumb-item active">
                <Link to={path}>
                  Auditor Competency
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <Switch>
        <Route exact path={path}>
          <Listing />
        </Route>
        {/* <Route path={`${path}/create`}>
          <Form />
        </Route> */}
        <Route path={`${path}/edit/:id`}>
          <Form path={path} />
        </Route>
        <Route path={`${path}/view/:id`}>
          <Form path={path} />
        </Route>
      </Switch>
    </div>
  )
}

export default CompetencyMatrix;