import React from 'react';

import {
  // Link,
  Switch,
  Redirect,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import Listing from './Listing';
import FormCreate from './FormCreate';
import Form from './Form';
import View from './View';
import ViewDocument from './ViewDocument';
import FormMspo from './FormMspo';
import ViewDraftCertificate from './ViewDraftCertificate';

const AuditReport = (props) => {
  let { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          <Redirect
            to={{
                pathname: `${path}/iso/iso`
                // state: { from: props.location }
            }}
          />
        </Route>
        <Route exact path={`${path}/:type/:subtype`}>
            <Listing path={path} {...props} />
        </Route>
        <Route path={`${path}/:type/:subtype/create`}>
          <FormCreate path={`${path}/:type/:subtype`} {...props} />
        </Route>
        <Route path={`${path}/:type/:subtype/edit/:id`}>
          <Form path={`${path}/:type/:subtype`} {...props} />
        </Route>
        <Route path={`${path}/:type/:subtype/view/:id`}>
          <ViewDocument path={path} type={props.type} readOnly={true} {...props} />
        </Route>
        <Route path={`${path}/:type/:subtype/draft-confirmation/:id`}>
          <FormMspo path={`${path}/:type/:subtype`} {...props} />
        </Route>
        <Route path={`${path}/:type/:subtype/view-draft-certificate/:id`}>
          <ViewDraftCertificate path={`${path}/:type/:subtype`} {...props} />
        </Route>
      </Switch>
    </React.Fragment>
  )
}

export default AuditReport;